import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  HTMLChakraProps,
  Input,
  Link,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import axios from 'axios'

import * as React from 'react'
import { BiArrowBack, BiLeftArrowAlt, BiLeftArrowCircle, BiRightArrowAlt } from 'react-icons/bi'
//   import { PasswordField } from './PasswordField'
import { Link as RouterLink, useParams } from 'react-router-dom'
import authService from '../Auth/auth.service'
import { UnloggedLayout } from '../Components/UnloggedLayout'
import Debug from '../Forms/Debug'
import { NewPasswordForm } from '../Forms/Password/New'
import { config } from '../Utils/Config'

export const NewPasswordWrapper = (props) => {

  const { attemptId } = useParams();

  const [attempt, setAttempt] = React.useState(null);
  React.useEffect(
    () => {
      if (attempt === null) {
        checkAttempt();
      }
    }, [attempt]
  )

  const checkAttempt = () => {
    axios.get(
      `${config.apiUrl}/password-recovery/check/${attemptId}`
    ).then(
      result => setAttempt(result.data),
      error => setAttempt(false)
    );
  }
  return (
    <UnloggedLayout
      title='Set a new password'
      subTitle=' '
    >
      {attempt === null ?
        <Flex alignItems={'center'}><Spinner size="sm" mr={2} />Securing the connexion</Flex>
        : attempt === false ?
          <>
            <Alert>
              <Box>
                <Text>
                  Unfortunately, your link is invalid, so we cannot change your password at this time. There are two possible reasons: it has expired, or it is incomplete (if you copied/pasted it for example).
                  Please try again.
                </Text>
              </Box>
            </Alert>
            <Button
              mt={4}
              variant='link'
              size='sm'
              leftIcon={<BiArrowBack />}
              as={RouterLink}
              to='./../..'
            >
              Back to login page
            </Button>
          </>

          : Object.keys(attempt).length ?
            <NewPasswordForm attempt={attempt} />
            : null
      }

      {/* <Debug data={attempt} /> */}

    </UnloggedLayout >

  )
}

