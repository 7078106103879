import { Box, Flex, Image, Text } from '@chakra-ui/react'
import * as React from 'react'

import logo from '../../Images/logo/primary-blue.svg'
import goldenGlobes from '../../Images/globes/golden-globes.svg'
import { Italic } from '../../UI/Texts'

let bgImgUrl = `https://images.unsplash.com/photo-1524190952534-79b1f7d6ad5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80`;
let bgImgUrl2 = `https://images.unsplash.com/photo-1531077386034-d12285794b5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80`;

export const PublicLayoutNew = ({ children }) => {
    return (
        <Box
            position={`relative`}
            bgColor='brand.blue.900'
        >
            <Image
                position={`absolute`}
                top={0}
                left={0}
                h={`50vh`}
                w={`full`}
                objectFit={`cover`}
                src={bgImgUrl2}
                zIndex={`base`}
            />
            <Flex
                pos={`relative`}
                zIndex={`banner`}

                minH={`100vh`}

                wrap={`wrap`}
                flexDir={`column`}
            >
                <Box
                    as='header'
                    p={10}
                    w='full'
                >
                    <Flex
                        as={`a`}
                        href={`https://eabhes.eu`}
                    >
                        <Image
                            src={logo}
                            alt={`EABHES Europe`}
                            w={`240px`}
                        />
                    </Flex>
                </Box>

                <Box
                    as={`main`}
                    pt={20}
                    mx={20}
                    p={10}
                    w={`full`}
                >

                    <Flex>
                        <Box
                            bgColor={`white`}
                            maxW={`4xl`}
                            px={16}
                            py={20}

                        >
                            {children}
                        </Box>
                    </Flex>

                </Box>

                <Flex
                    as={`footer`}
                    transform={`translateY(-20px)`}
                    flex={`1 1 auto`}
                    alignItems={`flex-end`}
                    color='white'
                >
                    <Flex
                        justifyContent={`flex-end`}
                        mx={10}
                        // px={10}
                        w={`95vw`}
                        bgImage={`url(${goldenGlobes})`}
                        bgRepeat={`repeat-x`}
                        bgPos={`left bottom`}

                    // flex={`1 1 auto`}
                    >
                        <Flex
                            justifyContent={`flex-end`}
                        // pb={10}

                        >
                            <Text
                                display={`inline`}
                                textAlign={`right`}
                                fontFamily={`Georgia`}
                                fontStyle={`italic`}
                                fontSize={`xl`}
                                w='100%'
                                bgColor={`brand.blue.900`}
                            >
                                Facilitating International<br />
                                Dialogue for Quality<br />
                                Higher Education
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

            </Flex >
        </Box>
    )
}