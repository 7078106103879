import { Box, Button, Divider, Flex, FormLabel, Input, Stack, SimpleGrid, Select, FormHelperText, ButtonGroup } from "@chakra-ui/react"
import axios from "axios"
import { Formik, Form, Field } from "formik"
import * as React from "react"
import { config } from "../../Utils/Config"
import { countries } from "../../Utils/Countries"
import Debug from "../Debug"
import FieldBlock from "../FieldBlock"
import { UserFormUpdateSchema } from "./Schema"
import { useToast } from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { Wrapper } from "../../Components/Wrapper"
import { FormPhoneIntHelper } from "../../UI/FormPhoneIntHelper"
import { PhoneField } from "../PhoneField"
import { BackButton } from "../../UI/BackButton"
import { SchoolSuggestField } from "../../School/SuggestField"

export const UserUpdate = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const toast = useToast()
    const [data, setData] = React.useState();
    const [schools, setSchools] = React.useState();

    React.useEffect(() => {
        if (userId) {
            getData();
            getSchools();
        }
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/user/${userId}`)
            .then(result => setData(result.data));
    }

    const getSchools = () => {
        axios.get(`${config.apiUrl}/school/all/`)
            .then(result => setSchools(result.data));
    }



    return (
        !userId || data ?
            <Formik
                initialValues={
                    data ?
                        data
                        :
                        {}
                }
                validationSchema={UserFormUpdateSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axios.put(
                        `${config.apiUrl}/user/${userId}`,
                        values
                    ).then(() => toast({
                        title: 'User updated',
                        description: "",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    }))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched
                    /* and other goodies */
                }) => (
                    <Wrapper>

                        <Stack
                            as={Form}
                            spacing={{ base: 2, lg: 22 }}
                        >
                            <BackButton label="back" />
                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='firstName'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Firstname</FormLabel>
                                            <Input {...field} />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='lastName'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Lastname</FormLabel>
                                            <Input {...field} />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>
                            <Box>
                                <Field name='gender'>
                                    {({ field, form, meta, name }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Gender</FormLabel>
                                            <ButtonGroup
                                                size='sm'
                                            >
                                                <Button
                                                    onClick={() => setFieldValue('gender', 'f')}
                                                    colorScheme={field.value === 'f' ? 'blue' : 'gray'}
                                                >Female</Button>
                                                <Button
                                                    onClick={() => setFieldValue('gender', 'm')}
                                                    colorScheme={field.value === 'm' ? 'blue' : 'gray'}

                                                >Male</Button>
                                            </ButtonGroup>
                                        </FieldBlock>
                                    )}
                                </Field>
                            </Box>
                            <Box>
                                <Field name='email'>
                                    {({ field, form, meta, name }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Email</FormLabel>
                                            <Input {...field} />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </Box>
                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='phoneNumber'>
                                    {({ field, form, meta, name }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Phone number</FormLabel>
                                            <PhoneField
                                                value={meta.value}
                                                name={field.name}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />
                                            <FormPhoneIntHelper />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='mobilePhoneNumber'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Mobile phone number</FormLabel>
                                            <PhoneField
                                                value={meta.value}
                                                name={field.name}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>

                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='password'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Password</FormLabel>
                                            <Input {...field} type={`password`} autocomplete={`new-password`} />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='passwordConfirmation'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Password confirmation</FormLabel>
                                            <Input {...field} type={`password`} autocomplete={`new-password`} />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>

                                <Field name='status'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Status</FormLabel>
                                            <Select {...field}>
                                                <option value='created'>created</option>
                                                <option value='activated'>activated</option>
                                                <option value='archived'>archived</option>
                                            </Select>
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='role'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Role</FormLabel>
                                            <Select
                                                {...field}
                                                placeholder={`Choose`}
                                            >
                                                <option value='user'>user</option>
                                                <option value='admin'>admin</option>
                                            </Select>
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>


                            {values.role === 'user' ?
                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>


                                    <Field name='school'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>School</FormLabel>

                                                <Select {...field}>
                                                    {schools ? schools.map(item =>
                                                        <option value={item._id}>{item.name}</option>
                                                    ) : null}
                                                </Select>
                                                {/* <SchoolSuggestField
                                                    onChange={(schoolId: string) => setFieldValue(field.name, schoolId)}
                                                /> */}
                                            </FieldBlock>
                                        )}
                                    </Field>


                                    <Field name='position'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Position</FormLabel>
                                                <Input {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                </SimpleGrid>
                                : null}



                            <Divider />
                            <Flex>
                                <Button
                                    type='submit'
                                    colorScheme={
                                        Object.keys(touched).length === 0 ? 'gray' : 'blue'
                                    }
                                    isDisabled={
                                        // errors // 👈 null and undefined check
                                        Object.keys(errors).length !== 0
                                        // && Object.getPrototypeOf(errors) === Object.prototype
                                    }>
                                    Save
                                </Button>
                            </Flex>
                            <Divider />
                            <Debug data={[errors, touched, values, Object.keys(errors).length]} />

                        </Stack>
                    </Wrapper>
                )}

            </Formik>
            : null

    )
}