var lodash = require('lodash');

export function isPassingSubject(note, promoNotes, year, program) {


    // console.log('isPassingSubject', note, promoNotes, year, program);

    // console.log(note.course.passing.system);


    if (note.note === null) return false

    if (note.course.passing.system === 'passOrFail') {
        console.log('case -1')
        if (parseInt(note.note) > 0) {
            console.log('case 1')
            return true;
        } else {
            console.log('case 2')
            return false;
        }
    }
    else if (note.course.passing.system === 'fixedLimite') {

        if (parseFloat(note.note) < parseInt(program.failingNote)) return false

        if (parseFloat(note.note) >= note.course.passing.value) {
            console.log('case 1')
            return true;
        } else {
            console.log('case 2')
            return false;
        }
    } else if (note.course.passing.system === 'marginToClassAverage') {


        if (parseFloat(note.note) < parseInt(program.failingNote)) return false

        if (note.note >= note.course.passing.value) {
            console.log('case 3')
            return true;
        } else {
            console.log('case 4')
            return isPassingMarginToClassAverage(note.course.passing.ratio, note.course, note.note, promoNotes, year);
        }
    }
}

function isPassingMarginToClassAverage(margin, course, note, promoNotes, year) {
    // console.log('isPassingMarginToClassAverage', `year : ${year}`)
    // console.log(course);
    let classAverage = getSubjectClassAverageByYear(course._id, promoNotes, year);
    // console.log(`TEST classAverage`, classAverage)
    let passingLimite = classAverage * margin;
    // console.log(`TEST passingLimite`, passingLimite)
    // console.log(`TEST Note`, note)



    // console.log('TR5 passingLimite', passingLimite)
    // console.log('note', note)
    // console.log('passingLimite', passingLimite);
    if (note >= passingLimite) {
        // console.log(`TEST return`, true)
        return true;
    } else {
        // console.log(`TEST return`, false)
        return false;
    }

    // console.log('margin', margin, 'note', note, 'promoNotes', promoNotes)

}

function getSubjectClassAverageByYear(courseId, promoNotes, year) {

    // console.log('TR1', promoNotes)
    // console.log('TR2', promoNotes.map(item => item.notes))
    // console.log('TR3 course', courseId)

    // let yearSubjectNotes = promoNotes.map(item => item.notes)
    // yearSubjectNotes = lodash.flatten(yearSubjectNotes)
    //     .filter(item => item.year === year)
    //     .map(item => item.values);

    let yearSubjectNotes =
        promoNotes.filter(
            item => item.course === courseId
                && item.note > 0
        )
            .map(item => item.note)


    // console.log('TR4 yearSubjectNotes', yearSubjectNotes)



    // yearSubjectNotes = lodash.flatten(yearSubjectNotes)
    //     .filter(item => item.course.toString() === courseId.toString())
    //     .map(item => item.note);

    // console.log('TR5 yearSubjectNotes', lodash.mean(yearSubjectNotes))


    return lodash.mean(yearSubjectNotes)

}