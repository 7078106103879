import { Box, Center } from '@chakra-ui/layout'
import * as React from 'react'


export const NotFound = () => (
    <Box>
        <Center minH='100vh'>
            Not found
        </Center>
    </Box>
)