import { ColorModeScript } from "@chakra-ui/react"
import axios from "axios"
import * as React from "react"
import ReactDOM from "react-dom"
import { useNavigate } from "react-router"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import './Styles/print.css'



axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.token = localStorage.getItem('token');
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {

//   console.log('heyrror', error.response)
//   if (error.response && error.response.status === 500) {
//     console.log("heyrror EXPIRED TOKEN!");
//     localStorage.clear();
//     // const navigate = useNavigate()
//   }
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
