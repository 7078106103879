import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Box, RadioGroup, Radio, Flex, Heading, Stack, Text, FormHelperText, Textarea, Select, CheckboxGroup, Checkbox, Grid, FormLabel } from '@chakra-ui/react'
import { Field, FieldArray } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt, BiX } from 'react-icons/bi'
import { Map } from '../../../Components/Map'
import LocationField from '../../AddressField'
import Debug from '../../Debug'
import FieldBlock from '../../FieldBlock'

import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react'
import { StepTitle } from './StepTitle'
import { nationalities } from '../../../Utils/Nationalities'
import { countries } from '../../../Utils/Countries'
import { languages } from '../../../Utils/Languages'
import { AddButton } from '../../../UI/AddButton'

export const ApplicationAccredirationDetails = ({
    handleGotNext,
    id,
    setFieldValue,
    values,
    setStep,
    step,
    isStarted
}) => (
    <Stack spacing={6} id={id}>

        <StepTitle
            step={step}
            setStep={setStep}
            isStarted={isStarted}
        >
            EABHES accreditation plan
        </StepTitle>

        <Stack pl={24} spacing={8}>
            {values.diplomasOffere && values.diplomasOffere.length ?

                <FieldArray
                    name="diplomasOffered"
                    render={arrayHelpers => (
                        <FieldBlock
                            title='Which programme(s) do you plan to accredit?'
                        >
                            <Stack>
                                {values.diplomasOffered && values.diplomasOffered.length > 0 ? (
                                    values.diplomasOffered.map((item, index) => (
                                        <Flex key={index}>
                                            {/* <Debug data={item} /> */}
                                            <Field name={`diplomasOffered[${index}].accreditationDesired`}>
                                                {({ field, meta }) => (
                                                    <>
                                                        <FormLabel cursor='pointer' htmlFor={field.name + index} display={'flex'}>
                                                            <Checkbox id={field.name + index} {...field} mr={2} />
                                                            <Text>{item.level} {item.title}</Text>
                                                        </FormLabel>
                                                    </>
                                                )}
                                            </Field>
                                        </Flex>
                                    ))
                                ) : (
                                    null
                                )}
                            </Stack>
                        </FieldBlock>
                    )}
                />
                : null}


            <Field name={`eabhesReferent`}>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Who will be the EABHES referent?"
                    >
                        <Select
                            {...field}
                            placeholder='Choose'
                            w='md'
                        >
                            {values.staff.map((item, index) =>
                                <option key={`eabhesReference-${index}`}>{item.firstName} {item.lastName}</option>
                            )}
                        </Select>
                    </FieldBlock>
                )}
            </Field>




        </Stack>
    </Stack >
)