import { Box, Center, Divider, Flex, Grid, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

export const CoursesList = ({ courses, coursePlan }) => {
    const pages = [
        {
            label: 'Foreword',
            isPrimary: true,
            number: 1
        },
        {
            label: 'Identification of the School',
            isPrimary: true,
            number: 2
        },
        {
            type: 'spacer'
        },
        {
            label: 'Program presentation',
            isPrimary: true,
            number: 3
        },
        {
            label: 'Program composition',
            isPrimary: true,
            number: 4
        },
        {
            type: 'spacer'
        },
        {
            label: 'Courses list',
            isPrimary: false,
            number: 5
        },

    ]

    const Line = ({ label, number, isPrimary }) => (
        <Flex
            justify={'space-between'}
            borderBottom={`dashed 1px`}
            borderBottomColor='gray.300'
            padding={0}
        >
            <Text
                fontSize={'md'}
                fontWeight={isPrimary ? 700 : 400}
                color={isPrimary ? 'blue.800' : 'gray.500'}
                lineHeight={1}
                bgColor='white'
                transform={'translateY(0.9mm)'}
                pr={1}
            >
                {label}
            </Text>
            <Text
                fontSize={'md'}
                fontWeight={isPrimary ? 700 : 400}
                color={isPrimary ? 'blue.800' : 'gray.500'}
                lineHeight={1}
                bgColor='white'
                transform={'translateY(1mm)'}
                pl={1}

            >
                {number}
            </Text>
        </Flex>
    )
    const Spacer = () => (<Box py={'5mm'}><Divider /></Box>)
    return (
        <Box
            p={`10mm`}
        >
            <VStack
                alignItems={'flex-start'}
                spacing={8}
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    COURSES LIST
                </Text>
                <Stack
                    spacing={3}
                    color='blue.800'
                    // lineHeight={'10'}
                    w='full'
                    fontSize={'sm'}

                >
                    <Grid
                        templateColumns={
                            `30mm 1fr`
                        }
                        gap={`2mm`}
                        rowGap={`10mm`}
                    >
                        {courses.map(
                            course =>

                                <>
                                    <Box>
                                        <Text
                                            fontWeight={600}
                                            color='red.700'
                                        >
                                            {course.code}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Stack>
                                            <Text
                                                fontWeight={600}
                                            >
                                                {course.name}
                                            </Text>
                                            <Text>
                                                {course.description}
                                            </Text>
                                        </Stack>

                                    </Box>
                                </>
                        )}
                    </Grid>

                </Stack>
            </VStack >

        </Box >
    )
}