import { Box, Flex, Heading, Stack, VStack } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { Wrapper } from '../Components/Wrapper'
import { BackButton } from '../UI/BackButton'
import { UploadButton } from '../Upload/Button'
import { config } from '../Utils/Config'
import { DocumentsList } from './List'

export const DocumentPage = ({ view }) => {

    const [data, setData] = React.useState()

    React.useEffect(
        () => {
            if (!data) getData()
        }
    )

    const getData = () => {
        axios.get(
            `${config.apiUrl}/document/corporate/`
        ).then(res => setData(res.data))
    }

    return (
        <Wrapper>
            <Box>
                {data && data.length ?
                    <Stack
                        spacing={6}
                    >
                        <Flex justifyContent={'space-between'}>
                            <BackButton
                                label='Back'
                            />
                        </Flex>

                        <Heading>
                            Documents sharing
                        </Heading>

                        <Stack spacing={2}>
                            <Heading mb={2} fontSize={'md'}>EABHES Documents and Forms</Heading>
                            <DocumentsList
                                data={data.filter(item => (item.Key.startsWith(`corporate/documents`) && item.Key.indexOf('.') > -1))}
                                deleteCallback={() => getData()}
                                deleteAllowed={view !== 'user'}

                            />
                            {view !== 'user' ?
                                <UploadButton
                                    uploadCallback={() => getData()}
                                    linkedDocumentId={`corporate/documents`}
                                    isPublic
                                />
                                : null}
                        </Stack>

                        <Stack spacing={2}>
                            <Heading mb={2} fontSize={'md'}>EABHES Communiqués</Heading>
                            <DocumentsList
                                data={data.filter(item => (item.Key.startsWith(`corporate/community`) && item.Key.indexOf('.') > -1))}
                                deleteCallback={() => getData()}
                                deleteAllowed={view !== 'user'}
                            />
                            {view !== 'user' ?
                                <UploadButton
                                    uploadCallback={() => getData()}
                                    linkedDocumentId={`corporate/community`}
                                    isPublic
                                />
                                : null}
                        </Stack>

                        <Stack spacing={2}>
                            <Heading mb={2} fontSize={'md'}>EABHES logos & charts</Heading>
                            <DocumentsList
                                data={data.filter(item => (item.Key.startsWith(`corporate/images`) && item.Key.indexOf('.') > -1))}
                                deleteCallback={() => getData()}
                                deleteAllowed={view !== 'user'}
                            />
                            {view !== 'user' ?
                                <UploadButton
                                    uploadCallback={() => getData()}
                                    linkedDocumentId={`corporate/images`}
                                    isPublic
                                />
                                : null}
                        </Stack>

                    </Stack>
                    : null}
            </Box>
        </Wrapper>
    )
}