import { Box, Button, ButtonGroup, Flex, FormControl, FormLabel, Grid, Heading, Input, Select, SimpleGrid, Stack, Table, Tag, Tbody, Td, Tr } from '@chakra-ui/react'
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { BiSave, BiX } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';
import { InvoiceDeleteButton } from './InvoiceDeleteButton';
import { InvoiceUploadButton } from './InvoiceUpload';
import { SchoolVerticalNav } from './VerticalNav';
import { periodOptions } from './invoiceOptionsList';

export const InvoiceUpdateForm = ({ data, schoolId, onSave, handleClose, deleteButton }) => {


    // React.useEffect(() => {
    //     getData();
    //     getInvoices();
    // }, []);

    // const getData = () => {
    //     axios.get(`${config.apiUrl}/school/${schoolId}`)
    //         .then(result => setData(result.data));
    // }
    // const getInvoices = () => {
    //     axios.get(`${config.apiUrl}/invoice/school/${schoolId}`)
    //         .then(result => setInvoices(result.data));
    // }

    const updateInvoice = (values) => {
        axios.put(
            `${config.apiUrl}/invoice/${data._id}`,
            values
        ).then(res => onSave())
    }

    return (
        <Formik
            initialValues={{
                ...data,
                issueDate: data.issueDate ? data.issueDate.slice(0, 10) : ``,
                paymentDate: data.paymentDate ? data.paymentDate.slice(0, 10) : ``
            }}
            onSubmit={(values, actions) => {
                updateInvoice(values);
                handleClose();
                // actions.resetForm({
                //     values: {
                //         title: '',
                //         number: '',
                //         file: '',
                //         status: '',
                //         amount: '',
                //         issueDate: '',
                //         paymentDate: '',
                //     }
                // });
            }}
        >
            {({
                values,
                setFieldValue
            }) => (
                <Form>
                    <Stack
                        spacing={6}
                    >
                        {/* <Debug data={data} /> */}
                        {/* <Debug data={values} /> */}
                        <SimpleGrid
                            columns={2}
                            gap={5}
                        >
                            <Field name="title">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Title</FormLabel>
                                        <Input bgColor={`white`} {...field} />
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="number">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Number</FormLabel>
                                        <Input bgColor={`white`} {...field} />
                                    </FormControl>
                                )}

                            </Field>
                        </SimpleGrid>
                        <SimpleGrid
                            columns={4}
                            gap={5}
                        >


                            <Field name="status">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Status</FormLabel>
                                        <Select
                                            {...field}
                                            placeholder={`Choose a status`}
                                            bgColor={`white`}
                                        >
                                            <option>unpaid</option>
                                            <option>paid</option>
                                        </Select>
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="amount">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Amount</FormLabel>
                                        <Input bgColor={`white`} {...field} />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="period">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Period</FormLabel>
                                        <Select placeholder="select" {...field}>
                                            {periodOptions()}
                                        </Select>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="issueDate">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Issue date</FormLabel>
                                        <Input bgColor={`white`} type={`date`} {...field} />
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="paymentDate">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Payment date</FormLabel>
                                        <Input bgColor={`white`} type={`date`} {...field} />
                                    </FormControl>
                                )}

                            </Field>

                        </SimpleGrid>

                        <Box>
                            <Field name="file">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>File</FormLabel>
                                        {values.file ?
                                            // <Tag>{values.file}</Tag>
                                            // <InvoiceDeleteButton />
                                            <InvoiceDeleteButton
                                                deleteCallback={(value) => setFieldValue(field.name, ``)}
                                                value={field.value}
                                                schoolId={schoolId}
                                            />
                                            :
                                            <InvoiceUploadButton
                                                linkedDocumentId={schoolId}
                                                uploadCallback={(value) => setFieldValue(field.name, value)}
                                            />
                                        }
                                    </FormControl>
                                )}
                            </Field>
                        </Box>

                        <ButtonGroup
                            size='sm'
                        >
                            <Button
                                type={`submit`}
                                colorScheme={`blue`}
                                leftIcon={<BiSave />}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => handleClose()}
                                leftIcon={<BiX />}
                            >
                                Close
                            </Button>
                            {deleteButton}
                        </ButtonGroup>

                        {/* <Debug data={values} /> */}
                    </Stack>
                </Form>
            )}

        </Formik >
    )
}