
import { Box, Button, Center, Flex, Grid, VStack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as React from 'react'
import { useParams, useNavigate } from 'react-router'
import Debug from '../../Debug';

import { animateScroll as scroll } from 'react-scroll'
import { Step } from './Step';
import { Summary } from './Summary';
import { ApplicationGeneralInformations } from './GeneralInformations';
import { ApplicationBoard } from './Board';
import { SaveIndicator } from './SaveIndicator';
import axios from 'axios';
import { config } from '../../../Utils/Config';
import { ApplicationAcademics } from './Academics';
import { ApplicationInternationalStrategy } from './InternationalStrategy';
import pixel from '../../../Images/pixel.svg'
import { schoolApplicationSchema } from './Schema';
import { ApplicationAccredirationDetails } from './ApplicationAccredirationDetails';

export const ApplicationForm = () => {
    const navigate = useNavigate();
    const { schoolId } = useParams();
    // const user = authService.getCurrentUser();
    const [isStarted, setStarted] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [needSave, setNeedSave] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [savedAt, setSavedAt] = React.useState(0);
    const [isSubmited, setSubmited] = React.useState(false);
    const [data, setData] = React.useState(0);
    const applicationForm = React.useRef();

    React.useEffect(async () => {
        const result = await axios(
            `${config.apiUrl}/school/${schoolId}`,
        );
        // console.log(result.data);
        if (['created', 'updated'].indexOf(result.data.status) === -1) {
            navigate('./thankyou')
        }
        setData(result.data);
    }, []);


    React.useEffect(() => {
        const interval = setInterval(() => {
            // console.log('This will run every 3 second!');
            if (needSave === true) {
                saveFormValues();
                setNeedSave(false);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [needSave]);

    React.useEffect(() => {
        const onScroll = e => {
            setStarted(true);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const saveFormValues = () => {
        if (isSubmited) { return }

        setIsSaving(true);
        let values = applicationForm.current.values;
        delete values.status;
        values.status = 'updated';

        axios.patch(
            `${config.apiUrl}/school/${schoolId}`, applicationForm.current.values
        ).then(result => {
            setSavedAt(Date.now())
            setTimeout(
                () => setIsSaving(false),
                1000
            )
        });

    }

    return (
        <>
            {data ?
                <Formik
                    validationSchema={schoolApplicationSchema}
                    initialValues={{
                        ...data,
                        haveALocalAccreditation: data.localAccreditation ? "true" : "false"
                    }}
                    onSubmit={(values) => {
                        // console.log("submit");
                        axios.post(
                            `${config.apiUrl}/school/${schoolId}/submit`
                        ).then(
                            () => {
                                setSubmited(true);
                                navigate('./thankyou')
                            }

                        );
                    }
                    }
                    validate={(values) => {

                        // console.log( values, values );
                        // console.log('validate');
                        setNeedSave(true);
                        setStarted(true);
                    }}
                    innerRef={applicationForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched
                        /* and other goodies */
                    }) => (

                        <Grid
                            templateColumns='300px 1fr'
                        >
                            <Box pt={48}>
                                <Box
                                    pos='sticky'
                                    top={10}
                                >
                                    <Flex
                                        wrap='wrap'
                                        h='100vh'
                                        justifyContent='space-between'
                                    >
                                        <Box w='full' h='80vh'>
                                            <Summary
                                                isActive={true}
                                                step={step}
                                                handleChangeStep={
                                                    (value) => {
                                                        scroll.scrollTo(`step${step}`,
                                                            {
                                                                duration: 1500,
                                                                delay: 100,
                                                                smooth: true,

                                                                offset: -50,
                                                            }
                                                        );
                                                    }
                                                }
                                            />
                                        </Box>
                                        <Box w='full'>
                                            <SaveIndicator isSaving={isSaving} savedAt={savedAt} />
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                            <Box>
                                <Form>
                                    <VStack spacing={64}
                                        pt={48}
                                        bgImage={`url(${pixel})`}
                                        bgRepeat='repeat-y'
                                        bgPos='100px'
                                    >


                                        <>
                                            <Center minH='100vh' minW='100%'>
                                                <Step name="step0" isActive={true}>
                                                    <ApplicationGeneralInformations
                                                        id='intro'
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                        values={values}
                                                        setStep={(value) => setStep(value)}
                                                        step={0}
                                                        isStarted={isStarted}
                                                    />
                                                </Step>
                                            </Center>

                                            <Center minH='100vh' minW='100%'>
                                                <Step name="step1" isActive={true}>
                                                    <ApplicationBoard
                                                        id='board'
                                                        handleGotNext={() => setStep(step + 1)}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                        setStep={(value) => setStep(value)}
                                                        step={1}
                                                        isStarted={isStarted}


                                                    />
                                                </Step>
                                            </Center>

                                            <Center minH='100vh' minW='100%'>
                                                <Step name="step2" isActive={true}>
                                                    <ApplicationAcademics
                                                        id='academics'
                                                        handleGotNext={() => setStep(step + 1)}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                        setStep={(value) => setStep(value)}
                                                        step={2}
                                                        isStarted={isStarted}


                                                    />
                                                </Step>
                                            </Center>

                                            <Center minH='100vh' minW='100%'>
                                                <Step name="step3" isActive={true}>
                                                    <ApplicationInternationalStrategy
                                                        id='internationalStrategy'
                                                        handleGotNext={() => setStep(step + 1)}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                        setStep={(value) => setStep(value)}
                                                        step={3}
                                                        isStarted={isStarted}

                                                    />
                                                </Step>
                                            </Center>

                                            <Center minH='0' minW='100%'>
                                                <Step name="step4" isActive={true}>
                                                    <ApplicationAccredirationDetails
                                                        id='accreditationDetails'
                                                        handleGotNext={() => setStep(step + 1)}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                        setStep={(value) => setStep(value)}
                                                        step={4}
                                                        isStarted={isStarted}

                                                    />
                                                </Step>
                                            </Center>
                                        </>
                                    </VStack>
                                    <Center py={12}>
                                        <Box>
                                            <Center>
                                                <Button
                                                    colorScheme='blue'
                                                    onClick={() => handleSubmit()}
                                                    isDisabled={
                                                        Object.keys(errors).length > 0
                                                    }
                                                >
                                                    Submit your school application
                                                </Button>
                                            </Center>
                                            {Object.keys(errors).length >= 1 ?
                                                <Text
                                                    fontSize='sm'
                                                    mt={4}
                                                    color='blackAlpha.700'
                                                    textAlign='center'>You should fill all the required fields before submit.</Text>
                                                : null}
                                        </Box>
                                    </Center>

                                    {/* <Debug data={[values]} /> */}

                                </Form>
                            </Box>
                        </Grid>


                    )}


                </Formik>
                : null}
        </>
    )
}