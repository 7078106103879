import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Stack,
  Table,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

import * as React from "react";
import { BiAddToQueue, BiExport, BiExtension } from "react-icons/bi";
import Debug from "../../Forms/Debug";
import { CoursePlansAddButton } from "./CoursePlansAddButton";
import { CoursePlansUpdate } from "./CoursePlansUpdate";
import { config } from "../../Utils/Config";

export const CoursePlansList = ({ data, updateData }) => {
  const [seeAll, setSeeAll] = React.useState(false);

  const CoursePlanStatudBadge = ({ children }) => (
    <Badge
      size={"sm"}
      colorScheme={children === "active" ? "green" : "black"}
      textDecoration={children === "active" ? "none" : "line-through"}
    >
      {children}
    </Badge>
  );

  const Line = ({ item }) => {
    const [formIsOpen, setFormIsOpen] = React.useState(false);
    return (
      <Tr
        fontSize="sm"
        opacity={item.status === "active" ? 1 : 0.4}
        // textDecor={item.status === "active" ? `` : `line-through`}
      >
        <Td>{item.name}</Td>
        <Td>
          <Link
            target="_blank"
            display={"flex"}
            alignItems={"center"}
            textDecor={"underline"}
            href={`https://ps.eabhes.eu/${item?.program}/${item?._id}`}
          >
            <Box mr={1}>Open</Box>
            <BiExport />
          </Link>
        </Td>
        <Td>
          <CoursePlanStatudBadge>{item.status}</CoursePlanStatudBadge>
        </Td>
        <Td textAlign={"right"}>
          <HStack justify={`flex-end`} spacing={2}>
            <Button
              // variant={'button'}
              leftIcon={<BiExport />}
              size="xs"
              as={`a`}
              target={`_blank`}
              href={`${config.apiUrl}/course-plan/${item._id}/export/`}
            >
              CSV
            </Button>
            <Popover
              isOpen={formIsOpen}
              onClose={() => setFormIsOpen(!formIsOpen)}
              placement="left"
            >
              <PopoverTrigger>
                <Button
                  variant={"link"}
                  size="sm"
                  onClick={() => setFormIsOpen(!formIsOpen)}
                >
                  Edit
                </Button>
              </PopoverTrigger>
              <PopoverContent textAlign={"left"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Update course plan</PopoverHeader>
                <PopoverBody>
                  <CoursePlansUpdate
                    coursePlanId={item._id}
                    handleSubmit={() => {
                      setFormIsOpen(!formIsOpen);
                      updateData();
                    }}
                    handleClose={() => setFormIsOpen(!formIsOpen)}
                  />

                  <Link
                    as={RouterLink}
                    to={`/admin/program/course-plan/${item._id}/update`}
                  >
                    Edit courses liste
                  </Link>

                  {/* <CoursePlansNew
                                        handleSubmit={handleSubmit}
                                        handleClose={() => setOpen(false)}
                                        programId={programId}
                                    /> */}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <Table spacing={2}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Programme Spec</Th>
            {/* <Th>Name</Th> */}
            {/* <Th>Local name</Th> */}
            <Th>Status</Th>
            <Th textAlign={"right"}>Action</Th>
          </Tr>
        </Thead>
        {data
          .filter((item) => item.status !== "archived" || seeAll)
          .map((item) => (
            <Line item={item} />
          ))}
      </Table>
      {data.filter((item) => item.status === "archived").length > 0 && (
        <Center py={2}>
          <Button size="xs" onClick={() => setSeeAll(!seeAll)}>
            {!seeAll ? `See` : `Hide`} archived
          </Button>
        </Center>
      )}
      {/* <Debug data={data} /> */}
    </>
  );
};
