import { Box, Button, Portal, SimpleGrid, Flex, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import Debug from '../Forms/Debug'
import { arrayIntRange } from '../Utils/arrayIntRange'
let lodash = require('lodash')
export const YearCoursesStats = ({ year, courses }) => {

    const getCourseLength = (course) => {
        let courseLength = arrayIntRange(course.startSemester, course.endSemester).length;
        return courseLength
    }

    let yearSemestersList = [year * 2 - 1, year * 2]

    const calculatedValue = (course, key) => {
        // let courseLength = getCourseLength(course);
        // return course[key] / courseLength;
        // console.log(course.semesters[semester])

        // semester == 3
        // course.startSemester == 3
        // => course.semesters[0]

        // semester == 4
        // course.startSemester == 3
        // => course.semesters[1]

        return (
            course.semesters[semester - course.startSemester] && course.semesters[semester - course.startSemester][key]
                ? course.semesters[semester - course.startSemester][key]
                : '-'
        )
    }

    const totalSemesterValueSum = (key) => {

        let ectsList = []

        for (let x = 0; x < courses.length; x++) {
            const course = courses[x];

            for (let y = 0; y < course.semesters.length; y++) {
                const semester = course.semesters[y];
                let realSemester = course.startSemester + y;
                if (yearSemestersList.includes(realSemester)) {
                    ectsList.push(semester[key])
                }
            }

        }
        console.log('ectsList', ectsList)

        return lodash.sum(ectsList);

    }



    return (
        <Box
            p={8}
            border='solid 1px'
            borderColor={'gray.100'}

        >
            <Text textDecor={'underline'} mb={2} fontSize={'md'}>Year : {year} → Semesters : {yearSemestersList.join(' & ')}</Text>

            <Table
                variant={'striped'}
            >
                <Tbody>
                    <Tr>
                        <Td>
                            Course work load :
                        </Td>
                        <Td isNumeric>
                            <strong>{totalSemesterValueSum('hours')}h</strong>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            Personal work load :
                        </Td>
                        <Td isNumeric>
                            <strong>{totalSemesterValueSum('personalWorkLoad')}h</strong>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            Total work load :
                        </Td>
                        <Td isNumeric>
                            <strong>{totalSemesterValueSum('hours') + totalSemesterValueSum('personalWorkLoad')}h</strong>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            Total ECTS :
                        </Td>
                        <Td isNumeric>
                            <strong>{totalSemesterValueSum('ectsCredits')}</strong>
                        </Td>
                    </Tr>

                </Tbody>
            </Table>
        </Box>
    )
}