import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { AccountSwitcherButton } from './AccountSwitcherButton'
import { Link } from 'react-router-dom'
import { UserContext } from '../../Auth/userContext'
import ReactTimeAgo from 'react-time-ago'

export const AccountSwitcher = () => {

  const { user } = React.useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        <Text fontWeight="medium" mb="2">
          {user ? user.email : ''}
          <Text fontSize={'xs'} as='span' color='gray.500' fontWeight={'light'}>
            <br />
            Session expiration <ReactTimeAgo date={parseInt(localStorage.getItem('expireTime') * 1000)} locale="en-US" />
          </Text>
        </Text>
        {/* <MenuOptionGroup defaultValue="chakra-ui">
          <MenuItemOption value="chakra-ui" fontWeight="semibold" rounded="md">
            Chakra UI
          </MenuItemOption>
          <MenuItemOption value="careerlyft" fontWeight="semibold" rounded="md">
            CareerLyft
          </MenuItemOption>
        </MenuOptionGroup> */}
        <MenuDivider />
        <MenuItem rounded="md" as={Link} to={`/admin/user/edit/${user ? user._id : ''}`}>Settings</MenuItem>
        {/* <MenuItem rounded="md">Add an account</MenuItem> */}
        <MenuDivider />
        <MenuItem rounded="md" onClick={
          () => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            document.cookie = 'EABHES_token=';
            navigate('/login');
          }
        }>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
