import * as Yup from 'yup';
import axios from 'axios'
import { config } from '../../Utils/Config';

export const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const UserFormSchema = Yup.object().shape({
    role:
        Yup.string()
            .oneOf(['user', 'admin'])
            .required('Required'),
    firstName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    lastName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    email: Yup.string().email('Invalid email').required('This field is required')
        .test('Unique Email', 'Your email already exists in our database',
            function (value) {
                return new Promise((resolve, reject) => {
                    axios.post(`${config.apiUrl}/user/already-exists`, { 'email': value })
                        .then(res => { if (res.data === 'already_exists') { resolve(false) } resolve(true) })
                })
            }
        ),
    phoneNumber:
        Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
    // .required('Required')
    ,
    mobilePhoneNumber:
        Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
    // .required('Required')
    ,
    password:
        Yup.string()
            // .required('Champ obligatoire')
            .min(6, '6 caractères mini svp')
            .nullable(),
    passwordConfirmation:
        Yup.string()
            // .required('Password is required please')
            .oneOf([Yup.ref('password'), null], 'It\'s not same :/')
});

export { UserFormSchema }

export const UserFormUpdateSchema = Yup.object().shape({
    role:
        Yup.string()
            .oneOf(['user', 'admin'])
            .required('Required'),
    firstName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    lastName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    email: Yup.string().email('Invalid email').required('This field is required'),
    phoneNumber:
        Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
    // .required('Required')
    ,
    mobilePhoneNumber:
        Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
    // .required('Required')
    ,
    password:
        Yup.string()
            // .required('Champ obligatoire')
            .min(6, '6 caractères mini svp')
            .nullable(),
    passwordConfirmation:
        Yup.string()
            // .required('Password is required please')
            .oneOf([Yup.ref('password'), null], 'It\'s not same :/')
});