import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Box, Flex, Heading, Stack, Text, SimpleGrid, FormHelperText, Select } from '@chakra-ui/react'
import { Field, FieldArray } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Map } from '../../../Components/Map'
import LocationField from '../../AddressField'
import Debug from '../../Debug'
import FieldBlock from '../../FieldBlock'

import { AddButton } from '../../../UI/AddButton';
import { RemoveButton } from '../../../UI/RemoveButton';
import { BackButton } from '../../../UI/BackButton';

import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react'
import { StepTitle } from './StepTitle'

export const ApplicationBoard = ({
    handleGotNext,
    id,
    setFieldValue,
    values,
    setStep,
    step,
    isStarted
}) => (
    <Stack spacing={6} id={id}>
        <StepTitle
            step={step}
            setStep={setStep}
            isStarted={isStarted}
        >
            Overseeing board
        </StepTitle>

        <FieldArray
            name="staff"
            render={arrayHelpers => (
                <FieldBlock
                    title={`Can you list here the members of your school's board`}
                >
                    <Stack>
                        {values.staff && values.staff.length > 0 ? (
                            values.staff.map((item, index) => (
                                <Flex key={index}>
                                    <Field name={`staff.${index}.firstName`}>
                                        {({ field, meta }) => (
                                            <Input mr={2} {...field} placeholder='First name' />
                                        )}
                                    </Field>
                                    <Field name={`staff.${index}.lastName`}>
                                        {({ field, meta }) => (
                                            <Input mr={2} {...field} placeholder='Last name' />
                                        )}
                                    </Field>
                                    <Field name={`staff.${index}.position`}>
                                        {({ field, meta }) => (
                                            <Select
                                                mr={2}
                                                {...field}
                                                placeholder='Position'
                                            >
                                                <option>Dean</option>
                                                <option>Academic Director</option>
                                                <option>International Responsible</option>
                                            </Select>
                                        )}
                                    </Field>
                                    <Field name={`staff.${index}.email`}>
                                        {({ field, meta }) => (
                                            <Input mr={2} {...field} placeholder='Email' />
                                        )}
                                    </Field>
                                    <RemoveButton
                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    />
                                </Flex>
                            ))
                        ) : (
                            null
                        )}
                        <Box>
                            <AddButton
                                onClick={() => arrayHelpers.push('')}
                            />
                        </Box>

                    </Stack>
                </FieldBlock>
            )}
        />

        {/* <Box>
            <Button onClick={() => handleGotNext(3)}>Next</Button>
        </Box> */}

    </Stack>
)