import { Box } from '@chakra-ui/layout'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import * as React from 'react'

interface fieldBlockProps {
    children: any,
    title?: string,
    isInvalid?: boolean,
    error?: string,
    isRequired?: boolean,
}

const FieldBlock: React.FC<fieldBlockProps> = (props) => {
    const {
        children,
        isInvalid,
        error,
        isRequired,
        title,
        size
    } = props
    return (
        <Box>
            <FormControl
                isInvalid={isInvalid}
                isRequired={isRequired}
            >
                {title ? <FormLabel fontSize={size === 'sm' ? 'sm' : 'normal'}>{title}</FormLabel> : null}
                {children}
                <FormErrorMessage>
                    {error}
                </FormErrorMessage>
            </FormControl>
        </Box>
    )
}

export default FieldBlock