import { Center, Flex, Text, Spinner } from '@chakra-ui/react'
import * as React from 'react'

interface loaderProps {
    label?: string
}

export const Loader: React.FC<loaderProps> = ({ label }) => (

    <Center py={4}>
        <Flex
            alignItems={'center'}
        >
            <Spinner size='xs' mr={label ? 2 : 0} />
            {label ?
                <Text fontSize={'sm'} letterSpacing={'wide'} color={'gray.600'}>{label}</Text>
                : null}
        </Flex>
    </Center>
)