import {
  Link as ChakraLink,
  Box,
  Button,
  Divider,
  Heading,
  Flex,
  FormLabel,
  Input,
  Stack,
  Select,
  Text,
  SimpleGrid,
  HStack,
  ButtonGroup,
  useToast,
  Grid,
  FormHelperText,
  Tag,
  Alert,
  RequiredIndicator,
  Textarea,
} from "@chakra-ui/react";
import * as React from "react";
import { BiArrowBack, BiX } from "react-icons/bi";
import { Wrapper } from "../Components/Wrapper";
import { Link, useNavigate } from "react-router-dom";
import { Field, FieldArray, Form, Formik } from "formik";
import FieldBlock from "../Forms/FieldBlock";
import axios from "axios";
import { config } from "../Utils/Config";
import { useParams } from "react-router";
import Debug from "../Forms/Debug";
import { AddButton } from "../UI/AddButton";
import { SingleInscriptionNotes } from "./Notes";
import { DeleteButton } from "../UI/DeleteButton";
import { DocumentsList } from "../Document/List";
import { UploadButton } from "../Upload/Button";
import { inscriptionSchema } from "./inscriptionSchema";
import { objectKeys } from "@chakra-ui/utils";

export const InscriptionUpdate = (props) => {
  const { view, context } = props;
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();

  const [showNotes, setShowNotes] = React.useState(false);

  let { handleClose } = props ? props : params;

  let inscriptionId = props.inscriptionId
    ? props.inscriptionId
    : params
    ? params.inscriptionId
    : null;

  const [data, setData] = React.useState();
  const [school, setSchool] = React.useState();
  const [coursePlans, setCoursePlans] = React.useState();

  React.useEffect(() => {
    fetchInscriptionData();
  }, [inscriptionId]);

  const getCoursePlans = (programId) => {
    console.log("course plan", programId);

    axios
      .get(`${config.apiUrl}/course-plan/program/${programId}`)
      .then((res) => setCoursePlans(res.data));
  };

  const fetchInscriptionData = async () => {
    const inscriptionData = await axios.get(
      `${config.apiUrl}/inscription/${inscriptionId}`
    );
    setData(inscriptionData.data);
    getCoursePlans(inscriptionData.data.program._id);

    const schoolData = await axios.get(
      `${config.apiUrl}/school/${inscriptionData.data.school._id}`
    );
    setSchool(schoolData.data);
    // setData(inscriptionData.res)
  };

  const handleUpdate = (values) => {
    let valuesForBackend = values;

    delete valuesForBackend.student;
    delete valuesForBackend.school;
    delete valuesForBackend.notes;

    delete valuesForBackend["student"];
    delete valuesForBackend["school"];
    delete valuesForBackend["notes"];

    // console.log("valuesForBackend", valuesForBackend);

    axios
      .put(`${config.apiUrl}/inscription/${inscriptionId}`, valuesForBackend)
      .then((res) => {
        toast({
          title: "Inscription updated",
          description: "Your changes have been recorded in database.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const yearsList = () => {
    let optionsList = [];
    for (
      let index = 1;
      index <= Math.ceil(data.program.semestersNumber / 2);
      index++
    ) {
      optionsList.push(<option value={index}>{`Year ${index}`}</option>);
    }
    return optionsList;
  };

  const handleDelete = () => {
    axios
      .delete(`${config.apiUrl}/inscription/${inscriptionId}`)
      .then((res) =>
        navigate(
          view === "user" ? "/account/inscription" : "/admin/inscription"
        )
      );
  };

  const backButtonProps = () =>
    context === "modal"
      ? {
          onClick: handleClose,
        }
      : {
          as: Link,
          to: `/${view === `admin` ? `admin` : `account`}/inscription`,
        };

  const isFltDoc = (str) => {
    const pattern = new RegExp("TOEIC|FLT|IELTS");
    if (pattern.test(str)) {
      // console.log('Matched');
      return true;
    } else {
      // console.log('Not Matched');
      return false;
    }
  };

  return data ? (
    <Wrapper>
      <Stack spacing={{ base: 2, lg: 22 }}>
        {/* <Debug data={data} /> */}
        <Flex justify={"space-between"} display={!view ? "none" : "flex"}>
          <Button
            leftIcon={<BiArrowBack />}
            // as={Link}
            // to='/admin/inscription'

            size="sm"
            {...backButtonProps()}
          >
            Back
          </Button>
          <ButtonGroup size={"sm"}>
            <Button
              as={Link}
              target="_blank"
              to={`/admin/student/update/${data.student._id}`}
            >
              Student form
            </Button>
            <Button
              as={Link}
              target="_blank"
              to={`/inscription/${inscriptionId}/registration-certificate/export`}
            >
              Registration certificate
            </Button>
            <Button
              as={Link}
              target="_blank"
              to={`/inscription/${inscriptionId}/transcript/export`}
            >
              Transcript
            </Button>
            <Button
              as={Link}
              target="_blank"
              to={`/inscription/${inscriptionId}/diploma/export`}
            >
              Diploma
            </Button>
          </ButtonGroup>
        </Flex>

        <Divider />

        <Heading fontSize={"lg"}>
          {`Inscription of ${
            data.student.firstName
          } ${data.student.lastName.toUpperCase()} for ${data.program.name}`}
        </Heading>

        <Box display={data.ectsCreditsObtained > 0 ? `block` : `none`}>
          <Tag colorScheme={`blue`}>
            ECTS credits obtained : {data.ectsCreditsObtained}
          </Tag>
        </Box>

        <Formik
          key={data}
          initialValues={{
            ...data,
            program: data.program._id,
            coursePlan: data.coursePlan._id,
            foreignLanguageTest: {
              ...data.foreignLanguageTest,
              date: data.foreignLanguageTest.date
                ? data.foreignLanguageTest.date.slice(0, 10)
                : "",
            },
            graduationDate: data.graduationDate
              ? data.graduationDate.slice(0, 10)
              : null,
          }}
          validationSchema={inscriptionSchema}
          onSubmit={(values) => handleUpdate(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Stack as={Form} spacing={{ base: 2, lg: 10 }}>
              <SimpleGrid columns={3} gap={2}>
                <Field name="status">
                  {({ field, form, meta }) => (
                    <FieldBlock
                      isInvalid={meta.error && meta.touched}
                      error={meta.error}
                    >
                      <FormLabel>Status</FormLabel>
                      <Select {...field} placeholder="Select">
                        <option>current</option>
                        <option>incomplete</option>
                        <option>finalized</option>
                        <option>completed</option>
                        <option>archived</option>
                      </Select>
                    </FieldBlock>
                  )}
                </Field>

                <Field name="program">
                  {({ field, form, meta }) => (
                    <FieldBlock
                      isInvalid={meta.error && meta.touched}
                      error={meta.error}
                    >
                      {/* <Debug data={field} /> */}
                      <FormLabel>Programme</FormLabel>
                      <Select
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue(field.name, e.target.value, true);
                          setFieldValue(`coursePlan`, ``, true);

                          getCoursePlans(e.target.value);
                        }}
                        onBlur={field.onBlur}
                        value={field.value}
                        // {...field}
                        placeholder="Select"
                        disabled={data.notes.length}
                      >
                        {school
                          ? school.programs.map((program) => (
                              <option key={program._id} value={program._id}>
                                {program.name}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FieldBlock>
                  )}
                </Field>

                <Field name="coursePlan">
                  {({ field, form, meta }) => (
                    <FieldBlock
                      isInvalid={meta.error && meta.touched}
                      error={meta.error}
                    >
                      <FormLabel>Course plan</FormLabel>
                      <Select {...field} placeholder="Select">
                        {coursePlans && coursePlans.length
                          ? coursePlans.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FieldBlock>
                  )}
                </Field>
              </SimpleGrid>
              <Divider />

              <SimpleGrid columns={2} gap={10}>
                <Field name="startDate">
                  {({ field, meta }) => (
                    <FieldBlock
                      title="Start date"
                      isRequired={true}
                      isInvalid={meta.touched && meta.error}
                      error={meta.error}
                    >
                      <Input {...field} type="month" />
                      <FormHelperText>
                        Expected format YYYY-MM ex : 2021-09
                      </FormHelperText>
                    </FieldBlock>
                  )}
                </Field>
                <Field name="endDate">
                  {({ field, meta }) => (
                    <FieldBlock
                      title="End date"
                      isRequired={true}
                      isInvalid={meta.touched && meta.error}
                      error={meta.error}
                    >
                      <Input {...field} type="month" />
                      <FormHelperText>
                        Expected format YYYY-MM ex : 2021-09
                      </FormHelperText>
                    </FieldBlock>
                  )}
                </Field>
              </SimpleGrid>

              {/* <Debug data={values.foreignLanguageTest.obtention} /> */}

              {data.program.foreignLanguageTest.isRequired ? (
                <Stack spacing={5}>
                  <Divider />

                  <FormLabel>Foreign language test :</FormLabel>

                  <Field name={"foreignLanguageTest.isExempted"}>
                    {({ field, form, meta }) => (
                      <Box>
                        <Stack isInline alignItems={`center`}>
                          <Text>Is exempted ?</Text>
                          <ButtonGroup size={`sm`}>
                            <Button
                              colorScheme={field.value === true ? `blue` : null}
                              onClick={() => {
                                setFieldValue(field.name, true);
                                setFieldValue(
                                  `foreignLanguageTest.obtention`,
                                  true
                                );
                                setFieldValue(`foreignLanguageTest.type`, ``);
                                setFieldValue(`foreignLanguageTest.value`, ``);
                                setFieldValue(`foreignLanguageTest.date`, ``);
                                setFieldValue(
                                  `foreignLanguageTest.description`,
                                  ``
                                );
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              colorScheme={
                                field.value === false ? `blue` : null
                              }
                              onClick={() => {
                                setFieldValue(field.name, false);
                              }}
                            >
                              No
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      </Box>
                    )}
                  </Field>

                  {values.foreignLanguageTest.isExempted === false ? (
                    <SimpleGrid columns={5} gap={2}>
                      <Field name={"foreignLanguageTest.obtention"}>
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Obtention"
                          >
                            <Select {...field} placeholder="Select">
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Select>
                          </FieldBlock>
                        )}
                      </Field>
                      {values.foreignLanguageTest.obtention &&
                      values.foreignLanguageTest.obtention !== "false" ? (
                        <>
                          <Field name={"foreignLanguageTest.type"}>
                            {({ field, form, meta }) => (
                              <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Type"
                              >
                                <Select {...field} placeholder="Select">
                                  <option>Cambridge</option>
                                  <option>IELTS</option>
                                  <option>TOEFL</option>
                                  <option>TOEIC</option>
                                  {/* <option>GESE</option> */}
                                  <option>Other</option>
                                </Select>
                              </FieldBlock>
                            )}
                          </Field>
                          <Field name={"foreignLanguageTest.value"}>
                            {({ field, form, meta }) => (
                              <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Score / Value"
                              >
                                <Input {...field} placeHolder={`800...`} />
                              </FieldBlock>
                            )}
                          </Field>
                          <Field name={"foreignLanguageTest.level"}>
                            {({ field, form, meta }) => (
                              <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Level"
                              >
                                <Input {...field} placeHolder={`C1, B2,...`} />
                              </FieldBlock>
                            )}
                          </Field>
                          <Field name={"foreignLanguageTest.date"}>
                            {({ field, form, meta }) => (
                              <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Date"
                              >
                                <Input type="date" {...field} />
                              </FieldBlock>
                            )}
                          </Field>
                          {values.foreignLanguageTest.type &&
                          values.foreignLanguageTest.type.toLowerCase() ===
                            "other" ? (
                            <Field name={"foreignLanguageTest.description"}>
                              {({ field, form, meta }) => (
                                <FieldBlock
                                  isInvalid={meta.error && meta.touched}
                                  error={meta.error}
                                  title="Description"
                                >
                                  <Input {...field} />
                                </FieldBlock>
                              )}
                            </Field>
                          ) : null}
                        </>
                      ) : null}
                    </SimpleGrid>
                  ) : null}

                  {values.foreignLanguageTest.obtention === true ||
                  values.foreignLanguageTest.obtention === "true" ? (
                    <>
                      <Heading fontWeight={`500`} fontSize={`md`}>
                        Foreign language test results document :
                      </Heading>
                      <UploadButton
                        linkedDocumentId={inscriptionId}
                        uploadCallback={() => fetchInscriptionData()}
                        isPublic={true}
                        align={`left`}
                      />
                      <DocumentsList
                        data={data.documents.filter((file) =>
                          isFltDoc(file.Key)
                        )}
                        deleteAllowed={true}
                        deleteCallback={() => fetchInscriptionData()}
                      />
                      {/* <Debug data={data.documents.filter(file => isFltDoc(file.Key))} /> */}
                    </>
                  ) : null}
                </Stack>
              ) : null}

              <Stack spacing={4}>
                {data.program.isInitialCreditTransferRequired ? (
                  <Field name={"creditTransfertValid"}>
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Is credits transfer valid"
                      >
                        <ButtonGroup size={`sm`}>
                          <Button
                            colorScheme={
                              field.value == true ? `blue` : undefined
                            }
                            onClick={() => setFieldValue(field.name, true)}
                          >
                            Yes
                          </Button>
                          <Button
                            colorScheme={
                              field.value != true ? `blue` : undefined
                            }
                            onClick={() => setFieldValue(field.name, false)}
                          >
                            No
                          </Button>
                        </ButtonGroup>
                        <FormHelperText>
                          As this program requires a credit transfer, we need to
                          know if the transfer has been justified.
                        </FormHelperText>
                      </FieldBlock>
                    )}
                  </Field>
                ) : null}

                {/* <Debug data={data.program} /> */}

                <Divider />

                <FieldArray
                  name="initialCredits"
                  render={(arrayHelpers) => (
                    <>
                      <FormLabel>Credit transfer</FormLabel>

                      {values.initialCredits
                        // .filter(course => !course.isCourseRecognition)
                        .map((item, index) =>
                          !item.isCourseRecognition ? (
                            <Flex key={`initialCredits-${index}`}>
                              <Field
                                name={`initialCredits.${index}.ectsCredits`}
                              >
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    w={"100px"}
                                    mr={2}
                                    {...field}
                                    placeholder="ECTS"
                                  />
                                )}
                              </Field>
                              <Field name={`initialCredits.${index}.usCredits`}>
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    w={"100px"}
                                    mr={2}
                                    {...field}
                                    placeholder="US"
                                  />
                                )}
                              </Field>
                              <Field
                                name={`initialCredits.${index}.description`}
                              >
                                {({ field, meta }) => (
                                  <Input
                                    disabled={
                                      false
                                      // !values.initialCredits[index].ectsCredits
                                      // || values.initialCredits[index].ectsCredits === ``
                                    }
                                    isInvalid={meta.touched && meta.error}
                                    mr={2}
                                    {...field}
                                    placeholder="Description"
                                  />
                                )}
                              </Field>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <BiX size={"30px"} />
                              </Button>
                            </Flex>
                          ) : null
                        )}
                      <Box>
                        <AddButton
                          onClick={() =>
                            arrayHelpers.push({ isCourseRecognition: false })
                          }
                        />
                      </Box>
                    </>
                  )}
                />

                {errors.initialCredits && touched.initialCredits ? (
                  <Alert>
                    Some errors or missing values in initial credits will cause
                    errors when saving this curriculum.
                  </Alert>
                ) : null}

                <Divider />

                <FieldArray
                  name="initialCredits"
                  render={(arrayHelpers) => (
                    <>
                      <FormLabel>
                        Credit recognition (Recognized courses)
                      </FormLabel>

                      {values.initialCredits
                        // .filter(course => course.isCourseRecognition)
                        .map((item, index) =>
                          item.isCourseRecognition ? (
                            <Flex key={`initialCredits-${index}`}>
                              <Field name={`initialCredits.${index}.code`}>
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    w={"120px"}
                                    mr={2}
                                    {...field}
                                    placeholder="Code"
                                  />
                                )}
                              </Field>
                              <Field
                                name={`initialCredits.${index}.ectsCredits`}
                              >
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    w={"100px"}
                                    mr={2}
                                    {...field}
                                    placeholder="ECTS"
                                  />
                                )}
                              </Field>
                              <Field name={`initialCredits.${index}.usCredits`}>
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    w={"100px"}
                                    mr={2}
                                    {...field}
                                    placeholder="US"
                                  />
                                )}
                              </Field>
                              <Field
                                name={`initialCredits.${index}.description`}
                              >
                                {({ field, meta }) => (
                                  <Input
                                    isInvalid={meta.touched && meta.error}
                                    mr={2}
                                    {...field}
                                    placeholder="Description"
                                  />
                                )}
                              </Field>
                              <Field name={`initialCredits.${index}.year`}>
                                {({ field, meta }) => (
                                  <Select
                                    {...field}
                                    mr={2}
                                    w={"240px"}
                                    isInvalid={meta.touched && meta.error}
                                    placeholder="Select"
                                  >
                                    {yearsList()}
                                  </Select>
                                )}
                              </Field>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <BiX size={"30px"} />
                              </Button>
                            </Flex>
                          ) : null
                        )}
                      <Box>
                        <AddButton
                          onClick={() =>
                            arrayHelpers.push({ isCourseRecognition: true })
                          }
                        />
                      </Box>
                    </>
                  )}
                />

                {errors.initialCredits && touched.initialCredits ? (
                  <Alert>
                    Some errors or missing values in initial credits will cause
                    errors when saving this curriculum.
                  </Alert>
                ) : null}

                <Divider />

                <FieldArray
                  name="additionnalCredits"
                  render={(arrayHelpers) => (
                    <>
                      <FormLabel>
                        Additional credits (all fields are required)
                      </FormLabel>

                      {/* <Debug data={errors.initialCredits} /> */}

                      {values.additionnalCredits.map((item, index) => (
                        <Box key={`additionnal-Credits-${index}`}>
                          <Flex>
                            <Box>
                              <Grid
                                gridTemplateColumns={`20% 20% 30% 1fr`}
                                gap={2}
                              >
                                <Field
                                  name={`additionnalCredits.${index}.ectsCredits`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"full"}
                                      {...field}
                                      placeholder="ECTS"
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`additionnalCredits.${index}.usCredits`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"full"}
                                      {...field}
                                      placeholder="US"
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`additionnalCredits.${index}.description`}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      placeholder="Description"
                                      isInvalid={meta.touched && meta.error}
                                    >
                                      <option value="Language and Culture">
                                        Language and Culture
                                      </option>
                                      <option value="School and Community Life">
                                        School and Community Life
                                      </option>
                                      <option value="External Projects">
                                        External Projects
                                      </option>
                                      <option value="Volunteering">
                                        Volunteering
                                      </option>
                                      <option value="Sports Activities">
                                        Sports Activities
                                      </option>
                                      <option value="Additional Professional Experience">
                                        Additional Professional Experience
                                      </option>
                                      <option value="Extra course">
                                        Extra course (course not present in
                                        programme)
                                      </option>
                                    </Select>
                                  )}
                                </Field>

                                <Field
                                  name={`additionnalCredits.${index}.year`}
                                >
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      isInvalid={meta.touched && meta.error}
                                      placeholder="Select"
                                    >
                                      {yearsList()}
                                    </Select>
                                  )}
                                </Field>
                              </Grid>
                              <Grid
                                mt={3}
                                gridTemplateColumns={`60% minmax(0,1fr)`}
                                gap={2}
                              >
                                <Field
                                  name={`additionnalCredits.${index}.details`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"full"}
                                      {...field}
                                      placeholder="Some details"
                                      // maxLength={40}
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`additionnalCredits.${index}.note`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"full"}
                                      {...field}
                                      placeholder="Note"
                                      // maxLength={40}
                                    />
                                  )}
                                </Field>
                              </Grid>
                            </Box>
                            <Button
                              ml={2}
                              h="auto"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              <BiX size={"15px"} />
                            </Button>
                          </Flex>
                        </Box>
                      ))}
                      <Box>
                        <AddButton onClick={() => arrayHelpers.push("")} />
                      </Box>
                    </>
                  )}
                />

                {errors.additionnalCredits && touched.additionnalCredits ? (
                  <Alert>
                    Some errors or missing values in additionnal credits will
                    cause errors when saving this curriculum.
                  </Alert>
                ) : null}

                <Divider />

                <FieldArray
                  name="finalCredits"
                  render={(arrayHelpers) => (
                    <>
                      <FormLabel>Final credits</FormLabel>

                      {values.finalCredits.map((item, index) => (
                        <React.Fragment key={`finalCredits-${index}`}>
                          {index > 0 ? <Divider /> : null}

                          <Flex>
                            <Box>
                              <Grid
                                gridTemplateColumns={`15% 10% 10% 20% 20% 1fr`}
                                rowGap={2}
                                gap={2}
                                w={`full`}
                              >
                                <Field name={`finalCredits.${index}.code`}>
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"auto"}
                                      {...field}
                                      placeholder="Code"
                                      maxLength={12}
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`finalCredits.${index}.ectsCredits`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"auto"}
                                      {...field}
                                      placeholder="ECTS"
                                    />
                                  )}
                                </Field>
                                <Field name={`finalCredits.${index}.usCredits`}>
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"auto"}
                                      {...field}
                                      placeholder="US"
                                    />
                                  )}
                                </Field>
                                <Field name={`finalCredits.${index}.note`}>
                                  {({ field, meta }) => (
                                    <Input
                                      isInvalid={meta.touched && meta.error}
                                      w={"auto"}
                                      {...field}
                                      placeholder="Local grade"
                                    />
                                  )}
                                </Field>
                                <Field
                                  title="grade"
                                  name={`finalCredits.${index}.grade`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      {...field}
                                      isInvalid={meta.touched && meta.error}
                                      textTransform={`uppercase`}
                                      w={"auto"}
                                      placeholder="ECTS Grade"
                                    />
                                  )}
                                </Field>
                                <Field name={`finalCredits.${index}.year`}>
                                  {({ field, meta }) => (
                                    <Select
                                      {...field}
                                      isInvalid={meta.touched && meta.error}
                                      w="auto"
                                      placeholder="Select"
                                    >
                                      {yearsList()}
                                    </Select>
                                  )}
                                </Field>
                              </Grid>
                              <Box mt={3}>
                                <Field
                                  name={`finalCredits.${index}.description`}
                                >
                                  {({ field, meta }) => (
                                    <Input
                                      {...field}
                                      isInvalid={meta.touched && meta.error}
                                      placeholder="Description"
                                    />
                                  )}
                                </Field>
                              </Box>
                            </Box>
                            <Button
                              type="button"
                              ml={2}
                              h="auto"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              <BiX size={"30px"} />
                            </Button>
                          </Flex>
                        </React.Fragment>
                      ))}
                      <Box>
                        <AddButton onClick={() => arrayHelpers.push("")} />
                      </Box>
                    </>
                  )}
                />
                {errors.finalCredits && touched.finalCredits ? (
                  <Alert>
                    Some errors or missing values in additionnal credits will
                    cause errors when saving this curriculum.
                  </Alert>
                ) : null}
                <Divider />
              </Stack>
              <Field name={`graduationDate`}>
                {({ field, meta }) => (
                  <FieldBlock title="Graduation date">
                    <Input
                      {...field}
                      type="date"
                      w={"sm"}
                      mr={2}
                      placeholder="Ects"
                    />
                  </FieldBlock>
                )}
              </Field>

              <Field name={`comment`}>
                {({ field, meta }) => (
                  <FieldBlock title="Comment">
                    <Textarea placeholder="General comment" {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Divider />
              {/* <Debug data={values} /> */}
              {/* <Debug data={errors} /> */}
              {/* <Debug data={objectKeys(errors).length > 0} /> */}
              <ButtonGroup>
                <Button
                  type="submit"
                  isDisabled={objectKeys(errors).length > 0}
                  colorScheme={
                    objectKeys(touched).length >= 1 &&
                    objectKeys(errors).length === 0
                      ? `blue`
                      : undefined
                  }
                >
                  Save
                </Button>
                <Button
                  variant={"link"}
                  // onClick={() => console.log('click')}
                  onClick={handleClose}
                >
                  or close
                </Button>
              </ButtonGroup>
            </Stack>
          )}
        </Formik>

        <Divider />

        <DocumentsList
          data={data.documents}
          deleteAllowed={true}
          deleteCallback={() => fetchInscriptionData()}
        />
        <UploadButton
          linkedDocumentId={inscriptionId}
          uploadCallback={() => fetchInscriptionData()}
          isPublic={true}
        />
        <Divider />

        <Stack spacing={4}>
          <Flex justify={"flex-start"}>
            <Button
              onClick={() => setShowNotes(!showNotes)}
              variant={"link"}
              size="xs"
            >
              Show notes form
            </Button>
          </Flex>

          {showNotes ? <SingleInscriptionNotes data={data} /> : null}
          {view === "admin" ? (
            <DeleteButton handleClick={() => handleDelete()} />
          ) : // <Stack pt={8} spacing={4}>
          //     <Divider />
          //     <Flex spacing={0}>
          //         <Text bgColor='gray.100' p={1} pr={4} borderLeftRadius={'md'}>Danger zone : </Text>
          //         <Button
          //             size='sm'
          //             colorScheme='red'
          //             ml={'-2'}
          //             onClick={() => handleDelete()}
          //         >Delete inscription</Button>
          //     </Flex>
          // </Stack>
          null}
        </Stack>
      </Stack>
    </Wrapper>
  ) : null;
};
