import { arrayIntRange } from "./arrayIntRange";
let lodash = require('lodash');

export const creditsFromYear = (course: any, year: number, key) => {

    let yearSemestersList = [2 * year - 1, 2 * year];
    let courseSemestersList = arrayIntRange(course.startSemester, course.endSemester);
    let commonSemesters = lodash.intersection(yearSemestersList, courseSemestersList);

    let creditsSum = 0;
    for (let index = 0; index < commonSemesters.length; index++) {
        const semester = commonSemesters[index];
        let semesterCredits = course.semesters[semester - course.startSemester][key];
        creditsSum = creditsSum + semesterCredits;
    }
    // console.log('creditsSum', creditsSum)

    return creditsSum;
}