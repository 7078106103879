import { Box, Button, Divider, Flex, FormLabel, Heading, Input, SimpleGrid, Stack } from '@chakra-ui/react'
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as React from 'react'
import { BiArrowBack, BiCheckCircle } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../../Components/Wrapper';
import { config } from '../../Utils/Config';
import FieldBlock from '../FieldBlock';
import * as yup from 'yup';
import { useToast } from '@chakra-ui/react'
import { PhoneField } from '../PhoneField';

export const SelfUpdate = () => {

    const toast = useToast();
    const { userId } = useParams();
    const [data, setData] = React.useState();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/user/${userId}`)
            .then(result => setData(result.data));
    }

    const validationSchema = yup.object().shape({
        firstName: yup.string().required('This field is required'),
        lastName: yup.string().required('This field is required'),
        email: yup.string().email('Invalid email').required('This field is required')
            .test('Unique Email', 'Your email already exists in our database',
                function (value) {
                    return new Promise((resolve, reject) => {
                        axios.post(`${config.apiUrl}/user/already-exists/${data._id}`, { 'email': value })
                            .then(res => { if (res.data === 'already_exists') { resolve(false) } resolve(true) })
                    })
                }
            ),
        position: yup.string().required('Field required'),
    })


    return (

        <Wrapper>
            <>
                {data ?
                    <Formik
                        initialValues={data ? data : { ...data, program: programId }}
                        onSubmit={(values, { setSubmitting }) => {
                            axios.put(
                                `${config.apiUrl}/user/${data._id}`,
                                values
                            ).then(() => toast({
                                title: 'User updated',
                                description: `Your account has been updated.`,
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            }))
                            navigate('./..')
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched
                            /* and other goodies */
                        }) => (
                            <Stack
                                as={Form}
                                // onSubmit={handleSubmit}
                                spacing={{ base: 2, lg: 22 }}
                            >

                                <Box>
                                    <Button
                                        //as={Link}
                                        size='sm'
                                        onClick={() => window.history.back()}
                                    >
                                        <BiArrowBack />
                                    </Button>
                                </Box>


                                <Heading>
                                    Your profile
                                </Heading>



                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field name='firstName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={true}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Firstname</FormLabel>
                                                <Input
                                                    {...field}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    <Field name='lastName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={true}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Last name</FormLabel>
                                                <Input
                                                    {...field}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </SimpleGrid>

                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field name='email'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={true}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    {...field}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    <Field name='position'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={true}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Position</FormLabel>
                                                <Input
                                                    {...field}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </SimpleGrid>

                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field name='phoneNumber'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={true}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Phone</FormLabel>
                                                <PhoneField
                                                    value={meta.value}
                                                    name={field.name}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    <Field name='mobilePhoneNumber'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isRequired={false}
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Mobile phone</FormLabel>
                                                <PhoneField
                                                    value={meta.value}
                                                    name={field.name}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </SimpleGrid>

                                {/* <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field name='position'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                            >
                                                <FormLabel>Position</FormLabel>
                                                <Input
                                                    {...field}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>


                                </SimpleGrid> */}

                                <Divider />
                                <Flex>
                                    <Button
                                        leftIcon={<BiCheckCircle />}
                                        type='submit'
                                        colorScheme={values !== data ? 'green' : 'gray'}
                                        isDisabled={
                                            // errors // 👈 null and undefined check
                                            Object.keys(errors).length !== 0
                                            // && Object.getPrototypeOf(errors) === Object.prototype
                                        }>
                                        Save
                                    </Button>
                                </Flex>

                            </Stack>
                        )}
                    </Formik>
                    : null}
            </>
        </Wrapper>
    )
}