import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiSave } from 'react-icons/bi'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'

TimeAgo.addDefaultLocale(en)

export const SaveIndicator = ({ isSaving, savedAt }) => {
    return (

        isSaving ?
            <Flex alignItems='center'>
                <Spinner size='xs' mr={2} colorScheme='gray' />
                <Text fontSize='sm' color='gray.500'>Saving</Text>
            </Flex>
            :
            <Flex>
                <Center mr={2} color='gray.500'>
                    <BiSave />
                </Center>

                <Text fontSize='sm' color='gray.500'>
                    {savedAt === 0
                        ?
                        <>Not saved yet</>
                        :
                        <>Saved <ReactTimeAgo date={savedAt} locale="en-US" /></>
                    }
                </Text>
            </Flex>
    )
}