import { Button } from '@chakra-ui/react'
import * as React from 'react'

export const PrimaryButton = ({ children, ...rest }) => {
    return (
        <Button
            bg={`brand.blue.900`}
            _hover={{
                bg: `brand.blue.700`
            }}
            color={`white`}
            {...rest}
        >
            {children}
        </Button>
    )
}