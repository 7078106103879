import * as React from 'react'

import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
} from '@chakra-ui/react'

import { BsSearch } from 'react-icons/bs'
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Field, Form, Formik, FieldProps } from 'formik'
import Debug from '../Forms/Debug'
import { config } from '../Utils/Config'
import { BiDownload } from 'react-icons/bi'

export const TableActions = ({ getValues, schoolFilters }) => {
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values) => console.log(values)}
            validate={(values) => getValues(values)}
        >
            {({ form, values, erros }) => (

                <Stack as={Form} spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <HStack>
                        <Field name='search'>
                            {({ field }) => (
                                <FormControl minW={{ md: '200px' }} id="search">
                                    <InputGroup size="sm">
                                        <FormLabel srOnly>Filter by name</FormLabel>
                                        <InputLeftElement pointerEvents="none" color="gray.400">
                                            <BsSearch />
                                        </InputLeftElement>
                                        <Input {...field} rounded="base" type="search" placeholder="Filter by name..." bgColor='white' />
                                    </InputGroup>
                                </FormControl>
                            )}

                        </Field>
                        <Field name='role'>
                            {({ field }) => (
                                <Select {...field} w={{ base: '300px', md: '200px' }} rounded="base" size="sm" placeholder="All roles">
                                    {/* <option>All roles</option> */}
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </Select>)}
                        </Field>
                        <Field name='status'>
                            {({ field }) => (
                                <Select {...field} w={{ base: '300px', md: '200px' }} rounded="base" size="sm" placeholder="All status">

                                    <option value="imported">Imported</option>
                                    <option value="created">Created</option>
                                    <option value="activated">Activated</option>
                                    <option value="archived">Archived</option>
                                </Select>)}
                        </Field>
                        <Field name='school'>
                            {({ field }) => (
                                <Select {...field} w={{ base: '300px', md: '200px' }} rounded="base" size="sm" placeholder="All schools">
                                    {/* <option>All schools</option> */}
                                    {schoolFilters && schoolFilters.map(item =>
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    )}
                                </Select>)}
                        </Field>
                    </HStack>
                    <ButtonGroup size="sm" variant="outline">
                        <Button
                            iconSpacing="1"
                            leftIcon={<BiDownload fontSize="1.25em" />}
                            as={'a'}
                            href={`${config.apiUrl}/user/all/export/`}
                        >
                            Export
                        </Button>
                        <Button
                            iconSpacing="1"
                            leftIcon={<RiAddFill fontSize="1.25em" />}
                            as={Link}
                            to='/admin/user/new'
                        >
                            New user
                        </Button>
                        {/* <Button iconSpacing="1" leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}>
                    Export CSV
                </Button> */}
                    </ButtonGroup>
                    {/* <Debug data={[values]} /> */}
                </Stack>
            )}
        </Formik>
    )
}
