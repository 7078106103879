import { Box, Flex, Image } from '@chakra-ui/react'
import * as React from 'react'
import { config } from '../../Utils/Config'

export const ProfilPict = ({ filePath, size }) => {

    // return null
    return (

        filePath ?
            <Flex
                flexShrink={0}
            >
                <Box
                    borderRadius={'full'}
                    bgColor='gray.300'
                    overflow={'hidden'}
                    w={size ? `${size}` : '50px'}
                    h={size ? `${size}` : '50px'}
                >
                    <Image
                        src={`${config.apiUrl}/document/${filePath}`}
                        alt='Profile pict'
                        w='50px'
                    />
                </Box>
            </Flex>
            : null
    )
}