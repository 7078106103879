import { Box, Circle, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import {
  BiBuoy,
  BiHome,
  BiUserCircle,
  BiCodeBlock,
  BiNotepad,
  BiGroup,
  BiBriefcaseAlt2,
  BiBookBookmark,
  BiBox,
  BiPyramid,
  BiBookmarks,
  BiBook
} from 'react-icons/bi'

import { Outlet } from 'react-router'
import { AccountSwitcher } from './AccountSwitcher'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'

export const AdminLayout = ({ children }) => {
  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" id="app-container">
        <Box w="64" bg="gray.900" color="white" fontSize="sm">
          <Flex h="full" direction="column" px="4" py="4">
            <AccountSwitcher />
            <Stack spacing="8" flex="1" overflow="auto" pt="8">
              <Stack spacing="1">
                {/* <NavItem active icon={<BiHome />} label="Dashboard" to='/admin' /> */}
                {/* <NavItem icon={<BiCommentAdd />} label="Inbox" /> */}
              </Stack>
              <NavGroup label="Accreditation">
                <NavItem icon={<BiBriefcaseAlt2 />} label="Schools" to='/admin/school' />
                <NavItem icon={<BiBookBookmark />} label="Programmes" to='/admin/program' />
                <NavItem icon={<BiBox />} label="Courses" to='/admin/course' />
                {/* <NavItem icon={<BiWallet />} label="Income" /> */}
                {/* <NavItem icon={<BiRedo />} label="Transfer" /> */}
              </NavGroup>

              <NavGroup label="Production">
                <NavItem icon={<BiGroup />} label="Students" to='/admin/student' />
                <NavItem icon={<BiPyramid />} label="Curriculum" to='/admin/inscription' />
                {/* <NavItem icon={<BiNotepad />} label="Notes" to='/admin/note' /> */}
                {/* <NavItem icon={<BiBookmarks />} label="Diplomas" to='/' /> */}
                {/* <NavItem icon={<BiPurchaseTagAlt />} label="Plans" /> */}
                {/* <NavItem icon={<BiRecycle />} label="Subscription" /> */}
              </NavGroup>
            </Stack>
            <Box>
              <Stack spacing="1">
                <NavItem
                  subtle
                  icon={<BiBook />} label="Members area" to='/admin/documents' />
                <NavItem subtle icon={<BiUserCircle />} label="Users" to='/admin/user' />
                {/* <NavItem subtle icon={<BiCodeBlock />} label="Logs" to='/admin/logs' /> */}
                <NavItem
                  subtle
                  icon={<BiCodeBlock />}
                  label="Logs"
                  // to='/admin/logs'
                  href={`https://logtail.com/team/57517/tail`}
                  target='_blank'
                />
                <NavItem
                  subtle
                  icon={<BiBuoy />}
                  label="Help & Support"
                  endElement={<Circle size="2" bg="blue.400" />}
                  href={'https://www.notion.so/olivierlivet/636fe7e7825743d098a182e4c2c72052?v=e7b7055e2e58462ebcf6a90b099e98a1'}
                  target='_blank'
                />
              </Stack>
            </Box>
          </Flex>
        </Box>
        <Box id='content' bg={mode('gray.50', 'gray.800')} flex="1" p="6" overflow='scroll'>
          <Box
            w="full"
            h="full"
            rounded="lg"
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
