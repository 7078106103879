import { Box, Button, Heading, Input, Stack, Tag, Text } from '@chakra-ui/react'
import axios from 'axios'
import { Formik } from 'formik'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Wrapper } from '../../Components/Wrapper'
import { TitlePage } from '../../Program/CoursesCatalogue/TitlePage'
import { BackButton } from '../../UI/BackButton'
import { config } from '../../Utils/Config'
import Debug from '../Debug'

export const CourseImportInProgram = () => {

    const { programId } = useParams();

    // console.log(programId)

    const [data, setData] = React.useState();
    const [hasFile, setHasFile] = React.useState(false);
    const [file, setFile] = React.useState(false);

    let input = React.useRef();

    let navigate = useNavigate();


    React.useEffect(
        () => {
            if (!data) {
                axios.get(
                    `${config.apiUrl}/program/${programId}`
                ).then(
                    res => setData(res.data)
                )
            }

        }
    )

    const uploadFile = file => {

        var formData = new FormData();
        // var imagefile = document.querySelector('#file');
        formData.append("file", file);
        console.log(file);
        // formData.append("convertEncoding", convertEncoding);
        axios.post(
            `${config.apiUrl}/course/import/program/${programId}`
            , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(async res => {

            console.log('res', res)
            navigate(`/admin/program/view/${data._id}/${data.primaryCoursePlan}`)
        })

    }

    const onSubmit = (e) => {
        e.preventDefault();
        uploadFile(file)
    }

    return (
        <Wrapper>

            {data ?
                <Stack
                    spacing={4}
                >
                    <BackButton label={`Back`} />

                    {/* <Debug data={data} /> */}

                    <Heading>
                        Course importation
                    </Heading>

                    <Text>
                        You are about to import a list of courses in program{` `}
                        <Tag>{data.name}</Tag>
                    </Text>

                    <form
                        onSubmit={(e) => onSubmit(e)}
                    >
                        <Box mb={8}>
                            <input
                                type='file'
                                name='file'

                                ref={input}

                                onChange={(e) => {


                                    const file = e.target.files[0];

                                    if (file) {
                                        setHasFile(true)
                                        setFile(file)
                                    }
                                    // console.log(file)
                                    // uploadFile(file);
                                    // readFile(file);
                                }}
                            />
                        </Box>
                        <Button
                            isDisabled={!hasFile}
                            colorScheme={hasFile ? `blue` : undefined}
                            type={`submit`}
                        >
                            Import
                        </Button>
                    </form>

                </Stack>
                : null
            }
        </Wrapper >

    )
}