import {
    Stack,
    Text
} from '@chakra-ui/react'
import * as React from 'react'

export const DiplomaLegal = () => {
    return (
        <Stack

            fontFamily={'Georgia'}
            fontSize='8.5px'
            fontStyle='italic'
            mb={`5mm`}
            spacing={`1mm`}
            color='brand.blue.900'
            transform={`translate(-10mm)`}
        >
            <Text>
                This certificate of accredited studies is issued by EABHES on behalf of the School, and attests ECTS allocation in accordance with the Joint Declaration adopted by European Ministers of Education in Bologna on 19 June 1999 and subsequent intergovernmental acts (collectively, the “Bologna process”) aiming at facilitating the mutual recognition of titles granted within the European Higher Education Area.
            </Text>
            <Text>
                This certificate is printed in blue color on security paper, and features EABHES embossed seal. Authenticity test: when held against a source of light, EABHES distinctive marks printed in the background of the page shall become visible; when photocopied, a latent statement ‘this is copy’ appears on the left side of the page.
            </Text>
        </Stack>
    )
}


