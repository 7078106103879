import * as React from 'react'
import { Box, Button, FormHelperText, Input, Select, SimpleGrid, Stack } from "@chakra-ui/react"
import { Wrapper } from "../Components/Wrapper"
import { BiArrowBack } from "react-icons/bi"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { Field, Form, Formik } from "formik"
import FieldBlock from "../Forms/FieldBlock"
import { ProgramsSuggestField } from "../Program/SuggestField"
import axios from "axios"
import { config } from "../Utils/Config"
import Debug from '../Forms/Debug'
import { PrimaryButton } from '../UI/PrimaryButton'

export const NewInscriptionStudent = (props) => {
    // const { view, context } = props
    const params = useParams();
    const navigate = useNavigate();


    const [programId, setProgramId] = React.useState('');
    const [programData, setProgramData] = React.useState('');
    const [coursePlanId, setCoursePlanId] = React.useState('');


    React.useEffect(() => {
        getData();
    }, [programId]);

    const getData = async () => {
        // axios.get(`${config.apiUrl}/program/`)
        //     .then(result => setPrograms(result.data));

        if (programId) {
            // axios.get(`${config.apiUrl}/program/${programId}`)
            //     .then(result => setProgramData(result.data));

            const programCoursePlans = await axios.get(`${config.apiUrl}/program/${programId}`);
            setProgramData(programCoursePlans.data);

            if (programCoursePlans.data.coursePlans.length > 1) {
                // setProgramData(programCoursePlans.data);
            } else {
                setCoursePlanId(programCoursePlans.data.coursePlans[0]._id);
            }
            // .then(result => setProgramData(result.data));
        }
    }

    const handleCreate = (values: any) => {
        console.log('proceed creation', values);
        axios.post(
            `${config.apiUrl}/inscription/student/${params.studentId}`,
            values
        ).then(response => navigate(`/admin/inscription/update/${response.data._id}`))
    }

    return (
        <Wrapper>
            <Stack spacing={6}>
                <Box>
                    <Button
                        leftIcon={<BiArrowBack />}
                        as={Link}
                        to={`/admin/student/view/${params.studentId}`}

                        size='sm'

                    >
                        Back
                    </Button>
                </Box>

                <Stack>
                    <SimpleGrid columns={2} gap={4}>
                        <FieldBlock
                            title='Involved programme'
                            isRequired={true}
                        >
                            <ProgramsSuggestField
                                onChange={(programId: string) => setProgramId(programId)}
                                schoolId={null}
                                value={programId}
                            />
                        </FieldBlock>
                        {programId && programData ?
                            <FieldBlock
                                title='Course plan'
                                isRequired={true}
                            >
                                <Select
                                    placeholder='Select the course plan'
                                    onChange={(e) => setCoursePlanId(e.target.value)}
                                >
                                    {programData.coursePlans.map(item =>
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    )}
                                </Select>
                            </FieldBlock>
                            : null}
                    </SimpleGrid>
                </Stack>
                {programId && coursePlanId ?
                    <Formik
                        initialValues={{
                            programId: programId,
                            coursePlanId: coursePlanId
                        }}
                        onSubmit={(values) => handleCreate(values)}

                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched
                        }) => (
                            <Form>
                                <Stack spacing={2}>
                                    <SimpleGrid columns={2} gap={3}>
                                        {coursePlanId && programId ?
                                            <>
                                                <Field name="startDate">
                                                    {({ field, meta }) => (
                                                        <FieldBlock
                                                            title='Start date'
                                                            isRequired={true}
                                                            isInvalid={meta.touched && meta.error}
                                                            error={meta.error}
                                                        >
                                                            <Input
                                                                {...field}
                                                                type='month'
                                                            />
                                                            <FormHelperText>
                                                                Expected format YYYY-MM ex : 2021-09
                                                            </FormHelperText>
                                                        </FieldBlock>
                                                    )}
                                                </Field>

                                                <Field name="endDate">
                                                    {({ field, meta }) => (
                                                        <FieldBlock
                                                            title='End date'
                                                            isRequired={true}
                                                            isInvalid={meta.touched && meta.error}
                                                            error={meta.error}
                                                        >
                                                            <Input
                                                                {...field}
                                                                type='month'
                                                            />
                                                            <FormHelperText>
                                                                Expected format YYYY-MM ex : 2022-06
                                                            </FormHelperText>
                                                        </FieldBlock>

                                                    )}
                                                </Field>
                                            </>
                                            : null}
                                    </SimpleGrid>
                                    <Box>
                                        <PrimaryButton
                                            type="submit"
                                        >Save inscription</PrimaryButton>
                                    </Box>
                                    {/* <Debug data={values} /> */}
                                </Stack>
                            </Form>
                        )}

                    </Formik>
                    : null}

            </Stack>
        </Wrapper>
    )
}