import axios from 'axios';
import * as React from 'react'
import { createContext, useState } from "react";
import { config } from '../Utils/Config';
// import AuthService from '../../services/auth.service';

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem('user'))
    );
    const [authed, setAuthed] = useState(true);

    React.useEffect(
        () => {
            if (user) {
                try {
                    setInterval(async () => {
                        try {
                            const res = await axios.get(
                                `${config.apiUrl}/user/check-auth`
                            );
                            const checkAuth = await res.data;
                            if (checkAuth && checkAuth !== authed) { setAuthed(checkAuth) }
                        } catch (error) {
                            localStorage.removeItem('user');
                            localStorage.removeItem('token');
                            window.location = '/login';
                        }

                        // console.log(checkAuth)
                        // if (user !== updatedUser) {
                        //     setUser(updatedUser);
                        // }
                        // else {
                        //     console.log('else');
                        // }
                    }, 30000);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [user]
    )

    const logout = () => {
        localStorage.removeItem('user')
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                authed
            }}
        >
            {children}
        </UserContext.Provider>
    );
};