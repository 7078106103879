import { Box, Flex, Button } from '@chakra-ui/react'
import * as React from 'react'
import { BiDownArrowAlt } from 'react-icons/bi'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export const Step = ({ name, children, isActive, handleIsVisible }) => {
    const step = parseInt(name.match(/\d+/)[0]);
    const nextStep = `step${(parseInt(name.match(/\d+/)[0]) + 1)}`;
    return (
        <Box
            w='full'

        >
            <Box
                as={Element}
                name={name}

                bgColor='white'
                borderRadius={'sm'}



                opacity={isActive ? 1 : 0}
                transition='opacity 200ms ease-in-out'

                boxShadow='var(--shadow-elevation-medium)'

            >
                <Box p={10}>
                    {children}
                </Box>


            </Box>
            <Flex
                justify='flex-start'
            // p={10}
            >
                <Flex
                    display={step < 3 ? 'flex' : 'none'}
                    ml={10}
                    fontSize='10px'
                    letterSpacing='widest'
                    p={1}
                    px={3}
                    textTransform='uppercase'
                    fontWeight='600'
                    // transform='translate3d(0px, 0px, 0)'
                    mt={10}
                    boxShadow='md'
                    alignItems='center'

                    cursor='pointer'
                    aria-label='Next step'

                    as={Link}
                    to={nextStep}
                    smooth={true}
                    duration={1000}
                    offset={-50}


                    borderRadius='3xl'
                    bg='gray.100'
                    color='blue.900'
                    border='solid 1px'
                    borderColor='gray.500'

                    _hover={{
                        bgColor: 'blue.900',
                        color: 'white'
                    }}



                >
                    Next step
                    <Box
                        ml={1}
                    >
                        <BiDownArrowAlt size='14px' />
                    </Box>
                </Flex>
            </Flex>
        </Box>

    )
}