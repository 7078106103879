import { Alert, Box, Badge, Button, Flex, Heading, HStack, List, ListItem, Stack, Text, ButtonGroup } from '@chakra-ui/react'
import { Grid } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { useNavigate, useParams } from 'react-router';
import { UserCard } from '../Forms/User/Card/Card';
import { config } from '../Utils/Config';
import { Link } from 'react-router-dom'
import { Map } from '../Components/Map';
import { BiEdit, BiLeftArrowAlt, BiTrashAlt, BiUserCheck, BiUserX } from 'react-icons/bi';

import { Table, Tr, Td, Th } from '@chakra-ui/react'
import { BackButton } from '../UI/BackButton';

export const UserView = () => {
    let params = useParams();
    const { userId } = params;

    const navigate = useNavigate();

    React.useEffect(() => {
        getData();
    }, []);

    const [data, setData] = React.useState(null)

    const getData = () => {
        axios.get(`${config.apiUrl}/user/${userId}`)
            .then(result => setData(result.data));
    }


    const handleActivate = () => {
        axios.put(`${config.apiUrl}/user/activate/${userId}`)
            .then(
                () => getData()
            );
    }

    const handleArchive = () => {
        axios.put(`${config.apiUrl}/user/archive/${userId}`)
            .then(
                () => getData()
            );
    }

    const handleUnArchive
        = () => {
            axios.put(`${config.apiUrl}/user/unarchive/${userId}`)
                .then(
                    () => getData()
                );
        }


    const handleDelete = () => {
        axios.delete(`${config.apiUrl}/user/${userId}`)
            .then(
                () => {
                    getData();
                    navigate('./../../')
                }
            );
    }

    return (
        data ?
            <Box maxW='4xl' mx='auto' bgColor='white' p={10}>
                <Box>
                    <Stack spacing={10}>
                        <Flex
                            justify={'space-between'}
                        >
                            <BackButton label="back" />
                            <ButtonGroup size='sm'>
                                <Button leftIcon={<BiEdit />} as={Link} to={`../edit/${data._id}`}>Update</Button>
                                {data.status === 'created' ?
                                    <Button
                                        leftIcon={<BiUserCheck />}
                                        isDisabled={!data.school}
                                        onClick={() => handleActivate()}>
                                        Activate
                                    </Button>
                                    : null}
                                {data.status === 'created' || data.status === 'activated' || data.status === 'updated' ?
                                    <Button leftIcon={<BiUserX />} onClick={() => handleArchive()}>Archive</Button>
                                    : null}
                                {data.status === 'archived' ?
                                    <>
                                        <Button leftIcon={<BiUserCheck />} onClick={() => handleUnArchive()}>Unarchive</Button>
                                        <Button leftIcon={<BiTrashAlt />} onClick={() => handleDelete()}>Delete</Button>
                                    </>
                                    : null}
                            </ButtonGroup>
                        </Flex>

                        <Heading mb={10}>
                            {data.firstName} {data.lastName}
                        </Heading>

                        <Table>
                            <Tr>
                                <Th>Item</Th>
                                <Th>Value</Th>
                            </Tr>
                            <Tr>
                                <Td>Type</Td>
                                <Td>
                                    <Badge colorScheme={data.role === 'admin' ? 'blue' : 'gray'}> {data.role}</Badge>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Status</Td>
                                <Td>
                                    <Badge colorScheme={data.status === 'created' ? 'teal' : 'gray'}> {data.status}</Badge>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>School</Td>
                                <Td>{data.school ? data.school.name : null}</Td>
                            </Tr>
                            <Tr>
                                <Td>Gender</Td>
                                <Td>{data.gender ? data.gender : null}</Td>
                            </Tr>
                            <Tr>
                                <Td>Phone</Td>
                                <Td>{data.phoneNumber}</Td>
                            </Tr>
                            <Tr>
                                <Td>Mobile</Td>
                                <Td>{data.mobilePhoneNumber}</Td>
                            </Tr>
                            <Tr>
                                <Td>Email</Td>
                                <Td>{data.email}</Td>
                            </Tr>
                            <Tr>
                                <Td>Position</Td>
                                <Td>{data.position}</Td>
                            </Tr>
                            <Tr>
                                <Td>School name provided</Td>
                                <Td>{data.schoolName}</Td>
                            </Tr>
                            <Tr>
                                <Td>Website provided</Td>
                                <Td>{data.websiteUrl}</Td>
                            </Tr>
                        </Table>
                        {
                            data.status === 'created'
                                && data.role === 'user'
                                && !data.school
                                ?
                                <Alert borderRadius='sm'>
                                    <Text>To be activated, this user must be linked to a school.</Text>
                                </Alert>
                                : null
                        }
                    </Stack>
                </Box>
            </Box>
            : null
    )
}
