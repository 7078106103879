import { Button, ButtonGroup, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'react-router-dom'


export const TablePagination = (
    { currentPage, lastPage, totalResults, baseLink }
) => {
    return (
        // <>
        // { totalResults}
        // </>
        <Flex align="center" justify="space-between">
            <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                {totalResults} user{totalResults > 1 ? 's' : ''}
            </Text>
            <ButtonGroup variant="outline" size="sm">
                <Button
                    as={Link}
                    to={`${baseLink}${currentPage - 1}`}
                    rel="prev"
                    isDisabled={currentPage === 1}
                >
                    Previous
                </Button>
                <Button
                    as={Link}
                    to={`${baseLink}${currentPage + 1}`}
                    rel="next"
                    isDisabled={currentPage === lastPage}
                >
                    Next
                </Button>
            </ButtonGroup>
            {/* { currentPage } */}
        </Flex>
    )
}
