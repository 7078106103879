import { Box, Button, Center, Divider, HStack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiX } from 'react-icons/bi'

export const NotesFormHeader = ({
    handleClose
}) => (
    <Box
        borderBottom={'solid 1px'}
        borderBottomColor={'gray.100'}
        px={12}
        py={8}
    >
        <HStack spacing={4}>

            <Button
                onClick={() => handleClose()}
                variant={'outline'}
                leftIcon={<BiX />}
            >
                Close
            </Button>

            <Text>Record the notes for this promotion</Text>

        </HStack>
    </Box>
)