import { Box, Button } from '@chakra-ui/react';
import * as React from 'react'
import { BiCommentEdit } from 'react-icons/bi';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';

export const CommentPublishButton = ({ ...rest }) => {
    return (
        <Button
            size='sm'
            {...rest}
            leftIcon={<BiCommentEdit />}
        >
            Publish a comment
        </Button>
    )
}