import * as React from "react"
import {
  ChakraProvider,
} from "@chakra-ui/react"

import Routes from "./Routes"
import { AuthProvider } from "./Auth/useAuth";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from 'react-helmet'
import './Styles/shadows.css';
import { theme } from "./Theme";
import Favicon from "react-favicon";
import './Styles/fonts.css'
import './Styles/fonts.css'
import './fonts/stylesheet.css'
export const App = () => {
  return (
    <>
      <Helmet>
        <title>EABHES Portal</title>
      </Helmet>
      {/* <Favicon
        url='https://www.eabhes.eu/wp-content/uploads/2018/12/cropped-logo_eabhes_vectorized1-2-180x180.png'
      // alertCount={1}
      // alertFillColor="red"
      // alertTextColor="white"
      /> */}
      <ChakraProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
      </ChakraProvider>
    </>
  )
}