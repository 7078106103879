import { Badge, Box, Center, Heading, HStack, Link } from '@chakra-ui/layout'
import axios from 'axios';
import * as React from 'react'
import { config } from '../Utils/Config';
import { useParams } from 'react-router';
import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
// import authService from '../Auth/auth.service';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Loader } from '../UI/Loader';
import { UserContext } from '../Auth/userContext';
import { scrollToTopContent } from '../Utils/scrollToTopContent';
import { GoAlert } from 'react-icons/go';
import { NoResults } from '../UI/NoResults';

const ProgramList = ({ view }) => {
    const { authed, user } = React.useContext(UserContext);

    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState('')
    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [schools, setSchools] = React.useState(null)

    let userSchool: string = '';
    if (view === 'user') {
        userSchool = user.school;
    } else {
        userSchool = '';
    }

    React.useEffect(() => {
        getData();
    }, [params.page, search]);

    React.useEffect(() => {
        getSchools();
    }, []);

    const getData = () => {

        axios.get(
            `
                ${config.apiUrl}/program/${params.page ? params.page : 1}
                /${search.search ? search.search : '-'}
                /${search.role ? search.role : '-'}
                /${search.status ? search.status : '-'}
                /${userSchool ? userSchool : search.school ? search.school : '-'}`.replace(/\s+/g, '')
        )
            .then(result => {
                setData(result.data);
                setLastPage(parseInt(result.headers.lastpage));
                setCurrentPage(parseInt(result.headers.currentpage));
                setTotalResults(parseInt(result.headers.results));
                if (result.data.length) {
                    scrollToTopContent();
                }
            })

        // axios.get(`${config.apiUrl}/user/${params.page ? params.page : ''}`)
        //     .then(result => {
        //         setData(result.data);
        //         setLastPage(parseInt(result.headers.lastpage));
        //         setCurrentPage(parseInt(result.headers.currentpage));
        //         setTotalResults(parseInt(result.headers.results));
        //     })

    }

    const getSchools = () => {
        axios.get(
            `${config.apiUrl}/school/all`
        )
            .then(result => {
                setSchools(result.data);
            })

    }

    const Role = ({ role }) => (
        role === 'user' ?
            <Badge colorScheme='teal'>user</Badge>
            : role === 'admin' ?
                <Badge colorScheme='orange'>admin</Badge>
                : null
    )

    const Status = ({ status }) => (
        status === 'created' ?
            <Badge colorScheme='blue'>{status}</Badge>
            : status === 'accredited' ?
                <Badge colorScheme='green'>{status}</Badge>
                : status === 'archived' ?
                    <Badge colorScheme='blackAlpha'> {status}</Badge>
                    : null
    )

    return (
        <>
            <Heading mb={4}>
                <div id='top'>
                    Programs
                </div>
            </Heading>
            {view !== 'user' ?
                <TableActions getValues={(values) => setSearch(values)} schoolFilters={schools} />
                : null}
            {
                data ?
                    data.length ?
                        <Table my="8" borderWidth="1px" fontSize="sm" bgColor='white'>
                            <Thead bg={mode('gray.50', 'gray.800')}>
                                <Tr>

                                    <Th>Name</Th>
                                    <Th>Major</Th>
                                    <Th>Minor</Th>
                                    <Th>Status</Th>
                                    <Th>Semesters</Th>
                                    <Th
                                        textAlign='right'
                                    >
                                        Action
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data ?
                                    data.map((item, index) =>
                                        <Tr key={index}>
                                            <Td whiteSpace="nowrap" key={index}>

                                                <Text
                                                    whiteSpace={`nowrap`}
                                                    overflow={`hidden`}
                                                    textOverflow={`ellipsis`}
                                                    maxW={`240px`}
                                                    title={item.name}

                                                >
                                                    {item.name}

                                                </Text>
                                            </Td>
                                            {/* <Td>{item.code} </Td> */}
                                            <Td>{item.major} </Td>
                                            {/* <Td><Link as={RouterLink} to={`/admin/school/view/${item.school._id}`}>
                                                {item.school.acronym ? item.school.acronym : item.school.name}
                                            </Link></Td> */}
                                            <Td>{item.primaryCoursePlan.minor === 'minor' ? '-' : item.primaryCoursePlan.minor} </Td>
                                            <Td>
                                                <HStack>
                                                    <Status status={item.status} />
                                                    {!item.accountingSituation ?
                                                        <GoAlert color='red' title='Accounting situation issue' />
                                                        : null}
                                                </HStack>
                                            </Td>
                                            <Td>{item.semestersNumber}</Td>

                                            <Td textAlign="right">
                                                <HStack justifyContent='flex-end'>
                                                    <Button
                                                        size='sm'
                                                        variant="link"
                                                        colorScheme="blue"
                                                        as={RouterLink}
                                                        to={view === 'user' ? `/account/program/view/${item._id}/${item.primaryCoursePlan ? item.primaryCoursePlan._id : ''}` : `/admin/program/view/${item._id}/${item.primaryCoursePlan ? item.primaryCoursePlan._id : ''}`}
                                                    >
                                                        Details
                                                    </Button>
                                                    {/* <Button
                                                size='sm'
                                                variant="link"
                                                colorScheme="blue"
                                                as={RouterLink}

                                                to={view === 'user' ? `/account/program/edit/${item._id}` : `/admin/program/edit/${item._id}`}

                                            >
                                                Edit
                                            </Button> */}
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    )
                                    : null}
                            </Tbody>
                        </Table>

                        : <NoResults label='No program found' />
                    : <Loader label='Loading programs' />
            }

            {view === 'user' ?
                <Center>
                    <Button as={RouterLink} to='/account/program/new' variant={'outline'} size="sm" rightIcon={<BiRightArrowAlt />}>Create a new program for accreditation</Button>
                </Center>
                : null}
            {totalResults && lastPage && currentPage ?
                <TablePagination
                    totalResults={totalResults}
                    lastPage={lastPage}
                    currentPage={currentPage}
                    baseLink={view === 'user' ? '/account/program/' : '/admin/program/'}
                />
                : null}

            <Debug data={search} />

        </>
    )
}

export default ProgramList