import * as Yup from 'yup';
import { phoneRegExp } from '../../User/Schema';

const schoolApplicationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(200, 'Too Long!')
        .required('Required'),
    acronym: Yup.string()
        .min(1, 'Too Short!')
        .max(10, 'Too Long!')
        .required('Required'),
    foundingDate: Yup.number()
        .min(1800)
        .max(new Date().getFullYear())
        .required('Required'),
    websiteUrl: Yup.string()
        .url()
        .required('Required'),
    phoneNumber:
        Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
    // .required('Required')
    ,
});

export { schoolApplicationSchema }