import { Box, Button, Input, Stack, Text, Textarea } from '@chakra-ui/react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { UserContext } from '../../Auth/userContext';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';

export const CommentPublishForm = ({ schoolId, onPublish }) => {

    const { user } = React.useContext(UserContext)


    return (
        <>

            <Formik
                initialValues={{
                    school: schoolId,
                    author: user._id
                }}
                onSubmit={(values) => {
                    axios.post(
                        `${config.apiUrl}/comment/`,
                        values
                    ).then(() => onPublish())

                }}

            >
                <Form>
                    <Stack>
                        <Field name='content'>
                            {({ field }) => (
                                <Textarea
                                    {...field}
                                    placeholder='Your comment'
                                />
                            )}
                        </Field>
                        <Box>
                            <Button
                                type='submit'
                            >
                                Submit
                            </Button>
                        </Box>

                    </Stack>
                </Form>

            </Formik>
        </>
    )
}