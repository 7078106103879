import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Img,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, FieldArray, Form, Formik } from "formik";
import * as React from "react";
import { BiX } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
// import authService from '../../Auth/auth.service';
import { Wrapper } from "../../Components/Wrapper";
import { AddButton } from "../../UI/AddButton";
import { BackButton } from "../../UI/BackButton";
import { RemoveButton } from "../../UI/RemoveButton";
import { config } from "../../Utils/Config";
import LocationField from "../AddressField";
import Debug from "../Debug";
import FieldBlock from "../FieldBlock";
import { UploadButton } from "../../Upload/Button";
import { UserContext } from "../../Auth/userContext";

export const SchoolUpdateForm = () => {
  const { schoolId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = React.useState();
  const [users, setUsers] = React.useState();

  let { user } = React.useContext(UserContext);

  React.useEffect(() => {
    if (schoolId) {
      getData();
      getUsers();
    }
  }, []);

  const getData = () => {
    axios
      .get(`${config.apiUrl}/school/${schoolId}`)
      .then((result) => setData(result.data));
  };

  const getUsers = () => {
    axios
      .get(`${config.apiUrl}/user/all`)
      .then((result) => setUsers(result.data));
  };

  return data && users ? (
    <Formik
      initialValues={
        data
          ? {
              ...data,
              accreditation: {
                ...data.accreditation,
                firstDate: data.accreditation.firstDate
                  ? data.accreditation.firstDate.slice(0, 10)
                  : null,
                renewalDate: data.accreditation.renewalDate
                  ? data.accreditation.renewalDate.slice(0, 10)
                  : null,
                agreementDate: data.accreditation.agreementDate
                  ? data.accreditation.agreementDate.slice(0, 10)
                  : null,
              },
              displaySchoolLogoOnDocuments: data.displaySchoolLogoOnDocuments
                ? "yes"
                : "no",
              // "accreditation.firstDate": data.accreditation.firstDate.slice(0, 10),
              users: data.users.map((item) => item._id),
            }
          : {}
      }
      onSubmit={(values, { setSubmitting }) => {
        axios.put(`${config.apiUrl}/school/${schoolId}`, values).then(() => {
          toast({
            title: "School updated",
            description: "",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate(`./../../view/${schoolId}`);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        /* and other goodies */
      }) => (
        <Wrapper>
          <Stack as={Form} spacing={{ base: 2, lg: 22 }}>
            <BackButton />
            <Heading>
              {values && values.name ? `${values.name}` : `New program`}
            </Heading>

            <SimpleGrid columns={3} gap={4}>
              <Field name="status">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                  >
                    <FormLabel>Status</FormLabel>
                    <Select {...field}>
                      <option value="created">created</option>
                      <option value="updated">updated</option>
                      <option value="completed">completed</option>
                      <option value="validated">validated</option>
                      <option value="archived">archived</option>
                      <option value="imported">imported</option>
                      <option value="accredited">accredited</option>
                    </Select>
                  </FieldBlock>
                )}
              </Field>
              <Field name="name">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Name"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
              <Field name="acronym">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Acronym"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
            </SimpleGrid>

            <Field name="imageUrl">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  title="Illustration URL"
                >
                  <Input {...field} />
                </FieldBlock>
              )}
            </Field>

            <Field name="logoFile">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  isRequired={false}
                >
                  <FormLabel>Logo</FormLabel>

                  {field.value ? (
                    <Img
                      bgColor={`black`}
                      maxH={`32`}
                      p={2}
                      mb={2}
                      rounded={`md`}
                      src={`${config.apiUrl}/document/${schoolId}/${field.value}`}
                    />
                  ) : null}

                  <UploadButton
                    align={`left`}
                    linkedDocumentId={schoolId}
                    outputName={`logo`}
                    isPublic={true}
                    uploadCallback={(file) => {
                      setFieldValue(
                        field.name,
                        `logo.${file.name.split(".").pop()}`,
                        null,
                        null
                      );
                      handleSubmit();
                    }}
                  />
                </FieldBlock>
              )}
            </Field>
            {/* <Debug data={values} /> */}
            <Field name="logoFileVertical">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  isRequired={false}
                >
                  <FormLabel>Logo vertical</FormLabel>

                  {field.value ? (
                    <Img
                      bgColor={`black`}
                      maxH={`32`}
                      p={2}
                      mb={2}
                      rounded={`md`}
                      src={`${config.apiUrl}/document/${schoolId}/${field.value}`}
                    />
                  ) : null}

                  <UploadButton
                    align={`left`}
                    linkedDocumentId={schoolId}
                    outputName={`logo-vertical`}
                    isPublic={true}
                    uploadCallback={(file) => {
                      console.log(file);
                      setFieldValue(
                        field.name,
                        `logo-vertical.${file.name.split(".").pop()}`,
                        null,
                        null
                      );
                      handleSubmit();
                    }}
                  />
                </FieldBlock>
              )}
            </Field>

            {user.role === "admin" && (
              <Field name="displaySchoolLogoOnDocuments">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                  >
                    <FormLabel>
                      Does this school display its own logo or EABHES's logo on
                      the documents?
                    </FormLabel>
                    <Select {...field}>
                      <option value="yes">Own logo</option>
                      <option value="no">EABHES logo</option>
                    </Select>
                  </FieldBlock>
                )}
              </Field>
            )}

            <Divider />
            <Field name="presentation">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  title="Short presentation"
                >
                  <Textarea {...field} />
                </FieldBlock>
              )}
            </Field>
            <Divider />

            <Heading fontSize={`md`}>Accreditation</Heading>

            <SimpleGrid columns={3} gap={3}>
              <Field name="accreditation.number">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Accreditation number"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
              <Field name="accreditation.firstDate">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="First accreditation date"
                  >
                    <Input {...field} type={`date`} />
                  </FieldBlock>
                )}
              </Field>
              <Field name="accreditation.renewalDate">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Accreditation renewal date"
                  >
                    <Input {...field} type={`date`} />
                  </FieldBlock>
                )}
              </Field>
              <Field name="agreementDate">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Agreement date"
                  >
                    <Input {...field} type={`date`} />
                  </FieldBlock>
                )}
              </Field>
            </SimpleGrid>

            <Divider />

            <Heading fontSize={`md`}>Members</Heading>

            <FieldArray
              name="users"
              render={(arrayHelpers) => (
                <FieldBlock title="Portal users">
                  <Stack>
                    {values.users && values.users.length > 0
                      ? values.users.map((item, index) => (
                          <Flex key={index}>
                            <Field name={`users.${index}`}>
                              {({ field, meta }) => (
                                <Select {...field} mr={2} placeholder="Select">
                                  {users.map((item) =>
                                    item.role === "user" ? (
                                      <option key={item._id} value={item._id}>
                                        {`${item.firstName} ${item.lastName}`}
                                      </option>
                                    ) : null
                                  )}
                                </Select>
                              )}
                            </Field>
                            <RemoveButton
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            />
                          </Flex>
                        ))
                      : null}
                    <Box>
                      <AddButton onClick={() => arrayHelpers.push("")} />
                    </Box>
                  </Stack>
                </FieldBlock>
              )}
            />

            <FieldArray
              name="staff"
              render={(arrayHelpers) => (
                <FieldBlock title="Staff members">
                  <Stack>
                    {values.staff && values.staff.length > 0
                      ? values.staff.map((item, index) => (
                          <Flex key={index}>
                            <Field name={`staff.${index}.firstName`}>
                              {({ field, meta }) => (
                                <Input
                                  mr={2}
                                  {...field}
                                  placeholder="First name"
                                />
                              )}
                            </Field>
                            <Field name={`staff.${index}.lastName`}>
                              {({ field, meta }) => (
                                <Input
                                  mr={2}
                                  {...field}
                                  placeholder="Last name"
                                />
                              )}
                            </Field>
                            <Field name={`staff.${index}.position`}>
                              {({ field, meta }) => (
                                <Input
                                  mr={2}
                                  {...field}
                                  placeholder="Position"
                                />
                              )}
                            </Field>
                            <Field name={`staff.${index}.email`}>
                              {({ field, meta }) => (
                                <Input mr={2} {...field} placeholder="Email" />
                              )}
                            </Field>
                            <Field name={`staff.${index}.phoneNumber`}>
                              {({ field, meta }) => (
                                <Input mr={2} {...field} placeholder="Phone" />
                              )}
                            </Field>
                            <RemoveButton
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            />
                          </Flex>
                        ))
                      : null}
                    <Box>
                      <AddButton onClick={() => arrayHelpers.push("")} />
                    </Box>
                  </Stack>
                </FieldBlock>
              )}
            />
            <Field name="staffComment">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  title="General comment about EABHES contacts"
                >
                  <Textarea {...field} />
                </FieldBlock>
              )}
            </Field>

            <Divider />

            <Heading fontSize={`md`}>Various informations</Heading>

            <SimpleGrid columns={2} gap={4}>
              <Field name="websiteUrl">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Website"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Field name="phoneNumber">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Phone number"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
            </SimpleGrid>

            <FieldBlock title="Address">
              {values.address ? (
                <>
                  <Button
                    onClick={() => setFieldValue("address", null)}
                    colorScheme="teal"
                    size="sm"
                    rightIcon={<BiX />}
                  >
                    {values.address}
                  </Button>
                </>
              ) : (
                <LocationField setFieldValue={setFieldValue} />
              )}
            </FieldBlock>

            <SimpleGrid columns={3} gap={4}>
              <Field name="foundingDate">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Founding date of the school"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Field name="institutionalCapital">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Capital"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Field name="institutionalAnnualTurnover">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Turnover"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Field name="numberOfTeachers">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Number of teachers"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
              <Field name="numberOfStudents">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Number of students"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>

              <Field name="numberOfAdminEmployees">
                {({ field, form, meta }) => (
                  <FieldBlock
                    isInvalid={meta.error && meta.touched}
                    error={meta.error}
                    title="Number of employees"
                  >
                    <Input {...field} />
                  </FieldBlock>
                )}
              </Field>
            </SimpleGrid>

            {/* <Heading fontSize={'md'}>
                                Finance
                            </Heading>
                            <Field name='invoicingPerYear'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        title='Invoicing per year'
                                    >
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name='invoicingComment'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        title='Finance comments'
                                    >
                                        <Textarea {...field} />
                                    </FieldBlock>
                                )}
                            </Field> */}

            <Divider />

            <FieldArray
              name="programsTaughtInEnglish"
              render={(arrayHelpers) => (
                <FieldBlock title="List of your programs taught in english">
                  <Stack>
                    {values.programsTaughtInEnglish &&
                    values.programsTaughtInEnglish.length > 0
                      ? values.programsTaughtInEnglish.map((item, index) => (
                          <Flex key={index}>
                            <Field name={`programsTaughtInEnglish.${index}`}>
                              {({ field, meta }) => (
                                <Input
                                  mr={2}
                                  {...field}
                                  placeholder="Programme name"
                                />
                              )}
                            </Field>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              <BiX />
                            </Button>
                          </Flex>
                        ))
                      : null}
                    <Box>
                      <AddButton onClick={() => arrayHelpers.push("")} />
                    </Box>
                  </Stack>
                </FieldBlock>
              )}
            />

            <Divider />

            <Field name="partnershipsDescription">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  title="Partnerships"
                >
                  <Textarea {...field} />
                </FieldBlock>
              )}
            </Field>

            <Field name="certificationsDescription">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  title="Certifications"
                >
                  <Textarea {...field} />
                </FieldBlock>
              )}
            </Field>

            {/* <FieldArray
                                name="financeComments"
                                render={arrayHelpers => (
                                    <FieldBlock
                                        title='Comments'
                                    >
                                        <Stack>
                                            {values.financeComments && values.financeComments.length > 0 ? (
                                                values.financeComments.map((item, index) => (
                                                    <Flex key={index}>
                                                        <Field name={`financeComments.${index}.content`}>
                                                            {({ field, meta }) => (
                                                                <Input {...field} />
                                                            )}
                                                        </Field>
                                                        <Box ml={2}>
                                                            <RemoveButton
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            />
                                                        </Box>
                                                    </Flex>
                                                ))
                                            ) : (
                                                null
                                            )}
                                            <Box>
                                                <AddButton
                                                    onClick={() => arrayHelpers.push('')}
                                                />
                                            </Box>

                                        </Stack>
                                    </FieldBlock>
                                )}
                            /> */}

            {/* <Divider />
                            <Debug data={data} /> */}
            <Divider />
            <Flex>
              <Button
                type="submit"
                isDisabled={Object.keys(errors).length !== 0}
              >
                Save
              </Button>
            </Flex>
            <Divider />
          </Stack>
        </Wrapper>
      )}
    </Formik>
  ) : null;
};
