import { Box, Button, ButtonGroup, Divider, Flex, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiArrowBack } from 'react-icons/bi';
import { useParams } from 'react-router-dom'
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';
import { Link } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';

export const CoursePlanCoursesListUpdate = () => {

    const { coursePlanId } = useParams();

    const [data, setData] = React.useState();
    const [planCourses, setPlanCourses] = React.useState();
    const [program, setProgram] = React.useState();

    React.useEffect(
        () => {
            getCoursePlanData();
        }, [coursePlanId]
    )

    React.useEffect(
        () => {
            if (data && data.program) {

                axios.get(
                    `${config.apiUrl}/program/${data.program._id}`
                ).then(res => setProgram(res.data));
            }

        }, [data, planCourses]
    )

    const getCoursePlanData = () => {
        axios.get(
            `${config.apiUrl}/course-plan/${coursePlanId}`
        ).then(res => {
            setData(res.data);
            setPlanCourses(
                res.data.courses.map(item => item && item.course ? item.course._id : undefined)
            )
        });
    }

    const isCourseInPlan = (id) => {
        return planCourses.includes(id)
    }

    const handleUpdate = (courseId) => {
        let updatedPlanCourses = []
        if (planCourses.includes(courseId)) {
            updatedPlanCourses = planCourses;
            for (var i = 0; i < updatedPlanCourses.length; i++) {
                if (updatedPlanCourses[i] === courseId) {
                    updatedPlanCourses.splice(i, 1);
                }
            }
            setPlanCourses(
                updatedPlanCourses
            )
        } else {
            updatedPlanCourses = [...planCourses, courseId];
            setPlanCourses(updatedPlanCourses);
            // console.log(updatedPlanCourses)
        }


        axios.put(
            `${config.apiUrl}/course-plan/${data._id}`,
            { courses: updatedPlanCourses.map(item => ({ "course": item })) }
        ).then(
            () => getCoursePlanData()
        );

    }

    const handleAll = () => {
        if (!program || !program.courses.length) return;
        axios.put(
            `${config.apiUrl}/course-plan/${data._id}`,
            { courses: program.courses.map(item => ({ "course": item._id })) }
        ).then(
            () => getCoursePlanData()
        );
    };

    const handleNone = () => {
        axios.put(
            `${config.apiUrl}/course-plan/${data._id}`,
            { courses: [] }
        ).then(
            () => getCoursePlanData()
        );
    };

    return (
        data && program ?
            <Wrapper key={planCourses}>

                <Stack
                    spacing={{ base: 2, lg: 22 }}
                >
                    <Box>
                        <Button leftIcon={<BiArrowBack />} as={Link} size='sm'
                            to={`/admin/program/view/${program._id}/${data._id}`}
                        >
                            Back
                        </Button>
                    </Box>
                    <Flex
                        justify={`space-between`}
                        align='flex-end'
                    >
                        <Heading>
                            {data.name}
                        </Heading>
                        <ButtonGroup size='xs'>
                            <Button onClick={() => handleAll()}>Select all</Button>
                            <Button onClick={() => handleNone()}>Select none</Button>
                        </ButtonGroup>
                    </Flex>



                    <Divider />

                    <Stack
                        maxW='xl'
                    >

                        {program.courses.map(item =>

                            <Box
                                key={item._id + planCourses.join(',')}

                                cursor='pointer'
                                p={1}
                                px={2}
                                borderRadius='sm'

                                bgColor={isCourseInPlan(item._id) ? 'blue.100' : 'gray.50'}
                                transform={`translateX(${isCourseInPlan(item._id) ? '20px' : '0'})`}

                                onClick={() => handleUpdate(item._id)}
                            >
                                <strong>{item.code}</strong> - {item.name} / {item.version}
                            </Box>

                        )}
                    </Stack>

                </Stack >
            </Wrapper>
            : null
    )
}