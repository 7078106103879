import { Box, Flex } from "@chakra-ui/react";
import * as React from "react";
import Debug from "../../Forms/Debug";
import { RegistrationCertificateBody } from "./Body";
import { RegistrationCertificateFooter } from "./Footer";
import { RegistrationCertificateHeader } from "./Header";
import bgGlobes from "../../Images/patterns/globes-white.svg";

export const RegistrationCertificateLayout = ({ data, bg }) => {
  const pageStyle = `
        @page {
            size: a4 portrait;
            margin: 0cm !important;
        }
        html, body {
            height: 295mm;
            width: 210mm;
        }
    `;

  const bgParams = () =>
    bg && bg === true
      ? {
          // "bgImage": `url(${diplomaBgImage})`,
          // "bgSize": '297mm 209.5mm'
          bgColor: "gray.100",
        }
      : {};

  return (
    <>
      <style>{pageStyle}</style>
      <Debug data={data} />
      <Box
        className="main-page"
        display="flex"
        bgColor="white"
        h="290mm"
        {...bgParams()}
      >
        <Flex
          flexDir="column"
          h="full"
          w="full"
          // bgColor={`brand.blue.100`}
          // bgImage={`url(${bgGlobes})`}
          // bgSize={`10%`}
        >
          <RegistrationCertificateHeader data={data} />
          <RegistrationCertificateBody data={data} />
          <RegistrationCertificateFooter />
        </Flex>
      </Box>
    </>
  );
};
