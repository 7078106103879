import * as React from 'react'
import { useParams } from 'react-router-dom'
import { TranscriptDocument } from './Document';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';
import { ToolsButtons } from '../Diploma/ToolsButtons';

export const TranscriptExporter = ({ }) => {
    const { inscriptionId, showBg } = useParams();

    const inscriptionIds = inscriptionId ? inscriptionId.split(',') : [];

    const TranscriptPages = () => {
        let transcriptPagesArray = [];
        for (let index = 0; index < inscriptionIds.length; index++) {
            transcriptPagesArray.push(
                <TranscriptDocument
                    key={`transcript-document-${index}`}
                    inscriptionId={inscriptionIds[index]}
                    single={inscriptionIds.length === 1}
                    bg={showBg === "true" ? true : false}

                />
                // <div>{inscriptionIds[index]}</div>
            );
        }
        return <>{transcriptPagesArray}</>
    }
    return (
        <>
            <TranscriptPages />
        </>
    )
}