import { Box, Center } from '@chakra-ui/react'
import * as React from 'react'

export const CoverPage = () => {
    let bgUrl = 'https://www.eabhes.eu/wp-content/uploads/2019/02/Header-EABHES1400x300.gif'
    return (
        <Center
            minH='full'
            h='full'

            bgImage={`url(${bgUrl})`}
            bgRepeat={`no-repeat`}
            bgSize='100% auto'
            bgPosition={'bottom center'}
        >
            FrontPage
        </Center>
    )
}