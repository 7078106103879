import { Button, Input, Stack } from '@chakra-ui/react';
import axios from 'axios';
import { Form, Formik, Field } from 'formik';
import * as React from 'react';
import Debug from '../../Forms/Debug';
import FieldBlock from '../../Forms/FieldBlock';
import { config } from '../../Utils/Config';


export const CoursePlansNew = ({
    programId,
    handleSubmit,
    handleClose
}) => {
    return (
        <Formik
            initialValues={{ program: programId }}
            onSubmit={(values) => {
                axios.post(
                    `${config.apiUrl}/course-plan`,
                    values
                ).then(
                    result => {
                        handleSubmit();
                        handleClose();
                    }
                )
            }}
        >
            {({
                values,
                setFieldValue
            }) => (
                <Form>
                    <Stack>
                        <Field name="name">
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    title='Name'
                                    isRequired={true}
                                >
                                    <Input {...field} />
                                </FieldBlock>
                            )}
                        </Field>
                        <Field name="minor">
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    title='Minor'
                                    isRequired={false}
                                >
                                    <Input {...field} />
                                </FieldBlock>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            size='sm'
                        >
                            Save
                        </Button>
                        {/* <Debug data={values} /> */}

                    </Stack>
                </Form>
            )}
        </Formik>
    )
}

