import { Box, Center, Divider, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

export const Summary = ({ program, coursePlan }) => {
    const pages = [
        {
            label: 'Foreword',
            isPrimary: true,
            number: 4
        },
        {
            label: 'Identification of the School',
            isPrimary: true,
            number: 5
        },
        {
            type: 'spacer'
        },
        {
            label: 'Program presentation',
            isPrimary: true,
            number: 6
        },
        {
            label: 'Program composition',
            isPrimary: true,
            number: 7
        },
        {
            type: 'spacer'
        },
        {
            label: 'Courses list',
            isPrimary: false,
            number: 8
        },

    ]

    const Line = ({ label, number, isPrimary }) => (
        <Flex
            justify={'space-between'}
            borderBottom={`dashed 1px`}
            borderBottomColor='gray.300'
            padding={0}
        >
            <Text
                fontSize={'md'}
                fontWeight={isPrimary ? 700 : 400}
                color={isPrimary ? 'blue.800' : 'gray.500'}
                lineHeight={1}
                bgColor='white'
                transform={'translateY(0.9mm)'}
                pr={1}
            >
                {label}
            </Text>
            <Text
                fontSize={'md'}
                fontWeight={isPrimary ? 700 : 400}
                color={isPrimary ? 'blue.800' : 'gray.500'}
                lineHeight={1}
                bgColor='white'
                transform={'translateY(1mm)'}
                pl={1}

            >
                {number}
            </Text>
        </Flex>
    )
    const Spacer = () => (<Box py={'5mm'}><Divider /></Box>)
    return (
        <Box
            pt={`120mm`}
            pl={`60mm`}
            pr={`30mm`}
        >
            <VStack
                alignItems={'flex-start'}
                spacing={8}
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    Summary
                </Text>
                <Stack
                    spacing={3}
                    color='blue.800'
                    // lineHeight={'10'}
                    w='full'

                >

                    {pages.map(page =>
                        page.type !== 'spacer' ?
                            <Line
                                label={page.label}
                                number={page.number}
                                isPrimary={page.isPrimary}
                            />
                            :
                            <Spacer />
                    )}

                </Stack>
            </VStack >

        </Box >
    )
}