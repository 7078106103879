import { Heading, Box, Button, Flex, Stack, Portal, Text, Table, Tr, Td, Tbody, Accordion, AccordionItem, AccordionButton, AccordionPanel, Modal, ModalContent, ModalBody, ModalOverlay, ButtonGroup } from '@chakra-ui/react'
import * as React from 'react'
import { BiCheck, BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom'

export const CourseLayerDetails = ({ position, data, courseDetailId, setCourseDetailId, isVisible, view }) => {

    let course = data.courses.filter(item => item._id === courseDetailId)[0];

    let pedagogicalItems = () => {
        let itemsList = []
        itemsList = [
            ...(course.learningOutcomes ? [{ title: "Learning outcomes", content: course.learningOutcomes }] : []),
            ...(course.courseDescription ? [{ title: "Course description", content: course.courseDescription }] : []),

            ...(course.teachingMethod ? [{ title: "Teaching method", content: course.teachingMethod }] : []),
            ...(course.plateformAndTechnology ? [{ title: "Plateform and technologies", content: course.plateformAndTechnology }] : []),
            ...(course.studentWorkload ? [{ title: "Student workload", content: course.studentWorkload }] : []),
            ...(course.assessmentMethods ? [{ title: "Assessment methods", content: course.assessmentMethods }] : []),
            ...(course.gradingSystem ? [{ title: "Grading system", content: course.gradingSystem }] : []),
            ...(course.bibliography ? [{ title: "Bibliography & webography", content: course.bibliography }] : []),


            // ...(course.pedagogicalPreview ? [{ title: "Pedagogical preview", content: course.pedagogicalPreview }] : []),
            // ...(course.results ? [{ title: "Results", content: course.results }] : []),
            // ...(course.bibliography ? [{ title: "Bibliography", content: course.bibliography }] : []),
            // ...(course.description ? [{ title: "Description", content: course.description }] : []),
            // ...(course.ratingScale ? [{ title: "Rating scale", content: course.ratingScale }] : []),
            // ...(course.plateformAndTechnology ? [{ title: "Plateform and technology", content: course.plateformAndTechnology }] : []),
            // ...(course.communicationPlateform ? [{ title: "Communication plateform", content: course.communicationPlateform }] : []),
            // ...(course.comment ? [{ title: "EABHES comment", content: course.comment }] : []),
        ]
        return itemsList

    }

    return (

        <Modal
            isOpen={true}
            onClose={() => setCourseDetailId(null)}
            size='3xl'
        >
            <ModalOverlay
                bgColor='whiteAlpha.600'
            />
            <ModalContent
                py={6}
            >
                <ModalBody>
                    {course ?
                        <Stack spacing={6}>

                            <Flex
                                justifyContent={'space-between'}
                                align='center'
                                px={0}
                            >
                                <Box>
                                    <Heading fontSize='lg'>{course.name}</Heading>
                                </Box>
                                <ButtonGroup>
                                    <Button
                                        as={Link}
                                        size='xs'
                                        variant={'link'}
                                        to={`/${view === 'user' ? 'account' : 'admin'}/course/edit/${course._id}`}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => setCourseDetailId(null)}
                                        variant='outline'
                                        size='sm'
                                        rightIcon={<BiX />}
                                    >
                                        Close
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                            <Table
                                size={'sm'}
                                variant='striped' colorScheme='blackAlpha'
                            >
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            Local name
                                        </Td>
                                        <Td textAlign={'right'}>
                                            {course.localName}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            Type
                                        </Td>
                                        <Td textAlign={'right'}>
                                            {course.type}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            Course hours
                                        </Td>
                                        <Td isNumeric>
                                            {course.hours ? course.hours : '- '}h
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            Personal hours
                                        </Td>
                                        <Td isNumeric>
                                            {course.personalWorkLoad ? course.personalWorkLoad : '- '}h
                                        </Td>
                                    </Tr>
                                    {course.hours && course.personalWorkLoad ?
                                        <Tr>
                                            <Td>
                                                Course total hours
                                            </Td>
                                            <Td textAlign={'right'}>
                                                {course.hours + course.personalWorkLoad}h
                                            </Td>
                                        </Tr>
                                        : null}
                                    {course.hours && course.personalWorkLoad ?
                                        <Tr>
                                            <Td>
                                                ECTS Credits
                                            </Td>
                                            <Td textAlign={'right'}>
                                                Declared : <strong>{course.ectsCredits}</strong> (US : {course.usCredits})
                                                / Calculated ECTS credits : <strong>{course.hours + course.personalWorkLoad / 25}</strong>
                                            </Td>
                                        </Tr>
                                        : null}
                                    <Tr>
                                        <Td>
                                            Is Elective ?
                                        </Td>
                                        <Td>
                                            <Flex
                                                justify={'flex-end'}
                                            >
                                                {!course.isMandatory ? <BiCheck /> : <BiX />}
                                            </Flex>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            Delivery mode
                                        </Td>
                                        <Td>
                                            <Flex
                                                justify={'flex-end'}
                                            >
                                                {course.deliveryMode}
                                            </Flex>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            {/* <Debug data={course} /> */}

                            <Heading fontSize={'md'}>
                                Pedagogical content
                            </Heading>
                            <Accordion>
                                {pedagogicalItems() && pedagogicalItems().length ? pedagogicalItems().map(item =>
                                    <AccordionItem>
                                        <div>
                                            <AccordionButton>
                                                <Text
                                                    fontWeight={'600'}
                                                    fontSize='sm'
                                                >
                                                    {item.title}
                                                </Text>
                                            </AccordionButton>
                                        </div>
                                        <AccordionPanel>
                                            <Stack spacing={1}>
                                                {item.content.split("\n").map(item =>
                                                    <Text
                                                        fontSize={'sm'}
                                                    >{item}</Text>
                                                )}
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>

                                ) : null}
                            </Accordion>
                        </Stack>
                        : null}

                </ModalBody>
            </ModalContent>
        </Modal>
    )
}


