import * as React from 'react'
import { useParams } from 'react-router-dom'
// import { TranscriptDocument } from './Document';
import Debug from '../../Forms/Debug';
import { RegistrationCertificateDocument } from './Document';

export const RegistrationCertificateExporter = ({ }) => {
    const { inscriptionId, showBg } = useParams();

    const inscriptionIds = inscriptionId ? inscriptionId.split(',') : [];

    const RegistrationCertificateDocuments = () => {
        let transcriptPagesArray = [];
        for (let index = 0; index < inscriptionIds.length; index++) {
            transcriptPagesArray.push(
                <RegistrationCertificateDocument
                    inscriptionId={inscriptionIds[index]}
                    single={inscriptionIds.length === 1}
                    bg={showBg === "true" ? true : false}

                />
            );
        }
        return <>{transcriptPagesArray}</>
    }
    return (
        <>
            <RegistrationCertificateDocuments />
        </>
    )
}