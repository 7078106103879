import { Button, Input } from '@chakra-ui/react'
import axios from 'axios'
import { Formik } from 'formik'
import * as React from 'react'
import { config } from '../../Utils/Config'

export const CourseImport = () => {

    const uploadFile = file => {

        var formData = new FormData();
        // var imagefile = document.querySelector('#file');
        formData.append("file", file);
        console.log(file);
        // formData.append("convertEncoding", convertEncoding);
        axios.post(
            `${config.apiUrl}/course/import`
            , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(async res => {

            console.log('res', res)
        })

    }

    return (

        <form
            onSubmit={() => console.log('submit')}
        >
            <input
                type='file'
                name='file'

            // onChange={(e) => {
            //     const file = e.target.files[0];
            //     console.log(file)
            //     uploadFile(file);
            // }}
            />
            <Button>Importer</Button>
        </form>
    )
}