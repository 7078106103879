import axios from 'axios';
import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';


// const fetchData = (inputValue: string, schoolId: string) => {

//     console.log(inputValue)

//     return axios.get(`${config.apiUrl}/program/1/${inputValue}/-/-/${schoolId ? schoolId : '-'}`).then(result => {
//         const formattedEntries = result.data.map(item => ({ label: item.name, value: item._id }));
//         return formattedEntries
//     });
// }

export const ProgramsSuggestField = ({ onChange, schoolId, value }) => {
    const [inputValue, setValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(null);



    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    // handle selection
    const handleChange = value => {
        setSelectedValue(value);
        onChange(value._id);
    }

    // load options using API call
    const loadOptions = (inputValue) => {
        if (inputValue.length > 3) {
            return fetch(`${config.apiUrl}/program/1/${inputValue}/-/-/${schoolId ? schoolId : '-'}`).then(res => res.json());
        }
    };


    return (
        <>

            <AsyncSelect
                cacheOptions
                defaultOptions

                value={selectedValue}

                getOptionLabel={e => e.name}
                getOptionValue={e => e._id}


                loadOptions={loadOptions}


                onInputChange={handleInputChange}
                onChange={handleChange}
            // defaultInputValue={value ? value : ''}


            />
        </>
    )
}