import { Button } from '@chakra-ui/button'
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'

import VisibilitySensor from "react-visibility-sensor";
import { StepTitle } from './StepTitle';

import { Link as ScrollLink } from 'react-scroll'


export const ApplicationFormIntro = ({ firstName, handleGotNext }) => (
    <Box>
        <Stack spacing={4}>
            <StepTitle
                step={0}
                setStep={() => null}
            >
                Hi {firstName},
            </StepTitle>

            <Stack
                pl={20}
                spacing={6}
            >

                <Text>
                    You are about to start the EABHES application process.
                    This consists of a form where you will give information about your school.
                </Text>
                <Text>
                    You will have to answer pedagogical and administrative questions.
                    {/* We will ask you to answer about 30 questions about your academic and international strategy. */}
                </Text>
                <Text>
                    Please allow 10-15 minutes to complete the questionnaire.
                </Text>

                <Flex>
                    <Button
                        cursor='pointer'
                        as={ScrollLink}
                        colorScheme='blue'
                        onClick={() => {
                            handleGotNext();
                        }}
                        to="step1" spy={true} smooth={true} offset={-50} duration={1000}
                        rightIcon={<BiRightArrowAlt />}
                    >
                        Let's start
                    </Button>
                </Flex>
            </Stack>

        </Stack>

    </Box>
)