import * as React from 'react'
import { Box, Flex, Table, Td, Th, Tr, Text } from '@chakra-ui/react';

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import { RiErrorWarningFill } from 'react-icons/ri';
import { BiCheck } from 'react-icons/bi';
import Debug from '../Forms/Debug';

export const StudentsListPreview = ({ data }) => (
    data.length > 0
        // && data[0].firstName
        // && data[0].lastName
        // && data[0].birthDate
        ?

        <>
            {/* <Debug data={data} /> */}
            <Table
                fontSize={'sm'}
            >
                <Tr>
                    <Th>Name</Th>
                    <Th>Birth</Th>
                    <Th>Diploma date</Th>
                    <Th>Speciality</Th>
                    <Th textAlign={'right'}>Duplicate ?</Th>
                </Tr>
                {data && data.length ? data.map(item =>
                    <>
                        {/* <Debug data={item} /> */}
                        <Tr
                            bgColor={item.isDuplicate ? 'orange.50' : item.isError ? 'red.200' : 'none'}
                            fontSize={'xs'}
                        >
                            <Td>{`${item.firstName} ${item.lastName}`}</Td>
                            <Td>
                                <Text
                                    as='span'
                                    fontSize='xs'
                                >
                                    {`${item.birthDate}`} /
                                    {item.birthPlaceCountry ? ` ${item.birthPlaceCountry} / ` : null}
                                    {`${item.birthPlaceCity} `}

                                </Text>
                            </Td>
                            <Td>
                                {item.highschoolDiplomaDate}
                            </Td>
                            <Td>
                                {item.highschoolDiploma}
                            </Td>
                            <Td textAlign={'right'}>
                                <Flex justify={'flex-end'} color={item.isDuplicate ? 'red.500' : 'green.400'}>
                                    {item.isDuplicate ? <RiErrorWarningFill color='red.500' /> : <BiCheck color='green.500' />}
                                </Flex>
                            </Td>
                        </Tr>
                    </>

                ) : null}

            </Table>
            {/* <Debug data={data} /> */}
        </>
        :

        <Alert
            status='error'
            size='xs'
        >
            <AlertIcon />

            <Box flex='1'>
                <AlertTitle>File handle error</AlertTitle>
                <AlertDescription>It was not possible to read your CSV, perhaps you forgot to add the right headers ?</AlertDescription>
            </Box>
        </Alert>
)