import * as React from 'react';
import {
    Box,
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,

    Link,
    Text,
    Stack
} from '@chakra-ui/react';

export const ApplicationThankYou = ({ isVisible }) => (
    <Box bgColor='gray.100' minH={'100vh'}>
        <Modal isOpen={isVisible} onClose={null} isCentered={true}>
            {/* <ModalOverlay bgColor='whiteAlpha.700' /> */}
            <ModalContent
                overflow={'hidden'}
            >
                <Image
                    src={`https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80`}
                />
                <ModalHeader mt={2}>
                    Your application form is complete
                </ModalHeader>
                <ModalBody mb={4}>
                    <Stack spacing={2}>
                        <Text>
                            Congratulations, your application is complete.
                        </Text>
                        <Text>
                            We will contact you shortly with informations about the next steps of the process.
                        </Text>
                        <Text>
                            Now, you can go back to{' '}
                            <Link href='https://eabhes.eu'>EABHES homepage</Link>
                        </Text>
                        <Box
                            pt={4}
                        >
                            <Button variant='outline' as={Link} size='sm' href='https://eabhes.eu'>Close</Button>
                        </Box>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
)