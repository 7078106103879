import * as React from "react";
import axios from "axios";
import { config } from "../Utils/Config";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import Debug from "../Forms/Debug";
import { BackButton } from "../UI/BackButton";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { BiCheck, BiImport, BiTrash, BiX } from "react-icons/bi";
import FieldBlock from "../Forms/FieldBlock";
import { ProgramsSuggestField } from "../Program/SuggestField";
import { UserContext } from "../Auth/userContext";
import { Field, Form, Formik } from "formik";
import { Wrapper } from "../Components/Wrapper";

export const InscriptionCreationForm = ({ view }) => {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const { studentsIds, schoolId } = params;
  const studentsList = studentsIds.split(",");

  const [programs, setPrograms] = React.useState();
  const [programId, setProgramId] = React.useState();
  const [programData, setProgramData] = React.useState("");

  const [coursePlanId, setCoursePlanId] = React.useState(null);

  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    axios
      .get(`${config.apiUrl}/program/school/${schoolId}/all`)
      .then((data) => setPrograms(data.data));
  }, [schoolId]);

  React.useEffect(() => {
    getData();
  }, [programId]);

  const getData = async () => {
    // axios.get(`${config.apiUrl}/program/`)
    //     .then(result => setPrograms(result.data));

    if (programId) {
      // axios.get(`${config.apiUrl}/program/${programId}`)
      //     .then(result => setProgramData(result.data));

      const programCoursePlans = await axios.get(
        `${config.apiUrl}/program/${programId}`
      );
      setProgramData(programCoursePlans.data);

      if (programCoursePlans.data.coursePlans.length > 1) {
        // setProgramData(programCoursePlans.data);
      } else {
        setCoursePlanId(programCoursePlans.data.coursePlans[0]._id);
      }
      // .then(result => setProgramData(result.data));
    }
  };

  return (
    <Wrapper>
      <Stack spacing={6} py={6}>
        <Flex justify={"space-between"}>
          <BackButton label="Back" />
          {/* <Button onClick={() => handleDelete()} leftIcon={<BiTrash />}>Delete all</Button> */}
        </Flex>
        <Heading>Inscription record for existing students</Heading>
        <Text>
          You are about to create new inscriptions for {studentsList.length}{" "}
          student(s).
        </Text>

        {/* <Debug data={students} /> */}
        {/* <Debug data={programs} /> */}

        <SimpleGrid columns={2} gap={4}>
          <FieldBlock title="Involved programme" isRequired={true}>
            <ProgramsSuggestField
              // onChange={(programId) => setProgramId(programId)}
              onChange={(programId: string) => setProgramId(programId)}
              schoolId={user.role === "user" ? user.school : null}
              value={programId}
            />
            {/* <Select
                            placeholder='Select program'
                            onChange={(e) => setProgramId(e.target.value)}
                        >
                            {programs.map(item =>
                                <option value={item._id}>{item.localName}</option>
                            )}
                        </Select> */}
          </FieldBlock>
          {programId && programData ? (
            <FieldBlock title="Course plan" isRequired={true}>
              <Select
                placeholder="Select the course plan"
                onChange={(e) => setCoursePlanId(e.target.value)}
              >
                {programData.coursePlans.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FieldBlock>
          ) : null}
        </SimpleGrid>

        {programId && coursePlanId ? (
          <Formik
            initialValues={{
              students: studentsList,
              program: programId,
              coursePlan: coursePlanId,
            }}
            onSubmit={(values) => {
              console.log("values", values);
              axios.post(`${config.apiUrl}/inscription/batch`, values).then(
                (result) => {
                  toast({
                    title: "Inscriptions created.",
                    description: "New inscriptions have been recorded.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                  navigate(
                    view === "user"
                      ? "/account/inscription"
                      : "/admin/inscription"
                  );
                },
                (error) => {
                  toast({
                    title: "An error has occured.",
                    description: "Student import could not be performed.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                }
              );
            }}
            validationSchema={yup.object().shape({
              program: yup.string().required(),
              startDate: yup
                .string()
                .matches(/^[0-9]{4}-[0-9]{2}$/, "Unexpected date format")
                .required(),
              endDate: yup
                .string()
                .matches(/^[0-9]{4}-[0-9]{2}$/, "Unexpected date format")
                .required(),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => (
              <Form>
                <Stack spacing={5}>
                  <SimpleGrid columns={2} gap={3}>
                    <Field name="startDate">
                      {({ field, meta }) => (
                        <FieldBlock
                          title="Start date"
                          isRequired={true}
                          isInvalid={meta.touched && meta.error}
                          error={meta.error}
                        >
                          <Input {...field} type="month" />
                          <FormHelperText>
                            Expected format YYYY-MM ex : 2021-09
                          </FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>

                    <Field name="endDate">
                      {({ field, meta }) => (
                        <FieldBlock
                          title="End date"
                          isRequired={true}
                          isInvalid={meta.touched && meta.error}
                          error={meta.error}
                        >
                          <Input {...field} type="month" />
                          <FormHelperText>
                            Expected format YYYY-MM ex : 2022-06
                          </FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>
                  </SimpleGrid>

                  <Box>
                    <Button
                      type="submit"
                      leftIcon={<BiImport />}
                      isDisabled={
                        Object.keys(errors).length > 0 ||
                        Object.keys(touched).length === 0
                      }
                    >
                      Create inscriptions
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : null}
      </Stack>
    </Wrapper>
  );
};
