import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue as mode,
  useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import Debug from '../../Forms/Debug'
import { config } from '../../Utils/Config'
// import useAuth from '../../Auth/useAuth'
// import authService from '../../Auth/auth.service'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../UI/PrimaryButton'


export const SigninForm = () => {

  const navigate = useNavigate();
  // const { login } = useAuth();
  const [error, setError] = React.useState(false);
  const toast = useToast();
  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(
    () => {
      // console.log('removeItem');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      document.cookie = ''
    }
  );

  return (

    <Formik
      initialValues={{}}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true);


        try {
          const logginAttempt = await axios.post(
            `${config.apiUrl}/user/login`,
            {
              email: values.email,
              password: values.password
            }
          );

          if (logginAttempt.data === 'auth_ok') {
            localStorage.setItem('user', JSON.stringify(parseJwt(logginAttempt.headers.authorization).user));
            localStorage.setItem('token', logginAttempt.headers.authorization.split(' ')[1]);
            localStorage.setItem('expireTime', parseJwt(logginAttempt.headers.authorization).exp);
            document.cookie = `EABHES_token=${logginAttempt.headers.authorization.split(' ')[1]}`

            // console.log(parseJwt(logginAttempt.headers.authorization));

            toast({
              title: 'Loggin success',
              description: `You will be logged in for 1 month.`,
              status: 'success',
              duration: 9000,
              isClosable: true,
            });

            setTimeout(
              () => navigate(parseJwt(logginAttempt.headers.authorization).user.role === 'admin' ? '/admin/school' : '/account/school'),
              2000
            )
            // window.location = '/admin';
          }

        }
        catch (error) {
          setError(true);
          setIsLoading(false);
        }




      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form>

          <Stack spacing="4">
            <FormControl id="email">
              <FormLabel mb={1}>Email</FormLabel>
              <Field name='email'>
                {({ field, form, meta }) => (
                  <Input {...field} type="email" autoComplete="email" />
                )}
              </Field>
            </FormControl>
            <FormControl>
              <Flex align="baseline" justify="space-between">
                <FormLabel mb={1}>Password</FormLabel>
                <Box
                  fontWeight="semibold"
                  fontSize="sm"
                  color={mode('blue.600', 'blue.200')}
                  as={Link}
                  to='password-recovery'
                >
                  Forgot Password?
                </Box>
              </Flex>
              <Field name='password'>
                {({ field, form, meta }) => (
                  <Input {...field} type="password" autoComplete="current-password" />
                )}
              </Field>

            </FormControl>
            {error ?
              <Alert status='error' borderRadius='md'>
                <AlertIcon />
                Your login details appear to be incorrect. Try again or start a password recovery.
              </Alert>
              : null}
            {/* <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              fontSize="md"
              isLoading={isLoading}
            >
              Log in to my account
            </Button> */}
            <Box>
              <PrimaryButton
                type="submit"
                isLoading={isLoading}
              >
                Login
              </PrimaryButton>
            </Box>

            {/* <Debug data={[errors, touched, values, Object.keys(errors).length]} /> */}

          </Stack>

        </Form>

      )}


    </Formik>
  )
}
