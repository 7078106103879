import { Box, Button, Flex, Heading, HStack, Stack, Tag } from '@chakra-ui/react'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment';

interface programCardProps {
    _id: string,
    name: string,
    status: string,
    category?: string,
    minor?: string,
    launchDate?: string
    toDetails: string,
    toEdit: string
}

export const ProgramCard: React.FC<programCardProps> = ({
    _id,
    name,
    status,
    category,
    minor,
    launchDate,
    toDetails,
    toEdit,
    primaryCoursePlan
}) => (
    <Box
        p={3}
        py={4}
        boxShadow='var(--shadow-elevation-medium)'
        borderRadius={'sm'}



    >
        <Stack>
            <Heading
                as='h4'
                textTransform={'uppercase'}
                letterSpacing={'wide'}
                fontSize={'xs'}
            >{name}</Heading>

            <Flex wrap='wrap'>
                <Tag colorScheme={'blue'} m={0.5} size='sm'>Status : {status}</Tag>
                <Tag colorScheme={'green'} m={0.5} size='sm'>Category : {category}</Tag>
                {minor ?
                    <Tag colorScheme={'blue'} m={0.5} size='sm'>Minor : {minor}</Tag>
                    : null}
                {launchDate ? <Tag m={0.5} size='sm'>Launch : {moment(launchDate).format('YYYY')}</Tag> : null}

            </Flex>
            <HStack justify={'flex-end'}>
                <Button
                    size="xs"
                    as={RouterLink}
                    to={toDetails}
                >View</Button>
                <Button
                    size="xs"
                    as={RouterLink}
                    to={toEdit}
                >Edit</Button>
            </HStack>
        </Stack>
    </Box>
)