import { Box, Divider, Flex, Text } from "@chakra-ui/layout"
import axios from "axios";
import * as React from "react"
import { Router, RouteComponentProps, Link as RouterLink } from "@reach/router"
import { Tag } from "@chakra-ui/tag";
import ReactTimeAgo from 'react-time-ago'
import { Button } from "@chakra-ui/react";

const Logs = (props: RouteComponentProps) => {

    const [data, setData] = React.useState([]);

    React.useEffect(async () => {
        getData()
    }, []);

    const getData = async () => {
        const result = await axios(
            `${process.env.REACT_APP_API_URL}/log`
        );
        setData(result.data);
    }

    const Level = ({ level }) => (
        <Tag
            fontSize={'xs'}
            size='sm'
            colorScheme={
                level === 'info' ? 'green'
                    : level === 'error' ? 'orange'
                        : 'gray'
            }
        >{level}</Tag>
    )

    const handleDelete = () => {
        axios.delete(
            `${process.env.REACT_APP_API_URL}/log`
        ).then(res => getData());
    }

    return (
        <Box
            p={10}
            m={10}
            borderRadius='2xl'
            bg='gray.900'
            color='gray.50'
            fontFamily='mono'
            fontSize={'xs'}
        >
            <Flex
                justify={'space-between'}
            >
                <Text
                    fontWeight={'600'}
                >
                    LOGS
                </Text>
                <Button
                    onClick={() => handleDelete()}
                    variant='outline'
                    colorScheme={'black'}
                >
                    Delete all
                </Button>
            </Flex>

            {data && data.map(item =>
                <Box py={4}>
                    <Box
                        mb={2}
                    >
                        <Level level={item.level} />{' '}
                        <Text as='span' color='green.400'>
                            <ReactTimeAgo date={item.timestamp} />
                            {' '}:{''}
                        </Text>
                    </Box>
                    <Text>
                        {item.message}
                    </Text>
                    <Divider py={4} />
                </Box>

            )}
        </Box>
    )
}

export default Logs