import { Box, Button, Divider, Flex, FormLabel, Input, SimpleGrid, Stack } from '@chakra-ui/react'
import axios from 'axios'
import { Form, Field, Formik } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import FieldBlock from '../FieldBlock'
import * as Yup from 'yup'
import { config } from '../../Utils/Config'

export const NewPasswordForm = ({ attempt }) => {
    const navigate = useNavigate();
    const schema = Yup.object().shape({
        password:
            Yup.string()
                .required('You must create a password')
                .min(6, '6 characters minimum')
                .nullable(),
        passwordConfirmation:
            Yup.string()
                .required('You must create a password')

                // .required('Password is required please')
                .oneOf([Yup.ref('password'), null], 'It\'s not same :/')
    });

    return (
        <Box>
            <Formik
                initialValues={{
                    // user: attempt.user._id,
                    attempt: attempt._id
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                    axios.post(`${config.apiUrl}/password-recovery/`, values)
                        .then(result =>
                            navigate('./../../')
                            // console.log('updated')
                        );
                }}
            >{({ values, errors, submitCount, touched }) => (
                <Stack
                    spacing={6}
                    as={Form}
                >
                    <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                        <Field name='password'>
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    isInvalid={meta.error && meta.touched}
                                    error={meta.error}
                                    isRequired={true}
                                >
                                    <FormLabel>Password</FormLabel>
                                    <Input autocomplete="new-password" {...field} type='password' />
                                </FieldBlock>
                            )}
                        </Field>
                        <Field name='passwordConfirmation'>
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    isInvalid={meta.error && meta.touched}
                                    error={meta.error}
                                    isRequired={true}
                                >
                                    <FormLabel>Confirmation</FormLabel>
                                    <Input autocomplete="new-password" {...field} type='password' />
                                </FieldBlock>
                            )}
                        </Field>
                    </SimpleGrid>
                    <Divider />
                    <Flex>
                        <Button
                            rightIcon={<BiRightArrowAlt />}
                            colorScheme='blue'
                            type='submit'

                            isDisabled={
                                Object.keys(errors).length > 0
                                || Object.keys(touched).length === 0
                            }
                        >Save</Button>

                        {/* <Debug data={errors} /> */}
                    </Flex>
                </Stack>






            )}
            </Formik>
        </Box >
    )
}