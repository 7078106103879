import {
    Center,
    Heading,
    Text,
    Stack,
    Divider,
    HStack,
    Button
} from '@chakra-ui/react'
import * as React from 'react'
import { PublicLayout } from '../Components/PublicLayout'
import { Link as RouterLink } from 'react-router-dom'
import { BiLogInCircle, BiUserPlus } from 'react-icons/bi'

import studentLibray from '../Images/student-library.jpeg'
import { PrimaryButton } from '../UI/PrimaryButton'
import { PublicLayoutNew } from '../Components/PublicLayout/new'

export const WelcomeScreen = () => (
    <PublicLayoutNew>
        <Stack
            // w='full'
            spacing={6}
            maxW={`3xl`}
        >
            <Heading
                color={`brand.blue.900`}
                fontSize={{ base: '3xl' }}
                letterSpacing={'tight'}
                fontFamily={`Georgia`}
                fontStyle={`italic`}

            >
                Welcome to EABHES Portal
            </Heading>

            <Text>
                The platform for EABHES members to share information, access resources and more.
            </Text>
            <Text
                fontFamily={`Georgia`}
                fontStyle={`italic`}
            >
                {/* Facilitating International Dialogue for Quality Higher Education */}


            </Text>

            <Divider />

            <HStack>
                <PrimaryButton
                    leftIcon={<BiLogInCircle />}
                    as={RouterLink}
                    to='/login'
                >
                    Login
                </PrimaryButton>
                <PrimaryButton
                    leftIcon={<BiUserPlus />}
                    as={RouterLink}
                    to='/signup'
                >
                    Signup
                </PrimaryButton>
            </HStack>
        </Stack>
    </PublicLayoutNew>
)