import * as Yup from 'yup';

const ProgramFormSchema = Yup.object().shape({
    name:
        Yup.string(),
    // .required('Required'),
    code:
        Yup.string()
            .min(2, 'Too Short!')
            .max(20, 'Too Long!'),
    school:
        Yup.string()
            .required('Required'),
    status:
        Yup.string()
            .required('Required'),
    major:
        Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Required'),
    category:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    presentation:
        Yup.string()
            .min(2, 'Too Short!')
    // .required('Required'),
    ,
    intakesMonth:
        Yup.string()
            .required('Required'),
    endMonth:
        Yup.string()
            .required('Required'),
    semestersNumber:
        Yup.number()
            .min(1, 'Too Short!')
            .max(12, 'Too Long! (max 12)')
            .required('Required'),
    lowestNote:
        Yup.number().required(),
    highestNote:
        Yup.number().required()

});

export { ProgramFormSchema }