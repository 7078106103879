import { Box, Button, Portal, SimpleGrid, Flex, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import Debug from '../Forms/Debug'
import { arrayIntRange } from '../Utils/arrayIntRange'
let lodash = require('lodash')
export const SemesterCoursesList = ({ courses, semester }) => {

    const getCourseLength = (course) => {
        let courseLength = arrayIntRange(course.startSemester, course.endSemester).length;
        return courseLength
    }

    const calculatedValue = (course, key) => {
        // let courseLength = getCourseLength(course);
        // return course[key] / courseLength;
        // console.log(course.semesters[semester])

        // semester == 3
        // course.startSemester == 3
        // => course.semesters[0]

        // semester == 4
        // course.startSemester == 3
        // => course.semesters[1]

        return (
            course.semesters[semester - course.startSemester] && course.semesters[semester - course.startSemester][key]
                ? course.semesters[semester - course.startSemester][key]
                : ''
        )
    }

    const CourseLine = ({ course }) => {


        return (

            <Tr>
                <Td fontSize='xs' width={'70px'}>
                    {course.name}
                </Td>
                <Td isNumeric fontSize='xs' >
                    {calculatedValue(course, 'hours')}h
                </Td>
                <Td isNumeric fontSize='xs' >
                    {calculatedValue(course, 'personalWorkLoad')}h
                </Td>
                <Td isNumeric fontSize='xs' >
                    {
                        (
                            calculatedValue(course, 'personalWorkLoad') + calculatedValue(course, 'hours')
                        )}h
                </Td>
                <Td isNumeric fontSize='xs' >
                    {calculatedValue(course, 'ectsCredits')}
                    {/* {course.ectsCredits / courseLength} */}
                    {/* {course.ectsCredits} */}
                </Td>
            </Tr>
        )

    }

    return (
        <Box
            bgColor={semester % 2 === 0 ? 'gray.50' : 'white'}
            p={4}
            border='solid 1px'
            borderColor={'gray.100'}
        >

            <Text
                fontSize={'sm'}
                px={4}
                mb={4}
            >
                Semester : <strong>{semester}</strong>
            </Text>
            {/* <Debug data={courses} /> */}


            <Flex
                flexDirection={'column'}
                height={'full'}
            >
                <Box
                    flex="1 1 auto"
                >
                    <Table
                        size='sm'
                    >
                        <Thead>
                            <Tr>
                                <Th>Course</Th>
                                <Th>CWL</Th>
                                <Th>PWL</Th>
                                <Th>Total</Th>
                                <Th>ECTS</Th>
                            </Tr>
                        </Thead>

                        <Tbody>

                            {courses.map(item =>
                                <>
                                    <CourseLine key={item._id} course={item} />
                                    {/* <Tr>
                                        <Td colspan={5}>
                                            <Debug data={item.semesters} />
                                        </Td>
                                    </Tr> */}
                                </>
                            )}

                        </Tbody>
                    </Table>
                </Box>

                <Box
                    flex="0 1 65px"
                >
                    <Table
                        size='sm'
                        fontWeight={'bold'}

                    >
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td fontSize='xs' width={'70px'}>
                                    Total
                                </Td>
                                <Td isNumeric fontSize='xs'>
                                    {lodash.sum(courses.map(item => calculatedValue(item, 'hours')))}h
                                </Td>
                                <Td isNumeric fontSize='xs'>
                                    {lodash.sum(courses.map(item => calculatedValue(item, 'personalWorkLoad')))}h
                                </Td>

                                <Td isNumeric fontSize='xs'>
                                    {
                                        lodash.sum(courses.map(item => calculatedValue(item, 'hours')))
                                        +
                                        lodash.sum(courses.map(item => calculatedValue(item, 'personalWorkLoad')))
                                    }h
                                </Td>
                                <Td isNumeric fontSize='xs'>
                                    {lodash.sum(courses.map(item => calculatedValue(item, 'ectsCredits')))}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
            </Flex>
        </Box >
    )
}