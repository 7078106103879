import { Button, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiPlus, BiX } from 'react-icons/bi'

interface addButtonProps {
    onClick?: Function,
}

export const RemoveButton: React.FC<addButtonProps> = (props) => (
    <Button
        type="button"
        // size='xs'
        {...props}
        colorScheme={'orange'}
    >
        <Text
            fontSize={'22px'}
            as={`span`}
        >
            <BiX />
        </Text>
    </Button>
)