import { Box, Badge, Button, Flex, Heading, HStack, List, ListItem, Stack, Text, Select, Tag, ButtonGroup, Portal, Tbody } from '@chakra-ui/react'
import axios from 'axios';
import * as React from 'react'
import { useParams, useNavigate } from 'react-router';
import { UserCard } from '../Forms/User/Card/Card';
import { config } from '../Utils/Config';
import { Link } from 'react-router-dom'
import { Map } from '../Components/Map';
import { BiAddToQueue, BiEditAlt, BiImport, BiInfoSquare, BiLeftArrowAlt, BiPrinter, BiUserCheck } from 'react-icons/bi';

import { Thead, Table, Tr, Td, Th } from '@chakra-ui/react'

import { StatsList } from '../Stats';
import { ectsToUsCredits } from '../Utils/ectsToUsCredits';
import moment from 'moment';
import { BackButton } from '../UI/BackButton';
import { ProgramQuickStatusForm } from '../Forms/Program/Status';
import { ProgramTimeline } from './Timeline';
import { CodeTag } from './CodeTag';
import Debug from '../Forms/Debug';
import { CoursePlansSwitcher } from './Switcher';
import { CoursePlansList } from './CoursePlan/CoursePlansList';
import { CoursePlansAddButton } from './CoursePlan/CoursePlansAddButton';
import { Loader } from '../UI/Loader';
import { ProgramPromotions } from './Promotions';
import { UploadButton } from '../Upload/Button';
import { DocumentsList } from '../Document/List';
import { CourseLayerDetails } from '../Course/LayerDetails';
import { ProgramPerioView } from './ProgramPeriodView';
import { CoursePeriodView } from './CoursePeriodView';
import { ProgrameCoursesList } from './ProgrameCoursesList';

var lodash = require('lodash');

export const ProgramView = ({ view }) => {
    let params = useParams();
    const { programId, coursePlanId } = params;
    const navigate = useNavigate();

    React.useEffect(() => {
        getData();
    }, [coursePlanId]);

    const [data, setData] = React.useState(null)
    const [coursePlan, setCoursePlan] = React.useState(null)

    const [courseDetailId, setCourseDetailId] = React.useState(null)
    const [clickedElementPosition, setClickedElementPosition] = React.useState(null)
    const [semestersCourseView, setSemestersCourseView] = React.useState(false)

    const [showCourses, setShowCourses] = React.useState(false)


    const getData = () => {
        axios.get(`${config.apiUrl}/program/${programId}`)
            .then(result => setData(result.data));

        axios.get(`${config.apiUrl}/course-plan/${coursePlanId}`)
            .then(result => setCoursePlan(result.data));

        // axios.get(`${config.apiUrl}/course-plan/${coursePlanId}`)
        //     .then(result => setData({
        //         ...data,
        //         courseplan: result.data
        //     }));
    }

    const amount = (item) => (
        item.course && item.course.ectsCredits ? item.course.ectsCredits : 0
    )

    const hours = (item) => (
        item.course && item.course.hours ? item.course.hours : 0
    )
    const personalWorkLoad = (item) => (
        item.course && item.course.personalWorkLoad ? item.course.personalWorkLoad : 0
    )
    const sum = (prev, next) => prev + next

    return (
        data && coursePlan ?
            <Box
                maxW='5xl'
                mx='auto'
                bgColor='white'
                p={10}
            >

                {semestersCourseView ?
                    <CoursePeriodView
                        data={data}
                        coursePlan={coursePlan}
                        handleClose={() => setSemestersCourseView(false)}
                    />
                    : null}

                {courseDetailId ?
                    <CourseLayerDetails
                        position={clickedElementPosition}
                        data={data}
                        courseDetailId={courseDetailId}
                        setCourseDetailId={setCourseDetailId}
                        view={view}
                    />

                    : null}

                <Box>
                    {/* <Debug data={data.coursePlans} /> */}
                    {/* <Debug data={data.courseMaps.filter(item => item._id === coursePlanId)[0].courses} /> */}
                    <Stack spacing={10}>
                        <Flex justifyContent={'space-between'}>
                            <BackButton
                                label='Back'
                            />
                            <HStack>

                                {/* <Button
                                    size='sm'
                                    as={Link}
                                    to={`../course-catalogue/${data._id}/${coursePlanId}`}
                                    leftIcon={<BiEditAlt />}
                                >
                                    Courses catalogue
                                </Button> */}

                                {view === 'admin' ?
                                    <Button
                                        size='sm'
                                        as={Link}
                                        to={`../edit/${data._id}`}
                                        leftIcon={<BiEditAlt />}
                                    >
                                        Update
                                    </Button>
                                    : null}
                                {/* <Button
                                    size='sm'
                                    leftIcon={<BiPrinter />}
                                    isDisabled={true}
                                    as={'a'}
                                    target='_blank'
                                    href={`${config.apiUrl}/program/${data._id}/course-catalog`}
                                >Edit course catalog</Button> */}

                                <CoursePlansSwitcher
                                    coursePlans={data.coursePlans}
                                    programId={programId}
                                    coursePlanId={coursePlanId}
                                    size='sm'
                                />
                            </HStack>
                        </Flex>
                        <Box>
                            <Heading mb={2}>
                                {/* {data.code} -{' '} */}
                                {data.name}
                            </Heading>
                            <Heading fontSize={'md'}>
                                School :{' '}
                                <Text
                                    as={Link}
                                    textDecor={`underline`}
                                    to={`/${view}/school/view/${data.school._id}`}
                                >
                                    {data?.school.acronym}
                                </Text>
                                {' '}/{' '}
                                Course plan :{' '}
                                <Text as='span' textDecor={'underline'}>
                                    {coursePlan.name}
                                </Text>
                            </Heading>




                            {/* <Debug data={coursePlan} /> */}

                            {/* <Box>
                                <Stack direction='row' ml={0}>
                                    <Badge colorScheme={data.code === 'admin' ? 'blue' : 'gray'}> {data.code}</Badge>
                                    <Badge colorScheme={data.status === 'accredited' ? 'green' : 'gray'}> {data.status}</Badge>
                                </Stack>
                            </Box> */}
                        </Box>
                        {/* <HStack>
                            <Heading fontSize='md'>Current process steps :</Heading>
                            <HStack>
                                {data.processSteps.map(item => <Tag colorScheme={'blue'} key={item}>{item}</Tag>)}
                            </HStack>
                        </HStack> */}
                        {/* <Debug data={data} /> */}


                        <StatsList
                            data={
                                [
                                    { "label": "Level", "value": `${data.level.slice(0, 1).toUpperCase()}${data.level.slice(1, 100)}` },
                                    { "label": "Category", "value": data.category },
                                    { "label": "Major", "value": data.major },
                                    { "label": "Tuition fees", "value": lodash.sum(data.fees.map(fee => parseInt(fee))) + '€' },
                                    { "label": "Accreditation", "value": data.accreditation && data.accreditation.date ? moment(data.accreditation.date).format('YYYY') : '' },

                                ]
                            }
                        />

                        <StatsList
                            data={
                                [
                                    { "label": "Semesters", "value": data.semestersNumber },
                                    { "label": "Courses number", "value": coursePlan.courses.length },
                                    { "label": "ECTS Credits", "value": coursePlan.courses && coursePlan.courses.length ? coursePlan.courses.map(amount).reduce(sum) : '-' },
                                    { "label": "US Credits", "value": coursePlan.courses && coursePlan.courses.length ? ectsToUsCredits(coursePlan.courses.map(amount).reduce(sum)) : '-' },

                                    { "label": "Course hours", "value": coursePlan.courses && coursePlan.courses.length ? coursePlan.courses.map(hours).reduce(sum) : '-' },
                                    { "label": "Work load", "value": coursePlan.courses && coursePlan.courses.length ? coursePlan.courses.map(personalWorkLoad).reduce(sum) : '-' },

                                    { "label": "FLT", "value": data.foreignLanguageTest.isRequired ? `${data.foreignLanguageTest.ectsCredits} ECTS` : `-` },
                                ]
                            }
                        />

                        {data && data.primaryCoursePlan ?
                            <Box>
                                <Heading fontSize='md'>Course plan{data.coursePlans.length > 1 ? 's' : ''} :</Heading>
                                <CoursePlansList
                                    data={data.coursePlans}
                                    updateData={() => getData()}
                                />
                                <Flex justify={'flex-end'} my={2}>
                                    <CoursePlansAddButton
                                        handleSubmit={() => getData()}
                                        programId={programId}
                                    />
                                </Flex>
                            </Box>
                            : null}

                        <Box>
                            <Heading fontSize='md'>
                                Courses :{' '}
                                <Button onClick={() => setShowCourses(!showCourses)} size='xs'>{!showCourses ? `Show` : `Hide`} courses list</Button>

                            </Heading>
                            {showCourses ?
                                <ProgrameCoursesList
                                    data={data}
                                    view={view}
                                    setCourseDetailId={(value: String) => setCourseDetailId(value)}
                                />
                                :
                                null
                            }

                            <Flex
                                justify='space-between'
                                my={2}
                            >
                                <ButtonGroup
                                    display={view === `admin` ? `block` : `none`}
                                >
                                    <Button
                                        size='xs'
                                        rightIcon={<BiImport />}
                                        as={Link}
                                        to={`/admin/course/import/program/${programId}`}
                                    >
                                        Mass import
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup
                                    justify='flex-end'

                                >
                                    <Button
                                        onClick={() => setSemestersCourseView(true)}
                                        size='xs'
                                    >
                                        View per semester
                                    </Button>
                                    <Button
                                        size="xs"
                                        as={Link}
                                        to={`../../course/new/${data._id}`}
                                        leftIcon={<BiAddToQueue />}
                                    >
                                        Add a new course
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Box>

                        {/* {
                            coursePlanId
                                && data.coursePlans.filter(item => item._id === coursePlanId)[0].courses ?
                                <Box>
                                    <Flex justify={'space-between'}>
                                        <Heading fontSize='md'>Program's courses plan timeline :</Heading>
                                        <HStack>
                                            <CoursePlansSwitcher
                                                coursePlans={data.coursePlans}
                                                programId={programId}
                                                coursePlanId={coursePlanId}
                                            />

                                            
                                        </HStack>
                                    </Flex>
                                    <Box>
                                        <ProgramTimeline
                                            program={data}
                                            courses={coursePlan.courses}
                                        />
                                    </Box>
                                </Box>
                                : null} */}

                        <Stack spacing={2} id='promotions'>
                            <Heading
                                mb={4}
                                fontSize='md'>Programme promotions :</Heading>
                            <ProgramPromotions
                                programId={programId}
                                program={data}
                            />
                            <Box>
                                <Button
                                    size='xs'
                                    as={Link}
                                    to={
                                        view === 'admin' ?
                                            `/admin/student/import/${data._id}`
                                            :
                                            `/account/student/import/${data._id}`
                                    }
                                >
                                    Record new inscriptions for this programme.
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={4}>
                            <Heading
                                fontSize='md'>Programme's documents :
                            </Heading>

                            <DocumentsList
                                data={data.documents}
                                deleteCallback={() => getData()}
                                deleteAllowed={true}
                            />
                            <UploadButton
                                linkedDocumentId={programId}
                                uploadCallback={() => getData()}
                            />

                        </Stack>


                    </Stack>
                </Box>


            </Box>
            : <Loader label="Load programme's details" />
    )
}
