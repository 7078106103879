import { Button, Grid, FormLabel, Input, SimpleGrid, Text, Box, VStack, Stack, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import Debug from '../Forms/Debug'
import { config } from '../Utils/Config'
const lodash = require('lodash')


export const SingleInscriptionNotes = (
    { data }
) => {

    const toast = useToast();

    let coursPlanCoursesIds = data.coursePlan.courses.map(item => item.course);
    let coursePlanCourses = data.program.courses.filter(
        item => coursPlanCoursesIds.includes(item._id)
    );

    let NotesFields = ({ year }) => {

        let yearSemesters = [
            ((year - 1) * 2 + 1),
            ((year - 1) * 2 + 2)
        ]

        let notesFieldsList = []
        for (let index = 0; index < coursePlanCourses.length; index++) {
            const course = coursePlanCourses[index];
            let courseSemesters = lodash.range(course.startSemester, course.endSemester + 1, 1);

            if (!lodash.intersection(yearSemesters, courseSemesters).length) continue

            notesFieldsList.push(
                <Field name={`${year}.${course._id}`}>
                    {({ field, meta }) => (
                        <Grid
                            templateColumns={`180px 1fr`}
                            alignItems='center'
                        >
                            <FormLabel fontSize={'xs'}>
                                {course.name}
                                {/* {course.startSemester} */}
                            </FormLabel>
                            <Input
                                {...field}
                                variant='outline'
                                bgColor='white'
                                size='sm'
                            />
                        </Grid>
                    )}

                </Field>
            )

        }

        return (
            <Box
                bgColor='gray.50'
                p={2}
                rounded={4}
                shadow='xs'
                border='solid 1px'
                borderColor={'gray.200'}
            >
                <Text>Year {year}</Text>
                <Stack>
                    {notesFieldsList}
                </Stack>
            </Box>
        )


    }

    const handleSubmit = async values => {
        for (const [key, value] of Object.entries(values)) {
            await axios.post(
                `${config.apiUrl}/inscription/note`,
                {
                    year: parseInt(key),
                    program: data.program._id,
                    coursePlan: data.coursePlan._id,
                    startYear: data.startDate,
                    endYear: data.endDate,
                    notes: {
                        [data._id]: value
                    }
                }
            ).then(res => {
                toast({
                    title: `Inscription notes recorded for year ${key}`,
                    description: "We've update this inscription notes in database.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
            )
        }
    }

    const formatInitialValue = () => {

        let formattedNotes = {};
        let allNotes = data.notes;
        for (let index = 0; index < allNotes.length; index++) {
            const yearNote = allNotes[index];
            formattedNotes[yearNote.year] = {}
            for (let index2 = 0; index2 < yearNote.values.length; index2++) {
                const note = yearNote.values[index2];
                formattedNotes[yearNote.year][note.course._id] = note.note
            }
        }
        return formattedNotes
    }

    const YearNotesFields = () => {
        let yearNotesFieldsList = [];
        let numberYear = Math.ceil(data.program.semestersNumber / 2);

        for (let index = 1; index <= numberYear; index++) {
            yearNotesFieldsList.push(
                <NotesFields year={index} />
            )

        }

        return <>{yearNotesFieldsList}</>
    }

    return (
        <>
            {/* <Debug data={data} /> */}
            <Formik
                onSubmit={(values) => handleSubmit(values)}
                initialValues={formatInitialValue()}
            >
                {({ values }) => (
                    <Form>
                        <Stack
                            spacing={4}
                        >
                            <SimpleGrid
                                columns={2}
                                gap={4}
                            >
                                <YearNotesFields />
                            </SimpleGrid>
                            <Box>
                                <Button
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </Box>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    )
}