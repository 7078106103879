import axios from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import Debug from "../../Forms/Debug";
import { Loader } from "../../UI/Loader";
import { config } from "../../Utils/Config";
import { ToolsButtons } from "../Diploma/ToolsButtons";
import { TranscriptLayout } from "./Layout";

export const TranscriptDocument = (props) => {
  let params = useParams();
  let { inscriptionId, single } = props ? props : params;

  const [bg, setBg] = React.useState(props.bg);

  const [data, setData] = React.useState();
  const [promoNotes, setPromoNotes] = React.useState();

  React.useEffect(() => {
    if (!data) {
      axios
        .get(`${config.apiUrl}/inscription/${inscriptionId}`)
        .then((res) => setData(res.data));
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      axios
        .get(
          `${config.apiUrl}/inscription/${data.program._id}/notes/${data.startDate}/${data.endDate}`
        )
        .then((res) => setPromoNotes(res.data));
    }
  }, [data]);

  const countAllNotes = (notes) => {
    let result = 0;
    for (let index = 0; index < notes.length; index++) {
      const year = notes[index];
      result = year.values.length + result;
    }
    return result;
  };

  const diplomaTitle = data
    ? `Transcript : ${data.student.firstName} ${data.student.lastName} ¬ ${data.program.localName} : ${data.startDate}-${data.endDate}`
    : "Document generation in progress";

  const programLengthInYear = (data) =>
    Math.round(data.program.semestersNumber / 2);

  const YearPages = () => {
    const result = [];

    for (let index = 1; index <= programLengthInYear(data); index++) {
      // const element = array[index];
      result.push(
        <TranscriptLayout
          data={data}
          promoNotes={promoNotes}
          bg={bg}
          page={index}
          lastPage={parseInt(programLengthInYear(data))}
        />
      );
    }

    return <>{result}</>;
  };

  return data && promoNotes ? (
    <>
      {single ? (
        <ToolsButtons
          handleChangeBg={() => setBg(!bg)}
          diplomaTitle="Title"
          bg={bg}
          exportUrl={`${config.apiUrl}/inscription/transcript/${inscriptionId}/${bg}/${diplomaTitle}`}
          showNoticeLink={
            data.school.displaySchoolLogoOnDocuments ? false : true
          }
        />
      ) : null}

      {/* <Debug data={countAllNotes(data.notes)} /> */}
      {countAllNotes(data.notes) <= 35 ? (
        <TranscriptLayout data={data} promoNotes={promoNotes} bg={bg} />
      ) : (
        <YearPages />
      )}
      {/* // <YearPages /> */}
    </>
  ) : (
    <>
      <Loader label="Creating transcript" />
    </>
  );
};
