import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Grid,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { DiplomaHeader } from "./Header";
// import { DiplomadQrCode } from './QrCode'

import diplomaBgImage from "../../Images/transcript-background.jpg";
import { useParams } from "react-router-dom";
import { TranscriptHeader } from "../Transcript/Header";
import { Loader } from "../../UI/Loader";
import { DiplomaLayout } from "./Layout";
import axios from "axios";
import { config } from "../../Utils/Config";
import { DiplomaBody } from "./Body";
import { DiplomaFooter } from "./Footer";
import { BgSwitcher } from "./BgSwitcher";
import { GrDocumentPdf } from "react-icons/gr";
import { ToolsButtons } from "./ToolsButtons";
import Debug from "../../Forms/Debug";
import { RiFileForbidLine } from "react-icons/ri";
import { DiplomaFailure } from "./Failure";

export const DiplomaContext = React.createContext();

export const DiplomaDocument = (props) => {
  let params = useParams();
  let { inscriptionId, single } = props ? props : params;

  const [bg, setBg] = React.useState(props.bg);

  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (!data) {
      axios
        .get(`${config.apiUrl}/inscription/${inscriptionId}`)
        .then((res) => setData(res.data));
    }
  });

  const diplomaTitle = data
    ? `Diploma : ${data.student.firstName} ${data.student.lastName} ¬ ${data.program.localName} : ${data.startDate}-${data.endDate}`
    : "Document generation in progress";

  return data ? (
    <>
      <DiplomaContext.Provider value={{ bg: bg }}>
        {single ? (
          <ToolsButtons
            handleChangeBg={() => setBg(!bg)}
            diplomaTitle={diplomaTitle}
            inscriptionId={inscriptionId}
            bg={bg}
            exportUrl={`${config.apiUrl}/inscription/diploma/${inscriptionId}/${bg}/${diplomaTitle}`}
          />
        ) : null}

        {data.status === "completed" ? (
          // || data.status !== 'completed'
          <DiplomaLayout
            bg={bg}
            body={[
              <DiplomaHeader data={data} />,
              <DiplomaBody data={data} />,
              <DiplomaFooter data={data} />,
            ]}
            data={data}
          />
        ) : (
          <DiplomaFailure />
        )}
      </DiplomaContext.Provider>
    </>
  ) : (
    <Loader label={`Preparing the diploma ${inscriptionId}`} />
  );
};
