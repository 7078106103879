import { Box, Circle, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import {
  BiBuoy,
  BiEnvelope,
  BiHome,
  BiGroup,
  BiBriefcaseAlt2,
  BiBookBookmark,
  BiBox,
  BiUserCircle,
  BiPyramid,
  BiBook,
} from 'react-icons/bi'
import { Outlet } from 'react-router'
import { UserContext } from '../../Auth/userContext'

import { AccountSwitcher } from './AccountSwitcher'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'

const UserLayout = ({ children }) => {

  const { user } = React.useContext(UserContext);

  return (
    user ?
      <Box height="100vh" overflow="hidden" position="relative">
        <Flex h="full" id="app-container">
          <Box w="64" bg="white" color="white" fontSize="sm">
            <Flex h="full" direction="column" px="4" py="4">
              <AccountSwitcher />
              <Stack spacing="8" flex="1" overflow="auto" pt="8">
                <Stack spacing="1">
                  {/* <NavItem active icon={<BiHome />} label="Dashboard" /> */}
                </Stack>
                <NavGroup label="Management">
                  <NavItem icon={<BiUserCircle />} label="Your profile" to={`/account/user/${user._id}`} />
                  <NavItem icon={<BiBriefcaseAlt2 />} label="School profile" to='/account/school' />
                  <NavItem icon={<BiBookBookmark />} label="Programmes" to='/account/program' />
                  <NavItem icon={<BiBox />} label="Courses" to='/account/course' />
                  <NavItem icon={<BiGroup />} label="Students" to='/account/student' />
                  <NavItem icon={<BiPyramid />} label="Curriculum" to='/account/inscription' />

                  {/* <NavItem icon={<BiWallet />} label="Income" /> */}
                  {/* <NavItem icon={<BiRedo />} label="Transfer" /> */}
                </NavGroup>

              </Stack>
              <Box>
                <Stack spacing="1">
                  <NavItem
                    // subtle
                    icon={<BiBook />} label="Members area" to='/account/documents' />
                  <NavItem
                    subtle
                    icon={<BiBuoy />}
                    label="Help & Support"
                    endElement={<Circle size="2" bg="blue.400" />}
                    href={'https://www.notion.so/olivierlivet/636fe7e7825743d098a182e4c2c72052?v=e7b7055e2e58462ebcf6a90b099e98a1'}
                    target='_blank'
                  />
                </Stack>
              </Box>
            </Flex>
          </Box>
          <Box bg={mode('gray.100', 'gray.800')} flex="1" p="6" overflow='scroll'>
            <Box
              w="full"
              h="full"
              rounded="lg"
              id='content'

            // border="3px dashed currentColor"
            // color={mode('gray.200', 'gray.700')}
            >
              {children}
              {<Outlet />}
            </Box>
          </Box>
        </Flex>
      </Box>
      : null
  )
}

export default UserLayout