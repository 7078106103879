import { Box, Button, Flex, Heading, HStack, Text } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import Debug from '../Forms/Debug'
import { config } from '../Utils/Config'

export const DocumentsList = ({ data, deleteCallback, deleteAllowed }) => {

    if (!data) return null

    const handleDeleteDocument = path => {

        axios.delete(
            `${config.apiUrl}/document/`,
            {
                data: {
                    path: path
                }
            }
        ).then(
            () => deleteCallback()
        );
    }

    return (
        <Box>

            {data && data.length ? data.map(item =>

                item.Key.indexOf('invoice') === -1 ?
                    <HStack
                        key={item.ETag}
                        justify={'space-between'}
                        w='full'
                        borderBottom={'solid 1px'}
                        borderBottomColor='gray.100'
                        py={1}
                    >
                        <Flex
                            as={'a'}
                            href={`${config.apiUrl}/document/${item.Key}`}
                            target='_blank'
                            alignItems={'center'}
                        >
                            <Heading
                                isTruncated
                                as='p'
                                maxW='xl'
                                fontWeight={600}
                                fontSize='xs'
                            // bgColor='blue.50'
                            // border='solid 1px'
                            // borderColor={'blue.100'}
                            // px={2}
                            // py={.5}
                            // borderRadius='lg'
                            // color={'blue.800'}
                            >
                                {item.Key.split('/').slice(-1)}
                            </Heading>
                            <Text
                                fontSize='xs'
                                color='gray.500'
                                ml={1}
                                fontWeight={600}
                            >
                                {Math.ceil((item.Size / 1000))}ko
                            </Text>

                        </Flex>

                        {deleteAllowed === true ?
                            <Button
                                size='xs'
                                onClick={() => handleDeleteDocument(item.Key)}
                            >
                                <BiX />
                            </Button>
                            : null}
                    </HStack>
                    : null

            ) :
                <Text color='gray.500' fontSize='sm'>
                    No document to display
                </Text>
            }
        </Box>
    )
}