import { Badge, Box, Heading, Link } from '@chakra-ui/layout'
import axios from 'axios';
import * as React from 'react'
import { config } from '../Utils/Config';
import { Outlet, useParams } from 'react-router';
import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
import { Loader } from '../UI/Loader';

const UserList = () => {
    const navigate = useNavigate();

    const params = useParams();

    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState('')
    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [schools, setSchools] = React.useState(null)


    React.useEffect(() => {
        getData();
    }, [params.page, search]);


    React.useEffect(() => {
        getSchools();
    }, []);

    const getData = () => {

        axios.get(
            `${config.apiUrl}/user/${params.page ? params.page : 1}/${search.search ? search.search : '-'}/${search.role ? search.role : '-'}/${search.status ? search.status : '-'}/${search.school ? search.school : '-'}`
        )
            .then(result => {
                setData(result.data);
                setLastPage(parseInt(result.headers.lastpage));
                setCurrentPage(parseInt(result.headers.currentpage));
                setTotalResults(parseInt(result.headers.results));
            })

        // axios.get(`${config.apiUrl}/user/${params.page ? params.page : ''}`)
        //     .then(result => {
        //         setData(result.data);
        //         setLastPage(parseInt(result.headers.lastpage));
        //         setCurrentPage(parseInt(result.headers.currentpage));
        //         setTotalResults(parseInt(result.headers.results));
        //     })

    }

    const getSchools = () => {
        axios.get(
            `${config.apiUrl}/school/all`
        )
            .then(result => {
                setSchools(result.data);
            })

    }

    const Role = ({ role }) => (
        role === 'user' ?
            <Badge colorScheme='teal'>user</Badge>
            : role === 'admin' ?
                <Badge colorScheme='orange'>admin</Badge>
                : null
    )

    const Status = ({ status }) => (

        <Badge
            colorScheme={
                status === 'created' ? 'teal'
                    : status === 'updated' ? 'teal'
                        : status === 'activated' ? 'green'
                            : status === 'archived' ? 'black'
                                : 'gray'
            }
        >
            {status}
        </Badge>
    )

    return (
        <>
            <Heading mb={4}>Users</Heading>
            {/* <Button size='sm' onClick={()=> navigate(`/user/new`)}>Create</Button> */}
            <TableActions getValues={(values) => setSearch(values)} schoolFilters={schools} />
            {data && data.length ?
                <Table my="8" borderWidth="1px" fontSize="sm" bgColor='white'>
                    <Thead bg={mode('gray.50', 'gray.800')}>
                        <Tr>
                            <Th>
                                Nom
                            </Th>
                            <Th>School</Th>
                            <Th>Email</Th>
                            <Th>Role</Th>
                            <Th>Status</Th>
                            <Th>Phone</Th>
                            <Th
                                textAlign='right'
                            >
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data ?
                            data.map((user, index) =>
                                <Tr key={index}>
                                    <Td whiteSpace="nowrap" key={index}>
                                        {user.firstName} {user.lastName}
                                    </Td>
                                    <Td>
                                        {user.school ?
                                            <Link as={RouterLink} to={`/admin/school/view/${user.school._id}`}>
                                                {user.school.acronym ? `${user.school.acronym} - ` : ``}{user.school.name}
                                            </Link>
                                            : user.schoolName ?
                                                user.schoolName
                                                : '-'
                                        } </Td>
                                    <Td>
                                        <Link href={`mailto:${user.email}`}>{user.email}</Link>
                                    </Td>
                                    <Td><Role role={user.role} /></Td>
                                    <Td><Status status={user.status} /></Td>
                                    <Td>
                                        <Link href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</Link> </Td>
                                    <Td textAlign="right">
                                        <Button
                                            size='sm'
                                            variant="link"
                                            colorScheme="blue"
                                            as={RouterLink}
                                            to={`/admin/user/view/${user._id}`}
                                        >
                                            Details
                                        </Button>
                                        {/* <Button
                                            size='sm'

                                            variant="link"
                                            colorScheme="blue"
                                            as={RouterLink}
                                            to={`edit/${user._id}`}
                                        >
                                            Edit
                                        </Button> */}
                                    </Td>
                                </Tr>
                            )
                            : null}
                    </Tbody>
                </Table>
                :
                <Loader label='No user to display' />
            }
            {totalResults && lastPage && currentPage ?
                <TablePagination
                    totalResults={totalResults}
                    lastPage={lastPage}
                    currentPage={currentPage}
                    baseLink={'/admin/user/'}
                />
                : null}

            <Debug data={search} />

        </>
    )
}

export default UserList