import {
  Link,
  Button,
  CheckboxGroup,
  Checkbox,
  Divider,
  FormControl,
  Heading,
  HStack,
  Text,
  Flex,
  FormLabel,
  Input,
  Stack,
  SimpleGrid,
  Select,
  Textarea,
  FormHelperText,
  ButtonGroup,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import { Formik, Form, Field, FieldArray } from "formik";
import * as React from "react";
import { config } from "../../Utils/Config";
import Debug from "../Debug";
import FieldBlock from "../FieldBlock";
import { ProgramFormSchema } from "./Schema";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../UI/BackButton";
import { UserContext } from "../../Auth/userContext";
import { months } from "../../Utils/Months";
import { programmeCategories } from "../../Utils/ProgrammeCategories";
import { Wrapper } from "../../Components/Wrapper";
import { arrayIntRange } from "../../Utils/arrayIntRange";
import { DeleteButton } from "../../UI/DeleteButton";
import { BiCheck, BiHide, BiShow, BiX } from "react-icons/bi";
import { MarkdownEditor } from "../MarkdownEditor.tsx";
var lodash = require("lodash");

export const ProgramFormBase = ({ view }) => {
  let { userId, programId, schoolId } = useParams();
  let { user } = React.useContext(UserContext);

  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = React.useState();
  const [schools, setSchools] = React.useState();
  const [programCoursePlans, setProgramCoursePlans] = React.useState();

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${config.apiUrl}/school/all/`)
      .then((result) => setSchools(result.data));

    axios
      .get(`${config.apiUrl}/course-plan/`)
      .then((result) => setProgramCoursePlans(result.data));

    if (programId) {
      axios.get(`${config.apiUrl}/program/${programId}`).then((result) => {
        let data = result.data;
        delete data.courses;
        // data.intakesDate = data.intakesDate.slice(0, 10);
        // data.endDate = data.endDate ? data.endDate.slice(0, 10) : '';
        setData(data);
      });
    }
  };

  const feesFields = (number) => {
    let fields = [];
    for (let index = 1; index <= Math.ceil(number / 2); index++) {
      fields.push(
        <Field name={`feesYear${index}`}>
          {({ field, form, meta }) => (
            <FieldBlock
              isInvalid={meta.error && meta.touched}
              error={meta.error}
            >
              {/* <FormLabel></FormLabel> */}
              <Input {...field} placeholder={`Year ${index}`} />
            </FieldBlock>
          )}
        </Field>
      );
    }
    return fields;
  };

  const traineeFields = (number, values) => {
    let fields = [];
    for (let index = 1; index <= Math.ceil(number / 2); index++) {
      fields.push(
        <Field name={`traineePeriods[${index - 1}]`}>
          {({ field, form, meta }) => (
            <FieldBlock
              isInvalid={meta.error && meta.touched}
              error={meta.error}
            >
              <FormLabel>Year {index}</FormLabel>
              <Flex alignContent="center">
                <Flex alignContent="center" mr={2}>
                  <Field name={`traineePeriods[${index - 1}].hasTrainee`}>
                    {({ field, form, meta }) => (
                      <>
                        {/* <Debug data={field} /> */}

                        <Select
                          {...field}
                          // value={field.value}
                          w="140px"
                          placeholder="Select"
                        >
                          <option value={true}>yes</option>
                          <option value={false}>no</option>
                        </Select>
                      </>
                    )}
                  </Field>
                </Flex>
                {/* <Debug data={values} /> */}
                <Field name={`traineePeriods[${index - 1}].weeksLength`}>
                  {({ field, form, meta }) => (
                    <Input
                      {...field}
                      placeholder={`How many weeks ?`}
                      isDisabled={
                        !values ||
                        !values[index - 1] ||
                        !values[index - 1].hasTrainee ||
                        !(
                          values[index - 1].hasTrainee === true ||
                          values[index - 1].hasTrainee === "true"
                        )
                      }
                    />
                  )}
                </Field>
              </Flex>
            </FieldBlock>
          )}
        </Field>
      );
    }
    return fields;
  };

  const semestersCourseHoursFields = (number, values) => {
    let fields = [];
    for (let index = 1; index <= number; index++) {
      fields.push(
        <Field name={`semestersCourseHours[${index - 1}]`}>
          {({ field, form, meta }) => (
            <FieldBlock
              isInvalid={meta.error && meta.touched}
              error={meta.error}
            >
              <FormLabel>Semester {index} :</FormLabel>
              <Flex alignContent="center">
                <Input {...field} placeholder={`Hours ?`} />
              </Flex>
            </FieldBlock>
          )}
        </Field>
      );
    }
    return fields;
  };

  const ProcessStepButton = ({ value, setFieldValue, field }) => (
    <Button
      onClick={() => {
        if (field.value.includes(value)) {
          setFieldValue(field.name, lodash.pull(field.value, value));
        } else {
          setFieldValue(field.name, field.value.concat([value]));
        }
      }}
      colorScheme={
        field && field.value && field.value.includes(value) ? "blue" : null
      }
    >
      {value}
    </Button>
  );

  const handleDelete = () => {
    axios
      .delete(`${config.apiUrl}/program/${programId}`)
      .then((res) =>
        navigate(view === "user" ? "/account/program" : "/admin/program")
      );
  };

  return !userId || data ? (
    <Formik
      key={data}
      initialValues={
        data
          ? {
              ...data,
              school: data.school._id,
              processSteps: data.processSteps ? data.processSteps : [],
              accreditation: {
                ...data.accreditation,
                date:
                  data.accreditation && data.accreditation.date
                    ? data.accreditation.date.slice(0, 10)
                    : ``,
              },
            }
          : view === "user"
          ? {
              school: user.school,
              status: "created",
              processSteps: [],
              transcript: { showYears: true },
            } // need to put user school here
          : {
              processSteps: [],
              transcript: { showYears: true },
            }
      }
      validationSchema={ProgramFormSchema}
      onSubmit={(values, { setSubmitting }) => {
        !data
          ? axios.post(`${config.apiUrl}/program/`, values).then(() => {
              toast({
                title: "Programme created.",
                description: "Success.",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              navigate(view === "user" ? "/account/program" : "/admin/program");
            })
          : axios.put(`${config.apiUrl}/program/${data._id}`, values).then(() =>
              toast({
                title: "Programme updated.",
                description: "Success",
                status: "success",
                duration: 9000,
                isClosable: true,
              })
            );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Wrapper>
          <Stack as={Form} spacing={{ base: 2, lg: 10 }} bgColor="white" p={10}>
            <Flex justify={`space-between`} alignItems={`center`}>
              <BackButton label="back" />
              <Link
                fontize={`sm`}
                textDecor={`underline`}
                onClick={() =>
                  navigate(`/admin/program/edit/details/${programId}`)
                }
              >
                Update details
              </Link>
            </Flex>
            <Heading>
              {values && values.name
                ? `${values.name} update`
                : values
                ? `Programme update`
                : `New programme`}
            </Heading>
            {view !== "user" ? (
              <>
                <Field name="school">
                  {({ field, form, meta }) => (
                    <FieldBlock
                      isInvalid={meta.error && meta.touched}
                      error={meta.error}
                      title="School"
                      isRequired={true}
                    >
                      {schools ? (
                        <Select {...field}>
                          <option>Choose</option>
                          {schools.map(
                            (item) => (
                              // item.status === 'validated' ?
                              <option key={item.key} value={item._id}>
                                {item.name}
                              </option>
                            )
                            // : null
                          )}
                        </Select>
                      ) : null}
                      <FormHelperText>
                        To be eligible, the school must have the status{" "}
                        <strong>validated</strong>.
                      </FormHelperText>
                    </FieldBlock>
                  )}
                </Field>

                <SimpleGrid columns={2} gap={4}>
                  {/* <Field name='processSteps'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                    title='Pedagogical commitee steps'
                                                    isRequired={false}
                                                >
                                                    <ButtonGroup
                                                        size='xs'
                                                    >
                                                        <ProcessStepButton
                                                            value={'payment'}
                                                            setFieldValue={setFieldValue}
                                                            field={field}
                                                        />
                                                        <ProcessStepButton
                                                            value={'sppc'}
                                                            setFieldValue={setFieldValue}
                                                            field={field}
                                                        />
                                                        <ProcessStepButton
                                                            value={'report'}
                                                            setFieldValue={setFieldValue}
                                                            field={field}
                                                        />
                                                        <ProcessStepButton
                                                            value={'letter'}
                                                            setFieldValue={setFieldValue}
                                                            field={field}
                                                        />

                                                    </ButtonGroup>
                                                    <FormHelperText>
                                                        This information does not trigger any particular behaviour and is reserved for EABHES.
                                                    </FormHelperText>
                                                </FieldBlock>
                                            )}
                                        </Field> */}

                  <Field name="accountingSituation">
                    {({ meta, field }) => (
                      <FieldBlock title="Accounting situation">
                        <ButtonGroup size="xs">
                          <Button
                            colorScheme={values[field.name] ? "blue" : "gray"}
                            onClick={() => setFieldValue(field.name, true)}
                          >
                            Ok
                          </Button>
                          <Button
                            colorScheme={
                              !values[field.name] ? "orange" : "gray"
                            }
                            onClick={() => setFieldValue(field.name, false)}
                          >
                            Ko
                          </Button>
                        </ButtonGroup>
                        <FormHelperText>
                          This information should reflect the accounting
                          position of the programme. If it is not ok, it
                          prevents document editing.
                        </FormHelperText>
                      </FieldBlock>
                    )}
                  </Field>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
                  <Field name="code">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        isRequired={false}
                      >
                        <FormLabel>Code</FormLabel>
                        <Input {...field} />
                      </FieldBlock>
                    )}
                  </Field>
                  <Field name="status">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Status"
                        isRequired={true}
                      >
                        <Select {...field} placeholder="choose">
                          <option>created</option>
                          {/* <option>updated</option> */}
                          <option>accredited</option>
                          <option>archived</option>
                        </Select>
                      </FieldBlock>
                    )}
                  </Field>

                  {data ? (
                    // Display course plan field only on update
                    <Field name="primaryCoursePlan">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          title="Primary course plan"
                          isRequired={false}
                        >
                          <Select {...field}>
                            {programCoursePlans
                              ? programCoursePlans.map((item) => (
                                  <option value={item._id} key={item._id}>
                                    {item.name}
                                  </option>
                                ))
                              : null}
                          </Select>
                        </FieldBlock>
                      )}
                    </Field>
                  ) : null}

                  <Field name="passingEctsCredit">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Credits needed to complete the degree?"
                        isRequired={true}
                      >
                        <Input {...field} />
                      </FieldBlock>
                    )}
                  </Field>

                  {/* <Field name='category'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                    title='Category'
                                                    isRequired={true}
                                                >
                                                    <Input {...field} />
                                                </FieldBlock>
                                            )}
                                        </Field> */}

                  {values.status === "accredited" ? (
                    <>
                      <Field name="accreditation.date">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="When was the programme accredited?"
                          >
                            <Input
                              type="date"
                              // w='xs'
                              {...field}
                            />
                          </FieldBlock>
                        )}
                      </Field>
                      <Field name="accreditation.number">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Accreditation number"
                          >
                            <Input
                              // w='xs'
                              {...field}
                            />
                          </FieldBlock>
                        )}
                      </Field>
                    </>
                  ) : null}

                  <Field name="eqf">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="EQF"
                      >
                        <Input
                          // w='xs'
                          {...field}
                        />
                        <FormHelperText>
                          European Qualifications Framework (E.Q.F)
                        </FormHelperText>
                      </FieldBlock>
                    )}
                  </Field>

                  <Field name="isInitialCreditTransferRequired">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Does this program require an initial credit transfer ?"
                      >
                        {/* <Debug data={field} /> */}
                        <ButtonGroup size={`sm`}>
                          <Button
                            colorScheme={
                              field.value == true ? `blue` : undefined
                            }
                            onClick={() => setFieldValue(field.name, true)}
                          >
                            Yes
                          </Button>
                          <Button
                            colorScheme={
                              field.value != true ? `blue` : undefined
                            }
                            onClick={() => setFieldValue(field.name, false)}
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </FieldBlock>
                    )}
                  </Field>
                </SimpleGrid>

                <Divider />
              </>
            ) : null}

            <Field name="localName">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  isRequired={true}
                >
                  <FormLabel>Local name</FormLabel>
                  <Input {...field} bgColor="white" size="lg" />
                  <FormHelperText>
                    The full name that is used publicly to communicate about
                    this programme.
                  </FormHelperText>
                </FieldBlock>
              )}
            </Field>
            <Field name="name">
              {({ field, form, meta }) => (
                <FieldBlock
                  isInvalid={meta.error && meta.touched}
                  error={meta.error}
                  isRequired={true}
                >
                  <FormLabel>Name</FormLabel>
                  <Input {...field} bgColor="white" size="lg" />
                  <FormHelperText>The name is used by EABHES.</FormHelperText>
                </FieldBlock>
              )}
            </Field>

            {values.localName || !values.localName ? (
              // && touched.localName
              <>
                <SimpleGrid
                  gap={{ base: 2, lg: 4 }}
                  columns={{ base: 1, lg: 3 }}
                >
                  <Field name="level">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        isRequired={true}
                      >
                        <FormLabel>Level</FormLabel>
                        <Select {...field} placeholder="Select">
                          <option value={`master`}>Master</option>
                          <option value={`bachelor`}>Bachelor</option>
                          <option value={`short-course`}>Short course</option>
                          <option value={`summer-course`}>Summer course</option>
                        </Select>
                      </FieldBlock>
                    )}
                  </Field>

                  <Field name="category">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        isRequired={true}
                      >
                        <FormLabel>Category</FormLabel>
                        <Select {...field} placeholder="Select">
                          {programmeCategories.map((item) => (
                            <option>{item}</option>
                          ))}
                        </Select>
                      </FieldBlock>
                    )}
                  </Field>
                  <Field name="major">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        isRequired={true}
                      >
                        <FormLabel>Major</FormLabel>
                        <Input {...field} />
                      </FieldBlock>
                    )}
                  </Field>
                  <Field name="language">
                    {({ field, form, meta }) => (
                      <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        isRequired={false}
                      >
                        <FormLabel>Language of instruction</FormLabel>
                        <Input {...field} />
                      </FieldBlock>
                    )}
                  </Field>
                </SimpleGrid>

                {values.level && values.category && values.major ? (
                  <>
                    <SimpleGrid
                      gap={{ base: 2, lg: 4 }}
                      columns={{ base: 1, lg: 2 }}
                    >
                      {/* <Field name='category'>
                                                            {({ field, form, meta }) => (
                                                                <FieldBlock
                                                                    isInvalid={meta.error && meta.touched}
                                                                    error={meta.error}
                                                                    isRequired={true}
                                                                >
                                                                    <FormLabel>Category</FormLabel>
                                                                    <Input
                                                                        // {...field}
                                                                        value={values ? values.category : ''}
                                                                        onChange={(e) => {
                                                                            setFieldValue('category', e.target.value);
                                                                            if (!touched['code']) {
                                                                                setFieldValue('code', e.target.value.replace(/\s+/g, ''));
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                    <FormHelperText display={'flex'} alignContent={'center'} justifyContent={'space-between'}>
                                                                        <Text lineHeight={1.5}>
                                                                            It can be{' '}
                                                                            <em>{`«${values.level} of ${values.category} and ${values.major}» `}</em>
                                                                        </Text>

                                                                        <Button
                                                                            variant='outline'
                                                                            size='xs'
                                                                            onClick={() => {
                                                                                setFieldValue('category', `${values.level} of ${values.category} and ${values.major}`)
                                                                            }}
                                                                        >Yes please</Button>
                                                                    </FormHelperText>
                                                                </FieldBlock>
                                                            )}
                                                        </Field> */}
                    </SimpleGrid>

                    <Field name="presentation">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          isRequired={false}
                        >
                          <FormLabel>
                            Brief presentation of the program
                          </FormLabel>
                          <MarkdownEditor
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                          <FormHelperText>
                            Maximum 200 words, overall objectives, method,
                            professions
                          </FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>

                    <Field name="aims">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          isRequired={false}
                        >
                          <FormLabel>Aims of the programme</FormLabel>
                          <MarkdownEditor
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        </FieldBlock>
                      )}
                    </Field>

                    <Field name="learningOutcomes">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          isRequired={false}
                        >
                          <FormLabel>Learning outcomes</FormLabel>
                          <MarkdownEditor
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        </FieldBlock>
                      )}
                    </Field>

                    <Field name="assessment">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          isRequired={false}
                        >
                          <FormLabel>Assessment</FormLabel>
                          <MarkdownEditor
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        </FieldBlock>
                      )}
                    </Field>
                    <Field name="admissionProcess">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          isRequired={false}
                        >
                          <FormLabel>Admission process</FormLabel>
                          <MarkdownEditor
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        </FieldBlock>
                      )}
                    </Field>

                    <SimpleGrid
                      gap={{ base: 2, lg: 4 }}
                      columns={{ base: 1, lg: 2 }}
                    >
                      <Field name="launchYear">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                          >
                            <FormLabel>Launch year</FormLabel>
                            <Input min={1900} type="number" {...field} />
                            <FormHelperText>
                              It can be in the future if it's a new program
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>

                      <Field name="programDeliveryModes">
                        {({ field, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Programme delivery mode"
                          >
                            <CheckboxGroup
                              onChange={(value) =>
                                setFieldValue("programDeliveryModes", value)
                              }
                              value={field.value}
                            >
                              <HStack spacing={4}>
                                <Checkbox value={"on-campus"}>
                                  On Campus
                                </Checkbox>
                                <Checkbox value={"online"}>Online</Checkbox>
                                <Checkbox value={"blended"}>Blended</Checkbox>
                              </HStack>
                            </CheckboxGroup>

                            <FormHelperText>
                              You can select several possibilities
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field name="semestersNumber">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          title="Number of semesters"
                          isRequired={true}
                        >
                          <Input {...field} w="2xs" min={1} max={10} />
                        </FieldBlock>
                      )}
                    </Field>

                    <SimpleGrid
                      gap={{ base: 2, lg: 4 }}
                      columns={{ base: 1, lg: 2 }}
                    >
                      <Field name="hoursPerYearNumber">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Class hours per year"
                          >
                            <Input {...field} />
                          </FieldBlock>
                        )}
                      </Field>

                      <Field name="weeksPerYearNumber">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Weeks of class per year"
                          >
                            <Input {...field} />
                          </FieldBlock>
                        )}
                      </Field>

                      <Field name="intakesMonth">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Intakes month"
                            isRequired={true}
                          >
                            <Select
                              {...field}
                              placeholder="Select"
                              // w='2xs'
                            >
                              {months.map((item, index) => (
                                <option
                                  key={`${item.name}-intakesMonth`}
                                  value={index + 1}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </FieldBlock>
                        )}
                      </Field>
                      <Field name="endMonth">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="End month"
                            isRequired={true}
                          >
                            <Select
                              {...field}
                              placeholder="Select"
                              // w='2xs'
                            >
                              {months.map((item, index) => (
                                <option
                                  key={`${item.name}-endMonth`}
                                  value={index + 1}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </FieldBlock>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field name="numberStudentsFirstYear">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          title="Number of students enrolled in first year"
                        >
                          <Input w="4xs" type="number" {...field} />
                        </FieldBlock>
                      )}
                    </Field>

                    {values && values.semestersNumber ? (
                      <FieldArray
                        name="traineePeriods"
                        render={(arrayHelpers) => (
                          <FieldBlock title="Does this program include trainee period(s) ?">
                            <SimpleGrid
                              columns={{ base: 1, lg: 2 }}
                              spacing={4}
                            >
                              {traineeFields(
                                values.semestersNumber,
                                values.traineePeriods
                              )}
                            </SimpleGrid>
                          </FieldBlock>
                        )}
                      />
                    ) : null}

                    {values && values.semestersNumber ? (
                      <FieldArray
                        name="semestersCourseHours"
                        render={(arrayHelpers) => (
                          <FieldBlock title="Course hours by semesters : (this value is used on «Programme Specifications» app) :">
                            <SimpleGrid
                              columns={{ base: 1, lg: 2 }}
                              spacing={4}
                            >
                              {semestersCourseHoursFields(
                                values.semestersNumber,
                                values.semestersCourseHours
                              )}
                            </SimpleGrid>
                          </FieldBlock>
                        )}
                      />
                    ) : null}

                    <Field name="dissertationDetails">
                      {({ field, form, meta }) => (
                        <FieldBlock
                          isInvalid={meta.error && meta.touched}
                          error={meta.error}
                          title="If the programme includes a dissertation, give details about subject and pre-requisites"
                        >
                          <Textarea w="lg" {...field} />
                          <FormHelperText>Maximum 100 words.</FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>
                    <SimpleGrid columns={2} gap={{ base: 2, lg: 4 }}>
                      <Field name="foreignLanguageTest.isRequired">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Foreign language"
                            isRequired={true}
                          >
                            <Select {...field} placeholder="select">
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Select>

                            <FormHelperText>
                              Is this program includes a language test ?
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>
                      {values.foreignLanguageTest &&
                      values.foreignLanguageTest.isRequired &&
                      values.foreignLanguageTest.isRequired !== "false" ? (
                        <Field name="foreignLanguageTest.ectsCredits">
                          {({ field, form, meta }) => (
                            <FieldBlock
                              isInvalid={meta.error && meta.touched}
                              error={meta.error}
                              title="How many credits are given for the FLT"
                              isRequired={true}
                            >
                              <Input {...field} />
                            </FieldBlock>
                          )}
                        </Field>
                      ) : null}
                    </SimpleGrid>
                    <SimpleGrid columns={2} gap={{ base: 2, lg: 4 }}>
                      <Field name="lowestNote">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Lowest note"
                            isRequired={true}
                          >
                            <Input {...field} />
                            <FormHelperText>
                              The lowest score on the rating scale.
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>

                      <Field name="highestNote">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Highest note"
                            isRequired={true}
                          >
                            <Input {...field} />
                            <FormHelperText>
                              The highest score on the rating scale.
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>

                      {/* <Field name='passingNote'>
                                                            {({ field, form, meta }) => (
                                                                <FieldBlock
                                                                    isInvalid={meta.error && meta.touched}
                                                                    error={meta.error}
                                                                    title='Passing note limite'
                                                                    isRequired={true}
                                                                >
                                                                    <Input {...field} />
                                                                    <FormHelperText>
                                                                        The lowest note to pass the course.
                                                                    </FormHelperText>
                                                                </FieldBlock>
                                                            )}
                                                        </Field> */}

                      <Field name="failingNote">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Failing note limite"
                            isRequired={false}
                          >
                            <Input {...field} />
                            <FormHelperText>
                              The grade below which the course fails. The
                              comparison is made with the strictly lower
                              operator.
                            </FormHelperText>
                          </FieldBlock>
                        )}
                      </Field>
                    </SimpleGrid>

                    {values && values.semestersNumber ? (
                      <FormControl>
                        <FormLabel>
                          Annual programme fees including tuition fees in local
                          currency
                        </FormLabel>
                        <SimpleGrid columns={3} gap={3}>
                          <FieldArray
                            name="fees"
                            render={(arrayHelpers) =>
                              arrayIntRange(
                                1,
                                Math.ceil(values.semestersNumber / 2)
                              ).map((year, index) => (
                                <Field name={`fees.${index}`}>
                                  {({ field, meta }) => (
                                    <Input
                                      {...field}
                                      type="text"
                                      placeholder={`Fees year ${year}`}
                                    />
                                  )}
                                </Field>
                              ))
                            }
                          />
                        </SimpleGrid>

                        {/* <SimpleGrid
                                                                columns={3}
                                                                gap={3}
                                                            >
                                                                {feesFields(values.semestersNumber)}
                                                            </SimpleGrid> */}

                        <FormHelperText>
                          Specify if different for each year and include the
                          currency sign ($,€,£, etc...)
                        </FormHelperText>
                      </FormControl>
                    ) : null}

                    <SimpleGrid columns={2}>
                      <Field name="transcript.showYears">
                        {({ field, form, meta }) => (
                          <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Show years on transcript"
                            isRequired={false}
                          >
                            {/* <Select
                                                                    onChange={(e) => setFieldValue(field.name, e.target.value === '1' ? true : false)}
                                                                    value={meta.value ? "1" : "0"}
                                                                >
                                                                    <option value={1}>Yes</option>
                                                                    <option value={0}>No</option>
                                                                </Select> */}
                            <ButtonGroup size={`sm`}>
                              <Button
                                onClick={() => setFieldValue(field.name, true)}
                                colorScheme={
                                  values.transcript &&
                                  values.transcript.showYears
                                    ? `blue`
                                    : undefined
                                }
                                leftIcon={<BiShow />}
                              >
                                Show
                              </Button>
                              <Button
                                onClick={() => setFieldValue(field.name, false)}
                                colorScheme={
                                  values.transcript &&
                                  values.transcript.showYears
                                    ? undefined
                                    : `blue`
                                }
                                leftIcon={<BiHide />}
                              >
                                Hide
                              </Button>
                            </ButtonGroup>
                          </FieldBlock>
                        )}
                      </Field>
                      {view === "admin" ? (
                        <Field name="isPSEnable">
                          {({ field, form, meta }) => (
                            <FieldBlock
                              isInvalid={meta.error && meta.touched}
                              error={meta.error}
                              title="Enable Programme Specifications page ?"
                              isRequired={false}
                            >
                              <ButtonGroup size={`sm`}>
                                <Button
                                  onClick={() =>
                                    setFieldValue(field.name, true)
                                  }
                                  colorScheme={
                                    values.isPSEnable ? `blue` : undefined
                                  }
                                  leftIcon={<BiCheck />}
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={() =>
                                    setFieldValue(field.name, false)
                                  }
                                  colorScheme={
                                    values.isPSEnable ? undefined : `blue`
                                  }
                                  leftIcon={<BiX />}
                                >
                                  No
                                </Button>
                              </ButtonGroup>
                            </FieldBlock>
                          )}
                        </Field>
                      ) : null}
                    </SimpleGrid>
                    <Divider />
                    <Flex>
                      <Button
                        type="submit"
                        isDisabled={Object.keys(errors).length !== 0}
                      >
                        Save
                      </Button>
                    </Flex>
                  </>
                ) : null}
              </>
            ) : null}

            {view === "admin" ? (
              <Box>
                <DeleteButton
                  handleClick={() => handleDelete()}
                  message={
                    <Text>
                      Be carreful, the deletion of this program will trigger the
                      deletion of : - all courses linked to it - all course
                      plans linked to it - all inscription linked to it
                    </Text>
                  }
                />
              </Box>
            ) : null}

            <Debug data={errors} />
          </Stack>
        </Wrapper>
      )}
    </Formik>
  ) : null;
};
