import { Box, Heading } from '@chakra-ui/layout'
import axios from 'axios';
import { config } from '../Utils/Config';
import * as React from 'react'
import FieldBlock from '../Forms/FieldBlock'
import {
    Button,
    ButtonGroup,
    HStack,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    Flex,
    useColorModeValue as mode,
    Checkbox,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

    List,
    ListItem,
    Tag,
    FormControl,
    FormLabel,
    Stack,
    SimpleGrid,
    FormHelperText
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
// import { StatusTag } from './Tag';
import { Loader } from '../UI/Loader';
import { BiArrowFromTop, BiBookBookmark, BiBookmark, BiChevronDown, BiChevronDownSquare, BiCloudDownload, BiFile, BiPencil, BiRightArrowAlt, BiSelectMultiple, BiWindows } from 'react-icons/bi';
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { GrDocumentPdf, GrEdit } from 'react-icons/gr'
import { BsFileArrowDown } from 'react-icons/bs';
import { StatusTag } from './Tag';
import { NoResults } from '../UI/NoResults';
import { UserContext } from '../Auth/userContext';
import { InscriptionCreationForm } from './New'

const InscriptionPromotionList = ({ view }) => {

    const { user } = React.useContext(UserContext);

    const navigate = useNavigate();
    const params = useParams();

    const { programId, coursePlanId, startDate, endDate } = params;

    const [data, setData] = React.useState(null)
    const [action, setAction] = React.useState(null)
    // const [currentPage, setCurrentPage] = React.useState('')
    // const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    // const [search, setSearch] = React.useState({})
    // const [schools, setSchools] = React.useState(null)
    const [program, setProgram] = React.useState(null)
    const [coursePlan, setCoursePlan] = React.useState(null)

    // const { values } = useFormikContext();
    // console.log('values', values);


    const [student, setStudent] = React.useState(null)

    const [fieldToUpdate, setFieldToUpdate] = React.useState('')

    React.useEffect(() => {
        if (
            programId
            && coursePlanId
            // && stardDate
            && endDate
        ) {
            getData();

        }
    }, [programId, coursePlanId, startDate, endDate]);


    React.useEffect(() => {
        getProgram();
        getCoursePlan();
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/inscription/${programId}/${coursePlanId}/${startDate}/${endDate}/`)
            .then(result => setData(result.data));
    }
    const getProgram = () => {
        axios.get(`${config.apiUrl}/program/${programId}/`)
            .then(result => setProgram(result.data));
    }
    const getCoursePlan = () => {
        axios.get(`${config.apiUrl}/course-plan/${coursePlanId}/`)
            .then(result => setCoursePlan(result.data));
    }

    // const getPrograms = () => {
    //     axios.get(
    //         user.role === 'user' ?
    //             `${config.apiUrl}/program/school/${user.school}`
    //             :
    //             `${config.apiUrl}/program`
    //     ).then(
    //         res => setPrograms(res.data)
    //     )

    // }

    const handleUpdateInscriptions = (postData) => {
        // console.log('postData', postData);
        axios.patch(
            `${config.apiUrl}/inscription/batch/`,
            postData
        ).then(res => getData());
    }

    const handleDelete = (inscriptionsIds) => {
        console.log(inscriptionsIds.join(','))
        axios.delete(
            `${config.apiUrl}/inscription/${inscriptionsIds.join(',')}/`
        ).then(res => getData());
    }


    return (
        <>
            <Heading mb={4}>
                Mass update of a promotion
            </Heading>

            {/* <Debug data={params} /> */}
            {/* <Debug data={user} /> */}
            {/* <Debug data={program} /> */}
            {/* <Debug data={coursePlan} /> */}

            {program && coursePlan ?
                <HStack>
                    <Tag>{program.name} → {coursePlan.name}</Tag>
                    <Tag>{startDate} → {endDate}</Tag>
                </HStack>
                : null}

            {/* {programs ?
                <TableActions
                    getValues={(values) => setSearch(values)}
                    programFilters={programs}
                    program={search.program}
                    startYear={search.startYear}
                    endYear={search.endYear}
                    key={data}
                    view={view}
                />
                : null} */}

            {/* <Debug data={data} /> */}

            {
                data && program ?
                    data.length ?
                        <Formik
                            initialValues={{ coursePlan: coursePlanId }}
                            onSubmit={(values) => {
                                let postData = values;
                                postData.inscriptions = values.inscriptions.filter(item => item && item.length);

                                handleUpdateInscriptions(postData);
                                // console.log(postData);
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    // program: Yup.string().required(),
                                    fieldToUpdate: Yup.string().required(),
                                    coursePlan: Yup.string().required()
                                })
                            }
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <Form>

                                    <Flex justify={`space-between`} mt="8" mb={2}>
                                        {values &&
                                            values.inscriptions
                                            && values.inscriptions.length
                                            && values.inscriptions.filter(item => item.length)
                                            && values.inscriptions.filter(item => item.length).length === data.length ?

                                            <Button
                                                onClick={() => setFieldValue('inscriptions', [])}
                                                leftIcon={<BiSelectMultiple />} size='xs'
                                            >
                                                Unselect all
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => setFieldValue('inscriptions', data.map(item => item._id))}
                                                leftIcon={<BiSelectMultiple />} size='xs'
                                            >
                                                Select all
                                            </Button>


                                        }
                                        <Button
                                            size='xs'
                                            leftIcon={<BiCloudDownload />}
                                            as={`a`}
                                            target={`_blank`}
                                            href={`${config.apiUrl}/inscription/${programId}/${coursePlanId}/${startDate}/${endDate}/export/`}
                                        >Export CSV</Button>
                                    </Flex>

                                    {/* <div>{data.length}</div> */}
                                    {/* <div>{values.inscriptions.filter(item => item.length).length}</div> */}

                                    <Table mb={2} borderWidth="1px" fontSize="sm" bgColor='white'>
                                        <Thead bg={mode('gray.50', 'gray.800')}>
                                            <Tr>
                                                <Th w='30px' p={0} />
                                                <Th>Student</Th>
                                                <Th>Period</Th>
                                                <Th>ECTS</Th>
                                                {/* <Th>Region</Th> */}
                                                <Th>Status</Th>
                                                <Th
                                                    textAlign='right'
                                                >
                                                    Action
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {data ?
                                                <FieldArray
                                                    name='inscriptions'
                                                    render={arrayHelpers => (
                                                        data.map((item, index) =>
                                                            <Tr key={item._id}>
                                                                <Td px={4}>
                                                                    <Field name={`inscriptions.${index}`}>
                                                                        {({ field, meta }) => (
                                                                            <Checkbox
                                                                                // value={values.inscriptions && values.inscriptions.includes(item._id)}
                                                                                // value={values.inscriptions && values.inscriptions.includes(item._id) ? true : false}
                                                                                isChecked={values.inscriptions && values.inscriptions.length && values.inscriptions.includes(item._id)}
                                                                                onChange={(e) => {
                                                                                    // console.log(e.target.value);
                                                                                    let updatedInscriptions = values && values.inscriptions ? values.inscriptions : [];
                                                                                    if (updatedInscriptions.length == 0) { updatedInscriptions = [item._id] }
                                                                                    if (values.inscriptions && values.inscriptions.includes(item._id)) { updatedInscriptions = values.inscriptions.filter(i => i !== item._id) }
                                                                                    if (values.inscriptions && !values.inscriptions.includes(item._id)) { updatedInscriptions = values.inscriptions; updatedInscriptions.push(item._id); }
                                                                                    setFieldValue(
                                                                                        `inscriptions`,
                                                                                        updatedInscriptions
                                                                                    )
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Td>
                                                                <Td whiteSpace="nowrap">
                                                                    {`${item.student.firstName} ${item.student.lastName.toUpperCase()}`}
                                                                </Td>
                                                                <Td>
                                                                    <Flex alignItems={'center'}>
                                                                        {`${item.startDate}`} <BiRightArrowAlt /> {`${item.endDate}`}
                                                                    </Flex>
                                                                </Td>
                                                                <Td>{item.ectsCreditsObtained}</Td>
                                                                {/* <Td>{item.status}</Td> */}
                                                                <Td>
                                                                    <StatusTag>
                                                                        {item.status}
                                                                    </StatusTag>
                                                                </Td>
                                                                <Td textAlign="right">

                                                                    <Menu>
                                                                        <MenuButton
                                                                            as={Button}
                                                                            rightIcon={<BiChevronDown />}
                                                                            size='xs'
                                                                        >
                                                                            <BiChevronDownSquare />
                                                                        </MenuButton>
                                                                        <MenuList>
                                                                            <MenuItem
                                                                                icon={<GrEdit />}
                                                                                as={RouterLink}
                                                                                to={`/${view === 'admin' ? `admin` : `account`}/inscription/update/${item._id}`}
                                                                            >
                                                                                Update details
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiFile />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/registration-certificate/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Registration certificate
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiFile />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/transcript/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Transcript
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiBookBookmark />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/diploma/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Certificate of Completion
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    </Menu>

                                                                    {/* <Button
                                                                size='sm'
                                                                variant="link"
                                                                colorScheme="blue"
                                                                as={RouterLink}
                                                                to={`/admin/inscription/view/${item._id}`}
                                                            >
                                                                Details
                                                            </Button>

                                                            <Button
                                                                size='sm'
                                                                variant="link"
                                                                colorScheme="blue"
                                                                as={'a'}
                                                                target={'_blank'}
                                                                href={`${config.apiUrl}/inscription/transcript/${item._id}`}
                                                            >
                                                                Transcript
                                                            </Button> */}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    )}
                                                />
                                                : null}

                                        </Tbody>
                                    </Table >

                                    <Debug data={values} />

                                    {
                                        values.inscriptions && values.inscriptions.length
                                            && values.inscriptions.filter(item => item && item.length).length > 0 ?
                                            // <Flex justify={'flex-start'} >
                                            <Stack
                                                spacing={4}
                                                bgColor='white'
                                                p={4}
                                            >

                                                <Text>
                                                    {values.inscriptions.filter(item => item && item.length).length} inscription
                                                    {values.inscriptions.filter(item => item && item.length).length > 1 ? `s ` : ` `}
                                                    have been selected, do you want to :
                                                </Text>
                                                <ButtonGroup size="sm">
                                                    <Button
                                                        onClick={() => navigate(
                                                            `/admin/inscription/new/students/${data
                                                                .filter(item => values.inscriptions.indexOf(item._id) !== -1)
                                                                .map(item => item.student._id)
                                                                .join(',')
                                                            }/school/${program.school._id}`

                                                        )}
                                                    >Create new inscriptions for those students</Button>
                                                    <Button onClick={() => setFieldValue('fieldToUpdate', 'coursePlan')}>Update course plan</Button>
                                                    <Button onClick={() => setFieldValue('fieldToUpdate', 'status')}>Update status</Button>
                                                    <Button onClick={() => setFieldValue('fieldToUpdate', 'period')}>Update period</Button>
                                                    <Button onClick={() => setFieldValue('fieldToUpdate', 'deleteAll')}>Delete all</Button>
                                                </ButtonGroup>


                                                {program ?
                                                    <>
                                                        {values.fieldToUpdate === `coursePlan` ?
                                                            <FormControl>
                                                                <FormLabel>Update course plan</FormLabel>
                                                                <Field name='coursePlan'>
                                                                    {({ field, meta }) => (
                                                                        <Select w='md' placeholder='Select' {...field}>
                                                                            {program.coursePlans.map(item =>
                                                                                <option key={item._id} value={item._id}>{item.name}</option>
                                                                            )}
                                                                        </Select>
                                                                    )}
                                                                </Field>
                                                            </FormControl>
                                                            : null}

                                                        {values.fieldToUpdate === `status` ?
                                                            <FormControl>
                                                                <FormLabel>Update status</FormLabel>
                                                                <Field name='status'>
                                                                    {({ field, meta }) => (
                                                                        <Select w='md' placeholder='Select' {...field}>
                                                                            <option>current</option>
                                                                            <option>incomplete</option>
                                                                            <option>finalized</option>
                                                                            <option>complete</option>
                                                                            <option>archived</option>
                                                                        </Select>
                                                                    )}
                                                                </Field>
                                                            </FormControl>
                                                            : null}

                                                        {values.fieldToUpdate === `period` ?
                                                            <FormControl>
                                                                <SimpleGrid columns={2} gap={10}>
                                                                    <Field name="startDate">
                                                                        {({ field, meta }) => (
                                                                            <FieldBlock
                                                                                title='Start date'
                                                                                isRequired={true}
                                                                                isInvalid={meta.touched && meta.error}
                                                                                error={meta.error}
                                                                            >
                                                                                <Input
                                                                                    {...field}
                                                                                    type='month'
                                                                                />
                                                                                <FormHelperText>
                                                                                    Expected format YYYY-MM ex : 2021-09
                                                                                </FormHelperText>
                                                                            </FieldBlock>
                                                                        )}
                                                                    </Field>
                                                                    <Field name="endDate">
                                                                        {({ field, meta }) => (
                                                                            <FieldBlock
                                                                                title='End date'
                                                                                isRequired={true}
                                                                                isInvalid={meta.touched && meta.error}
                                                                                error={meta.error}
                                                                            >
                                                                                <Input
                                                                                    {...field}
                                                                                    type='month'
                                                                                />
                                                                                <FormHelperText>
                                                                                    Expected format YYYY-MM ex : 2021-09
                                                                                </FormHelperText>
                                                                            </FieldBlock>
                                                                        )}
                                                                    </Field>
                                                                </SimpleGrid>
                                                            </FormControl>
                                                            : null}

                                                        {values.fieldToUpdate === `deleteAll` ?
                                                            <FormControl>
                                                                <FormLabel>Delete confirmation</FormLabel>
                                                                <Button
                                                                    colorScheme={`red`}
                                                                    onClick={() => handleDelete(values.inscriptions)}
                                                                >Confirm delete {values.inscriptions.length} inscription(s)</Button>
                                                            </FormControl>
                                                            : null}

                                                    </>

                                                    : null}
                                                <Box
                                                >
                                                    <Button
                                                        size='sm'
                                                        type='submit'
                                                        leftIcon={<BiPencil />}
                                                        isDisabled={
                                                            (errors && Object.keys(errors).length > 0)
                                                        }
                                                    >
                                                        Update
                                                    </Button>
                                                </Box>
                                            </Stack>
                                            // </Flex>
                                            : null}
                                    {/* <Debug data={errors} /> */}
                                </Form>
                            )}



                        </Formik>
                        : <NoResults label='No curriculum found' />
                    : <Loader label='Loading curriculums' />
            }

            {/* {action === `createNewInscriptions` ?
                <>
                    <InscriptionCreationForm
                        schoolId={program.school._id}
                        students={
                            data
                                .filter(item => values.inscriptions.indexOf(item._id) !== -1)
                                .map(item => item.student._id)
                        }
                    />
                </>
                : null} */}

            {
                totalResults && currentPage ?
                    <TablePagination
                        totalResults={totalResults}
                        lastPage={lastPage}
                        currentPage={currentPage}
                        baseLink={'/admin/user/'}
                    />
                    : null
            }


        </>
    )
}

export default InscriptionPromotionList