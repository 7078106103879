import * as React from 'react'

import {
    Button,


    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton
} from '@chakra-ui/react'

export const CsvHeaderAlert = ({ isOpen, handleClose }) => {

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            // leastDestructiveRef={cancelRef}
            onClose={() => handleClose()}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>Does your file contain headers?</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    To be handled, your file must contain headers: firstName, lastName, email, birthDate.
                    If not, their will be added automaticaly.
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button
                        // ref={cancelRef}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    <Button colorScheme='green' ml={3} >
                        Yes
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}