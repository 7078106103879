import { Box, Portal, Tab, TabList, TabPanel, TabPanels, Tabs, TabsProvider } from '@chakra-ui/react'
import axios from 'axios';
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Debug from '../../Forms/Debug';
import { Loader } from '../../UI/Loader';
import { config } from '../../Utils/Config';
import { NotesForm } from './form';
import { NotesFormHeader } from './NotesFormHeader';

export const PromoNoteForm = ({ view }) => {

    const navigate = useNavigate();
    let params = useParams();

    const {
        programId,
        coursePlanId,
        start,
        end
    } = useParams();

    const [inscriptions, setInscriptions] = React.useState('');
    const [program, setProgram] = React.useState('');

    React.useEffect(() => {
        if (!inscriptions) {
            getData();
        }
    }, [programId]);




    const getData = () => {
        axios.get(
            `${config.apiUrl}/inscription/${programId}/${coursePlanId}/${start}/${end}/all`
        ).then(res => {
            setInscriptions(res.data);
        });

        axios.get(`${config.apiUrl}/program/${programId}`)
            .then(result => setProgram(result.data));
    }

    const YearForms = () => {
        let headers = [];
        let yearFormsList = [];
        let yearNumber = Math.ceil(program.semestersNumber / 2)

        for (let index = 0; index < yearNumber; index++) {
            headers.push(
                <Tab
                    key={`header-${programId}${coursePlanId}${start}${end}${index}`}

                >
                    Year
                    {index + 1}
                    : {parseInt(start) + index}
                    /
                    {parseInt(start) + index + 1}
                </Tab>
            )
            yearFormsList.push(
                <TabPanel
                    // bgColor={`gray.${(index + 1) * 50}`}
                    bgColor={`gray.50`}
                    p={12}
                    key={`${programId}${coursePlanId}${start}${end}${index}`}

                >
                    <NotesForm
                        programId={programId}
                        coursePlanId={coursePlanId}
                        startYear={start}
                        endYear={end}
                        year={index + 1}
                        handleClose={() => console.log("close")}
                    />
                </TabPanel>
            )

        }
        return (
            <Tabs
                align='left'
            >
                <TabList
                    px={12}
                >
                    {headers}
                </TabList>
                <TabPanels>
                    {yearFormsList}
                </TabPanels>
            </Tabs>)
    }

    return (
        <Box>

            {/* <Debug
                data={[programId, coursePlanId, start, end]}
            /> */}

            {inscriptions ?

                <Portal>
                    <Box
                        position={'fixed'}
                        top={0}
                        right={0}
                        left={0}
                        bottom={0}
                        // minH='100vh'
                        bgColor={'white'}
                        overflowY={'scroll'}
                    >
                        <Box
                            h='auto'
                        >
                            {/* <div>coucou {view}</div> */}
                            <NotesFormHeader
                                handleClose={() => navigate(`/${view === `admin` ? 'admin' : 'account'}/program/view/${programId}/${coursePlanId}`)}
                            />
                            <YearForms />
                            {/* <Debug data={inscriptions} /> */}
                        </Box>
                    </Box>
                </Portal>


                : <Loader label={'Setting the notes form'} />
            }
        </Box>
    )
}