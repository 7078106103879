import { Box, Button, Flex, Grid } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiX } from 'react-icons/bi';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';
import { CommentDeleteButton } from './DeleteButton';

export const Comment = ({ data, onCommentDelete, odd, even }) => {
    const date = new Date(data.createdAt)
    let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

    const handleDelete = () => {
        axios.delete(`${config.apiUrl}/comment/${data._id}`)
            .then(res => onCommentDelete())
    }

    return (
        <Box
            bgColor='gray.50'
            opacity={odd ? .95 : 1}
            border={`solid 1px`}
            borderColor={odd ? `gray.100` : `gray.50`}

            p={4}
            rounded={'md'}

        >
            <Grid
                gridTemplateColumns={{
                    base: `100%`,
                    lg: `1fr 120px`
                }}
            >

                <Box>
                    <Box
                        fontSize={`sm`}
                        color={`gray.400`}
                    >{data.author.firstName} {data.author.lastName} on {dateMDY} :</Box>
                    <Box
                        color='gray.700'
                    >
                        {data.content}
                    </Box>
                </Box>
                <Flex
                    justify={`flex-end`}
                >
                    <CommentDeleteButton
                        handleDelete={() => handleDelete()}
                    />
                </Flex>
            </Grid>
        </Box>
    )
}