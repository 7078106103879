import * as React from 'react'
import { Box, Button, ButtonGroup, Center, Grid, Heading, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { RiFileForbidLine } from 'react-icons/ri'

export const DiplomaFailure = () => {

    const pageStyle = `
    @page {
        size: a4 landscape;
        margin: 0cm !important;
    }
    html, body {
        width: 297mm;
        height: 209mm;
    }
`;

    return (
        <>
            <style>
                {pageStyle}
            </style>
            <Center
                w='297mm'
                h='209mm'
            >
                <VStack
                    maxW='2xl'
                >
                    {/* RiFileForbidLine */}
                    <Center>
                        <RiFileForbidLine size='2cm' />
                    </Center>
                    <Text
                        textAlign={'center'}
                    >
                        This inscription does not have all the necessary requirements to award a certificate of completion.
                    </Text>
                </VStack>

            </Center>
        </>
    )
}