import {
    Box,
    Center,
    Flex,
    Heading,
    Grid,
    Stack,
    Text
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react'
import Debug from '../../Forms/Debug';
import { ectsToUsCredits } from '../../Utils/ectsToUsCredits';
import stampPurple from '../../Images/stamps/stamps-purple.png'




export const RegistrationCertificateBody = ({
    data,
    bg
}) => {

    const programAccreditationDate = dayjs(data.program.accreditation.date).format(`MMMM DD, YYYY`)
    const ProgramTitle = () => (
        <Stack spacing={`3mm`}>
            <Heading
                fontSize={`30px`}
                fontFamily={`Georgia`} fontStyle={`italic`}>{data.program.category ? data.program.category : ``}</Heading>
            {data.program.accreditation.date ?
                <Text fontWeight={`normal`} fontStyle={`italic`} fontSize={`12px`} fontFamily={`Georgia`}>Accredited by <strong>EABHES</strong> on <strong>{programAccreditationDate}</strong></Text>
                :
                <Text fontWeight={`normal`} fontStyle={`italic`} fontSize={`12px`} fontFamily={`Georgia`}>Accredited by <strong>EABHES</strong></Text>
            }
        </Stack>
    )

    const items1 = [

        {

            key: `On behalf of`,
            value: data.school.name
        },
        {
            key: `This is to certify that`,
            value: `${data.student.firstName} ${data.student.lastName.toUpperCase()}`
        },
        {
            key: `Student N°`,
            value: `${data.student.code}`
        },
        {
            key: `Study duration`,
            value: `${data.startDate.slice(0, 4)}/${data.endDate.slice(0, 4)}`
        }];
    const items2 = [
        {
            key: `Is enrolled in`,
            value: <ProgramTitle />
        }];

    const items3 = [
        {
            key: `Ref N°`,
            value: `${data.school.accreditation && data.school.accreditation.number ? data.school.accreditation.number : ``}`
        },
        {
            key: `Equivalent to`,
            value: data.program.passingEctsCredit ? `${data.program.passingEctsCredit} ECTS / ${ectsToUsCredits(data.program.passingEctsCredit)} US credits` : `-`
        },
        {
            key: `Held in`,
            value: `${data.program.language ? data.program.language : '-'} `
        },
        {
            key: <>with specialization in <br />/ majoring in</>,
            value: `${data.program.major} `
        },
        {
            key: `In`,
            value: `${data.program.semestersNumber} semesters`
        }

    ]

    const Line = ({ name, value, index, topSpace }) => (
        <React.Fragment
            key={`bodyRegistrationCertificat${index}`}
        >
            <Flex
                justifyContent={`flex-end`}
                alignItems={`center`}
                pt={topSpace}
            >
                <Text
                    // fontFamily={`Georgia`}
                    // fontStyle={`Italic`}
                    fontWeight={300}
                    fontSize={`12px`}
                    textAlign={`right`}
                >
                    {name}
                </Text>
            </Flex>
            <Text
                fontWeight={'600'}
                fontFamily='Satoshi'
                textAlign={`center`}
                fontSize={`16px`}
                pt={topSpace}
                pr={`12mm`}

            >
                {value}
            </Text>
        </React.Fragment>
    )

    return (
        <>
            <Box
                flex='1 1 auto'
                w='full'
                // px={12}
                // my={`10mm`}
                color={`brand.blue.900`}
            // border='solid 1px red'
            >
                <Center
                // h='full'
                // w={`full`}
                // border='solid 1px red'
                >
                    <Stack
                        spacing='10mm'
                        w='full'
                    >

                        <Grid
                            gridTemplateColumns={`25% 1fr`}
                            gap={`10mm`}
                            rowGap={`2mm`}
                            columnGap={`20mm`}
                            w={`full`}
                        >
                            {items1 ? items1.map((item, index) =>
                                <Line name={item.key} value={item.value} index={index} />
                            ) : null}


                            {items2 ? items2.map((item, index) =>
                                <Line topSpace={`15mm`} name={item.key} value={item.value} index={index} />
                            ) : null}

                            {items3 ? items3.map((item, index) =>
                                <Line topSpace={index === 0 ? `15mm` : 0} name={item.key} value={item.value} index={index} />
                            ) : null}

                        </Grid>
                        <Flex
                            justifyContent={`flex-end`}
                            px={`8mm`}
                        >
                            <Box
                                bgImage={`url(${stampPurple})`}
                                bgSize={`3cm 3cm`}
                                bgRepeat={`no-repeat`}
                                bgPos={`bottom center`}
                                fontFamily={`Satoshi`}

                                minH={`40mm`}

                            >
                                <Text
                                    fontSize={`13px`}
                                >
                                    Paris, {dayjs().format('MMMM DD, YYYY')}
                                </Text>
                            </Box>
                        </Flex>
                    </Stack>
                </Center>
            </Box>
        </>
    )
}