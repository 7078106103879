import * as React from 'react'
import { useParams } from 'react-router-dom'
import { DiplomaDocument } from './Document';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';


export const DiplomaExporter = ({ }) => {
    const { inscriptionId, showBg } = useParams();



    const inscriptionIds = inscriptionId ? inscriptionId.split(',') : [];

    const DiplomasPages = () => {
        let transcriptPagesArray = [];
        for (let index = 0; index < inscriptionIds.length; index++) {
            transcriptPagesArray.push(
                <DiplomaDocument
                    inscriptionId={inscriptionIds[index]}
                    single={inscriptionIds.length === 1}
                    bg={showBg === "true" ? true : false}
                />
                // <div>{inscriptionIds[index]}</div>
            );
        }
        return <>{transcriptPagesArray}</>
    }
    return (
        <>

            <DiplomasPages />
            {/* <Debug data={showBg} /> */}
        </>
    )
}