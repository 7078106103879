import { Box, Button, ButtonGroup } from "@chakra-ui/react"
import * as React from "react"
import { BiLeftArrowAlt } from "react-icons/bi"
import { GrDocumentPdf } from "react-icons/gr"
import { Link } from 'react-router-dom'

export const ExportButton = ({ exportUrl, backLinkUrl }) => {
    return (
        <Box
            borderBottom={'solid 1px'}
            borderBottomColor='gray.100'
            p={10}
            className='hide-print'
        >
            <ButtonGroup
                size='xs'
            >
                <Button
                    as={Link}
                    to={backLinkUrl}
                    leftIcon={<BiLeftArrowAlt />}
                >
                    Back
                </Button>
                <Button
                    as='a'
                    // href={`${config.apiUrl}/inscription/diploma/${inscriptionId}/${bg}/${diplomaTitle}`}
                    href={`${exportUrl}`}
                    target='_blank'
                    leftIcon={<GrDocumentPdf />}
                >
                    Export PDF
                </Button>
            </ButtonGroup>
        </Box>
    )
}