import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverCloseButton, PopoverHeader, PopoverTrigger, Input, Stack, Text, Select, HStack } from '@chakra-ui/react';
import axios from 'axios';
import { Form, Formik, Field } from 'formik';
import * as React from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import Debug from '../../Forms/Debug';
import FieldBlock from '../../Forms/FieldBlock';
import { config } from '../../Utils/Config';
import { CoursePlansDeleteButton } from './CoursePlansDeleteButton';


export const CoursePlansUpdate = ({
    coursePlanId,
    handleSubmit,
    handleClose
}) => {

    const [data, setData] = React.useState(null);

    React.useEffect(
        () => {
            axios.get(
                `${config.apiUrl}/course-plan/${coursePlanId}`
            ).then(res => setData(res.data))
        }, []
    )

    return (
        data ?
            <Formik
                initialValues={data}
                onSubmit={(values) => {
                    let updatedValues = values
                    delete updatedValues.courses
                    axios.put(
                        `${config.apiUrl}/course-plan/${coursePlanId}`,
                        updatedValues
                    ).then(
                        result => {
                            handleSubmit();
                            handleClose();
                        }
                    )
                }}
            >
                {({
                    values,
                    setFieldValue
                }) => (
                    <Form>
                        <Stack>
                            <Field name="name">
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        title='Name'
                                        isRequired={true}
                                    >
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name="localName">
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        title='Local name'
                                        isRequired={false}
                                    >
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name="status">
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        title='Status'
                                        isRequired={true}
                                    >
                                        <Select
                                            {...field}
                                            placeholder='Select'
                                        >
                                            <option>active</option>
                                            <option>archived</option>
                                        </Select>
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name="minor">
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        title='Minor'
                                        isRequired={false}
                                    >
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name="major">
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        title='Major'
                                        isRequired={false}
                                    >
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Button
                                type='submit'
                                size='sm'
                            >
                                Save
                            </Button>
                            {/* <Debug data={values} /> */}

                            <HStack justify={'flex-end'}>
                                <Text fontSize={'sm'}>or</Text>
                                <CoursePlansDeleteButton
                                    coursePlanId={coursePlanId}
                                    handleDeleted={() => handleClose()}
                                />
                            </HStack>
                        </Stack>
                    </Form>
                )}
            </Formik>
            : null
    )
}

