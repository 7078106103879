import { SimpleGrid, Button, Center } from "@chakra-ui/react";
import axios from "axios";
import * as React from "react";
import Debug from "../Forms/Debug";
import { config } from "../Utils/Config";
import { PromotionBlock } from "./PromotionBlock";
import dayjs from "dayjs";

export const ProgramPromotions = ({ programId, program }) => {
  const [data, setData] = React.useState(null);
  const [seeAll, setSeeAll] = React.useState(false);

  React.useEffect(() => {
    if (!data) getData();
  });

  const getData = () => {
    axios
      .get(`${config.apiUrl}/inscription/promo/${programId}`)
      .then((result) => setData(result.data));
  };

  console.log(dayjs("2023-01").isAfter(dayjs()));

  return data && data.length ? (
    <>
      {/* <Debug data={data} /> */}
      <SimpleGrid columns={1} gap={4}>
        {data.map((item) =>
          item.data
            ? item.data
                .filter(
                  (promo) =>
                    seeAll || dayjs(promo.end).add(6, "month").isAfter(dayjs())
                )
                .map((promo) => (
                  <>
                    {/* <div>{promo.end}</div> */}
                    <PromotionBlock
                      key={`${programId}-${promo.start}-${promo.end}`}
                      programId={programId}
                      coursePlanId={item.coursePlan._id}
                      start={promo.start}
                      end={promo.end}
                      count={promo.count}
                      program={program}
                      coursePlan={item.coursePlan}
                    />
                    {/* <Debug data={promo} /> */}
                  </>
                ))
            : null
        )}
      </SimpleGrid>

      <Center>
        <Button size={"xs"} onClick={() => setSeeAll(!seeAll)}>
          {!seeAll ? `See` : `Hide`} older promotions
        </Button>
      </Center>
    </>
  ) : (
    <Debug data={data} />
  );
};

//http://localhost:3001/inscription/61eed835826e698589fa99fd/ids/2020/2022/
