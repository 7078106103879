import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            // ...
            900: "#1a202c",
            blue: {
                100: '#deebf9',
                500: '#4f63a2',
                700: '#19346f',
                900: '#001250'
            },
            violet: {
                100: '#ddecf9',
                200: '#c3d1f8',
                300: '#abb6f9',
                400: '#8e9cfb'
            },
            red: {
                100: '#fbc3b6',
                200: '#ff977e',
                300: '#ff6745',
                400: '#ff371c'
            },
            yellow: {
                100: '#f8e2c6',
                200: '#fecf9d',
                300: '#ffaf4c',
                400: '#ffaf4c'
            },
            orange: '#ffb04c',
        },
    },

    fonts: {
        heading: 'Satoshi',
    },

    components: {
        Button: {
            // 1. We can update the base styles
            baseStyle: {
                fontWeight: 'bold', // Normally, it is "semibold"
                bgColor: 'white'
            },
        },
        Select: {
            baseStyle: {
                bgColor: 'white'
            },
        },
        Input: {
            baseStyle: {
                bgColor: 'white',
            },
        }
    }
})