import * as React from 'react'
import QRCode from 'qrcode'
import { Image } from '@chakra-ui/react'
import { DiplomaContext } from './Document';


export const DiplomadQrCode = ({ w, bg }) => {

    const [qrImage, setQrImage] = React.useState();


    const generateQR = async text => {

        var opts = {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            quality: 0.3,
            margin: 0,
            width: 500,
            height: 500,
            color: {
                dark: "#001250",
                light: bg ? "#ddecf9" : '#FFFFFF'
            }
        }

        try {
            let qrDataUrl = await QRCode.toDataURL(text, opts);
            // console.log(qrDataUrl)
            return qrDataUrl;
        } catch (err) {
            console.error(err)
        }
    }

    // const qrDataUrl = await generateQR('coucou')
    const generateQrImage = async () => {
        let qrDataUrl = await generateQR('https://eabhes.eu/');
        if (
            qrDataUrl
        ) {
            setQrImage(qrDataUrl);
        }
        else {
            return 'wait'
        }
    }

    generateQrImage();

    return (
        qrImage ?
            <Image
                w={w}
                alt='qrcodeimage'
                src={qrImage}
            // key={value.bg}
            />
            : null

    )
}