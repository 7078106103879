import { Box } from '@chakra-ui/layout'
import * as React from 'react'
import ReactJson from 'react-json-view'

const Debug = ({ data }) => (



    process.env.NODE_ENV === 'development'
        // && 1 === 2
        ?
        <Box
            fontFamily='Mono'
            bgColor='gray.900'
            color='whiteAlpha.800'
            p={3}
            borderRadius='lg'
            fontSize={'sm'}
            my={10}
            textAlign='left'
        >
            {
                typeof data !== 'object' ?
                    <pre>
                        {JSON.stringify(data, null, 2)}
                    </pre>
                    :
                    <ReactJson
                        name={null}
                        src={data}
                        theme={'monokai'}
                    />
            }
        </Box >
        : null
)

export default Debug