import { Box } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react';
import * as React from 'react'

type Props = {
    children?: JSX.Element | null,
    image?: string
};

export const Wrapper = ({ children, image }: Props) => (
    <Box
        mx='auto'
        w='900px'
        p={10}
        bgColor='white'
        borderTopRadius='md'
        boxShadow='sm'

        position='relative'
    // overflow={`hidden`}
    >
        {image ?
            <Box
                overflow={`hidden`}
                mx={-10}
                mt={-10}
                w='110%'
                maxW='110%'
                roundedTop={'lg'}
            >
                <Image
                    src={image}
                    w='full'
                    h='200px'
                    objectFit={`cover`}

                    mb={6}
                />
            </Box>
            : null}
        {children}
    </Box>
)