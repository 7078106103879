import { Box, Button, Divider, Grid, Heading, HStack, Portal, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import Debug from '../Forms/Debug'
import { arrayIntRange } from '../Utils/arrayIntRange'
import { SemesterCoursesList } from './SemesterCoursesList'
import { YearCoursesStats } from './YearCoursesStats'
var lodash = require('lodash')

export const CoursePeriodView = ({ data, coursePlan, handleClose }) => {

    const extractSemesterCourses = (semester) => {
        let courses = coursePlan.courses;
        let semesterCourses = []
        for (let index = 0; index < courses.length; index++) {
            const course = courses[index].course;
            if (!course) continue;

            let courseSemestersList = arrayIntRange(course.startSemester, course.endSemester);
            if (courseSemestersList.includes(semester)) {
                semesterCourses.push(course);
            }
        }

        return semesterCourses

    }

    const SemestersBlock = () => {
        let semestersList = []
        for (let index = 1; index <= data.semestersNumber; index++) {
            semestersList.push(<SemesterCoursesList semester={index} courses={extractSemesterCourses(index)} />)
        }
        return <>{semestersList}</>
    }


    const extractYearCourses = (year) => {
        let courses = coursePlan.courses;
        let yearSemester = [year * 2 - 1, year * 2]
        let yearCourses = []
        for (let index = 0; index < courses.length; index++) {
            const course = courses[index].course;
            if (!course) continue;

            let courseSemestersList = arrayIntRange(course.startSemester, course.endSemester);
            // console.log('course', course.name, course.startSemester)
            // console.log('courseSemestersList', courseSemestersList)
            // console.log('yearSemester', yearSemester)
            if (lodash.intersection(courseSemestersList, yearSemester).length > 0) {
                yearCourses.push(course);
            }
        }

        console.log('yearCourses', year, yearCourses)
        return yearCourses

    }

    const YearsBlock = () => {
        let semestersList = []
        for (let index = 1; index <= Math.ceil(data.semestersNumber / 2); index++) {
            semestersList.push(<YearCoursesStats year={index} courses={extractYearCourses(index)} />)
            // semestersList.push(<div>{index}</div>)
        }
        return <>{semestersList}</>
    }



    return (
        <Portal>
            <Box
                zIndex={'toast'}
                position={'fixed'}
                top={0}
                bottom={0}
                left={0}
                right={0}
                overflow='scroll'
                bgColor={'white'}
                fontSize='sm'
            >
                <HStack
                    p={8}
                    borderBottom='solid 1px'
                    borderBottomColor={'gray.100'}

                >
                    <Button
                        leftIcon={<BiX />}
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>
                    <Divider orientation='vertical' />
                    <Text color='gray.700'>
                        Semester view for <em>{coursePlan.name}</em> course plan
                    </Text>
                </HStack>

                {/* <Debug data={data.courses.map(item => () => { "start": item.startSemester })} /> */}

                <Stack
                    p={8}
                    spacing={5}
                >
                    <Text fontSize={'sm'} color='gray.700' textAlign={'right'}>
                        <strong>CWL</strong> : course work load / <strong>PWL</strong> : personal work load
                    </Text>

                    <Heading fontSize={'md'}>Semesterly view</Heading>
                    <Box
                        overflowX={'scroll'}
                        pb={10}
                    >
                        <Grid
                            templateColumns={`repeat(${data.semestersNumber}, 400px)`}
                        >
                            <SemestersBlock />
                        </Grid>
                    </Box>

                    <Heading fontSize={'md'}>View per year</Heading>
                    <Box
                        overflowX={'scroll'}
                        pb={10}
                    >
                        <Grid
                            templateColumns={`repeat(${data.semestersNumber / 2}, 800px)`}
                        >
                            <YearsBlock />
                        </Grid>
                    </Box>
                </Stack>


            </Box>
        </Portal>
    )
}