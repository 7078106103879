import {
    Box,
    Flex,
    Stack,
    Text
} from '@chakra-ui/react';
import * as React from 'react'
import Debug from '../../Forms/Debug';


export const RegistrationCertificateFooter = ({
    data,
    promoNotes,
    bg
}) => {

    return (
        // <Flex
        //     justify={'flex-end'}
        //     fontSize='9px'
        //     mb={10}
        // >
        //     <Stack
        //         spacing={'1mm'}
        //         w={`108mm`}
        //     >
        //         <Box>
        //             <Text as='span' fontWeight={'600'}>EABHES EUROPE </Text>
        //             • 30 rue Cambronne • 75015 PARIS - France
        //         </Box>
        //         <Box>
        //             Phone +33 (0)1 42 96 86 90 • E-mail : eabhes.europe@eabhes.eu
        //         </Box>
        //         <Box
        //             bgColor='brand.blue.900'
        //             color='white'
        //             fontSize='10px'
        //             px={'2mm'}
        //             py={'2mm'}
        //         >
        //             European Accrditation Board of Higher Education Schools
        //         </Box>
        //         <Box
        //             fontSize={'7px'}
        //             textTransform='uppercase'
        //         >
        //             société par action simplifiée au capital de 5000€ • RCS Paris 512 046 657 • N° TVA FR 655 120 466 57
        //         </Box>
        //     </Stack>
        // </Flex>


        <Box
            py={`8mm`}
            mx={`8mm`}
            borderTop={`solid 1px`}
            borderTopColor={`red.400`}
        >
            <Text
                textAlign={`center`}
                fontSize={`10px`}
                color={`brand.blue.900`}
            >
                <strong>
                    EABHES EUROPE{' '}
                </strong>
                <Text
                    fontFamily={`Georgia`}
                    display={`inline`}
                >
                    30 rue de Cambronne, 75015 Paris - France | +33 (0)1 42 96 86 90 | eabhes.europe@eabhes.eu
                    <br />
                    <em>
                        SOCIÉTÉ PAR ACTION SIMPLIFIÉE AU CAPITAL DE  5 000€ - RCS PARIS 512 046 657 - N° TVA FR 655 120 466 57
                    </em>
                </Text>

            </Text>
        </Box>
    )
}