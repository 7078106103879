import { Box, Button } from '@chakra-ui/react'
import * as React from 'react'


export const BgSwitcher = ({
    bg,
    handleChangeBg
}) => (
    <Button
        onClick={handleChangeBg}
    // onClick={() => console.log('click')}
    >
        <Box
            w='20px'
            bgColor='white'
            borderRadius={'full'}
            mr={2}
        >
            <Box
                borderRadius={'full'}
                bgColor={!bg ? 'gray.300' : 'green.300'}
                w='10px'
                h='10px'
                transition='transform 300ms ease-in-out'

                transform={bg ? `translateX(10px)` : ''}
            />
        </Box>
        Background
        {/* {bg ? `hide background` : `show background`} */}
    </Button>
)