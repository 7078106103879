import { Badge, Box, Flex, Center, Heading, Link } from '@chakra-ui/layout'
import axios from 'axios';
import * as React from 'react'
import { config } from '../Utils/Config';
import { Outlet, useParams } from 'react-router';
import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
import { BiCheck, BiRightArrowAlt, BiX } from 'react-icons/bi';
// import authService from '../Auth/auth.service';
import { Loader } from '../UI/Loader';
import { UserContext } from '../Auth/userContext';
import { NoResults } from '../UI/NoResults';
import { scrollToTopContent } from '../Utils/scrollToTopContent';

const CourseList = ({ view }) => {

    const navigate = useNavigate();

    const params = useParams();



    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState('')
    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [programs, setPrograms] = React.useState(null)

    const user = React.useContext(UserContext).user

    let userSchool
    if (view === 'user') {
        userSchool = user.school;
        // alert(school);
    } else {
        userSchool = null;
    }

    React.useEffect(() => {
        getData();
    }, [params.page, search]);


    React.useEffect(() => {
        getPrograms();

    }, []);

    const getData = () => {

        axios.get(
            `${config.apiUrl}/course/${params.page ? params.page : 1}
            /${search.search ? search.search : '-'}
            /${search.program ? search.program : '-'}
            /${userSchool ? userSchool : search.school ? search.school : '-'}`.replace(/\s+/g, '')
        )
            .then(result => {
                setData(result.data);
                setLastPage(parseInt(result.headers.lastpage));
                setCurrentPage(parseInt(result.headers.currentpage));
                setTotalResults(parseInt(result.headers.results));
                if (result.data.length) {
                    // console.log("scroll to top")
                    scrollToTopContent();
                }
            })
    }

    const getPrograms = () => {
        axios.get(
            `${config.apiUrl}/program/all`
        )
            .then(result => {
                setPrograms(result.data);
            })
    }

    return (
        <>
            <Heading id='top' mb={4}>Courses</Heading>
            {view === 'admin' ?
                <TableActions getValues={(values) => setSearch(values)} programFilters={programs} view={view} />
                : null}
            {data ? data.length ?
                <Table my="8" borderWidth="1px" fontSize="sm" bgColor='white'>
                    <Thead bg={mode('gray.50', 'gray.800')}>
                        <Tr>
                            <Th>
                                Name
                            </Th>
                            <Th>Code</Th>
                            <Th>Program</Th>
                            <Th
                                textAlign='right'
                            >
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data ?
                            data.map((item, index) =>
                                <Tr key={index}>
                                    <Td whiteSpace="nowrap" key={index}>
                                        {item.name} - {item.version}
                                    </Td>
                                    <Td>{item.code} </Td>
                                    {/* <Td>{item.code} </Td> */}
                                    {item.program ?
                                        <Td><Link as={RouterLink} to={`/${view === 'admin' ? 'admin' : 'account'}/program/view/${item.program._id}/${item.program.primaryCoursePlan}`}>{item.program.name}</Link></Td>
                                        : null}

                                    {/* <Td>{item.minor} </Td> */}
                                    {/* <Td><Status status={item.status} /></Td> */}

                                    <Td textAlign="right">
                                        <Button
                                            variant="link"
                                            colorScheme="blue"
                                            as={RouterLink}
                                            to={`/${view === 'admin' ? 'admin' : 'account'}/course/edit/${item._id}`}
                                        >
                                            Edit
                                        </Button>
                                    </Td>
                                </Tr>
                            )

                            : null}
                    </Tbody>
                </Table>
                : <NoResults label='No course found' />
                : <Loader label='Loading courses' />
            }

            {
                view === 'user' ?
                    <Center>
                        <Button as={RouterLink} to='/account/course/new' variant={'outline'} size="sm" rightIcon={<BiRightArrowAlt />}>Create a new course</Button>
                    </Center>
                    : null
            }
            {
                totalResults && lastPage && currentPage ?
                    <TablePagination
                        totalResults={totalResults}
                        lastPage={lastPage}
                        currentPage={currentPage}
                        baseLink={view === 'user' ? '/account/course/' : '/admin/course/'}
                    />
                    : null
            }

            <Debug data={search} />

        </>
    )
}

export default CourseList