import * as React from 'react'
import { Box, Button, ButtonGroup, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Table, Tag, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom'

import { BiInfoCircle, BiInfoSquare } from 'react-icons/bi';
import { CodeTag } from './CodeTag';
import Debug from '../Forms/Debug';

export const ProgrameCoursesList = ({
    view,
    data,
    setCourseDetailId
}) => {

    const [coursePlanFilter, setCoursePlanFilter] = React.useState(`all`);

    const coursePresentInCoursePlan = (courseId: String) => {

        let coursePlanCourses = data.coursePlans.filter(item => item._id === coursePlanFilter)[0].courses;
        let courseIsInCoursePlan = coursePlanCourses.filter(course => course.course === courseId)

        return courseIsInCoursePlan.length > 0

    }

    const sortCourses = (data) => {
        console.log('data1', data);
        let sorted = data.sort((a, b) => a.code > b.code)
        console.log('data2', sorted);
        return sorted
    }

    return (
        <>
            <ButtonGroup
                // alignContent={`flex-end`}
                justifyContent={`flex-end`}
                alignItems={`center`}
                w={`full`}
                size={`xs`}

                mb={4}

            >

                <Text>Course plan : </Text>
                <Menu>
                    <MenuButton
                        as={Button}
                    >
                        {
                            coursePlanFilter === `all` ? `All`
                                :
                                data.coursePlans.filter(coursePlan => coursePlan._id === coursePlanFilter)[0].name}
                    </MenuButton>

                    <MenuList zIndex={`tooltip`}>
                        <MenuItem
                            // colorScheme={coursePlanFilter === `all` ? `blue` : undefined}
                            onClick={() => setCoursePlanFilter(`all`)}
                        >
                            {coursePlanFilter === `all` ? `→ ` : ``}
                            All
                        </MenuItem>
                        {data.coursePlans.map(coursePlan =>
                            <MenuItem
                                onClick={() => setCoursePlanFilter(coursePlan._id)}
                            >
                                {coursePlan._id === coursePlanFilter ? `→ ` : ``}
                                {coursePlan.name}
                            </MenuItem>
                        )}
                    </MenuList>
                </Menu>
                {/* <Button
                    colorScheme={coursePlanFilter === `all` ? `blue` : undefined}
                    onClick={() => setCoursePlanFilter(`all`)}
                >
                    All
                </Button>

                {data.coursePlans.map(coursePlan =>
                    <Button
                        // onClick={ }
                        colorScheme={coursePlanFilter === coursePlan._id ? `blue` : undefined}
                        onClick={() => setCoursePlanFilter(coursePlan._id)}
                    >
                        {coursePlan.name}
                    </Button>
                )} */}
            </ButtonGroup>

            <Table spacing={2}>
                <Thead>
                    <Tr>
                        <Th>Code</Th>
                        <Th>Name</Th>
                        <Th>Local name</Th>
                        <Th>ECTS</Th>
                        <Th textAlign={'right'}>Action</Th>
                    </Tr>
                </Thead>
                {
                    data && data.courses
                        ?
                        data.courses
                            .sort(
                                (a, b) => {
                                    let fa = a.code.toLowerCase(),
                                        fb = b.code.toLowerCase();

                                    if (fa < fb) {
                                        return -1;
                                    }
                                    if (fa > fb) {
                                        return 1;
                                    }
                                    return 0;
                                }
                            )
                            .map(item =>

                                coursePlanFilter === `all`
                                    || coursePresentInCoursePlan(item._id) ?

                                    <Tr fontSize='sm'>
                                        <Td>
                                            <HStack spacing={.5}>
                                                <CodeTag type={item.type}>
                                                    {item.code}
                                                </CodeTag>
                                                <Tag size={`sm`}>
                                                    {item.version}
                                                </Tag>
                                            </HStack>
                                        </Td>
                                        <Td>
                                            {item.name}
                                        </Td>
                                        <Td>
                                            <Box>
                                                {item.name}
                                                {/* / {item.startSemester} - {item.endSemester} */}
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Box>{item.ectsCredits}</Box>
                                        </Td>
                                        <Td textAlign={'right'}>
                                            <ButtonGroup
                                                position='relative'
                                                zIndex={'popover'}
                                            >
                                                <Button
                                                    size='xs'
                                                    variant={'link'}
                                                    onMouseEnter={
                                                        (e) => {
                                                            console.log('enter');
                                                            let elementPosition = e.target.getBoundingClientRect();
                                                            setCourseDetailId(item._id);
                                                            // setClickedElementPosition(
                                                            //     {
                                                            //         x: elementPosition.x,
                                                            //         y: elementPosition.y,
                                                            //     }
                                                            // )
                                                        }
                                                    }
                                                    onMouseOut={
                                                        () => { console.log('out') }
                                                    }
                                                >
                                                    <BiInfoSquare />
                                                </Button>
                                                <Button
                                                    as={Link}
                                                    size='xs'
                                                    variant={'link'}
                                                    to={`/${view === 'user' ? 'account' : 'admin'}/course/edit/${item._id}`}
                                                >
                                                    Edit
                                                </Button>
                                            </ButtonGroup>
                                        </Td>
                                    </Tr>
                                    : null
                            ) : null}
            </Table>


        </>
    )

}