import { Box, Button, ButtonGroup, Flex, Grid, Heading, HStack, Stack, Table, Text, Tbody, Td, Tr, Thead, Th } from '@chakra-ui/react'
import axios from 'axios';
import * as React from 'react'
import { BiAddToQueue, BiCheck, BiEdit, BiFileBlank, BiLeftArrowAlt, BiTrashAlt, BiX } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';
import { InvoiceForm } from './InvoiceForm';
import { SchoolVerticalNav } from './VerticalNav';
import { Link as RouterLink } from 'react-router-dom'
import { InvoiceUpdateForm } from './InvoiceUpdateForm';
import { BackButton } from '../UI/BackButton';

const lodash = require('lodash')

export const SchoolInvoicesList = ({ view }) => {

    const [data, setData] = React.useState(null)
    const [invoices, setInvoices] = React.useState(null)
    const [showForm, setShowForm] = React.useState(false)
    const [updateInvoice, setUpdateInvoice] = React.useState(false)

    let params = useParams();
    let { schoolId } = params;

    React.useEffect(() => {
        getData();
        getInvoices();
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/school/${schoolId}`)
            .then(result => setData(result.data));
    }
    const getInvoices = () => {
        axios.get(`${config.apiUrl}/invoice/school/${schoolId}`)
            .then(result => setInvoices(result.data));
    }

    const handleDelete = (invoiceId) => {
        axios.delete(`${config.apiUrl}/invoice/${invoiceId}`)
            .then(result => getInvoices());
    }

    const invoicePeriodsList = (data) => {
        let list = data.invoices.map(item => item.period).filter(item => item)
        list.push('other')
        // list = _.unik(list)
        list = lodash.uniq(list);
        list.sort()
        return list
    }

    return (
        <Grid
            templateColumns={`100%`}
            // gap={10}
            id={`top`}
        >

            {data ?
                <Wrapper>

                    <Stack
                        spacing={8}
                    >
                        <Box>
                            <BackButton label="Back" />
                        </Box>
                        <Flex>
                            <Heading mb={4} color='brand.blue.900'>
                                Invoices : {data.acronym}
                            </Heading>
                        </Flex>

                        {/* <Debug data={invoicePeriodsList(data)} /> */}
                        {/* <Debug data={data?.invoices} /> */}

                        <Box>
                            {invoices.length === 0 ?
                                <Text>No invoice has been registered for this account yet.</Text>
                                : null}

                            <Table
                                size={`sm`}
                            // bgColor={item.status === 'paid' ? `green.50` : `gray.50`}
                            >
                                <Thead>
                                    <Tr>
                                        <Th w='35%'>Name</Th>
                                        <Th w='10%'>Amount</Th>
                                        <Th w='15%'>Issue</Th>
                                        <Th w='15%'>Payment</Th>
                                        <Th textAlign={`right`}>File</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        invoicePeriodsList(data).map(period =>

                                            invoices.filter(
                                                item => item.period === period
                                                    || !item.period && period === `other`
                                            ).length > 0 ?
                                                <React.Fragment key={period}>
                                                    <Tr bgColor={`gray.50`}>
                                                        <Td fontSize='sm' fontWeight='600' colspan={3} textAlign={`left`}>Period : {period !== 'other' ? period : `unknown`}</Td>
                                                        <Td
                                                            opacity={view !== 'admin' ? 0 : 1}
                                                            colspan={2} textAlign={`right`} fontStyle={`italic`}>
                                                            Total period :{' '}
                                                            {
                                                                lodash.sum(
                                                                    invoices.filter(
                                                                        item => item.period === period
                                                                            || !item.period && period === `other`
                                                                    ).map(item => item.amount))
                                                            }€
                                                        </Td>
                                                    </Tr>
                                                    {invoices.filter(
                                                        item => item.period === period
                                                            || !item.period && period === `other`
                                                    ).map((item, index) =>
                                                        <>
                                                            {/* <Debug data={item} /> */}
                                                            <Tr
                                                                bgColor={item.status === `paid` ? `green.50` : `transparent`}
                                                            >
                                                                <Td w='35%'>
                                                                    <HStack>
                                                                        <Text
                                                                            color={item.status === 'paid' ? `green.500` : `red.500`}
                                                                            fontSize='20px'
                                                                        >
                                                                            {
                                                                                item.status === 'paid'
                                                                                    ?
                                                                                    <BiCheck />
                                                                                    :
                                                                                    <BiX />
                                                                            }
                                                                        </Text>

                                                                        <Text>
                                                                            {item.title.slice(0, 20)}
                                                                            {item.title.length > 20 ? '...' : ''}
                                                                        </Text>
                                                                    </HStack>
                                                                </Td>
                                                                <Td w='10%' textAlign={`right`}>{item.amount}€</Td>
                                                                <Td w='15%'>{item.issueDate ? item.issueDate.slice(0, 10) : null}</Td>
                                                                <Td w='15%'>{item.paymentDate ? item.paymentDate.slice(0, 10) : null}</Td>
                                                                <Td textAlign={`right`}>
                                                                    <ButtonGroup size={`xs`}>
                                                                        <Button
                                                                            leftIcon={<BiFileBlank />}

                                                                            as={`a`}
                                                                            target={`_blank`}
                                                                            href={`${config.apiUrl}/document/${schoolId}/invoice/${item.file}`}
                                                                        >
                                                                            Document
                                                                        </Button>
                                                                        {view === 'admin' ?
                                                                            <Button
                                                                                leftIcon={<BiEdit />}
                                                                                onClick={() => setUpdateInvoice(updateInvoice === item._id ? `` : item._id)}

                                                                            >
                                                                                Update
                                                                            </Button>
                                                                            : null}
                                                                    </ButtonGroup>
                                                                    {/* {item.paymentDate} */}
                                                                </Td>
                                                            </Tr>


                                                            {updateInvoice === item._id ?
                                                                <Tr>
                                                                    <Td colspan={5} p={0}>
                                                                        <Box p={8} bgColor={`gray.50`} border={`solid 1px`} borderColor={`gray.100`}>
                                                                            <InvoiceUpdateForm
                                                                                data={item}
                                                                                schoolId={schoolId}
                                                                                onSave={() => getInvoices()}
                                                                                handleClose={() => setUpdateInvoice(``)}

                                                                                deleteButton={
                                                                                    <Button
                                                                                        onClick={() => handleDelete(item._id)}
                                                                                        leftIcon={<BiTrashAlt />}
                                                                                    >Delete</Button>
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    </Td>
                                                                </Tr>
                                                                : null}
                                                            {/* <InvoiceUpdateForm id={item._id} /> */}
                                                        </>
                                                    )}
                                                </React.Fragment>
                                                : null
                                        )
                                    }


                                </Tbody>
                            </Table>
                        </Box>

                        {
                            view !== 'user' ?
                                !showForm ?
                                    <Box>
                                        <Button
                                            size="sm"
                                            leftIcon={<BiAddToQueue />}
                                            onClick={() => setShowForm(!showForm)}
                                        >
                                            Add an invoice
                                        </Button>
                                    </Box>
                                    :
                                    <Box>

                                        <InvoiceForm
                                            schoolId={schoolId}
                                            handleClose={() => setShowForm(!showForm)}
                                            onSave={() => {
                                                getInvoices();
                                                setShowForm(false);
                                            }}
                                        />
                                    </Box>
                                : null
                        }
                    </Stack>

                    {/* {invoices.length ?
                        <Text mt={8}>
                            Total invoiced :{' '}
                            <strong>
                                {
                                    _.sum(
                                        data.invoices.map(item => item.amount)
                                    )
                                } €
                            </strong>
                        </Text>
                        : null} */}
                </Wrapper>
                : null
            }

        </Grid>
    )
}