import { Box, Flex, Grid, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs';
import * as React from 'react'
import Debug from '../../Forms/Debug';
import { Italic, Strong } from '../../UI/Texts';
import { TranscriptBarCode } from './BarCode'

export const TranscriptHeader = ({ data }) => {

    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;

    const primaryData = [
        // {
        //     label: "Institution",
        //     content: data.school ? data.school.name : ''
        // },
        {
            label: "Category",
            content: data.program && data.program.category ? data.program.category : ''
        },
        {
            label: "Major",
            content: data.coursePlan && data.coursePlan.major ? data.coursePlan.major : data.program && data.program.major ? data.program.major : ''
        },

        ...data.coursePlan.minor ?
            [{
                label: "Minor",
                content: data.coursePlan && data.coursePlan.minor ? data.coursePlan.minor : ''
            }] : []
        ,
        ...data.program.transcript.showYears === false ?

            [{
                label: "Study duration",
                content: `${dayjs(data.startDate).format(`MMMM YYYY`)} / ${dayjs(data.endDate).format(`MMMM YYYY`)}`
            }]
            : []
    ]

    const secondaryData = [

        {
            label: "Student Name",
            content: `${data.student.firstName} ${data.student.lastName.toUpperCase()}`
        },
        {
            label: "Student ID",
            content: `${data.student.code}`
        },
        {
            label: "Birthdate",
            content:
                `
                    ${data.student.birth.date ?
                    dayjs(data.student.birth.date).format('YYYY/MM/DD')
                    : ''}`
        },
        {
            label: "Birthplace",
            content: `${data.student.birth.city} (${data.student.birth.country})`
        },

    ]

    // const allData = [...primaryData, ...secondaryData]

    const Line = ({ data }) => (
        <Box fontSize={'11px'}>
            <Italic>{data.label}</Italic>
            <Text as='span' fontWeight={'bold'}></Text>
            {`: `}
            <Strong>{data.content}</Strong>
        </Box>
    )

    return (
        <Stack
            p={`8mm`}
            minH={`40mm`}
            bgColor='white'
            color='brand.blue.900'
        >
            <Heading
                fontFamily={`Satoshi`}
                fontSize={`13px`}
            >
                <Text
                    fontWeight={`normal`}
                    fontStyle={`italic`}
                    fontFamily={`Georgia`}
                    as={`span`}
                >
                    Institution:{` `}
                </Text>
                {data?.school?.name}
            </Heading>
            {/* <Debug data={data.program ? data.program : null} /> */}
            <Grid
                gridTemplateColumns={
                    `62% 38%`
                }
                gap={`5mm`}
                rowGap={0}
            >
                <Stack spacing={.4}>
                    {primaryData.map((item, index) =>
                        <Line key={`transcript-header-line-primary-${index}`} data={item} />

                    )}
                </Stack>
                <Stack spacing={.4}>
                    {secondaryData.map((item, index2) =>
                        <Line key={`transcript-header-line-secondary-${index2}`} data={item} />

                    )}
                </Stack>
            </Grid>
        </Stack>
    )
}