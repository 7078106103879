import { Box, Button, Center, Divider, Heading, Link, FormLabel, Input, SimpleGrid, Stack, Text, ButtonGroup } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import FieldBlock from "../FieldBlock"
import * as yup from 'yup'
import axios from 'axios'
import { config } from '../../Utils/Config'
import { Link as RouterLink } from 'react-router-dom'

import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { PhoneField } from '../PhoneField'
import { UrlField } from '../URLField'
import { PublicLayout } from '../../Components/PublicLayout'
import { Helmet } from 'react-helmet'
import { PublicLayoutNew } from '../../Components/PublicLayout/new'

const validationSchema = yup.object().shape({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    email: yup.string().email('Invalid email').required('This field is required')
        .test('Unique Email', 'Your email already exists in our database',
            function (value) {
                return new Promise((resolve, reject) => {
                    axios.post(`${config.apiUrl}/user/already-exists`, { 'email': value })
                        .then(res => { if (res.data === 'already_exists') { resolve(false) } resolve(true) })
                })
            }
        ),
    websiteUrl: yup.string().url('This URL seems to not be valid.').required(),
    position: yup.string().required('Field required'),
    schoolName: yup.string().required('This field is required'),
    country: yup.string(),



})


export const SignupForm = () => {
    return (

        <PublicLayoutNew>
            <Helmet>
                <title>
                    Receive the EABHES accreditation quick guide
                </title>
            </Helmet>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    position: '',
                    schoolName: '',
                    websiteUrl: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axios.post(`${config.apiUrl}/user/create/lead`, values);
                }}
            >{({ values, errors, touched, submitCount, setFieldValue, setFieldTouched }) => (

                submitCount > 0
                    ?
                    <Center
                        py={24}
                    >
                        <Stack
                            spacing={6}
                        >
                            <Text>
                                Thank you for your request. You are going to receive our Quick Guide in your inbox in a few moment.
                            </Text>
                            <Text>
                                You can contact us for any other information at eabhes.europe@eabhes.eu.
                            </Text>

                            <Divider />
                            <Box>
                                <Button
                                    as={Link}
                                    colorScheme='blue'
                                    href='https://eabhes.eu'
                                    leftIcon={<BiLeftArrowAlt />}
                                >
                                    Go to our homepage
                                </Button>
                            </Box>
                        </Stack>
                    </Center>
                    :

                    <Box
                        as={Form}
                        order={{
                            base: 2, lg: 1
                        }}
                    >

                        <Center>
                            <Stack
                                w='full'
                                mx='auto'
                                spacing={{ base: 4, lg: 6 }}
                            >
                                <Stack
                                    spacing={3}
                                >
                                    <Heading
                                        color='brand.blue.900'
                                        fontSize={{ base: '3xl' }}
                                        letterSpacing={'tight'}
                                        fontFamily={`Georgia`}
                                        fontStyle={`italic`}
                                    >
                                        Get the accreditation Quick Guide
                                    </Heading>
                                    <Text
                                        letterSpacing={'wide'}
                                    >
                                        Fill in the form below and receive EABHES Accreditation
                                        <br />
                                        Quick Guide in your inbox.
                                    </Text>
                                </Stack>

                                <Divider />

                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field id='firstName' name='firstName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                            >
                                                <FormLabel>Firstname</FormLabel>
                                                <Input bgColor='white' {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    <Field name='lastName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                            >
                                                <FormLabel>Lastname</FormLabel>
                                                <Input bgColor='white' {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>

                                    <Field name='email'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                            >
                                                <FormLabel>Email address</FormLabel>
                                                <Input bgColor='white' {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    <Field name='position'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}

                                            >
                                                <FormLabel>Your position</FormLabel>
                                                <Input bgColor='white' {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                </SimpleGrid>
                                <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                    <Field name='schoolName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                            >
                                                <FormLabel>School / Institution</FormLabel>
                                                <Input bgColor='white' {...field} />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    <Field name='websiteUrl'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                            >
                                                <FormLabel>School website</FormLabel>
                                                <UrlField
                                                    value={meta.value}
                                                    name={field.name}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <Divider />
                                <ButtonGroup
                                    spacing={4}
                                    flexWrap={{ base: 'wrao', lg: 'nowrap' }}
                                >
                                    <Button
                                        type='submit'
                                        colorScheme={'blue'}
                                        rightIcon={<BiRightArrowAlt />}
                                        isDisabled={
                                            Object.keys(touched).length === 0
                                            || Object.keys(errors).length > 0
                                        }
                                    >
                                        Receive your guide
                                    </Button>
                                    <Button
                                        variant={'link'}
                                        bgColor='transparent'
                                        as={RouterLink}
                                        to='../'
                                        display={{ base: 'none', lg: 'flex' }}
                                    >
                                        Or go to login page
                                    </Button>
                                </ButtonGroup>
                            </Stack>
                        </Center>
                    </Box>


            )}

            </Formik>
        </PublicLayoutNew>
    )

}