import { Box, Center, Text } from '@chakra-ui/layout'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Link as ScrollLink } from 'react-scroll'

const steps = [
    'General information',
    'Board',
    'Academics',
    'International strategy',
    'Accreditation plan'
]

export const Summary = ({
    step,
    handleChangeStep,
    isActive
}) => (
    <Box
        opacity={isActive ? 1 : 0}
        transition='opacity 300ms'
    >
        <Text
            textTransform='uppercase'
            letterSpacing='widest'
            fontWeight='500'
            fontSize='sm'
            color='gray.400'
            mb={2}
            pl={6}

        >Application steps</Text>
        <Box
            pos='relative'
            pl={6}
        >
            <Box
                pos='absolute'
                left='0'
                h='28px'
                top={`${step * 28}px`}
                transition='top 450ms ease-in-out'
            >
                <Center h='full'>
                    <BiRightArrowAlt />
                </Center>
            </Box>
            {steps.map((item, index) =>
                <Box
                    key={`${item}-${index}`}
                    display='block'
                    // onClick={()=> handleChangeStep( index  )}
                    role='group'
                    cursor='pointer'
                    py={.5}
                    as={ScrollLink}
                    to={`step${index}`}
                    spy={true} smooth={true} offset={-50} duration={1000}
                >
                    <Text
                        color={index === step ? 'gray.800' : 'gray.400'}
                        transition='color 500ms ease-in-out'
                        _groupHover={{ color: 'blue.400' }}
                    >
                        {item}
                    </Text>
                </Box>
            )}
        </Box>
    </Box>
)