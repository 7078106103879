import { Button, Divider, Heading, Link, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { scroller } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
import { BiEditAlt } from 'react-icons/bi'
export const SchoolVerticalNav = ({ schoolId }) => {

    const items = [
        {
            label: 'Top',
            target: 'top'
        },
        {
            label: 'General information',
            target: 'general'
        },

        {
            label: 'Documents',
            target: 'documents'
        },
        {
            label: 'Comments',
            target: 'comments'
        },
        {
            label: 'Programmes',
            target: 'programs'
        },
        {
            label: 'Details',
            target: 'details'
        },
        {
            label: 'Partnerships & certifications',
            target: 'partnershipsandcertifications'
        },
    ]

    return (
        <Stack
            position={`sticky`}
            top={0}
            bgColor='white'
            p={6}
            shadow='sm'
            rounded={'md'}
        >
            <Heading
                size='md'
                mb={3}
            >
                Navigation
            </Heading>
            {
                items.map(item =>
                    <Link
                        textTransform={`uppercase`}
                        fontSize={`xs`}
                        letterSpacing={`widest`}
                        fontWeight={600}
                        color={`gray.500`}
                        _hover={{
                            color: `gray.600`
                        }}

                        key={item.target}
                        onClick={() => {
                            scroller.scrollTo(
                                item.target,
                                {
                                    spy: true,
                                    smooth: true,
                                    duration: 350,
                                    delay: 0,
                                    containerId: 'content',
                                    offset: -30
                                }
                            )
                        }}



                    >
                        {item.label}
                    </Link>
                )
            }
            <Divider pt={4} />
            <Button
                variant={`link`}
                size='sm'
                justifyContent='flex-start'
                as={RouterLink}
                to={`/admin/school/edit/${schoolId}`}

                leftIcon={<BiEditAlt />}
            >
                Update
            </Button>

        </Stack >
    )
}