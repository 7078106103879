import { Heading } from '@chakra-ui/layout'
import axios from 'axios';
import * as React from 'react'
import { config } from '../Utils/Config';

import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
import { StatusTag } from './Tag';
import { Loader } from '../UI/Loader';
import { scrollToTopContent } from '../Utils/scrollToTopContent';
import { NoResults } from '../UI/NoResults';

const SchoolList = ({ view }) => {

    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState('')
    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [schools, setSchools] = React.useState(null)

    const page = params.page ? parseInt(params.page) : 1;

    React.useEffect(() => {
        getData();
    }, [params.page, search]);

    React.useEffect(() => {
        getSchools();
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/school/${page ? page : '-'}/${search.search ? search.search : '-'}/${search.status ? search.status : '-'}`)
            .then(result => {
                setData(result.data);
                scrollToTopContent();
            })
    }

    const getSchools = () => {
        axios.get(
            `${config.apiUrl}/school`
        )
            .then(result => {
                setData(result.data);
                setLastPage(parseInt(result.headers.lastpage));
                setTotalResults(parseInt(result.headers.results));
                if (result.data.length) {
                    scrollToTopContent();
                }
            })
    }

    return (
        <>
            <Heading id='top' mb={4}>Schools</Heading>
            <TableActions getValues={(values) => setSearch(values)} schoolFilters={schools} />
            {data
                ? data.length ?
                    <>
                        <Table mt="8" mb={2} borderWidth="1px" fontSize="sm" bgColor='white'>
                            <Thead bg={mode('gray.50', 'gray.800')}>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Type</Th>
                                    <Th>Country</Th>
                                    <Th>Region</Th>
                                    <Th>Status</Th>
                                    <Th
                                        textAlign='right'
                                    >
                                        Action
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data ?
                                    data.map((school, index) =>
                                        <Tr key={index}>
                                            <Td whiteSpace="nowrap" key={index}>
                                                {school.acronym ? `${school.acronym} - ` : null}
                                                {school.name}
                                            </Td>
                                            <Td>{school.typeOfLegalEntity} </Td>
                                            <Td>
                                                {school.country}
                                            </Td>
                                            <Td>
                                                {school.region}
                                            </Td>
                                            <Td>
                                                <StatusTag>{school.status}</StatusTag>
                                            </Td>
                                            <Td textAlign="right">
                                                <Button
                                                    size='sm'
                                                    variant="link"
                                                    colorScheme="blue"
                                                    as={RouterLink}
                                                    to={`/admin/school/view/${school._id}`}
                                                >
                                                    Details
                                                </Button>
                                            </Td>
                                        </Tr>
                                    )
                                    : null}
                            </Tbody>
                        </Table >
                        <Text
                            fontSize={'xs'}
                            color='gray.400'
                        >
                            Archived schools are only displayed if you filter on "archived" status.
                        </Text>
                    </>
                    : <NoResults label='No results found' />
                : <Loader label='Loading school' />
            }

            {totalResults && page ?
                <TablePagination
                    totalResults={totalResults}
                    lastPage={lastPage}
                    currentPage={page}
                    baseLink={`/${view === 'admin' ? 'admin' : 'account'}/school/`}
                />
                : null}

            {/* <Debug data={search} /> */}

        </>
    )
}

export default SchoolList