import { Box, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import stampPurple from '../../Images/stamps/stamps-purple.png'


export const TranscriptPagination = ({ pageNumber, lastPage }) => (
    <Flex
        justify={`flex-end`}
        px={`4mm`}
        position='relative'
    >
        <Text
            w={`12.5%`}
            pt={`10mm`}
            textAlign={`center`}
            fontSize={`10px`}
            color={`gray.500`}
        >
            Page {pageNumber} of {lastPage}
        </Text>
        {pageNumber < lastPage ?
            <Box
                position='absolute'
                top={14}
                right={4}
                w={`25mm`}
                h={`25mm`}
                bgImage={`url(${stampPurple})`}
                bgSize={`2.5cm 2.5cm`}
                bgRepeat={`no-repeat`}
            // display={bg ? `block` : `none`}
            // bgPos={`10mm 10mm`}
            />
            : null}
    </Flex>
)