import { Button, ButtonGroup, Divider, Flex, HStack, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'react-router-dom'


export const TablePagination = (
    {
        currentPage,
        lastPage,
        totalResults,
        baseLink,
        itemName
    }
) => {
    return (
        // <>
        // { totalResults}
        // </>
        <Flex
            align="center"
            justify="space-between"
            py={{ base: 2, lg: 4 }}
        >
            <HStack>
                <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                    {totalResults} {`${itemName}${totalResults > 1 ? 's' : ''}`}
                </Text>
                <Text color='gray.600'>•</Text>
                <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                    Page {currentPage} / {lastPage}
                </Text>
            </HStack>

            <ButtonGroup variant="outline" size="sm">
                <Button
                    as={Link}
                    to={`${baseLink}${currentPage - 1}`}
                    rel="prev"
                    isDisabled={currentPage === 1}
                    pointerEvents={currentPage !== 1 ? 'auto' : 'none'}
                >
                    Previous
                </Button>
                <Button
                    as={Link}
                    to={`${baseLink}${currentPage + 1}`}
                    rel="next"
                    isDisabled={currentPage === lastPage}
                    pointerEvents={currentPage !== lastPage ? 'auto' : 'none'}
                >
                    Next
                </Button>
            </ButtonGroup>
            {/* { currentPage } */}
        </Flex>
    )
}
