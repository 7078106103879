import { Box, Button, Center, Flex, Input, Progress, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiCloudUpload, BiPlus } from 'react-icons/bi';
import { config } from '../Utils/Config';

export const UploadButton = ({
    uploadCallback,
    linkedDocumentId,
    isPublic,
    align,
    outputName
}) => {
    const [isActive, setIsActive] = React.useState(false);

    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(0);

    const fileInput = React.useRef()

    React.useEffect(
        () => {
            if (uploadProgress && !isUploading) {
                setIsUploading(true);
            } else if (!uploadProgress) {
                setIsUploading(false);
            }
        }, [uploadProgress]
    )

    var axiosParams = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    const proceedUpload = file => {
        console.log('file', file)
        var formData = new FormData();
        formData.append("document", file);
        formData.append("public", isPublic ? isPublic : false);
        formData.append("outputName", outputName ? outputName : file.name);
        axios.post(
            `${config.apiUrl}/document/${linkedDocumentId}`
            , formData,
            axiosParams
        ).then(async res => {
            setUploadProgress(null)
            uploadCallback(file);
            if (fileInput.current) { fileInput.current.value = null; };
            setIsActive(false);
        })
    }

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            proceedUpload(file);
        }
    }

    const handleChange = e => {
        const files = e.target.files;
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            proceedUpload(file);
        }
    }

    return (
        !isActive
            ?
            <Flex
                justify={align === `left` ? 'flex-start' : 'flex-end'}
            >
                <Button
                    onClick={() => setIsActive(true)}
                    leftIcon={<BiPlus />}
                    size='sm'
                >
                    Add files
                </Button>
            </Flex>
            :
            <>
                <Center
                    cursor='copy'
                    border='dashed 2px'
                    borderColor='gray.300'
                    borderRadius='md'
                    transition='transform 200ms ease-in-out, border-color 200ms ease-in-out'
                    _hover={{
                        borderColor: 'green.400',
                        // transform: 'scale(1.01)'
                    }}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    <Box>

                        <Center
                            minH='150px'
                        >
                            <Box>
                                <Button
                                    as='label'
                                    htmlFor='fileInput'
                                    size='sm'
                                    leftIcon={<BiCloudUpload />}
                                    cursor='pointer'
                                    display={isUploading ? 'none' : 'flex'}

                                >
                                    Browse and upload
                                    {/* {uploadProgress ? ` (${uploadProgress})%` : ''} */}
                                    {/* {isUploading ? <Spinner size='xs' /> : null} */}
                                </Button>
                                <Box
                                    my={2}
                                    display={isUploading ? 'block' : 'none'}
                                >
                                    <Progress borderRadius={'md'} hasStripe size={'sm'} value={uploadProgress} />
                                    <Text textAlign={'center'} color='gray.400' fontSize={'xs'}>{uploadProgress ? `${uploadProgress}%` : null}</Text>
                                </Box>
                                <Text textAlign='center' fontSize='xs' color='gray.600' mt={2}>
                                    {!isUploading ? 'Or drop your file here' : 'Upload in progress, please, wait'}
                                </Text>
                            </Box>
                        </Center>


                    </Box>
                </Center>
                <input
                    style={{
                        opacity: 0,
                        position: "relative",
                        zIndex: 1
                    }}
                    id='fileInput'
                    multiple
                    type='file'
                    name='file'
                    onChange={handleChange}
                    ref={fileInput}
                />
            </>
    )
}