import { Box, Button, Divider, Flex, Heading, HStack, Input, Portal, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import Debug from '../../Forms/Debug'
import FieldBlock from '../../Forms/FieldBlock'
import { BackButton } from '../../UI/BackButton'
import { config } from '../../Utils/Config'
import { Wrapper } from '../Wrapper'
import { NotesForm } from './form'
import { NotesFormHeader } from './NotesFormHeader'

export const NoteImport = ({ }) => {


    const [programs, setPrograms] = React.useState([]);
    const [programId, setProgramId] = React.useState('');
    const [programData, setProgramData] = React.useState('');
    const [coursePlanId, setCoursePlanId] = React.useState('');

    const [startYear, setStartYear] = React.useState('');
    const [endYear, setEndYear] = React.useState('');

    const [inscriptions, setInscriptions] = React.useState('');
    const [promotions, setPromotions] = React.useState('');


    const [showNoteTable, setShowNoteTable] = React.useState(false)


    React.useEffect(() => {
        getData();
    }, [programId]);


    React.useEffect(() => {
        if (
            programId
            && coursePlanId
            && startYear
            && endYear
            // && !inscriptions
        ) {
            getinscriptions();
        }
    }, [programId, startYear, endYear]);

    React.useEffect(() => {
        // console.log('somtehing changes');
        getPromotions();
    }, [programId, coursePlanId]);

    React.useEffect(() => {
        // console.log('course plan id has changed');
        setPromotions(null)
    }, [coursePlanId]);

    React.useEffect(() => {
        // console.log('course plan id has changed');
        getinscriptions();
    }, [startYear, endYear]);

    const getData = () => {

        axios.get(`${config.apiUrl}/program/all`)
            .then(result => setPrograms(result.data));

        if (programId) {
            axios.get(`${config.apiUrl}/program/${programId}`)
                .then(result => setProgramData(result.data));
        }
    }

    const getPromotions = () => {
        if (programId && coursePlanId) {
            axios.get(
                `${config.apiUrl}/inscription/promo/${programId}/${coursePlanId}/`
            ).then(res => {
                setPromotions(res.data);
            });
        }
    }

    const getinscriptions = () => {
        if (programId && coursePlanId && startYear && endYear) {
            axios.get(
                `${config.apiUrl}/inscription/${programId}/${coursePlanId}/${startYear}/${endYear}/all`
            ).then(res => {
                setInscriptions(res.data);
            });
        }
    }

    const YearForms = () => {
        let yearFormsList = [];
        let yearNumber = Math.ceil(programData.semestersNumber / 2)

        for (let index = 0; index < yearNumber; index++) {
            yearFormsList.push(
                <Box
                    bgColor={`gray.${(index + 1) * 50}`}
                    p={12}
                    key={`note-form-${index}`}
                >
                    <Debug
                        data={[coursePlanId, programId, startYear, endYear, index + 1]}
                    />
                    <NotesForm
                        programId={programId}
                        coursePlanId={coursePlanId}
                        startYear={startYear}
                        endYear={endYear}
                        year={index + 1}
                        handleClose={() => setShowNoteTable(false)}
                        key={`${programId}${coursePlanId}${startYear}${endYear}${index}`}
                    />
                </Box>
            )

        }
        return <>{yearFormsList}</>
    }

    return (
        <Wrapper>
            {showNoteTable ?
                <Portal>
                    <Box
                        position={'fixed'}
                        top={0}
                        right={0}
                        left={0}
                        bottom={0}
                        // minH='100vh'
                        bgColor={'white'}
                        overflowY={'scroll'}
                    >
                        <Box
                            h='auto'
                        >
                            <NotesFormHeader
                                handleClose={() => setShowNoteTable(false)}
                            />
                            <Box>
                                <YearForms />
                            </Box>
                        </Box>
                    </Box>
                </Portal>
                : null}

            <Stack spacing={4}>
                <Flex
                    justify={'space-between'}
                >
                    <BackButton label='Back' />
                </Flex>
                <Heading>
                    Notes importation
                </Heading>

                <SimpleGrid columns={3} gap={4}>
                    <FieldBlock
                        title='Involved programme'
                    >
                        <Select
                            placeholder='Select program'
                            onChange={(e) => setProgramId(e.target.value)}
                        >
                            {programs.map(item =>
                                <option value={item._id} key={`program-${item._id}`}>{item.localName}</option>
                            )}
                        </Select>
                    </FieldBlock>
                    {programId && programData ?
                        <FieldBlock
                            title='Course plan'
                        >
                            <Select
                                placeholder='Select the course plan'
                                onChange={(e) => setCoursePlanId(e.target.value)}
                            >
                                {programData.coursePlans.map(item =>
                                    <option value={item._id} key={`course-plan-${item._id}`}>{item.name}</option>
                                )}
                            </Select>
                        </FieldBlock>
                        : null}

                    {coursePlanId && promotions ?
                        <FieldBlock
                            title='Promotion'
                        >
                            <Select
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    let index = e.target.value;
                                    setStartYear(promotions[index].data.start);
                                    setEndYear(promotions[index].data.end);
                                }}
                                placeholder='select'
                            >
                                {promotions.map((item, index) =>
                                    item.coursePlan._id === coursePlanId ?
                                        <option key={item} value={index}>
                                            {`${item.data.start} → ${item.data.end}`}
                                        </option>
                                        : null
                                )}
                            </Select>
                        </FieldBlock>
                        : null}
                </SimpleGrid>

                <Divider pt={5} mb={5} />
                <HStack key={inscriptions}>
                    {
                        programId
                            // && coursePlanId
                            && startYear
                            && endYear
                            && inscriptions && inscriptions.length
                            ?
                            <>
                                <Button
                                    onClick={() => setShowNoteTable(true)}
                                >
                                    Import notes

                                </Button>
                                <Text>
                                    {`(${inscriptions.length} inscriptions)`}
                                </Text>
                            </>
                            :
                            <Text>
                                No inscription for this parameters
                            </Text>
                    }
                </HStack>
            </Stack>
        </Wrapper>
    )
}