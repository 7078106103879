var lodash = require('lodash');



export const passingRank = (note, program) => (
    !failingRank(note, program)
)

export const failingRank = (note, passingNote, failingNote) => {

    // console.log('notezzzz', note, passingNote, failingNote);

    // return (`${note}-${passingNote}`)

    if (note && note < passingNote) {
        return (
            note <= failingNote
                ? 'F' :
                note < passingNote
                    ? 'Fx' :
                    false
        )
    } else {
        return false
    }

}

export const coursePromoNotes = (courseCode, promoNotes) => {

    let courseNotes = promoNotes
        .filter(item => item.course === courseCode) // Only course corresponding to course code
        // .filter(item => !failingRank(item.note, program)) // Only passing notes
        .filter(item => item.note > 0) // remove pendings note (0)
        .map(item => item.note); // Select only the note

    courseNotes.sort((a, b) => a - b);
    courseNotes.reverse();
    // console.log('courseNotes', courseNotes)

    return courseNotes
}

export const ectsGrade = (note, promoNotes, program) => {

    if (
        !note.note
        || note.note === 0
        || note.note === -1
        || note.course.passing.system === 'passOrFail'
    ) return '-'

    let failingNote = program.failingNote;

    // return note.course.passing.system === 'fixedLimite' ? 'fixedLimite' : 'average'
    let passingSystem = note.course.passing.system;
    let passingValue = note.course.passing.value;
    let passingRatio = note.course.passing.ratio;

    // console.log('coursePromoNotes', coursePromoNotes(note.course._id, promoNotes));
    let coursePromoAverage = lodash.mean(coursePromoNotes(note.course._id, promoNotes));
    let marginToClassAverage = coursePromoAverage * passingRatio;

    let passingNote =
        passingSystem === 'fixedLimite' ?
            passingValue
            :
            marginToClassAverage < passingValue && marginToClassAverage > failingNote
                ? marginToClassAverage
                : passingValue

    // console.log(`passing rank grade`, marginToClassAverage < passingValue, passingNote)
    // console.log(`rank marginToClassAverage`, marginToClassAverage)
    // console.log(`failingNote rank`, failingNote)


    // console.log(`rank info`, note.note, passingNote, failingNote)

    if (failingRank(note.note, passingNote, failingNote)) {
        // Case F & Fx grades
        // console.log('failing rank');
        return (failingRank(note.note, passingNote, failingNote))
    }
    else {
        // Case A,B,C,D,& E Grades
        // console.log('success rank')
        if (coursePromoNotes(note.course._id, promoNotes).length <= 8) {
            // console.log('classSize', "low")
            return lowClassSizeRank(coursePromoNotes(note.course._id, promoNotes), note.note)
        } else {
            // console.log('classSize', "large")
            return largeClassSizeRank(coursePromoNotes(note.course._id, promoNotes), note.note)
        }

    }
}

export const largeClassSizeRank = (promoCourseNotes, studentNote) => {

    let position = promoCourseNotes.indexOf(studentNote) + 1;
    let rank = (100 * (position - 1) / promoCourseNotes.length);

    // console.log('position', position, typeof (position))
    // console.log('rank', rank, typeof (position))


    if (rank <= 10) {
        return 'A'
    }
    else if (rank <= 35) {
        return 'B'
    }
    else if (rank <= 65) {
        return 'C'
    }
    else if (rank <= 90) {
        return 'D'
    }
    else if (rank <= 100) {
        return 'E'
    }
}

export const lowClassSizeRank = (promoCourseNotes, studentNote) => {
    let position = promoCourseNotes.indexOf(studentNote) + 1;

    if (promoCourseNotes.length <= 5) {
        return lowClassSizeRank5(position)
    }
    if (promoCourseNotes.length === 6) {
        return lowClassSizeRank6(position)
    }
    if (promoCourseNotes.length === 7) {
        return lowClassSizeRank7(position)
    }
    if (promoCourseNotes.length === 8) {
        return lowClassSizeRank8(position)
    }
}

export const lowClassSizeRank5 =
    (
        position
    ) => {

        let rank = ''
        switch (position) {
            case 0:
                rank = 'A'
                break;
            case 1:
                rank = 'A'
                break;
            case 2:
                rank = 'B'
                break;
            case 3:
                rank = 'C'
                break;
            case 4:
                rank = 'D'
                break;
            case 5:
                rank = 'E'
                break;

            default:
                rank = 'bug-r-5 ' + position
                break;
        }

        return rank
    }

export const lowClassSizeRank6 =
    (
        position
    ) => {

        let rank = ''
        switch (position) {
            case 1:
                rank = 'A'
                break;
            case 2:
                rank = 'B'
                break;
            case 3:
                rank = 'C'
                break;
            case 4:
                rank = 'C'
                break;
            case 5:
                rank = 'D'
                break;
            case 6:
                rank = 'E'
                break;

            default:
                rank = 'bug-r-6'
                break;
        }

        return rank
    }

export const lowClassSizeRank7 =
    (
        position
    ) => {

        let rank = ''
        switch (position) {
            case 1:
                rank = 'A'
                break;
            case 2:
                rank = 'B'
                break;
            case 3:
                rank = 'C'
                break;
            case 4:
                rank = 'C'
                break;
            case 5:
                rank = 'C'
                break;
            case 6:
                rank = 'D'
                break;
            case 7:
                rank = 'E'
                break;

            default:
                rank = 'bug-r-7'
                break;
        }

        return rank
    }


export const lowClassSizeRank8 =
    (
        position
    ) => {

        let rank = ''
        switch (position) {
            case 1:
                rank = 'A'
                break;
            case 2:
                rank = 'B'
                break;
            case 3:
                rank = 'B'
                break;
            case 4:
                rank = 'C'
                break;
            case 5:
                rank = 'C'
                break;
            case 6:
                rank = 'D'
                break;
            case 7:
                rank = 'D'
                break;
            case 8:
                rank = 'E'
                break;

            default:
                rank = 'bug-r-8'
                break;
        }

        return rank
    }


export const convertEctsGradeToEuGpa = (grade) => (
    grade === 'A' ? 4
        : grade === 'B' ? 3.33
            : grade === 'C' ? 2.67
                : grade === 'D' ? 2
                    : grade === 'E' ? 1
                        : grade === 'F' ? 0
                            : 0
)