import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Table, Tr, Td, Text, Portal, Textarea, FormLabel, Stack, HStack, FormHelperText, FormControl, ModalFooter } from '@chakra-ui/react';
import React, { ClipboardEvent } from 'react';
import { BiCopyAlt, BiImport, BiTrash } from 'react-icons/bi';
import Debug from '../../Forms/Debug';

export const PastingTool = ({ handleImport, linesNumber, colsNumber }) => {
    const [active, setActive] = React.useState()
    const [data, setData] = React.useState();
    const [error, setError] = React.useState('');


    React.useEffect(
        () => {
            if (data) checkErrors(data);
        }, [data]
    );

    const checkErrors = (data) => {
        console.log('data error detection', data)
        let colError = false;
        for (let index = 0; index < data.length; index++) {
            if (data[index].length !== colsNumber) colError = true
        }
        if (
            data.length > linesNumber
            || colError

        ) {
            setError('Your data does not match with expected format, please check.')
        } else {
            setError('');
        }
    }

    const InputComponent = () => {
        const handleClipboardEvent = (e: ClipboardEvent<HTMLInputElement>) => {
            // Do something
            let input = e.clipboardData.getData("text");
            let parsedInput = input.trim().split(/\r\n|\n|\r/).map(row => row.split('\t'));
            setData(parsedInput);
            // checkErrors(parsedInput);

        };


        return (
            !data
                ?
                <Textarea
                    placeholder='Paste notes from spreadsheet here'
                    onPaste={handleClipboardEvent}
                    bgColor='white'
                />
                :
                <>
                    <Preview data={data} />
                </>
        )
    };

    const Preview = ({ data }) => {
        return (
            <Stack>
                <Table
                    size='sm'
                >
                    {data.map(row =>
                        <Tr>
                            {row.map(cell => <Td>{cell}</Td>)}
                        </Tr>
                    )}
                </Table>

                {error ?
                    <Flex>
                        <Text
                            color={'red.500'}
                            borderBottom={'solid 3px'}
                            borderBottomColor='red.100'
                            lineHeight={1}
                            p={.5}
                            rounded='xs'
                            fontSize={'xs'}
                        >
                            {error}
                        </Text>
                    </Flex>
                    : null}

                <HStack>

                    {!error ?
                        <Button
                            size='sm'
                            leftIcon={<BiImport />}
                            onClick={() => {
                                handleImport(data);
                                setData(null);
                                setActive(false);
                            }}
                        >
                            Import
                        </Button>
                        : null}
                    <Button
                        size='sm'
                        leftIcon={<BiTrash />}
                        onClick={() => setData(null)}
                    >
                        Clear
                    </Button>
                </HStack>

            </Stack>
        )
    }

    return (
        !active ?
            <Button
                leftIcon={<BiCopyAlt />}
                onClick={() => setActive(!active)}
                size='xs'
            >
                Paste notes
            </Button>
            :
            <Modal
                isOpen={active}
                onClose={() => setActive(false)}
                size={data && data[0].length > 5 ? 'full' : 'md'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>
                                Paste data from Excel or Spreasheets here :
                            </FormLabel>
                            <InputComponent />
                            <FormHelperText>
                                You must paste data with exactly <strong>{linesNumber}</strong> lines and <strong>{colsNumber}</strong> cols. Cells must contains only numbers.
                            </FormHelperText>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter><Button size='sm' onClick={() => setActive(false)}>Close</Button></ModalFooter>
                </ModalContent>

            </Modal>

    )
}