import { Center, Flex, Text, Spinner, Box } from '@chakra-ui/react'
import * as React from 'react'
import { BiMessageSquareX, BiX } from 'react-icons/bi'

interface loaderProps {
    label?: string
}

export const NoResults: React.FC<loaderProps> = ({ label }) => (

    <Center py={4}>
        <Flex
            alignItems={'center'}
        >
            {/* BiMessageSquareX */}
            <Box mr={2}>
                <BiMessageSquareX />
            </Box>
            {label ?
                <Text fontSize={'sm'} letterSpacing={'wide'} color={'gray.600'}>{label}</Text>
                : null}
        </Flex>
    </Center>
)