import * as Yup from 'yup';

const SchoolFormSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(200, 'Too Long!')
        .required('Required'),
    user: Yup.string()
    // .required('Required'),

});

export { SchoolFormSchema }