import { scroller } from 'react-scroll';
export const scrollToTopContent = () => {

    // return null
    // setTimeout(
    // () => {
    let target = document.querySelector('#top');
    // console.log('target', target)
    // console.log('type of target', typeof (target))
    // console.log(getComputedStyle(target).position)
    if (target === null) return
    else {
        scroller.scrollTo(
            'top',
            {
                spy: true,
                smooth: true,
                duration: 250,
                delay: 2000,
                containerId: 'content',
                offset: -30
            }
        )
    }
    // },
    // 2000
    // )
}