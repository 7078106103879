import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Box, Checkbox, CheckboxGroup, Flex, FormLabel, FormControl, HStack, Heading, Stack, VStack, Grid, Radio, Select, Text, FormHelperText, SimpleGrid, Textarea, RadioGroup } from '@chakra-ui/react'
import { Field, FieldArray, FieldProps } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt, BiX } from 'react-icons/bi'
import { Map } from '../../../Components/Map'
import LocationField from '../../AddressField'
import Debug from '../../Debug'
import FieldBlock from '../../FieldBlock'

import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react'
import { StepTitle } from './StepTitle'
import { AddButton } from '../../../UI/AddButton'
import { languages } from '../../../Utils/Languages'

export const ApplicationAcademics = ({
    handleGotNext,
    id,
    setFieldValue,
    values,
    setStep,
    step,
    isStarted
}) => (
    <Stack spacing={6} id={id}>
        <StepTitle
            step={step}
            setStep={setStep}
            isStarted={isStarted}
        >
            Academics
        </StepTitle>

        <Stack pl={24} spacing={8}>


            <Field name='requirementsUnderGraduatePrograms'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Requirements for undergraduate programmes"
                    >
                        <Textarea {...field} />
                    </FieldBlock>
                )}
            </Field>

            <Field name='requirementsGraduatePrograms'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Requirements for graduate programmes"
                    >
                        <Textarea {...field} />
                    </FieldBlock>
                )}
            </Field>

            <Field name='numberStudentsPerProgram'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Average number of students enrolled per programme"
                    >
                        <Input w='4xs' type='number' {...field} />
                    </FieldBlock>
                )}
            </Field>

            <FieldArray
                name="diplomasOffered"
                render={arrayHelpers => (
                    <FieldBlock
                        title='Diplomas offered'
                    >
                        <Stack>
                            {values.diplomasOffered && values.diplomasOffered.length > 0 ? (
                                values.diplomasOffered.map((item, index) => (
                                    <HStack
                                        key={index}
                                        spacing={6}
                                    >
                                        <Grid
                                            templateColumns={'30% 40% 30%'}
                                            w='100%'
                                            gap={2}
                                        >
                                            <Field name={`diplomasOffered[${index}].level`}>
                                                {({ field, meta }) => (
                                                    <Select {...field} placeholder='Choose' size='sm'>
                                                        <option>Bachelor</option>
                                                        <option>Master</option>
                                                        <option>Short programmes</option>
                                                        <option>Professional programmes</option>
                                                        <option>other</option>
                                                    </Select>
                                                )}
                                            </Field>
                                            <Field name={`diplomasOffered[${index}].title`}>
                                                {({ field, meta }) => (
                                                    <Input
                                                        placeholder="Diplomas's title" {...field}
                                                        mr={3}
                                                        size='sm'
                                                    />
                                                )}
                                            </Field>
                                            <Field name={`diplomasOffered[${index}].language`}>
                                                {({ field, meta }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder='Held in'
                                                        size='sm'
                                                    >
                                                        {languages.map(item =>
                                                            <option>{item.name}</option>
                                                        )}
                                                    </Select>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Button
                                            type="button"
                                            ml={2}
                                            size='sm'
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            <BiX />
                                        </Button>
                                    </HStack>
                                ))
                            ) : (
                                null
                            )}
                            <Box>
                                <AddButton
                                    onClick={() => arrayHelpers.push('')}
                                />
                            </Box>
                        </Stack>
                    </FieldBlock>
                )}
            />

            <Field name='programDeliveryModes'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Programme delivery mode"
                    >
                        <CheckboxGroup onChange={(value) => setFieldValue('programDeliveryModes', value)}>
                            <HStack spacing={4}>
                                <Checkbox value={'on-campus'}>On Campus</Checkbox>
                                <Checkbox value={'online'} >Online</Checkbox>
                                <Checkbox value={'blended'} >Blended</Checkbox>
                            </HStack>
                        </CheckboxGroup>

                        <FormHelperText>
                            You can select several possibilities
                        </FormHelperText>

                    </FieldBlock>
                )}
            </Field>



            <Field name='haveALocalAccreditation'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Does your structure has a local accreditation for your school or one of your programmes?"
                    >
                        <RadioGroup
                            onChange={(value) => {
                                setFieldValue('haveALocalAccreditation', value);
                                if (value === 'false') {
                                    setFieldValue('localAccreditation', '');

                                }

                            }}
                            value={values.haveALocalAccreditation}
                        >
                            <HStack spacing={4}>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </HStack>
                        </RadioGroup>

                    </FieldBlock>
                )}
            </Field>

            {
                values.haveALocalAccreditation === "true"
                    || values.haveALocalAccreditation === true
                    ?

                    <Field name='localAccreditation'>
                        {({ field, meta }) => (
                            <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Which local accreditation?"
                            >
                                <Input {...field} />
                            </FieldBlock>
                        )}
                    </Field>
                    : null}

        </Stack>

    </Stack>
)