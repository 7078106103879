import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Box, RadioGroup, Radio, Flex, Heading, Stack, Text, FormHelperText, Textarea, Select } from '@chakra-ui/react'
import { Field, FieldArray } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt, BiX } from 'react-icons/bi'
import { Map } from '../../../Components/Map'
import LocationField from '../../AddressField'
import Debug from '../../Debug'
import FieldBlock from '../../FieldBlock'

import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react'
import { StepTitle } from './StepTitle'
import { nationalities } from '../../../Utils/Nationalities'
import { countries } from '../../../Utils/Countries'
import { languages } from '../../../Utils/Languages'
import { AddButton } from '../../../UI/AddButton'

export const ApplicationInternationalStrategy = ({
    handleGotNext,
    id,
    setFieldValue,
    values,
    setStep,
    step,
    isStarted
}) => (
    <Stack spacing={6} id={id}>

        <StepTitle
            step={step}
            setStep={setStep}
            isStarted={isStarted}
        >
            International strategy
        </StepTitle>

        <Stack pl={24} spacing={8}>

            <FieldArray
                name="programsTaughtInEnglish"
                render={arrayHelpers => (
                    <FieldBlock
                        title='List of your programs taught in english'
                    >
                        <Stack>
                            {values.programsTaughtInEnglish && values.programsTaughtInEnglish.length > 0 ? (
                                values.programsTaughtInEnglish.map((item, index) => (
                                    <Flex key={index}>
                                        <Field name={`programsTaughtInEnglish.${index}`}>
                                            {({ field, meta }) => (
                                                <Input mr={2} {...field} placeholder='Programme name' />
                                            )}
                                        </Field>
                                        <Button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            <BiX />
                                        </Button>
                                    </Flex>
                                ))
                            ) : (
                                null
                            )}
                            <Box>
                                <AddButton
                                    onClick={() => arrayHelpers.push('')}
                                />
                            </Box>

                        </Stack>
                    </FieldBlock>
                )}
            />

            <FieldArray
                name="internationalPrograms"
                render={arrayHelpers => (
                    <FieldBlock
                        title='List of your international programmes'
                    >
                        <Stack>
                            {values.internationalPrograms && values.internationalPrograms.length > 0 ? (
                                values.internationalPrograms.map((item, index) => (
                                    <Flex key={index}>
                                        <Field name={`internationalPrograms.${index}`}>
                                            {({ field, meta }) => (
                                                <Input mr={2} {...field} placeholder='Programme name' />
                                            )}
                                        </Field>
                                        <Button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            <BiX />
                                        </Button>
                                    </Flex>
                                ))
                            ) : (
                                null
                            )}
                            <Box>
                                <AddButton
                                    onClick={() => arrayHelpers.push('')}
                                />
                            </Box>

                        </Stack>
                    </FieldBlock>
                )}
            />

            <FieldArray
                name="internationalLanguageTests"
                render={arrayHelpers => (
                    <FieldBlock
                        title='International language test or language diploma offered at your school'
                    >
                        <Stack>
                            {values.internationalLanguageTests && values.internationalLanguageTests.length > 0 ? (
                                values.internationalLanguageTests.map((item, index) => (
                                    <Flex key={index}>
                                        <Field name={`internationalLanguageTests.${index}`}>
                                            {({ field, meta }) => (
                                                <Select {...field} mr={3}>
                                                    <option></option>
                                                    {["TOEIC", "TOEFL"].map(item =>
                                                        <option key={item}>{item}</option>
                                                    )}
                                                </Select>
                                            )}
                                        </Field>
                                        <Button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            <BiX />
                                        </Button>
                                    </Flex>
                                ))
                            ) : (
                                null
                            )}
                            <Box>
                                <AddButton
                                    onClick={() => arrayHelpers.push('')}
                                />
                            </Box>

                        </Stack>
                    </FieldBlock>
                )}
            />

            <FieldArray
                name="studentsNationalites"
                render={arrayHelpers => (
                    <FieldBlock
                        title='Student nationalities'
                    >
                        <Stack>
                            {values.studentsNationalites && values.studentsNationalites.length > 0 ? (
                                values.studentsNationalites.map((item, index) => (
                                    <Flex key={index}>
                                        <Field name={`studentsNationalites.${index}`}>
                                            {({ field, meta }) => (
                                                <Select {...field} mr={3}>
                                                    <option></option>
                                                    {nationalities.map(item =>
                                                        <option key={item}>{item}</option>
                                                    )}
                                                </Select>
                                            )}
                                        </Field>
                                        <Button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            <BiX />
                                        </Button>
                                    </Flex>
                                ))
                            ) : (
                                null
                            )}
                            <Box>
                                <AddButton
                                    onClick={() => arrayHelpers.push('')}
                                />

                            </Box>

                        </Stack>
                    </FieldBlock>
                )}
            />

            <Field name='isMemberOfInternationalOrganisation'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Is your school you member of an international organisation?"
                    >
                        <RadioGroup
                            value={values.isMemberOfInternationalOrganisation}
                            onChange={(value) => {
                                setFieldValue('isMemberOfInternationalOrganisation', value);
                                if (value === 'false') {
                                    setFieldValue('memberOfOrganisationDetails', '');

                                }
                            }}
                        >
                            <Stack direction='row'>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </Stack>
                        </RadioGroup>

                    </FieldBlock>
                )}


            </Field>
            {
                (
                    values.isMemberOfInternationalOrganisation
                    && values.isMemberOfInternationalOrganisation === "true"
                )
                    ?
                    <Field name='memberOfOrganisationDetails'>
                        {({ field, meta }) => (
                            <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Can you precise which international organisation?"
                            >
                                <Input type='text' {...field} />
                            </FieldBlock>
                        )}
                    </Field>
                    : null}




            <Field name='isPartnerShipWithSchoolsAbroad'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Have you already established any academic partnerships ?"
                    >
                        <RadioGroup
                            value={values.isPartnerShipWithSchoolsAbroad}
                            onChange={(value) => {
                                setFieldValue('isPartnerShipWithSchoolsAbroad', value);
                                if (value === 'false') {
                                    setFieldValue('partnerWithSchoolDetails', '');

                                }
                            }}
                        >
                            <Stack direction='row'>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </Stack>
                        </RadioGroup>

                    </FieldBlock>
                )}


            </Field>
            {
                (
                    values.isPartnerShipWithSchoolsAbroad
                    && values.isPartnerShipWithSchoolsAbroad === "true"
                )
                    ?
                    <Field name='partnerWithSchoolDetails'>
                        {({ field, meta }) => (
                            <FieldBlock
                                isInvalid={meta.error && meta.touched}
                                error={meta.error}
                                title="Can you precise which school / institution ?"
                            >
                                <Input type='text' {...field} />
                            </FieldBlock>
                        )}
                    </Field>
                    : null}


            <Field name='internationalStrategyDescription'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Can you share a short description of your future international strategy, including mobility plans."
                    >
                        <Textarea {...field} />
                    </FieldBlock>
                )}
            </Field>

        </Stack>
    </Stack>
)