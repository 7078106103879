import * as Yup from 'yup';
const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

Yup.addMethod(Yup.number, "ectsMultiple", function (errorMessage) {
    return this.test(
        `test-valid-ects-credit`,
        'The number of ECTS credits must be a multiple of 1.5',
        function (value) {
            const { path, createError } = this;

            return (
                (value && value % 1.5 === 0 || value === 0) ||
                createError({ path, message: errorMessage })
            );
        });
});

// Yup.addMethod(Yup.string, "hundredToTwoHundredWords", function (errorMessage) {
//     return this.test(
//         `test-input-words-lenght`,
//         'You must provided a 100 to 200 words description.',
//         function (value) {
//             const { path, createError } = this;
//             if (!value) return `0 word`
//             var regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
//             var cleanString = value.replace(regex, '')
//             var wordLength = cleanString.split(' ').length
//             return (
//                 (wordLength && wordLength >= 100 && wordLength <= 200) ||
//                 createError({ path, message: errorMessage })
//             );
//         });
// });

const CourseFormSchema = Yup.object().shape({
    name:
        Yup.string()
            .required('Required'),
    localName:
        Yup.string()
            .required('Required'),
    version:
        Yup.string()
            .required('Required'),
    code:
        Yup.string()
            .required('Required'),
    type:
        Yup.string()
            .required('Required'),
    startSemester:
        Yup.number()
            .required('Required'),
    endSemester:
        Yup.number()
            .required('Required'),
    hours:
        Yup.number(),
    // .required('Required'),
    personalWorkLoad:
        Yup.number(),
    // .required('Required'),
    ectsCredits:
        Yup.number(),
    // .ectsMultiple(),
    // .required('Required'),
    description:
        Yup.string(),
    // .hundredToTwoHundredWords(),
    semesters: Yup.array()
        .of(
            Yup.object().shape({
                hours: Yup.number().required('Required'), // these constraints take precedence
                personalWorkLoad: Yup.number().required('Required'), // these constraints take precedence
                ectsCredits: Yup.number().required('Required'), // these constraints take precedence
                usCredits: Yup.number().required('Required'), // these constraints take precedence
            })
        )
        .required('One semester is required') // these constraints are shown if and only if inner constraints are satisfied
});

export { CourseFormSchema }