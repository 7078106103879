import * as React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Debug from '../Debug';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { config } from '../../Utils/Config';




const LocationField = ({ setFieldValue }) => {

    const [value, setValue] = React.useState(null);

    React.useEffect(
        () => {

            if (value && value.value.place_id) {
                // console.log(value);
                setFieldValue('address', value.value.description);

                geocodeByPlaceId(value && value.value ? value.value.place_id : null)
                    .then(results => {
                        // console.log(results);
                        setFieldValue('location',
                            {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            })

                        results[0].address_components.forEach(line => {

                            if (line.types.includes('route')) {
                                setFieldValue('street', line.long_name)
                            }

                            if (line.types.includes('locality')) {
                                setFieldValue('city', line.long_name)
                            }

                            if (line.types.includes("administrative_area_level_1")) {
                                setFieldValue('region', line.long_name)
                            }

                            if (line.types.includes("country")) {
                                setFieldValue('country', line.long_name)
                            }

                            if (line.types.includes("postal_code")) {
                                setFieldValue('zipCode', line.long_name)
                            }

                        });
                        setFieldValue('')
                    })
                    .catch(error => console.error(error));
            }


        }, [value]
    )

    return (
        <div>
            <GooglePlacesAutocomplete
                apiKey={config.googleMap.apiKey}
                apiOptions={{ language: 'en' }}
                selectProps={{
                    value,
                    onChange: setValue,
                }}
            />

            {/* <Debug data={ value } /> */}
        </div>
    )
}

export default LocationField;