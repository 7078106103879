import { Avatar, Box, Flex, FlexProps, HStack, Img, useMenuButton } from '@chakra-ui/react'
import * as React from 'react'
import { HiSelector } from 'react-icons/hi'
import { UserContext } from '../../Auth/userContext'


export const AccountSwitcherButton = (props: FlexProps) => {
  const buttonProps = useMenuButton(props)
  const { user } = React.useContext(UserContext);
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg="gray.50"
      border='solid 1px'
      boxShadow='inner'
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: 'gray.200' }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Avatar color='white' bgColor='gray.700' size='sm' name={`${user.firstName} ${user.lastName}`} />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold" color='gray.500'>
            {`${user.firstName} ${user.lastName}`}
          </Box>
          <Box fontSize="xs" color="gray.500" textTransform="uppercase">
            {user.schoolName}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  )
}
