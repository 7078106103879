import * as React from 'react'
import * as yup from 'yup';

import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HTMLChakraProps,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'



import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
//   import { PasswordField } from './PasswordField'
import { Link as RouterLink } from 'react-router-dom'
// import authService from '../Auth/auth.service'
import { UnloggedLayout } from '../Components/UnloggedLayout'

import { Form, Formik, Field } from 'formik'
import Debug from '../Forms/Debug'
import axios from 'axios';
import { config } from '../Utils/Config';
import { PublicLayout } from '../Components/PublicLayout';
import { PrimaryButton } from '../UI/PrimaryButton';
import { PublicLayoutNew } from '../Components/PublicLayout/new';

const ForgottenPassword = (props: HTMLChakraProps<'form'>) => {

  const [requestProcessed, setRequestProcessed] = React.useState(false);
  return (


    <PublicLayoutNew>
      <Box
      >
        <Formik
          initialValues={{ email: '' }}
          onSubmit={(values) => {
            axios.post(
              `${config.apiUrl}/user/password-recovery`,
              values
            ).then(result => setRequestProcessed(true));
          }}
          validationSchema={
            yup.object().shape({
              email: yup.string().email().required()
            })
          }
        >
          {({
            values,
          }) => (
            <Form>
              <Stack spacing="6">

                <Heading
                  fontFamily={`Georgia`}
                  fontSize={`4xl`}
                  color={`brand.blue.900`}
                  fontStyle={`italic`}
                >
                  Password recovery
                </Heading>
                {!requestProcessed ?
                  <>
                    <Field name='email'>
                      {({ field, meta }) => (
                        <FormControl
                          id="email"
                          isInvalid={meta.error && meta.touched}
                        >
                          <FormLabel>Email address of forgotten account</FormLabel>
                          <Input
                            {...field}
                            type="email"
                            autoComplete="email"
                            required
                          />
                          {meta.error && meta.touched ?
                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                            : null}
                          {!meta.error && meta.touched ?
                            <FormHelperText>If an account with this address exist, you'll receive an email with password recovery instructions.</FormHelperText>
                            : null}
                        </FormControl>
                      )}
                    </Field>
                    <PrimaryButton
                      type="submit"
                      colorScheme="blue"
                      size="lg"
                      fontSize="md"
                      isLoading={requestProcessed}
                    >
                      Get a new password
                    </PrimaryButton>
                  </>
                  :
                  <Alert status='success'>
                    <AlertIcon />
                    <AlertDescription>
                      Your request has been processed.
                      If an account associated with this address exists, we have sent it an email to create a new password.
                    </AlertDescription>
                  </Alert>
                }
                <Box>
                  {/* <PrimaryButton>
                  
                  </PrimaryButton> */}
                  <Button size='sm' leftIcon={<BiLeftArrowAlt />} variant='link' as={RouterLink} to='./..'>Back to login page</Button>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </PublicLayoutNew>
  )
}

export default ForgottenPassword