import { Box, Center, Flex, Grid, Stack } from "@chakra-ui/react";
import * as React from "react";
import Debug from "../../Forms/Debug";
import diplomaBgImage from "../../Images/diploma-background.jpg";
import { DiplomaLegal } from "./Legal";
import { DiplomaLogo } from "./Logo";
import bgGlobe from "../../Images/patterns/globes-white.svg";
import stampWhite from "../../Images/stamps/stamps-white.png";

export const DiplomaLayout = ({ body, bg, data }) => {
  const pageStyle = `
    @page {
        size: a4 landscape;
        margin: 0cm !important;
    }
    html, body {
        width: 297mm;
        height: 209mm;
    }
`;

  const bgParams = () =>
    bg && bg === true
      ? {
          // "bgImage": `url(${diplomaBgImage})`,
          // "bgSize": '297mm 209mm'
        }
      : {};

  return (
    <>
      <style>{pageStyle}</style>
      <Box
        className="main-page"
        w="297mm"
        h="209.5mm"
        overflow={"hidden"}
        bgImage={bg ? `url(${bgGlobe})` : ""}
        bgSize={`8%`}
        bgRepeat="repeat"
        bgColor={bg ? "brand.violet.100" : ""}
        // p={12}
        // border='solid 1px'

        display="flex"
        flexFlow={"column"}
        {...bgParams()}
      >
        <Box visibility={bg ? "initial" : "hidden"} bgColor="brand.blue.100">
          <Center
            bgColor={`#2f3b72`}
            ml={`16mm`}
            h="16mm"
            color="white"
            letterSpacing={"2.8mm"}
            fontSize="xl"
            textAlign={"center"}
            textTransform="uppercase"
            fontFamily={`Satoshi`}
            fontWeight="600"
          >
            Certificate of completion
          </Center>
        </Box>
        <Grid templateColumns={`16mm 1fr 28mm`} minH="194mm">
          <Flex
            visibility={bg ? "initial" : "hidden"}
            bgColor={`#8d99f8`}
            color="white"
            justifyItems="center"
            alignItems="center"
            transform="rotate(180deg)"
            letterSpacing={"3mm"}
            textTransform="uppercase"
            fontSize="xl"
            fontWeight={500}
            sx={{
              writingMode: "vertical-rl",
            }}
          >
            <Flex px={`10mm`} display={`none`}>
              This is a copy
            </Flex>
          </Flex>

          <Flex
            flexFlow={"column"}
            justify="space-between"
            // ml={'2.4cm'}
            px={`0mm`}
            h="full"
            pt={`12mm`}
            pb={`8mm`}
            bgImage={!bg ? `` : `url(${stampWhite})`}
            bgSize={`12cm 12cm`}
            bgRepeat={`no-repeat`}
            bgPos={`center center`}
            ml={`28mm`}
          >
            {body}
            <DiplomaLegal />
          </Flex>
          <DiplomaLogo data={data} />
        </Grid>
      </Box>
    </>
  );
};
