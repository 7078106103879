import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverCloseButton, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import * as React from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import { CoursePlansNew } from './CoursePlansNew';


export const CoursePlansAddButton = ({ handleSubmit, programId }) => {

    const [open, setOpen] = React.useState(false);

    return (
        <Popover
            isOpen={open}
            placement='right'
        >
            <PopoverTrigger>
                <Button
                    size='xs'
                    leftIcon={<BiAddToQueue />}
                    onClick={() => setOpen(!open)}
                >
                    Add a new course plan
                </Button></PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                <PopoverHeader>New course plan</PopoverHeader>
                <PopoverBody>
                    <CoursePlansNew
                        handleSubmit={handleSubmit}
                        handleClose={() => setOpen(false)}
                        programId={programId}
                    />
                </PopoverBody>
            </PopoverContent>
        </Popover >
    )
}

