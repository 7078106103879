import { Box, SimpleGrid, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
// import data from './data.json'
import { Stat } from './Stat'
import { StatLabel } from './StatLabel'
import { StatNumber } from './StatNumber'
import Debug from '../Forms/Debug'

export const StatsList = ({ data }) => (
  <Box as="section" py="3">
    {/* <Debug data={data} /> */}
    <Box mx="auto" px={{ base: '6', md: '0' }}>
      <SimpleGrid columns={{ base: 1, md: data.length < 5 ? data.length : 5 }} spacing="6">
        {data && data.length ? data.map(({ label, value }) => (

          value ?
            <Stat key={label}>
              <StatLabel>{label}</StatLabel>
              <StatNumber longValue={value.toString().length > 10}>{value}</StatNumber>
            </Stat>
            : null
        )) : null}
      </SimpleGrid>
    </Box>
  </Box>
)