import axios from 'axios'
import * as React from 'react'
import { config } from '../../Utils/Config';
import { useParams, Navigate } from 'react-router-dom';
export const ProgramRedirect = () => {
    let params = useParams();
    const { programId } = params;
    const [primaryCoursePlanId, setPrimaryCoursePlanId] = React.useState();
    React.useEffect(
        async () => {
            let request = await axios.get(`${config.apiUrl}/program/${programId}`);
            setPrimaryCoursePlanId(request.data.primaryCoursePlan);
        }, []
    )

    return (
        primaryCoursePlanId ?
            <Navigate to={`./${primaryCoursePlanId}`} />
            : null
    )
}