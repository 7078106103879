import { Box, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';
import { Comment } from './Comment';

export const CommentList = ({ schoolId, formIsVisible }) => {

    const [data, setData] = React.useState()

    React.useEffect(
        () => {
            if (!data) {
                getData();
            }
        }, [schoolId]
    )

    React.useEffect(
        () => {

            getData();
        }, [formIsVisible]
    )

    const getData = () => {
        axios.get(
            `${config.apiUrl}/comment/school/${schoolId}`
        ).then(res => setData(res.data))
    }

    return (
        <Box>

            {/* <Debug data={data} /> */}
            <Stack>
                {data && data.length ? data.map((item, index) =>
                    <Comment
                        odd={index % 2 === 0}
                        even={index % 2 !== 0}
                        data={item}
                        onCommentDelete={() => getData()}
                    />
                ) :
                    <Text fontSize={`sm`} color={`gray.400`}>No comment yet for this entry</Text>
                }
            </Stack>
        </Box>
    )
}