import { Box, Button, Divider, Flex, FormLabel, Input, Stack, SimpleGrid, Select, useToast, Heading } from "@chakra-ui/react"
import axios from "axios"
import { Formik, Form, Field } from "formik"
import * as React from "react"
import { config } from "../../Utils/Config"
import { countries } from "../../Utils/Countries"
import Debug from "../Debug"
import FieldBlock from "../FieldBlock"
import { SchoolFormSchema } from "./Schema"
import { BiArrowBack } from 'react-icons/bi'
import { useParams, useNavigate } from "react-router"
import LocationField from "../AddressField"
import { Wrapper } from "../../Components/Wrapper"
import { BackButton } from "../../UI/BackButton"


const SchoolFormBase = () => {
    const { schoolId } = useParams();
    const navigate = useNavigate();

    const toast = useToast()
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();

    React.useEffect(() => {
        if (schoolId) {
            getData();
        }

        getUsers();
    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/school/${schoolId}`)
            .then(result => setData(result.data));
    }

    const getUsers = () => {
        axios.get(`${config.apiUrl}/user/all/`)
            .then(result => setUsers(result.data));
    }

    return (
        !schoolId || data ?
            <Wrapper>
                <Formik
                    initialValues={data ? data : {}}
                    validationSchema={SchoolFormSchema}

                    // validate={values => {
                    //     const errors = {};
                    //     if (!values.email) {
                    //         errors.email = 'Required';
                    //     } else if (
                    //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    //     ) {
                    //         errors.email = 'Invalid email address';
                    //     }
                    //     return errors;
                    // }}
                    onSubmit={(values, { setSubmitting }) => {
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);

                        axios.post(
                            `${config.apiUrl}/school/${schoolId ? schoolId : ''}`,
                            values
                        ).then(() => {
                            toast({
                                title: 'School created.',
                                description: `School ${values.name} has been created and user is notified by email.`,
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            });
                            navigate('./..');
                        })

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (

                        <Stack as={Form} onSubmit={handleSubmit} spacing={{ base: 2, lg: 8 }}>
                            <Box>
                                <BackButton label='Back' />
                            </Box>
                            <Heading>
                                {values && values.name ?
                                    `${values.name}'s creation`
                                    :
                                    `Create a school`
                                }
                            </Heading>

                            <Field name='name'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                    >
                                        <FormLabel>Institution full name</FormLabel>
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            {/* 
                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='email'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Email address</FormLabel>
                                            <Input {...field} />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='websiteUrl'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>Website Url</FormLabel>
                                            <Input {...field} />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>
                            <Field name='typeOfLegalEntity'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                    >
                                        <FormLabel>Type of legal entity</FormLabel>
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name='phoneNumber'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                    >
                                        <FormLabel>Phone number</FormLabel>
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>

                            <FieldBlock
                                        // isInvalid={meta.error && meta.touched}
                                        // error={meta.error}
                                    >
                                        <FormLabel>Address</FormLabel>
                                <LocationField setFieldValue={ setFieldValue } />
                            </FieldBlock> */}

                            {users ?
                                <Field name='user'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                        >
                                            <FormLabel>User</FormLabel>
                                            <Select
                                                {...field}
                                                placeholder='Select the main user of this school'
                                            >
                                                {users.map(item =>
                                                    item.role === 'user' ?
                                                        <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                                                        : null
                                                )}
                                            </Select>
                                        </FieldBlock>
                                    )}
                                </Field>
                                : null}
                            <Divider />
                            <Flex>
                                <Button
                                    type='submit'
                                    isDisabled={
                                        // errors // 👈 null and undefined check
                                        Object.keys(errors).length !== 0
                                        // && Object.getPrototypeOf(errors) === Object.prototype
                                    }>
                                    Save
                                </Button>
                            </Flex>
                            <Divider />
                            <Debug data={[data, errors, touched, values, Object.keys(errors).length]} />

                        </Stack>

                    )}

                </Formik>
            </Wrapper>
            : null
    )
}

export default SchoolFormBase