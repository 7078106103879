import { Image } from '@chakra-ui/image'
import { Box, Center, Grid } from '@chakra-ui/layout'
import * as React from 'react'
import { UserContext } from '../../../Auth/userContext'
// import authService from '../../../Auth/auth.service'
import { Fade } from '../../../Transition/SlideIn'
import { ApplicationForm } from './Form'
import { ApplicationFormIntro } from './Intro'

export const Application = () => {


    const { authed, user } = React.useContext(UserContext);

    const [hasStarded, setHasStarted] = React.useState(false)
    const [isLoaded, setIsLoaded] = React.useState(false)

    React.useEffect(() => {
        setTimeout(
            () => {
                if (!isLoaded) { setIsLoaded(true); }
            }, 1000
        )
        // if (!isLoaded) { setIsLoaded(); }
    }, [])

    const loadForm = () => {
        setIsLoaded(false);
        setTimeout(
            () => setHasStarted(true),
            400
        )
    }

    return (
        <Box bgColor='gray.100'>
            <Box maxW='5xl' mx='auto'>

                <Center minH='100vh'>

                    {!hasStarded ?
                        <Fade in={isLoaded}>
                            <Grid
                                gridTemplateColumns='700px 1fr'
                            >
                                <Box
                                    maxW='3xl'
                                    bgColor='white'
                                    p={10}
                                    boxShadow='var(--shadow-elevation-medium)'
                                >
                                    <Center h='full'>
                                        <ApplicationFormIntro
                                            firstName={user.firstName}
                                            handleGotNext={() => loadForm()}
                                        />
                                    </Center>

                                </Box>
                                <Box>
                                    <Image
                                        src={`https://images.unsplash.com/photo-1517971129774-8a2b38fa128e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80`}
                                    />
                                </Box>
                            </Grid>

                        </Fade>
                        :
                        <ApplicationForm />
                    }
                </Center>
            </Box>
        </Box>

    )
}