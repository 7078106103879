import { Box, Center, Grid, Heading, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'
import Debug from '../../Forms/Debug'

export const SchoolExcerpt = ({ school, program }) => {
    return (
        <Box
            p={`20mm`}
        >
            <VStack
                alignItems={'flex-start'}
                spacing={8}
                fontSize='sm'
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    Identification of the School
                </Text>

                {/* <Debug data={program} /> */}

                <Grid
                    gridTemplateColumns={
                        `40mm 1fr`
                    }
                    gap={`10mm`}
                    rowGap={`4mm`}
                // styles={{ rawGap: `2mm` }}
                >
                    <Text
                        color='blue.700'
                        fontWeight={'600'}
                    >
                        School
                    </Text>
                    <Text>
                        {school.name}
                    </Text>


                    {program.accreditation && program.accreditation.number ?
                        <>
                            <Text
                                color='blue.700'
                                fontWeight={'600'}
                            >
                                Accreditation number
                            </Text>
                            <Text>
                                {program.accreditation.number}
                            </Text>
                        </>
                        : null}


                    {program.accreditation && program.accreditation.date ?
                        <>
                            <Text
                                color='blue.700'
                                fontWeight={'600'}
                            >
                                Date of accreditation
                            </Text>
                            <Text>
                                {program.accreditation.date}
                            </Text>
                        </>
                        : null}

                    <Text
                        color='blue.700'
                        fontWeight={'600'}
                    >
                        Address
                    </Text>
                    <Text>
                        {school.address}
                        <br />
                        {school.country}
                    </Text>

                    <Text
                        color='blue.700'
                        fontWeight={'600'}
                    >
                        Web site
                    </Text>
                    <Text>
                        {school.websiteUrl}
                    </Text>

                    <Text
                        color='blue.700'
                        fontWeight={'600'}
                    >
                        School general information
                    </Text>
                    <Text>
                        The Idées HOUSE school is a private institution that is based on Swiss legislation for post-compulsory education. Certified and accredited, the  school  provides  architecture  and  interior  design  instruction  for students who are interested in mastering project creation and construction. The mission of Idées HOUSE is to educate students in manual and theoretical practices related to interior design and construction. To this end, it provides academic, artistic and technical higher professional education. It provides an opening to practical teaching as a research laboratory or workshop.
                        <br />
                        •   Theoretical knowledge: the guarantee of broad, time-honoured knowledge,  essential  for  interdisciplinary  and  innovative approaches.
                        <br />
                        •   Learning by doing: numerous projects in workshops to help build practical know-how.
                    </Text>


                </Grid>
            </VStack>

        </Box >
    )
}