import { Box, Button, ButtonGroup, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Stack, Tag, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiArch, BiArrowToBottom, BiBookBookmark, BiFile, BiUser, BiExport } from 'react-icons/bi';
import { FaFilePdf } from 'react-icons/fa';
import { GoFilePdf } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { UserContext } from '../Auth/userContext';
import { MissingNotes } from '../Components/Note/MissingNotes';
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';

export const PromotionBlock = ({
    programId,
    coursePlanId,
    start,
    end,
    count,
    program,
    coursePlan
}) => {

    const { user } = React.useContext(UserContext);

    const [data, setData] = React.useState(null)

    React.useEffect(
        () => {
            if (!data) getData();
        }
    )

    const getData = () => {
        axios.get(
            `${config.apiUrl}/inscription/${programId}/${coursePlanId}/ids/${start}/${end}/`
        ).then(result => setData(result.data));

    }

    return (
        data ?
            <Box
                boxShadow='sm'
                border='solid 1px'
                borderColor='gray.100'
                borderRadius={'md'}
            >
                {/* <Debug data={data} /> */}
                <Flex
                    justify={'space-between'}

                    px={3}
                    py={2}

                    fontSize='sm'
                    alignItems='center'
                >
                    <HStack>
                        <Text>Period: </Text>
                        <Tag>
                            {`${start} → ${end}`}
                        </Tag>
                        <Text>Course plan: </Text>
                        <Tag>
                            {coursePlan.name}
                        </Tag>
                        <Text>Students: </Text>
                        <Tag>
                            {`${count} `}{` `}
                            <BiUser />
                        </Tag>
                    </HStack>

                    {/* <Debug data={data} /> */}

                    <Menu>

                        <MenuButton
                            as={Button}
                            size='sm'
                            rightIcon={<BiArrowToBottom />}
                            colorScheme={`blue`}
                        >
                            Details
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                as={Link}
                                to={`/${user.role === `admin` ? `admin` : `account`}/note/form/${programId}/${coursePlanId}/${start}/${end}`}
                            >
                                Notes
                            </MenuItem>
                            <MenuItem
                                as={Link}
                                to={`/${user.role === `admin` ? `admin` : `account`}/inscription/${programId}/${coursePlanId}/${start}/${end}`}
                            >
                                Update promotion
                            </MenuItem>
                            <MenuItem
                                as={'a'}
                                target='_blank'
                                href={
                                    `${config.apiUrl}/inscription/registration-certificate/${data.join(',')}/export/${program.name} : ${start}-${end}`
                                }
                            >
                                <Box as="span" mr={2}><BiFile /></Box>{` `}
                                Registration certificate
                            </MenuItem>
                            <MenuItem
                                as={'a'}
                                target='_blank'
                                href={
                                    `${config.apiUrl}/inscription/transcript/${data.join(',')}/export/${program.name} : ${start}-${end}`
                                }
                            >
                                <Box as="span" mr={2}><BiFile /></Box>{` `}
                                Transcripts
                            </MenuItem>
                            <MenuItem

                                as={'a'}
                                target='_blank'
                                href={
                                    `${config.apiUrl}/inscription/diploma/${data.join(',')}/export/${program.name} : ${start}-${end}`
                                }
                            >
                                <Box as="span" mr={2}><BiBookBookmark /></Box>{` `}
                                Diplomas
                            </MenuItem>
                            <MenuItem

                                as={'a'}
                                target='_blank'
                                href={
                                    `${config.apiUrl}/inscription/promotion/export/${programId}/${coursePlanId}/${start}/${end}`
                                }
                            >
                                <Box as="span" mr={2}><BiExport /></Box>
                                {`  `}
                                Export CSV
                            </MenuItem>
                        </MenuList>

                        {/* http://localhost:3002/inscription/promotion/export/62c83c645236d99eee3c55c6/62c83c645236d99eee3c55d0/2022-09/2025-06/1 */}

                    </Menu>

                    {/* <Debug data={data} /> */}

                </Flex>
                {/* <Box
                    px={3}
                    py={2}
                    bgColor='gray.50'
                >
                    <Button
                        variant={'link'}
                        as={Link}
                        to={`/admin/note/form/${programId}/${'61f2790fd611593512bc2904'}/${start}/${end}`}
                    >
                        Notes
                    </Button>
                    <MissingNotes />
            </Box>
            */}
            </Box >
            : null
    )
}

//http://localhost:3001/inscription/61eed835826e698589fa99fd/ids/2020/2022/