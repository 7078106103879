import { Button } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { Box, Grid, SimpleGrid, Stack, HStack } from '@chakra-ui/layout'
import { FormHelperText, FormLabel } from '@chakra-ui/react'
import { Field, FieldArray } from 'formik'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import { AddButton } from '../../../UI/AddButton'
import LocationField from '../../AddressField'
import Debug from '../../Debug'
import FieldBlock from '../../FieldBlock'
import { PhoneField } from '../../PhoneField'
import { UrlField } from '../../URLField'
import { StepTitle } from './StepTitle'

export const ApplicationGeneralInformations = ({
    id,
    setFieldValue,
    values,
    setStep,
    step,
    isStarted,
    setFieldTouched
}) => (
    <Stack spacing={10} id={id}>

        <StepTitle
            step={step}
            setStep={setStep}
            isStarted={isStarted}
        >
            General information
        </StepTitle>

        <Stack pl={24} spacing={6}>
            <Field name='name'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="School name"
                        isRequired={true}
                    >
                        <Input {...field} />
                    </FieldBlock>
                )}
            </Field>
            <Field name='acronym'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Acronym"
                        isRequired={true}
                    >
                        <Input {...field} w='3xs' />
                    </FieldBlock>
                )}
            </Field>
            <Field name='foundingDate'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Founding year"
                        isRequired={true}
                    >
                        <Input
                            w='4xs'
                            type='number'
                            {...field}
                            min={1900}
                            max={new Date().getFullYear()}
                        />
                    </FieldBlock>
                )}
            </Field>
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
                <Field name='phoneNumber'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Phone number"
                        >
                            <PhoneField
                                value={meta.value}
                                name={field.name}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                            />
                        </FieldBlock>
                    )}
                </Field>
                <Field name='websiteUrl'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Website URL"
                        >
                            <UrlField
                                value={meta.value}
                                name={field.name}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                            />
                            <FormHelperText>With http:// please</FormHelperText>
                        </FieldBlock>
                    )}
                </Field>
            </SimpleGrid>
            <Field name='typeOfLegalEntity'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Type of legal entity"
                    >
                        <Input {...field} />
                    </FieldBlock>
                )}
            </Field>
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
                <Field name='institutionalCapital'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Institutional capital"
                        >
                            <Input {...field} />
                            <FormHelperText>With currency sign please ($,€,£)</FormHelperText>
                        </FieldBlock>
                    )}
                </Field>
                <Field name='institutionalAnnualTurnover'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Institutional annual turnover"
                        >
                            <Input {...field} />
                            <FormHelperText>With currency sign please ($,€,£)</FormHelperText>
                        </FieldBlock>
                    )}
                </Field>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
                <Field name='numberOfTeachers'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Number of teachers"
                        >
                            <Input {...field} type='number' />
                        </FieldBlock>
                    )}
                </Field>

                <Field name='numberOfStudents'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Total number of students"
                        >
                            <Input type='number' {...field} />
                        </FieldBlock>
                    )}
                </Field>
            </SimpleGrid>


            <Field name='numberOfAdminEmployees'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Number of administrative employees"
                    >
                        <Input {...field} type='number' />
                    </FieldBlock>
                )}
            </Field>

            <Field name='isPartOfGroup'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Is your school part of a group ?"
                    >
                        <HStack>
                            <Button
                                onClick={() => setFieldValue(field.name, true)}
                                colorScheme={field.value === true ? 'blue' : undefined}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => setFieldValue(field.name, false)}
                                colorScheme={field.value === false ? 'blue' : undefined}
                            >
                                No
                            </Button>
                        </HStack>
                        {/* <Debug data={field} /> */}
                    </FieldBlock>
                )}
            </Field>

            {values.isPartOfGroup ?
                <Field name='groupName'>
                    {({ field, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                            title="Which is the name of the group ?"
                        >
                            <Input
                                {...field}
                                type='text'
                            />
                        </FieldBlock>
                    )}
                </Field>
                : null}

            <Field name='hasSeveralCampuses'>
                {({ field, meta }) => (
                    <FieldBlock
                        isInvalid={meta.error && meta.touched}
                        error={meta.error}
                        title="Does your school have several campuses?"
                    >
                        <HStack>
                            <Button
                                onClick={() => setFieldValue(field.name, true)}
                                colorScheme={field.value === true ? 'blue' : undefined}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => setFieldValue(field.name, false)}
                                colorScheme={field.value === false ? 'blue' : undefined}
                            >
                                No
                            </Button>
                        </HStack>
                        {/* <Debug data={field} /> */}
                    </FieldBlock>
                )}
            </Field>

            {
                values.hasSeveralCampuses ?
                    <FieldArray
                        name="campuses"
                        render={arrayHelpers => (
                            <FieldBlock
                                title='Can you list this campuses :'
                            >
                                <Stack>

                                    {values.campuses ? values.campuses.map((item, index) => (
                                        <HStack
                                            key={index}
                                            spacing={2}
                                        >

                                            <Field name={`campuses[${index}].name`}>
                                                {({ field, meta }) => (
                                                    <Input
                                                        {...field}
                                                        placeholder="Campus name"
                                                        // mr={3}
                                                        size='sm'
                                                    />
                                                )}
                                            </Field>

                                            <Button
                                                type="button"
                                                ml={2}
                                                size='sm'
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                                <BiX />
                                            </Button>
                                        </HStack>
                                    )
                                    ) : null}

                                    <Box>
                                        <AddButton
                                            onClick={() => arrayHelpers.push('')}
                                        />
                                    </Box>
                                </Stack>
                            </FieldBlock>
                        )}
                    />
                    : null
            }

            <FieldBlock
                title="Main campus address"
            >
                {values.address ?
                    <>
                        <Button
                            onClick={() => setFieldValue('address', null)}
                            colorScheme='teal'
                            size='sm'
                            rightIcon={<BiX />}>{values.address}</Button>
                    </>

                    :
                    <LocationField setFieldValue={setFieldValue} />
                }
            </FieldBlock>
        </Stack>

    </Stack>
)