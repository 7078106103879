import { Box, Portal } from '@chakra-ui/react'
import axios from 'axios';
import * as React from 'react'
import { useParams } from 'react-router-dom';
import Debug from '../../Forms/Debug';
import { config } from '../../Utils/Config';
import { CoursesList } from './CoursesList';
import { CoverPage } from './CoverPage';
import { ExportButton } from './ExportButton';
import { ForeWord } from './ForeWord';
import { PageWrapper } from './PageWrapper';
import { ProgramCredits } from './ProgramCredits';
import { ProgramExcerpt } from './ProgramExcerpt';
import { SchoolExcerpt } from './SchoolExcerpt';
import { Summary } from './Summary';
import { TitlePage } from './TitlePage';

export const CoursesCatalogue = ({ view }) => {

    const { programId, coursePlanId } = useParams();

    const [program, setProgram] = React.useState()
    const [coursePlan, setCoursePlan] = React.useState()

    const pageStyle = `
        @page {
            size: a4 portrait;
            margin: 0cm !important;
        }
        html, body {
            height: 297mm;
            width: 210mm;
        }
    `;

    const pages = [
        {
            pagination: false,
            component: 'coverpage'
        },
        {
            pagination: true,
            component: 'titlepage'
        },
        {
            pagination: true,
            component: 'summary'
        },
        {
            pagination: true,
            component: 'foreword'
        },
        {
            pagination: true,
            component: 'schoolExcerpt'
        },
        {
            pagination: true,
            component: 'programExcerpt'
        },
        {
            pagination: true,
            component: 'programCredits'
        },
        {
            pagination: false,
            component: 'coursesList'
        },
        // {
        //     pagination: true,
        //     component: <ProgramExcerpt />
        // }
    ]

    React.useEffect(
        () => {
            getData();
        },
        [programId, coursePlanId]
    )

    const getData = () => {
        axios.get(
            `${config.apiUrl}/program/${programId}`
        ).then(res => setProgram(res.data));

        axios.get(
            `${config.apiUrl}/course-plan/${coursePlanId}`
        ).then(res => setCoursePlan(res.data));
    }

    return (
        <React.Fragment>
            <style>
                {pageStyle}
            </style>

            {/* <Debug
                data={coursePlan}
            /> */}
            {program && coursePlan ?
                <ExportButton
                    exportUrl={`${config.apiUrl}/program/course-catalogue/${programId}/${coursePlanId}/export/Course Catalogue ${program.localName}`}
                    backLinkUrl={`/admin/program/view/${programId}/${coursePlanId}`}
                />
                : null}
            {program && coursePlan ?


                pages.map((page, index) =>
                    <PageWrapper
                        number={index + 1}
                        pagination={page.pagination}
                    >
                        {
                            page.component === 'coverpage' ?
                                <CoverPage />
                                : page.component === 'titlepage' ?
                                    <TitlePage
                                        program={program}
                                        coursePlan={coursePlan}
                                    />
                                    : page.component === 'summary' ?
                                        <Summary
                                            program={program}
                                            coursePlan={coursePlan}
                                        />
                                        : page.component === 'foreword' ?
                                            <ForeWord
                                                program={program}
                                                coursePlan={coursePlan}
                                            />
                                            : page.component === 'schoolExcerpt' ?
                                                <SchoolExcerpt
                                                    school={program.school}
                                                    program={program}
                                                />
                                                : page.component === 'programExcerpt' ?
                                                    <ProgramExcerpt
                                                        program={program}
                                                        coursePlan={coursePlan}
                                                    />
                                                    : page.component === 'programCredits' ?
                                                        <ProgramCredits
                                                            program={program}
                                                            coursePlan={coursePlan}
                                                        />
                                                        : page.component === 'coursesList' ?
                                                            <CoursesList
                                                                courses={program.courses}
                                                                coursePlan={coursePlan}

                                                            />
                                                            : null
                        }
                    </PageWrapper>
                )


                : null
            }
        </React.Fragment >
    )
}