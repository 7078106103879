import { Box, Center, Flex, Heading, List, ListItem, Stack, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

export const ForeWord = () => {
    return (
        <Box
            px={'10mm'}
            py={'20mm'}

        >
            <VStack
                alignItems={'flex-start'}
                spacing={4}

                fontSize='xs'
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    Foreword
                </Text>

                <Heading
                    fontSize={'lg'}
                >
                    Contents
                </Heading>

                <Text
                    fontSize={'xs'}
                >
                    This  Course  Catalogue  contains  all  the  information  relevant  to  the  EABHES  accredited  study  programme(s):
                    <List
                        listStyleType='disc'
                        pl={4}
                    >
                        <ListItem>a brief introduction of the school and the study programme(s);</ListItem>
                        <ListItem>detailed information about the different components of the study plan (courses, internships, allocated credits);</ListItem>
                        <ListItem>explanation of the ECTS credit system and grading scale;</ListItem>
                        <ListItem>extracurricular credits information and forms;</ListItem>
                        <ListItem>degree completion requirements, such as foreign language studies. </ListItem>
                    </List>
                </Text>

                <Heading
                    fontSize={'lg'}
                >
                    EABHES Accreditation
                </Heading>

                <Text>
                    EABHES accreditation offers a range of services to facilitate the attainment of the principles of The Bologna Joint Declaration June 1999 and subsequent relevant intergovernmental acts (collectively identified as the “Bologna Process”), with the goal of facilitating the mutual recognition of titles granted by Higher Education Institutions within the European Higher Education Area (EHEA), including the assessment of programs and EABHES’ Accreditation, Certificates, and Network of Member Schools. (For more information see annex 1).
                </Text>

                <Text>
                    By pursuing an EABHES accredited programme, students are guaranteed the highest academic quality in compliance with European Standards established by the Bologna Process. students will be part of a school with a student-centered system, whose professional team has been strictly selected and trained to provide the best academic experience, from pedagogical content and methodology, to school and student life services and opportunities.
                </Text>
                <Text>
                    During their studies, students will have access to student mobility opportunities within the EABHES network and other partner schools, which enables you to have an international experience within the study programme. Students will be required to successfully pass international language tests, which will maximise their internationalisation potential of their career.
                </Text>

                <Box>
                    <Text>
                        By acquiring a diploma of accredited studies by EABHES students will:
                    </Text>

                    <List
                        listStyleType='disc'
                        pl={4}
                    >
                        <ListItem>Obtain official academic transcripts and diploma in compliance with European standards, which increases the international recognition of their degree.</ListItem>
                        <ListItem>Increase the possibilities of continuing studies in other schools in Europe and throughout the world thanks to ECTS credits, which are recognised by most educational schools worldwide.</ListItem>
                        <ListItem>Be part of EABHES Alumni network, which will provide support when applying for recognition of diplomas in another educational school for continuing their studies.</ListItem>
                        <ListItem>Improve employability thanks to the international recognition of diploma and credits. </ListItem>
                    </List>
                </Box>
                <Heading
                    fontSize={'lg'}
                >
                    Documents for students
                </Heading>

                <Text>
                    EABHES provides to the School the following documents and certificates for each student enrolled in an accredited programme:
                    <br />
                    a)   At the end of the academic period, a transcript summarizing the credits and grades of each student;
                    <br />
                    b)   At the successful completion of an accredited programme, a Diploma of completion of Accredited Studies.
                </Text>

                <Text>
                    To learn more about EABHES Accreditation, visit the website https://www.eabhes.eu/ and read the FAQ section for students at https://www.eabhes.eu/students/.
                </Text>

            </VStack >

        </Box >
    )
}