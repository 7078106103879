import axios from 'axios';
import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { config } from '../Utils/Config';


const fetchData = (inputValue: string) => {
    return axios.get(`${config.apiUrl}/school/1/${inputValue}/-`).then(result => {
        const formattedEntries = result.data.map(item => ({ label: item.name, value: item._id }));
        return formattedEntries
    });
}

export const SchoolSuggestField = ({ onChange }) => {
    const [inputValue, setInputValue] = React.useState();

    // handle selection
    const handleChange = value => {
        onChange(value.value);
    }


    return (
        <div>
            {/* <pre>inputValue: "{this.state.inputValue}"</pre> */}
            <AsyncSelect
                cacheOptions
                loadOptions={() => fetchData(inputValue)}
                defaultOptions
                onInputChange={(value) => setInputValue(value)}
                onChange={handleChange}
            />
        </div>
    )
}