import { Box, Flex, Image, Text } from "@chakra-ui/react";
import * as React from "react";
import Debug from "../../Forms/Debug";
import Logo from "../../Images/logo/horizontal-blue-extended.svg";
import { config } from "../../Utils/Config";

export const RegistrationCertificateHeader = ({ data, promoNotes, bg }) => {
  const {
    school: { _id: schoolId, logoFile, displaySchoolLogoOnDocuments },
  } = data;

  const LogoUrl = !displaySchoolLogoOnDocuments
    ? Logo
    : `${config.apiUrl}/document/${schoolId}/${logoFile}`;
  return (
    <Box mb={`8mm`}>
      <Text
        textAlign={`center`}
        textTransform={`uppercase`}
        fontFamily={`Satoshi`}
        fontWeight={700}
        fontSize={`xl`}
        color={`brand.blue.900`}
        letterSpacing={`4mm`}
        bgColor={`brand.blue.100`}
        py={`6mm`}
      >
        Certificate
      </Text>
      <Box p={`8mm`}>
        <Flex>
          <Image src={LogoUrl} alt="EABHES" w="75mm" />
        </Flex>
      </Box>
    </Box>
  );
};

// "logoFile":string"logo.png"
// "displaySchoolLogoOnDocuments":booltrue
