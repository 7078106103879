import * as React from 'react'

import MDEditor, { commands } from '@uiw/react-md-editor';


export const MarkdownEditor = ({
    value,
    onChange
}) => {
    // const [value, setValue] = React.useState("**Hello world!!!**");

    return (
        <MDEditor
            commands={[
                commands.bold, commands.italic, commands.checkedListCommand, commands.orderedListCommand
            ]}
            // extraCommands={[]}
            preview={"edit"}

            value={value}
            // onChange={(value) => console.log('change', value)}
            onChange={(value: string) => onChange(value)}
        />
    )
}
