import * as React from "react";
import {
  Link,
  Routes as Router,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { NotFound } from "../404";
import { Welcome } from "../Welcome";
import { LoginForm } from "../Components/LoginFormWithProviders/App";
import SchoolList from "../School/List";
import { SchoolView } from "../School/View";
import SchoolFormBase from "../Forms/School/New";
import UserList from "../User/List";
import { UserUpdate } from "../Forms/User/Update";
import { ForgottenPassword } from "../Login";
import { Admin } from "../Admin";
import { AdminLayout } from "../Components/AdminLayout/Layout";
import Logs from "../Logs";
import { Center } from "@chakra-ui/layout";
import { SignupForm } from "../Forms/User/Signup";
import ProgramList from "../Program/List";
import { Application } from "../Forms/School/Appplication";
import { ProgramFormBase } from "../Forms/Program/New";
import { ProgramFormDetails } from "../Forms/Program/Details";
import CourseList from "../Course/List";
import { CourseFormBase } from "../Forms/Course/New";
import UserLayout from "../Components/UserLayout/Layout";
import { UserView } from "../User/View";
import { ProgramView } from "../Program/View";
import { Dashboard } from "../Dashboard";
import { ActivationForm } from "../Forms/User/Activation";
import UserFormNew from "../Forms/User/New";
import { CourseFormWrapper } from "../Forms/Course";
// import authService from "../Auth/auth.service";
import { SelfUpdate } from "../Forms/User/SelfUpdate";
import { NewPassword } from "../Login/NewPassword";
import { NewPasswordWrapper } from "../Login/NewPasswordWrapper";
import { SchoolUpdateForm } from "../Forms/School/Update";
import { ApplicationThankYou } from "../Forms/School/Appplication/ThankYou";
import { UserContext } from "../Auth/userContext";
import Debug from "../Forms/Debug";
import { UserProvider } from "../Auth/userContext";
import { StudentImport } from "../Student/Import";
import InscriptionList from "../Inscription/List";
import { StudentsList } from "../Student/List";
import { NoteImport } from "../Components/Note/import";
import { TranscriptDocument } from "../Components/Transcript/Document";
import { SchoolImport } from "../Forms/School/Import";
import { ProgramImport } from "../Forms/Program/Import";
import { CoursePlanCoursesListUpdate } from "../Program/CoursesListUpdate";
import { InscriptionUpdate } from "../Inscription/Update";
import { TranscriptExporter } from "../Components/Transcript/MassExport";
import { BrowseAndParse } from "../BrowseAndParse";
import { DiplomaExporter } from "../Components/Diploma";
import { DemoFieldGooglePlace } from "../FieldGooglePlace";
import { PromoNoteForm } from "../Components/Note/PromoForm";
import { RegistrationCertificateExporter } from "../Components/RegistrationCertificate/MassExport";
import { StudentView } from "../Student/View";
import { StudentUpdate } from "../Student/Update";
import { WelcomeScreen } from "../Welcome/Screen";
import { CoursesCatalogue } from "../Program/CoursesCatalogue";
import { DocumentPage } from "../Document/Page";
import { CourseImport } from "../Forms/Course/Import";
import { SchoolInvoicesList } from "../School/InvoiceList";
import { ProgramMerge } from "../Program/Merge/index";
import InscriptionPromotionList from "../Inscription/ListPromotion";
import { PublicLayoutNew } from "../Components/PublicLayout/new";
import { CourseImportInProgram } from "../Forms/Course/ImportInProgram";
import { ProgramRedirect } from "../Forms/Program/redirect";
import { NewInscriptionStudent } from "../Inscription/NewForStudent";
import { InscriptionCreationForm } from "../Inscription/New";

function RequireAuth({ children }) {
  const { authed } = React.useContext(UserContext);
  const location = useLocation();
  return authed === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function RequireAdminAuth({ children }) {
  const { authed, user } = React.useContext(UserContext);

  return authed === true && user && user.role === "admin" ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}

const Routes = () => (
  <>
    <Router>
      <Route path="/layout" element={<PublicLayoutNew />} />

      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/login/password-recovery" element={<ForgottenPassword />} />
      <Route
        path="/login/password-recovery/:attemptId"
        element={<NewPasswordWrapper />}
      />

      {/* <Route path='diploma/:inscriptionId' element={<Diploma view='admin' />} /> */}
      {/* <Route path='diploma/:inscriptionId/:showBg' element={<Diploma view='admin' />} /> */}

      <Route path="activation" element={<Outlet />}>
        <Route path=":userId" element={<ActivationForm />} />
      </Route>

      <Route
        path="inscription/:inscriptionId/registration-certificate/export/"
        element={<Outlet />}
      >
        <Route
          index
          element={<RegistrationCertificateExporter view="admin" />}
        />
        <Route
          path=":showBg"
          element={<RegistrationCertificateExporter view="admin" />}
        />
      </Route>

      <Route
        path="inscription/:inscriptionId/transcript/export/"
        element={<Outlet />}
      >
        <Route index element={<TranscriptExporter view="admin" />} />
        <Route path=":showBg" element={<TranscriptExporter view="admin" />} />
      </Route>

      <Route
        path="inscription/:inscriptionId/diploma/export"
        element={<Outlet />}
      >
        <Route index element={<DiplomaExporter bg={false} />} />
        <Route path=":showBg" element={<DiplomaExporter view="admin" />} />
      </Route>

      <Route path="program" element={<Outlet />}>
        <Route
          path="course-catalogue/:programId/:coursePlanId"
          element={<CoursesCatalogue view="admin" />}
        />
      </Route>

      {/* <Route path="inscription/transcript/:inscriptionId/" element={<Outlet />}>
        <Route index element={<TranscriptDocument bg={false} />} />
        <Route path=':bg' element={<TranscriptDocument bg={true} />} />
      </Route> */}

      <Route
        path="/account"
        element={
          <UserProvider>
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          </UserProvider>
        }
      >
        {/* <Route index element={<UserLayout><>coucou</></UserLayout>} /> */}

        <Route path="user" element={<Outlet />}>
          <Route
            path=":userId"
            element={
              <UserLayout>
                <SelfUpdate />
              </UserLayout>
            }
          />
        </Route>

        <Route path="school" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <SchoolView view="user" />
              </UserLayout>
            }
          />
          <Route path="application/:schoolId" element={<Application />} />
          <Route
            path="application/:schoolId/thankyou"
            element={<ApplicationThankYou isVisible={true} />}
          />
          <Route
            path="invoice/:schoolId"
            element={
              <UserLayout>
                <SchoolInvoicesList view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="program" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <ProgramList view="user" />
              </UserLayout>
            }
          />
          <Route
            path=":page"
            element={
              <UserLayout>
                <ProgramList view="user" />
              </UserLayout>
            }
          />
          <Route
            path="view/:programId"
            element={
              <UserLayout>
                <ProgramView view="user" />
              </UserLayout>
            }
          />
          <Route
            path="view/:programId/:coursePlanId"
            element={
              <UserLayout>
                <ProgramView view="user" />
              </UserLayout>
            }
          />
          <Route
            path="edit/:programId"
            element={
              <UserLayout>
                <ProgramFormBase view="user" />
              </UserLayout>
            }
          />
          <Route
            path="new"
            element={
              <UserLayout>
                <ProgramFormBase view="user" />
              </UserLayout>
            }
          />
          <Route
            path="new/school/:schoolId"
            element={
              <UserLayout>
                <ProgramFormBase view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="course" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <CourseList view="user" />
              </UserLayout>
            }
          />
          <Route
            path=":page"
            element={
              <UserLayout>
                <CourseList view="user" />
              </UserLayout>
            }
          />
          <Route
            path="new"
            element={
              <UserLayout>
                <CourseFormWrapper view="user" />
              </UserLayout>
            }
          />
          <Route
            path="edit/:courseId"
            element={
              <UserLayout>
                <CourseFormWrapper view="user" />
              </UserLayout>
            }
          />
          <Route
            path="new/:programId"
            element={
              <UserLayout>
                <CourseFormWrapper view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="student" element={<Outlet />}>
          {/* <Route index element={<UserLayout><div>Student management not available yet</div></UserLayout>} /> */}

          <Route
            index
            element={
              <UserLayout>
                <StudentsList view="user" />
              </UserLayout>
            }
          />
          <Route
            path=":page"
            element={
              <UserLayout>
                <StudentsList view="user" />
              </UserLayout>
            }
          />

          <Route
            path="import"
            element={
              <UserLayout>
                <StudentImport view="user" />
              </UserLayout>
            }
          />

          <Route
            path="import/:preProgramId"
            element={
              <UserLayout>
                <StudentImport view="user" />
              </UserLayout>
            }
          />

          <Route
            path="view/:studentId"
            element={
              <UserLayout>
                <StudentView view="user" />
              </UserLayout>
            }
          />
          <Route
            path="update/:studentId"
            element={
              <UserLayout>
                <StudentUpdate view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="inscription" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <InscriptionList view="user" />
              </UserLayout>
            }
          />
          <Route
            path=":page"
            element={
              <UserLayout>
                <InscriptionList view="user" />
              </UserLayout>
            }
          />
          <Route
            path="student/:studentId"
            element={
              <UserLayout>
                <InscriptionList view="user" />
              </UserLayout>
            }
          />
          <Route
            path="student/:studentId/new"
            element={
              <UserLayout>
                <NewInscriptionStudent view="user" />
              </UserLayout>
            }
          />
          <Route
            path=":programId/:coursePlanId/:startDate/:endDate/"
            element={
              <UserLayout>
                <InscriptionPromotionList />
              </UserLayout>
            }
          />
          <Route
            path="update/:inscriptionId"
            element={
              <UserLayout>
                <InscriptionUpdate view="user" />
              </UserLayout>
            }
          />
          <Route
            path="new/:studentsIds"
            element={
              <UserLayout>
                <InscriptionCreationForm view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="documents" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <DocumentPage view="user" />
              </UserLayout>
            }
          />
        </Route>

        <Route path="note" element={<Outlet />}>
          <Route
            index
            element={
              <UserLayout>
                <Link to={"./import"}>Import note</Link>
              </UserLayout>
            }
          />
          <Route
            path="import"
            element={
              <UserLayout>
                <NoteImport view="user" />
              </UserLayout>
            }
          />
          <Route
            path="form/:programId/:coursePlanId/:start/:end"
            element={
              <UserLayout>
                <PromoNoteForm view="user" />
              </UserLayout>
            }
          />
        </Route>
      </Route>

      {/* ADMIN ROUTES */}

      <Route
        path="/admin"
        element={
          <UserProvider>
            <RequireAdminAuth>
              {/* <AdminLayout /> */}
              <Outlet />
            </RequireAdminAuth>
          </UserProvider>
        }
      >
        <Route
          index
          element={
            <AdminLayout>
              <Dashboard />
            </AdminLayout>
          }
        />

        <Route path="school" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <SchoolList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <SchoolList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="view/:schoolId"
            element={
              <AdminLayout>
                <SchoolView view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="new"
            element={
              <AdminLayout>
                <SchoolFormBase />
              </AdminLayout>
            }
          />
          <Route
            path="base/:schoolId"
            element={
              <AdminLayout>
                <SchoolFormBase />
              </AdminLayout>
            }
          />
          <Route
            path="edit/:schoolId"
            element={
              <AdminLayout>
                <SchoolUpdateForm />
              </AdminLayout>
            }
          />
          <Route
            path="import"
            element={
              <AdminLayout>
                <SchoolImport />
              </AdminLayout>
            }
          />
          <Route
            path="invoice/:schoolId"
            element={
              <AdminLayout>
                <SchoolInvoicesList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":schoolId/students/"
            element={
              <AdminLayout>
                <StudentsList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":schoolId/students/:page"
            element={
              <AdminLayout>
                <StudentsList view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="program" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <ProgramList />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <ProgramList />
              </AdminLayout>
            }
          />
          <Route
            path="new"
            element={
              <AdminLayout>
                <ProgramFormBase />
              </AdminLayout>
            }
          />
          <Route
            path="merge"
            element={
              <AdminLayout>
                <ProgramMerge />
              </AdminLayout>
            }
          />
          <Route
            path="new/school/:schoolId/"
            element={
              <AdminLayout>
                <ProgramFormBase view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="edit/:programId"
            element={
              <AdminLayout>
                <ProgramFormBase view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="edit/details/:programId"
            element={
              <AdminLayout>
                <ProgramFormDetails view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="view/:programId"
            element={
              <AdminLayout>
                <ProgramRedirect view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="view/:programId/:coursePlanId"
            element={
              <AdminLayout>
                <ProgramView view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="course-catalogue/:programId/:coursePlanId"
            element={<CoursesCatalogue view="admin" />}
          />
          <Route
            path="course-plan/:coursePlanId/update"
            element={
              <AdminLayout>
                <CoursePlanCoursesListUpdate view="admin" />
              </AdminLayout>
            }
          />

          <Route
            path="import/"
            element={
              <AdminLayout>
                <ProgramImport view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="course" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <CourseList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <CourseList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="new"
            element={
              <AdminLayout>
                <CourseFormWrapper view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="edit/:courseId"
            element={
              <AdminLayout>
                <CourseFormWrapper view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="new/:programId"
            element={
              <AdminLayout>
                <CourseFormWrapper view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="import"
            element={
              <AdminLayout>
                <CourseImport />
              </AdminLayout>
            }
          />
          <Route
            path="import/program/:programId"
            element={
              <AdminLayout>
                <CourseImportInProgram />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="user" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <UserList />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <UserList />
              </AdminLayout>
            }
          />
          <Route
            path="new"
            element={
              <AdminLayout>
                <UserFormNew />
              </AdminLayout>
            }
          />
          <Route
            path="edit/:userId"
            element={
              <AdminLayout>
                <UserUpdate />
              </AdminLayout>
            }
          />
          <Route
            path="view/:userId"
            element={
              <AdminLayout>
                <UserView />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="student" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <StudentsList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <StudentsList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="import"
            element={
              <AdminLayout>
                <StudentImport view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="import/:preProgramId"
            element={
              <AdminLayout>
                <StudentImport view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="view/:studentId"
            element={
              <AdminLayout>
                <StudentView view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="update/:studentId"
            element={
              <AdminLayout>
                <StudentUpdate view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="inscription" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <InscriptionList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":page"
            element={
              <AdminLayout>
                <InscriptionList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="student/:studentId"
            element={
              <AdminLayout>
                <InscriptionList view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="student/:studentId/new"
            element={
              <AdminLayout>
                <NewInscriptionStudent view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="transcript/:inscriptionId"
            element={<AdminLayout>Inscription transcript</AdminLayout>}
          />
          <Route
            path="update/:inscriptionId"
            element={
              <AdminLayout>
                <InscriptionUpdate view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="new/students/:studentsIds/school/:schoolId"
            element={
              <AdminLayout>
                <InscriptionCreationForm view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path=":programId/:coursePlanId/:startDate/:endDate/"
            element={
              <AdminLayout>
                <InscriptionPromotionList view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        {/* <Route path="diploma" element={<Outlet />}>
          <Route index element={<DiplomaDocument view='admin' />} />
        </Route> */}

        <Route path="note" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <Link to={"./import"}>Import note</Link>
              </AdminLayout>
            }
          />
          <Route
            path="import"
            element={
              <AdminLayout>
                <NoteImport view="admin" />
              </AdminLayout>
            }
          />
          <Route
            path="form/:programId/:coursePlanId/:start/:end"
            element={
              <AdminLayout>
                <PromoNoteForm view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        <Route path="documents" element={<Outlet />}>
          <Route
            index
            element={
              <AdminLayout>
                <DocumentPage view="admin" />
              </AdminLayout>
            }
          />
        </Route>

        <Route
          path="logs"
          element={
            <AdminLayout>
              <Logs />
            </AdminLayout>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Router>
    {/* <PublicRoutes /> */}
  </>
);

export default Routes;
