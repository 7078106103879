import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  VStack,
  Text,
  Divider,
  Tag,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import * as React from "react";
import { BiCheck, BiEditAlt, BiX } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { UserContext, UserProvider } from "../Auth/userContext";
import { ProfilPict } from "../Components/ProfilPict/View";
import { Wrapper } from "../Components/Wrapper";
import { DocumentsList } from "../Document/List";
import Debug from "../Forms/Debug";
import { BackButton } from "../UI/BackButton";
import { UploadButton } from "../Upload/Button";
import { config } from "../Utils/Config";

export const StudentView = ({ view }) => {
  const { studentId } = useParams();

  const { user } = React.useContext(UserContext);

  const [data, setData] = React.useState();

  React.useEffect(() => getData(), [studentId]);

  const getData = () => {
    axios
      .get(`${config.apiUrl}/student/${studentId}`)
      .then((res) => setData(res.data));
  };

  return data ? (
    <Box>
      <Wrapper>
        <Stack spacing={10}>
          <Flex justifyContent={"space-between"}>
            <BackButton label="Back" />
            <HStack>
              <Button
                size="sm"
                as={Link}
                to={`../update/${studentId}`}
                leftIcon={<BiEditAlt />}
              >
                Update
              </Button>
            </HStack>
          </Flex>
          <HStack>
            <ProfilPict filePath={data.profilPict.document} />
            <Heading mb={0}>
              {data.firstName} {data.lastName.toUpperCase()}
            </Heading>
          </HStack>

          <VStack align={"flex-start"}>
            <Box>
              Birth : {dayjs(data.birth.date).format("YYYY/MM/DD")} (
              {data.birth.city}/{data.birth.country})
            </Box>
            <Box>Student ID : {data.code}</Box>
            <Box>Status : {data.status}</Box>
            <Box>
              <Flex>
                High school diploma :{" "}
                <Tag
                  colorScheme={
                    data.highschoolDiploma.status ? `green` : `orange`
                  }
                  mx={1}
                >
                  {" "}
                  {data.highschoolDiploma.status ? <BiCheck /> : <BiX />}{" "}
                </Tag>{" "}
                {data.highschoolDiploma.date ? (
                  <>
                    ({dayjs(data.highschoolDiploma.date).format("YYYY/MM/DD")})
                  </>
                ) : null}
                {data.highschoolDiploma.speciality ? (
                  <>
                    {" "}
                    <em>{data.highschoolDiploma.speciality}</em>
                  </>
                ) : null}
              </Flex>
            </Box>

            <Box display={data.comment ? `block` : `none`}>
              Comment : <br />
              {data.comment}
            </Box>
          </VStack>
          <VStack align={"flex-start"} spacing={4}>
            <Divider />
            <Heading fontSize="md">Inscriptions :</Heading>

            {/* <Debug data={data.inscriptions} /> */}

            <Stack w="full" spacing={3}>
              {data.inscriptions && data.inscriptions.length ? (
                data.inscriptions.map((item, index) =>
                  user.role === `admin` ||
                  user.school === item.program.school ? (
                    <React.Fragment key={item._id}>
                      <Flex justifyContent={"space-between"} fontSize={`xs`}>
                        <Text w={"50%"}>
                          {item.program.name} / {item.program.major}
                        </Text>
                        <Text
                          color={`gray.500`}
                          fontWeight={600}
                          textAlign={"right"}
                        >
                          {item.startDate}/{item.endDate}
                        </Text>
                        <Text>
                          <Button
                            size="sm"
                            variant="link"
                            colorScheme={"blue"}
                            as={Link}
                            to={
                              view === "admin"
                                ? `/admin/inscription/update/${item._id}`
                                : `/account/inscription/update/${item._id}`
                            }
                          >
                            Détails
                          </Button>
                        </Text>
                      </Flex>
                      {/* {index !== data.inscriptions.length - 1 ?
                                                        <Divider />
                                                        : null} */}
                    </React.Fragment>
                  ) : null
                )
              ) : (
                <Text color="gray.500" fontSize={"sm"}>
                  No inscription for this student for the moment
                </Text>
              )}
              <Flex justify={"flex-start"} mt={2}>
                <Button
                  as={Link}
                  to={
                    view === "admin"
                      ? `/admin/inscription/student/${studentId}/new`
                      : `/account/inscription/student/${studentId}/new`
                  }
                  size="xs"
                >
                  Create an inscription
                </Button>
              </Flex>
            </Stack>

            <Divider />

            <Heading fontSize="md">Documents :</Heading>
            <Box w="full">
              <DocumentsList
                data={data.documents}
                deleteCallback={() => getData()}
                deleteAllowed={view === "admin"}
              />
            </Box>
          </VStack>

          <UploadButton
            uploadCallback={() => getData()}
            linkedDocumentId={studentId}
          />

          {/* <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                console.log('handle submit');

                            }
                            }
                        >
                            <input type='file' name='file' onChange={uploadFile} />
                            <button type="submit">submit</button>
                        </form> */}
        </Stack>
      </Wrapper>
    </Box>
  ) : null;
};
