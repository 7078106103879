import { arrayIntRange } from "./arrayIntRange";

export const courseCode = (
    courseName: string,
    courseType: string,
    startSemester: number,
    endSemester: number
) => {

    if (!courseName || !courseType) { return '' }

    var str = courseName;
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']

    if (!matches) return

    matches = matches.filter(char =>

        char === char.toUpperCase()

    )
    console.log(matches)
    var acronym = matches ? matches.slice(0, 3).join('') : null; // JSON

    acronym = acronym && acronym.length > 1 ? acronym : courseName.slice(0, 2).toUpperCase();

    let firstLetter = courseType.slice(0, 1);
    let code = `${firstLetter}${acronym}`;
    let semesters = startSemester && endSemester ? arrayIntRange(startSemester, endSemester) : []
    // if (startSemester) { code = `${code}-${startSemester}` }
    code = `${code}-${semesters.join('-')}`
    // if (endSemester && endSemester !== startSemester) { code = `${code}-${endSemester}` } else { console.log('nope') }
    return code.toUpperCase()

}