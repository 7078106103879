import * as React from 'react'
import { Box, Divider, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import { ectsToUsCredits } from '../../Utils/ectsToUsCredits'
import { NotesTableHeader } from './NotesTableHeader'
import Debug from '../../Forms/Debug'
import { convertEctsGradeToEuGpa, coursePromoNotes, ectsGrade, failingRank, lowClassSizeRank } from './rankUtils'
import { creditsFromYear } from '../../Utils/creditsFromYear'
import { isPassingSubject } from '../../Utils/isPassingSubject'
// import { creditsFromYear } from '../../Utils/courseEctsFromYear'
var lodash = require('lodash');

export const NotesTable = ({
    data,
    year,
    promoNotes,
    notes,
    finalCredits
}) => {

    // console.log('deploy test #1');

    // console.log('finalCredits', finalCredits)
    // console.log('year', year)

    const promoAvg = (courseId) => {
        let coursePromoNotes = promoNotes.filter(item => item.course === courseId).map(item => item.note);
        let coursePromoNotesAverage = Math.round(100 * lodash.mean(coursePromoNotes)) / 100;
        return (coursePromoNotesAverage)
    }


    const Line = ({ note, year }) => {

        // console.log(`debug`, note, promoNotes, data.program)
        // if (note.course.code !== `FMIT-2`) return null

        let lineEctsGrade = ectsGrade(note, promoNotes, data.program)

        return (
            note.note !== undefined
                && note.note !== null
                && note.note !== ``
                ?
                <>
                    <Box>
                        {note.course.code}
                    </Box>
                    <Box>
                        {note.course.name}
                    </Box>
                    <Box textAlign={'center'}>
                        {/* { note.note } */}
                        {
                            // !['F', 'Fx'].includes(lineEctsGrade) ?

                            isPassingSubject({ note: note.note, course: note.course }, promoNotes, year, data.program) ?
                                creditsFromYear(note.course, year, 'ectsCredits')
                                : '-'

                            // : '-'
                        }
                    </Box>
                    <Box textAlign={'center'}>
                        {
                            // !['F', 'Fx'].includes(lineEctsGrade) ?
                            isPassingSubject({ note: note.note, course: note.course }, promoNotes, year, data.program) ?
                                lodash.round(creditsFromYear(note.course, year, 'usCredits'))
                                : '-'

                            // : '-'
                        }
                    </Box>
                    <Box
                        textAlign={'center'}
                        textTransform='uppercase'
                        whiteSpace={`pre`}
                        fontSize={
                            note.course.passing.system === 'passOrFail'
                                || note.note <= 0 ?
                                `8px`
                                : undefined
                        }
                    >
                        {note.course.passing.system === 'passOrFail' ?
                            note.note === 1 ? 'pass' : 'fail'
                            : note.note === 0 ? `pending`
                                : note.note === -1 ? `exempted`
                                    : lodash.round(note.note, 2)
                        }
                        {/* // lodash.round(note.note, 2)} */}
                        {/* Promo AVG : {promoAvg(note.course._id)} */}
                    </Box>
                    <Box textAlign={'center'}>
                        {lineEctsGrade}
                    </Box>
                </>
                : null
        )
    }

    const ExtraCreditLine = ({ data, year, prefix }) => (
        <>
            <Box>
                {prefix}
            </Box>
            <Box>
                {data.description} {data.details ? ` / ${data.details}` : null}
            </Box>
            <Box textAlign={'center'} textTransform={`uppercase`}>
                {
                    data.ectsCredits
                }
            </Box>
            <Box textAlign={'center'}>
                {data.usCredits ?
                    data.usCredits :
                    ectsToUsCredits(data.ectsCredits)
                }
            </Box>
            <Box
                textAlign={'center'}
                textTransform={`uppercase`}
                fontSize={data.note === `recognized` ? `8px` : `9px`}
            >
                {data.note ? data.note : `-`}
            </Box>
            <Box textAlign={'center'}>
                {data.grade ? data.grade.toUpperCase() : `-`}
            </Box>
        </>
    )

    // console.log('finalCredits', finalCredits)
    // console.log('finalCreditsyear', year)
    const catchUpGrades = () => finalCredits.filter(item => item.year === year).map(item => convertEctsGradeToEuGpa(item.grade.toUpperCase()))

    // console.log(
    //     'finalCredits',
    //     finalCredits.filter(item => item.year === year).map(item => item.grade.toUpperCase())
    // )

    // console.log('catchUpGrades', catchUpGrades())

    const ectsCreditGrades =
        [
            ...notes
                .filter(item => item.course.passing.system !== 'passOrFail')
                .map(item =>
                    convertEctsGradeToEuGpa(
                        ectsGrade(item, promoNotes, data.program)
                    )
                )
            ,
            ...catchUpGrades()
        ]

    // console.log('ectsCreditGrades', ectsCreditGrades)

    const catchUpGradesLength = () => finalCredits.map(item => item.grade).length


    return (
        <Box
            fontFamily={`Satoshi`}
            borderTop={`solid 1px`}
            borderTopColor={`brand.blue.500`}
            px={`4mm`}
        >

            {/* <Debug data={promoNotes} /> */}
            {/* <Debug data={notes} /> */}
            {/* <Debug data={ectsCreditGrades() / notes.length} /> */}
            {/* <Debug data={data.program.transcript} /> */}


            <Heading
                fontSize={'10px'}
                fontWeight={'bold'}
                py={2}
                color={`brand.blue.900`}
            >
                {data.program.transcript.showYears ?
                    <>
                        Academic Year:{` `}
                        {`${parseInt(data.startDate.slice(0, 4)) + year - 1}/${parseInt(data.startDate.slice(0, 4)) + year} `}
                        {` - semester`}
                    </>

                    : `Semesters`}
                {/* - */}

                {` ${(year - 1) * 2 + 1} & ${(year - 1) * 2 + 2}`}

            </Heading>

            <Grid
                gridTemplateColumns={
                    `15% 40% 10% 10% 12.5% 12.5%`
                }
                py={'1mm'}
                fontSize={'9px'}
                fontWeight={500}
            >
                {notes.map(item =>
                    <Line note={item} year={year} key={item._id} />
                )}
            </Grid>

            <Divider />
            <Box
            // py={'1mm'}
            >


                {/* <Debug data={data.initialCredits} /> */}

                <Grid
                    gridTemplateColumns={
                        `15% 40% 10% 10% 12.5% 12.5%`
                    }

                    // py={'1mm'}
                    fontSize={'9px'}
                >
                    {data.initialCredits.filter(item => item.year === year && item.isCourseRecognition).map((item, index) =>
                        <ExtraCreditLine data={{ ...item, note: `recognized` }} year={year} key={`additionnalCredits-${index}`} prefix={`${item.code}`} />
                    )}
                </Grid>

                <Grid
                    gridTemplateColumns={
                        `15% 40% 10% 10% 12.5% 12.5%`
                    }

                    // py={'1mm'}
                    fontSize={'9px'}
                >
                    {data.finalCredits.filter(item => item.year === year).map((item, index) =>
                        <ExtraCreditLine prefix={item.code} data={item} year={year} key={`finalCredits-${index}`} />
                    )}
                </Grid>

                <Grid
                    gridTemplateColumns={
                        `15% 40% 10% 10% 12.5% 12.5%`
                    }

                    // py={'1mm'}
                    fontSize={'9px'}
                >
                    {data.additionnalCredits.filter(item => item.year === year).map((item, index) =>
                        <ExtraCreditLine data={item} year={year} key={`additionnalCredits-${index}`} prefix={`ADDC-${year}-${index + 1}`} />
                    )}
                </Grid>
            </Box>

            {/* <Debug data={data.initialCredits} /> */}

            <Grid
                gridTemplateColumns={
                    `55% 10% 10% 12.5% 12.5%`
                }

                py={'.45mm'}
                fontSize={'9px'}
                borderRadius='sm'
                fontWeight={600}
            >
                <Text>
                    Year credits
                </Text>

                <Text
                    textAlign={'center'}
                >
                    {
                        // Courses credits
                        lodash.sum(
                            data.notes
                                .filter(item => item.year === year) // only notes corresponding to current year
                                .map(item => item.values)[0] // mapping notes on by one
                                .filter(item => isPassingSubject({ note: item.note, course: item.course }, promoNotes, year, data.program)) // only notes over the passing limite
                                .map(item => creditsFromYear(item.course, year, 'ectsCredits')) // select only the ectsCredits value
                        )
                        +
                        // Initial credits course recognition
                        lodash.sum(data.initialCredits.filter(item => item.year === year && item.isCourseRecognition).map(item => item.ectsCredits))
                        +
                        // ADDC credits
                        lodash.sum(data.additionnalCredits.filter(item => item.year === year).map(item => item.ectsCredits))
                        +
                        // Final credits
                        lodash.sum(data.finalCredits.filter(item => item.year === year).map(item => item.ectsCredits))
                    }
                    <br />
                </Text>
                <Text
                    textAlign={'center'}
                >
                    {
                        // Courses credits
                        lodash.round(
                            lodash.sum(
                                data.notes
                                    .filter(item => item.year === year) // only notes corresponding to current year
                                    .map(item => item.values)[0] // mapping notes on by one
                                    .filter(item => isPassingSubject({ note: item.note, course: item.course }, promoNotes, year, data.program)) // only notes over the passing limite
                                    .map(item => creditsFromYear(item.course, year, 'usCredits')) // select only the ectsCredits value
                            )
                            +
                            // Initial credits course recognition
                            lodash.sum(data.initialCredits.filter(item => item.year === year && item.isCourseRecognition).map(item => item.usCredits))
                            +
                            // ADDC credits
                            lodash.sum(data.additionnalCredits.filter(item => item.year === year).map(item => item.usCredits))
                            +
                            // ADDC credits
                            lodash.sum(data.finalCredits.filter(item => item.year === year).map(item => item.usCredits))
                        )
                    }
                </Text>
                <Box />
                <Text
                    fontSize={'9px'}
                    fontStyle='normal'
                    whiteSpace={'pre'}
                    textAlign={`center`}
                    display={
                        ectsCreditGrades.filter(val => val !== 0).length === 0 ? `none` : `block`
                    }
                >
                    EU GPA :{' '}
                    {
                        lodash.round(lodash.mean(ectsCreditGrades), 2)
                    }

                </Text>
            </Grid>
            {/* <Debug data={ectsCreditGrades} /> */}

        </Box >
    )
}