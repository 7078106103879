import { Box, Button, Flex, Grid, Input, Stack } from '@chakra-ui/react'
import { Field, FieldArray, Form, Formik } from 'formik'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import Debug from '../../Forms/Debug'
import FieldBlock from '../../Forms/FieldBlock'
import { AddButton } from '../../UI/AddButton'
import { ProgramsSuggestField } from '../SuggestField'

export const SubProgramSelect = ({ onSave }) => {
    return (
        <Formik
            initialValues={{ subPrograms: [{ id: '', coursePlanName: '', minor: '' }] }}
            onSubmit={(values) => onSave(values.subPrograms)}
        >
            {({ values, errors, setFieldValue }) => (
                <Form>

                    <Stack spacing={4}>

                        <FieldArray
                            name="subPrograms"
                            render={arrayHelpers => (

                                <>
                                    <FieldBlock
                                        title='List of programs to merge'
                                    >
                                        <Stack spacing={2}>

                                            {values && values.subPrograms ? values.subPrograms.map((item, index) =>

                                                <Grid
                                                    gridTemplateColumns={`46% 22% 22% 1fr`}
                                                    gap={2}
                                                    key={`subPrograms-${index}`}
                                                >
                                                    <Field name={`subPrograms.${index}.id`}>
                                                        {({ field, meta }) => (
                                                            <ProgramsSuggestField
                                                                onChange={
                                                                    (value) => setFieldValue(field.name, value)
                                                                }
                                                            />
                                                        )}

                                                    </Field>
                                                    <Field name={`subPrograms.${index}.coursePlanName`}>
                                                        {({ field, meta }) => (

                                                            <Input {...field} placeholder='Course plan name' />
                                                        )}

                                                    </Field>

                                                    <Field name={`subPrograms.${index}.minor`}>
                                                        {({ field, meta }) => (

                                                            <Input {...field} placeholder='Minor' />
                                                        )}

                                                    </Field>

                                                    <Button
                                                        // ml={3}
                                                        type="button"
                                                        size='sm'
                                                        h={`auto`}
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        <BiX size={'30px'} />
                                                    </Button>

                                                </Grid>
                                            ) : null}
                                            <Box>
                                                <AddButton
                                                    onClick={() => arrayHelpers.push({ id: '', coursePlanName: '', minor: '' })}
                                                />
                                            </Box>
                                        </Stack>

                                    </FieldBlock>

                                </>

                            )}
                        />
                        <Box>
                            <Button type='submit'>Save sub programs list</Button>
                        </Box>
                    </Stack>
                </Form>
            )}

        </Formik >
    )
}