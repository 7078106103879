import { Box, Center, Grid, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import * as React from 'react'
import Debug from '../../Forms/Debug'
import { arrayIntRange } from '../../Utils/arrayIntRange';

const lodash = require('lodash')

export const ProgramCredits = ({ program, coursePlan }) => {

    const yearNumber = program.semestersNumber / 2;

    const courseYear = (year) => {
        return coursePlan.courses.filter(
            item => isCourseInYear(item.course, year)
        )
    }

    const isCourseInYear = (course, year) => {

        let courseSemesters = arrayIntRange(course.startSemester, course.endSemester)
        let yearSemester = [year * 2 - 1, year * 2]

        if (lodash.intersection(courseSemesters, yearSemester).length) {
            return true
        } else {
            return false
        }

    }

    const TableHead = () => (
        <Thead>
            <Tr>
                <Th>Code</Th>
                <Th>Course Unit</Th>
                <Th>CR</Th>
            </Tr>
        </Thead>
    )

    const TableFooter = ({ courses }) => (
        <Tfoot>
            <Tr>
                <Th>Total</Th>
                <Th></Th>
                <Th>{lodash.sum(courses.map(line => line.course.ectsCredits))}</Th>
            </Tr>
        </Tfoot>
    )

    const YearTitle = ({ year }) => (
        <Heading
            fontSize={'sm'}
        >
            {year}
            <sup>
                {year === 1 ? 'st' : year === 2 ? 'nd' : 'st'}
            </sup>
            {' '}year
        </Heading>
    )


    const YearCoursesTable = () => {

        let list = []
        for (let index = 1; index <= yearNumber; index++) {
            list.push(
                <React.Fragment
                    key={`yearCourseTable-${index}`}
                >
                    <YearTitle year={index} />
                    <TableContainer>
                        <Table
                            variant='simple'
                            size='sm'
                        >
                            <TableHead />

                            {courseYear(1).map(line =>
                                <Tr
                                    key={line.course._id}
                                >
                                    <Td>
                                        {line.course.code}
                                    </Td>
                                    <Td>
                                        {line.course.name}
                                    </Td>
                                    <Td>
                                        {line.course.ectsCredits}
                                    </Td>
                                </Tr>
                            )}

                            <TableFooter courses={courseYear(1)} />


                        </Table>
                    </TableContainer>
                </React.Fragment>
            )
        }

        return (
            <Stack w='full' spacing={5}>{list}</Stack>
        )
    }

    return (
        <Box
            p={`20mm`}
        >
            <VStack
                alignItems={'flex-start'}
                spacing={8}
                fontSize='sm'
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    Credits list per year
                </Text>

                <YearCoursesTable />

            </VStack>

        </Box >
    )
}