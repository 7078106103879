import { Box, Center, Divider, Flex, Grid, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs';
import * as React from 'react'
import { DiplomaContext } from './Document';
import Debug from '../../Forms/Debug'
import { convertEctsGradeToEuGpa, ectsGrade, passingRank } from '../Transcript/rankUtils'

var lodash = require('lodash');

export const DiplomaBody = ({ data }) => {

    const value = React.useContext(DiplomaContext);

    const totalEctsCredits = () => {

    }
    let allYearNotes = lodash.flatten(data.notes.map(item => item.values));

    const ectsCreditSum = () => {
        let allYearsPassingNotes = allYearNotes.filter(item => passingRank(item.note, data.program));
        let allCourseEctsSum = lodash.sum(allYearsPassingNotes.map(item => item.course.ectsCredits));
        return allCourseEctsSum;
    }

    const usCreditsSum = () => Math.ceil(.66 * ectsCreditSum())

    const Italic = ({ children, ...rest }) => (
        <Text
            as='span'
            fontFamily={'Georgia'}
            fontStyle='italic'
            {...rest}
        >
            {children}
        </Text>
    )

    const Strong = ({ children, ...rest }) => (
        <Text
            as='span'
            fontFamily={'Satoshi'}
            fontWeight={600}
            {...rest}
        >
            {children}
        </Text>
    )

    return (
        <Stack
            spacing={'8mm'}
            textAlign='center'
            color='brand.blue.900'
        >

            {/* <Debug data={data} /> */}

            <Heading
                fontFamily={`Satoshi`}
                textTransform='uppercase'
                fontSize={'md'}
                letterSpacing='tight'
                textAlign={`center`}
                color='brand.blue.900'
            >
                {`${data.school.name} - ${data.school.city} (${data.school.country})`}
            </Heading >

            <Stack
                spacing={`0mm`}
            >
                <Italic
                    fontSize={'11px'}
                >
                    Hereby certifies that:
                </Italic>
                <Heading
                    fontSize={
                        `${data.student.firstName}${data.student.lastName}`.length <= 18 ?
                            '5xl'
                            : '4xl'
                    }
                    fontFamily={'Satoshi'}
                    lineHeight={`14mm`}
                >
                    <Text
                        as='span'
                        textTransform={`uppercase`}
                    >
                        {`${data.student.lastName.toUpperCase()}`}
                    </Text>{' '}
                    {`${data.student.firstName}`}
                </Heading>
                <Box>
                    <Flex
                        // gap={`20mm`}
                        // columns={3}
                        maxW={`200mm`}
                        mx='auto'
                        fontSize={'11px'}
                        justifyContent={`center`}
                    >
                        <Box>
                            <Italic>
                                Born on:
                            </Italic>
                            {' '}
                            <Strong>
                                {dayjs(data.student.birth.date).format('YYYY/MM/DD')}
                            </Strong>
                        </Box>
                        <Center height='20px' px={`4mm`}>
                            <Divider orientation='vertical' borderLeftWidth={`.25mm`} borderColor={'brand.blue.900'} />
                        </Center>

                        <Box>
                            <Italic>
                                In:
                            </Italic>
                            {' '}
                            <Strong>
                                {data.student.birth.city}

                                {data.student.birth.country ?
                                    ` (${data.student.birth.country})`
                                    : null}
                            </Strong>
                        </Box>
                        <Center height='20px' px={`4mm`}>
                            <Divider orientation='vertical' borderLeftWidth={`.25mm`} borderColor={'brand.blue.900'} />
                        </Center>
                        <Box>
                            <Italic>
                                Student n°
                            </Italic>
                            {' '}
                            <Strong>
                                {data.student.code}
                            </Strong>
                        </Box>

                    </Flex>
                </Box>
            </Stack>


            <Stack
                spacing={`2mm`}
            >
                <Italic
                    fontSize={'11px'}
                >
                    Has completed the
                </Italic>
                <Heading
                    fontSize={'xl'}
                    fontFamily={'Satoshi'}
                    fontWeight='600'
                >
                    {
                        data.program.category
                    }
                </Heading>
                {/* <Debug data={data.coursePlan} /> */}
                <Flex
                    justify={`center`}
                    fontSize={`11px`}
                    py={`2mm`}
                >

                    <Box
                        textAlign={data.program.minor || data.coursePlan.minor ? 'left' : 'center'}
                    >
                        <Italic display={`block`}>Major</Italic>
                        <Strong>{data.coursePlan.major ? data.coursePlan.major : data.program.major}</Strong>
                    </Box>

                    {data.program.minor || data.coursePlan.minor ?
                        <>
                            <Center height='30px' px={`4mm`}>
                                <Divider orientation='vertical' borderLeftWidth={`.25mm`} borderColor={'brand.blue.900'} />
                            </Center>
                            <Box
                                textAlign={'left'}

                            >
                                <Italic display={`block`}>Minor</Italic>
                                <Strong>
                                    {
                                        data.program.minor ? data.program.minor
                                            : data.coursePlan.minor ? data.coursePlan.minor
                                                : ``
                                    }
                                </Strong>
                            </Box>
                        </>
                        : null}

                </Flex>
                <Italic
                    fontSize={'11px'}
                >
                    Accredited by EABHES N° {data.program.accreditation.number}
                    {/* {data.program.accreditation.number} */}
                </Italic>
            </Stack>
            {/* <Debug data={data.program} /> */}
            {/* <Center> */}
            <Box
                fontSize={'11px'}
            >
                <SimpleGrid
                    border='solid .25mm'
                    borderColor='brand.blue.900'
                    columns={2}
                    maxW='140mm'
                    mx='auto'
                    textAlign={'left'}
                    p={`3mm`}
                // rowGap={`4mm`}
                // columnGap={`10mm`}
                >
                    <Box
                        minW='60mm'
                        pr={`5mm`}
                    >
                        <Italic>
                            As a
                        </Italic>
                        {' '}
                        <Strong>
                            {data.program.localName}
                        </Strong>
                    </Box>
                    <Box
                        minW='60mm'
                        borderLeft={`solid .25mm`}
                        borderLeftColor={`brand.blue.900`}
                        pl={`5mm`}
                    >
                        <Italic>
                            And has obtained a score of{' '}
                        </Italic>
                        <br />
                        <Strong>
                            {data.ectsCreditsObtained} ECTS{' - '}
                            {lodash.round(data.ectsCreditsObtained * 2 / 3)} US Credits
                        </Strong>
                    </Box>
                    {/* <Box
                        minW='60mm'
                        pt={`4mm`}
                        pr={`5mm`}
                        visibility={data.coursePlan.minor || data.program.minor ? `visible` : `hidden`}
                    >
                        <Italic>
                            Specializing in
                        </Italic>
                        <br />
                        <Strong>
                            {
                                data.coursePlan.minor ? data.coursePlan.minor
                                    : data.program.minor ? data.program.minor
                                        : ``
                            }
                        </Strong>
                    </Box> */}
                    {/* <Box
                        minW='60mm'
                        borderLeft={`solid .25mm`}
                        borderLeftColor={`brand.blue.900`}
                        pl={`5mm`}
                        pt={`4mm`}
                    >

                    </Box> */}
                </SimpleGrid>
            </Box>
            {/* </Center> */}

        </Stack >
    )
}