import { Box, Button, ButtonGroup, Flex, Grid, HStack, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiX } from 'react-icons/bi';

export const CommentDeleteButton = ({ handleDelete }) => {

    const [proceedDelete, setProceedDelete] = React.useState(false)

    return (
        !proceedDelete ?
            <Button
                variant={'outline'}
                // bgColor='transparent'
                leftIcon={<BiX />}
                size="xs"

                onClick={() => setProceedDelete(true)}

            >
                Deletion
            </Button>
            :
            <ButtonGroup
                size='xs'
                alignItems={`flex-start`}
            >
                <Button
                    onClick={() => handleDelete()}
                    colorScheme={`red`}
                >
                    Yes
                </Button>
                <Button
                    onClick={() => setProceedDelete(false)}
                    colorScheme={null}
                >
                    No
                </Button>
            </ButtonGroup>
    )
}