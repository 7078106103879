import { Box, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'
import { Link, useResolvedPath, useMatch } from 'react-router-dom'

interface NavItemProps {
  href?: string
  label: string
  subtle?: boolean
  active?: boolean
  icon: React.ReactElement
  endElement?: React.ReactElement
  children?: React.ReactNode,
  target?: string,
  to?: string
}

export const NavItem = (props: NavItemProps) => {
  const { subtle, icon, children, label, endElement, href, target, to } = props

  let resolved = useResolvedPath(to ? to : '/');
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <HStack
      as={to ? Link : 'a'}
      to={to}
      href={href}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={match ? 'gray.700' : undefined}
      target={target}
      _hover={{ bg: 'gray.700' }}
      _active={{ bg: 'gray.600' }}
    >
      <Box fontSize="lg" color={match ? 'currentcolor' : 'gray.400'}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit" color={subtle ? 'gray.400' : undefined}>
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  )
}
