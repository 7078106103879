import * as Yup from 'yup'

export const inscriptionSchema = Yup.object().shape({
    initialCredits: Yup.array().of(
        Yup.object({
            ectsCredits: Yup.number().required("Required!"),
            usCredits: Yup.number().required("Required!"),
            description: Yup.string().required("Required!")
        })
    ),
    additionnalCredits: Yup.array().of(
        Yup.object({
            ectsCredits: Yup.number().required("Required!"),
            usCredits: Yup.number().required("Required!"),
            description: Yup.string().required("Required!"),
            year: Yup.number().required("Required!"),
            details: Yup.string()
        })
    ),
    finalCredits: Yup.array().of(
        Yup.object({
            code: Yup.string().required("Required!"),
            ectsCredits: Yup.number().required("Required!"),
            usCredits: Yup.number().required("Required!"),
            note: Yup.number().required("Required!"),
            grade: Yup.string().required("Required!"),
            year: Yup.number().required("Required!"),
            description: Yup.string().required("Required!")
        })
    )
});