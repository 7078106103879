import { Box, Flex } from '@chakra-ui/react'
import * as React from 'react'

export const PageWrapper = ({ children, number, pagination }) => (
    <Flex
        flexDirection={'column'}
        border='solid 1px'
        borderColor='gray.200'
        h='297mm'
    >
        <Box
            flex='1 1 0'
        >
            {children}
        </Box>
        <Box
            p={'4mm'}
            color='gray.500'
            textAlign={'center'}
            fontSize='sm'
            letterSpacing={'wider'}
            display={pagination ? 'block' : 'none'}
        >
            Page {number}
        </Box>
    </Flex>
)