import * as React from 'react'

import {
    Button,
    ButtonGroup,
    HStack,
    Input,
    Select,
    Stack,
} from '@chakra-ui/react'

import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Field, Form, Formik, FieldProps } from 'formik'
import Debug from '../Forms/Debug'

export const TableActions = ({
    getValues,
    programFilters,
    program,
    startYear,
    endYear,
    view
}) => {
    return (
        <Formik
            initialValues={{
                program: program,
                startYear: startYear,
                endYear: endYear
            }}
            onSubmit={(values) => console.log(values)}
            validate={(values) => getValues(values)}
        >
            {({ form, values, erros }) => (

                <Stack as={Form} spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <HStack>
                        {/* <Field name='search'>
                            {({ field }) => (
                                <FormControl minW={{ md: '200px' }} id="search">
                                    <InputGroup size="sm">
                                        <FormLabel srOnly>Filter by name</FormLabel>
                                        <InputLeftElement pointerEvents="none" color="gray.400">
                                            <BsSearch />
                                        </InputLeftElement>
                                        <Input {...field} rounded="base" type="search" placeholder="Filter by name..." bgColor='white' />
                                    </InputGroup>
                                </FormControl>
                            )}
                        </Field> */}
                        <Field name='program'>
                            {({ field }) => (
                                <Select
                                    {...field}
                                    w={{ base: '300px', md: '200px' }}
                                    rounded="base"
                                    size="sm"
                                    placeholder="All programs"
                                    bgColor='white'
                                >
                                    {programFilters.map(item =>
                                        <option
                                            key={item._id}
                                            value={item._id}
                                        >
                                            {item.name}
                                        </option>

                                    )}
                                </Select>)}
                        </Field>

                        {/* {program && program.coursePlans} */}
                        {/* <Field name='startYear'>
                            {({ field }) => (
                                <Input {...field} w='100px' rounded="base" size='sm' bgColor='white' placeholder='2021' />
                            )}
                        </Field>
                        <Field name='endYear'>
                            {({ field }) => (
                                <Input {...field} w='100px' rounded="base" size='sm' bgColor='white' placeholder='2022' />
                            )}
                        </Field> */}

                    </HStack>
                    <ButtonGroup size="sm" variant="outline">
                        <Button
                            iconSpacing="1"
                            leftIcon={<RiAddFill fontSize="1.25em" />}
                            as={Link}
                            to={`/${view === 'user' ? 'account' : 'admin'}/student/import`}
                            bgColor='white'
                        >
                            Import
                        </Button>
                        {/* <Button iconSpacing="1" leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}>
                    Export CSV
                </Button> */}
                    </ButtonGroup>
                    {/* <Debug data={[values]} /> */}
                </Stack>
            )}
        </Formik>
    )
}
