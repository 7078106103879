import { Box, Grid, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'

import { useParams } from 'react-router-dom'
import Debug from '../../Forms/Debug'
import { Italic } from '../../UI/Texts'
import { NotesTable } from './NotesTable'
import { NotesTableHeader } from './NotesTableHeader'
import { TranscriptPagination } from './Pagination'
var lodash = require('lodash');

export const TranscriptBody = ({
    data,
    promoNotes,
    pageNumber,
    lastPage
}) => {

    // console.log('TranscriptBody promonotes', promoNotes)

    if (!data.notes || data.notes.length === 0) return (
        <Box
            minH={`150mm`}
        // border='solid 2px'
        >
            <Italic>
                No notes have yet been recorded for this entry.
            </Italic>

        </Box>
    )

    let yearNumber = Math.ceil(data.program.semestersNumber / 2);

    const extractYearNotes = (data, yearNumber) => {

        return (
            data.filter(item => item.year === yearNumber)[0] ?
                data.filter(item => item.year === yearNumber)[0].values
                : null
        )
    }

    const sortNotesAccordingCourseCode = (notes) => (
        notes.sort(function (a: any, b: any) {
            if (a.course.code > b.course.code) {
                return 1;
            }
            if (a.course.code < b.course.code) {
                return -1;
            }
            return 0;
        })
    )

    const YearNotesTables = () => {

        // return <><Debug data={page} /></>
        let noteTablesArray = []
        for (let index = 0; index < yearNumber; index++) {
            let yearNumber = index + 1;

            // noteTablesArray.push(<div>page : {page}</div>)
            // console.log(`Page ${pageNumber}, year ${yearNumber}`)

            if (pageNumber && pageNumber !== yearNumber) continue;
            console.log('noteTablesArray', noteTablesArray)
            noteTablesArray.push(
                extractYearNotes(data.notes, yearNumber)
                    && extractYearNotes(data.notes, yearNumber).length
                    ?
                    <NotesTable
                        key={`year-note-table-${index}`}
                        year={yearNumber}
                        data={data}
                        finalCredits={data.finalCredits}
                        notes={sortNotesAccordingCourseCode(extractYearNotes(data.notes, yearNumber))}
                        promoNotes={lodash.flatten(
                            lodash.flatten(promoNotes)
                                .filter(item => item.year === yearNumber)
                                .map(item => item.values)
                        )}
                    />
                    : null
            )
        }
        return <>{noteTablesArray}</>
    }

    return (
        <Box
        // minH={`180mm`}
        // border='solid 2px'
        >
            {/* <Grid
            gridTemplateColumns={'repeat(2, 1fr)'}
            gridTemplateRows={'repeat(2, 1fr)'}
            gridAutoFlow={'column'}
            gap={4}
        > */}
            <NotesTableHeader />
            <Stack
                spacing={`4mm`}
            >
                {/* <div>{page}</div> */}
                <YearNotesTables />
                {data.program.highestNote ?
                    <Text textAlign={`right`} fontSize={`7px`}>
                        <sup>*</sup> {data.program.highestNote}-point marking system.
                    </Text>
                    : null}
                {pageNumber ? <TranscriptPagination pageNumber={pageNumber} lastPage={lastPage} /> : null}

            </Stack>
            {/* </Grid> */}
        </Box>
    )
}