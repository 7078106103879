import { Badge } from '@chakra-ui/react'
import * as React from 'react'

interface codeTagProps {
    children: object,
    type: string
}

export const CodeTag: React.FC<codeTagProps> = ({ type, children }) => (
    <>
        {/* type={type} */}
        <Badge
            bgColor={type === 'Fundamental' ? 'orange.100' : type === 'Practical' ? 'blue.100' : 'green.100'}
            color={type === 'Fundamental' ? 'orange.500' : type === 'Practical' ? 'blue.500' : 'green.500'}
        >
            {children}
        </Badge>
    </>
)