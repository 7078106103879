import axios from 'axios';
import * as React from 'react'
import { useParams } from 'react-router-dom'
import Debug from '../../Forms/Debug';
import { Loader } from '../../UI/Loader';
import { config } from '../../Utils/Config';
import { ToolsButtons } from '../Diploma/ToolsButtons';
import { RegistrationCertificateLayout, TranscriptLayout } from './Layout';

export const RegistrationCertificateDocument = (props) => {

    let params = useParams();
    let { inscriptionId, single } = props ? props : params;

    const [bg, setBg] = React.useState(props.bg);

    const [data, setData] = React.useState();
    const [promoNotes, setPromoNotes] = React.useState();

    React.useEffect(
        () => {
            if (!data) {
                axios.get(
                    `${config.apiUrl}/inscription/${inscriptionId}`
                ).then(res => setData(res.data));
            }
        },
        []
    )

    React.useEffect(
        () => {
            if (data) {
                axios.get(
                    `${config.apiUrl}/inscription/${data.program._id}/notes/${data.startDate}/${data.endDate}`
                ).then(res => setPromoNotes(res.data));
            }
        },
        [data]
    )

    console.log(`student`, data)

    const pdfTitle =
        data && data.student ?
            `Registration Certificate : ${data.student.firstName} ${data.student.lastName} ¬ ${data.program.localName} : ${data.startDate}-${data.endDate}`
            : 'Document generation in progress'

    return (
        data && promoNotes ?
            <>
                {single ?
                    <ToolsButtons
                        handleChangeBg={() => setBg(!bg)}
                        diplomaTitle='Title'
                        bg={bg}
                        exportUrl={`${config.apiUrl}/inscription/registration-certificate/${inscriptionId}/${bg}/${pdfTitle}`}
                    />

                    : null}
                <RegistrationCertificateLayout
                    data={data}
                    bg={bg}
                />
            </>
            :
            <>
                <Loader label='Creating registration certificate' />
            </>
    )
}