import * as React from 'react'
import { Box, Grid, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { Italic } from '../../UI/Texts'

export const NotesTableHeader = () => {
    const HeaderTitle = ({ children }) => (
        <Heading
            fontSize={'10px'}
            fontWeight={'500'}
        >
            {children}
        </Heading>
    )
    return (

        <Grid
            gridTemplateColumns={
                `15% 40% 22.5% 22.5%`
            }
            // borderTop='solid 1px'
            // borderBottom='solid 1px'
            // borderColor='gray.500'
            py={'2mm'}
            px={`4mm`}
            fontFamily={`Satoshi`}
            color={`brand.blue.900`}
            textTransform={`uppercase`}
        >
            <Box>
                <HeaderTitle>
                    Course

                </HeaderTitle>
            </Box>
            <Box>
                <HeaderTitle>
                    Course Title
                </HeaderTitle>

            </Box>
            <Box textAlign={'center'}>
                <HeaderTitle>
                    Credits
                </HeaderTitle>
                <SimpleGrid pt={1} columns={2} textAlign={'center'}>
                    <Italic fontSize={'06px'}>ECTS</Italic>
                    <Italic fontSize={'06px'}>US</Italic>
                </SimpleGrid>

            </Box>
            <Box textAlign={'center'}>
                <HeaderTitle>
                    Grades
                </HeaderTitle>
                <SimpleGrid pt={1} columns={2} textAlign={'center'}>
                    <Italic fontSize={'06px'}>Local*</Italic>
                    <Italic fontSize={'06px'}>ECTS</Italic>
                </SimpleGrid>

            </Box>
        </Grid>
    )
}