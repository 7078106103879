import { Box, Button, Center, Flex, Heading, SimpleGrid, Input, Text, Link, Alert, AlertIcon } from '@chakra-ui/react'
import axios from 'axios';
import * as React from 'react'
import { BiCloudUpload, BiLinkAlt } from 'react-icons/bi';
import { config } from '../Utils/Config';
import { CsvHeaderAlert } from './CsvHeaderAlert';
import { duplicateCheck } from './duplicateCheck';
let Papa = require('papaparse')


export const LoadCsvButton = ({ handleData, convertEncoding }) => {

    const [csvHeaderAlertVisible, setCsvHeaderAlertVisible] = React.useState(false);
    const [csvHasHeader, setCsvHasHeader] = React.useState(null);

    const fileInputField = React.useRef(null);


    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // console.log(files[0]);
        readFile(files[0]);
    }

    const proceedUpload = files => {
        console.log(files)
    }

    const readFile = (file) => {

        if (!file) return
        // else console.log(file)
        const reader = new FileReader()
        reader.onload = async (e) => {

            const textContent = e.target.result
            let json = Papa.parse(textContent,
                { header: true }
            );

            handleData(await duplicateCheck(json.data));

        }
        reader.onerror = (e) => {
            const error = e.target.error
            console.error(`Error occured while reading ${file.name}`, error)
        }
        reader.readAsText(file)

    }

    const uploadFile = file => {

        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("file", file);
        formData.append("convertEncoding", convertEncoding);
        axios.post(
            `${config.apiUrl}/student/upload`
            , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(async res => {

            let json = Papa.parse(res.data,
                { header: true }
            );

            handleData(await duplicateCheck(json.data));
        })

    }





    return (
        <>
            <CsvHeaderAlert
                isOpen={csvHeaderAlertVisible}
                handleClose={() => setCsvHeaderAlertVisible(false)}
            />
            <Center
                cursor='copy'
                border='dashed 2px'
                borderColor='gray.300'
                borderRadius='md'
                transition='transform 200ms ease-in-out, border-color 200ms ease-in-out'
                _hover={{
                    borderColor: 'green.400',
                    // transform: 'scale(1.01)'
                }}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                p={30}
            >
                <Box>
                    <Text textAlign='center' fontSize='sm' color='gray.600' mb={2}>
                        Drop your csv file here or
                    </Text>
                    <Center>
                        <Button
                            as='label'
                            size="sm"
                            htmlFor={'fileInput'}
                            cursor='pointer'
                        // isLoading={uploadProgress > 0}
                        >
                            {/* Browse and join */}
                            <Box mr={1}>
                                <BiCloudUpload />
                            </Box>

                            browse
                        </Button>
                        <Input
                            id={'fileInput'}
                            accept=".csv"
                            display='none'
                            type='file'
                            // onChange={(e) => proceedUpload(e.target.files)}
                            onChange={(e) => {
                                console.log('onchange');
                                const file = e.target.files[0];
                                readFile(file);
                                // readFile(file);
                            }}
                        />
                    </Center>


                </Box>
            </Center>
            <Alert
                status='info'
                rounded={`sm`}
            >
                <AlertIcon />
                All date (birthDate and highschoolDiplomaDate) must be written with this syntax : YYYY-MM-DD, ex 2003-08-22)
            </Alert>
            <Text
                fontSize={'sm'}
                color='gray.600'
                textAlign={'center'}
            >
                Nb : your csv file must have headers : firstName, lastName, birthDate, birthPlaceCity, birthPlaceCountry, highschoolDiplomaDate, highschoolDiploma, creditTransfer, previousStudies.
                <br />
                An example file is <Button rightIcon={<BiLinkAlt />} as={'a'} size='sm' variant='link' href={`${config.apiUrl}/student/csv-example/`}>available here</Button>.
            </Text>

            {/* <input
                id="csv"
                ref={fileInputField}
                type="file"
                onChange={(e) => {
                    readFile(e.target);
                }}
            /> */}

        </>
    )
}