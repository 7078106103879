import {
  Box,
  Badge,
  Button,
  Heading,
  HStack,
  Link,
  ListItem,
  Stack,
  Text,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Tbody,
  VStack,
  Image,
  Center,
  Tooltip,
  Divider,
  Tag,
} from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import axios from "axios";
import * as React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { UserCard } from "../Forms/User/Card/Card";
import { config } from "../Utils/Config";
import { Map } from "../Components/Map";
import {
  BiEdit,
  BiFolder,
  BiLeftArrowAlt,
  BiPhone,
  BiPhoneCall,
  BiPlus,
  BiPlusCircle,
  BiPlusMedical,
  BiUser,
  BiUserCheck,
} from "react-icons/bi";
import moment from "moment";

import { Table, Tr, Td, Th } from "@chakra-ui/react";
import { Wrapper } from "../Components/Wrapper";
import { BackButton } from "../UI/BackButton";
import { StatusTag } from "./Tag";
import Debug from "../Forms/Debug";
import { ProgramCard } from "../Program/Card";
import { UserContext } from "../Auth/userContext";
import { appRoute } from "../Utils/appRoute";
import { UploadButton } from "../Upload/Button";
import { DocumentsList } from "../Document/List";
import { CommentList } from "../Components/Comment/List";
import { CommentPublishButton } from "../Components/Comment/PublishButton";
import { CommentPublishForm } from "../Components/Comment/PublishForm";
import { SchoolVerticalNav } from "./VerticalNav";
import _ from "lodash";

export const SchoolView = ({ view }) => {
  const { authed, user } = React.useContext(UserContext);
  let params = useParams();
  let { schoolId } = params;

  if (!schoolId && view === "user") {
    schoolId = user.school;
  }

  React.useEffect(() => {
    getData();
  }, []);

  const [data, setData] = React.useState(null);
  const [showCommentForm, setShowCommentForm] = React.useState(false);

  const getData = () => {
    axios
      .get(`${config.apiUrl}/school/${schoolId}`)
      .then((result) => setData(result.data));
  };

  return data ? (
    <Grid
      templateColumns={`260px 1fr`}
      // gap={10}
      id={`top`}
    >
      <Box>
        <SchoolVerticalNav schoolId={schoolId} />
      </Box>

      <Wrapper image={data.imageUrl}>
        {data.location ? (
          <Center
            position={"absolute"}
            top={0}
            right={0}
            h={`200px`}
            w={`200px`}
          >
            <Image
              rounded={`full`}
              shadow="2xl"
              src={`https://maps.googleapis.com/maps/api/staticmap?
                                    center=${data.location.lat},${data.location.lng}
                                    &zoom=4
                                    &autoscale=1
                                    &size=150x150
                                    &maptype=roadmap
                                    &key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png
                                    &visual_refresh=true
                                    &markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${data.location.lat},${data.location.lng}`}
            />
          </Center>
        ) : null}

        <Box>
          <Stack spacing={10} py={4}>
            <Flex justify={"space-between"}>
              <BackButton label="Back" />
              <ButtonGroup size="sm" colorScheme="gray">
                {view === "admin" ? (
                  <Button
                    as={RouterLink}
                    to={`./../../edit/${data._id}`}
                    leftIcon={<BiEdit />}
                  >
                    Edit this school
                  </Button>
                ) : null}

                {/* {data.status === 'created' || data.status === 'updated' ? */}

                <Button
                  as={"a"}
                  target="_blank"
                  href={`/account/school/application/${data._id}`}
                  leftIcon={<BiFolder />}
                >
                  Application form
                </Button>

                <Button
                  as={"a"}
                  // target='_blank'
                  href={`/${
                    view === "admin" ? "admin" : "account"
                  }/school/invoice/${data._id}`}
                  leftIcon={<BiFolder />}
                >
                  Invoices
                  {data.invoices.length ? ` (${data.invoices.length})` : null}
                </Button>
                {/* : null} */}

                <Button
                  as={RouterLink}
                  to={`/${appRoute(view)}/school/${data._id}/students/`}
                  leftIcon={<BiUser />}
                >
                  Students
                </Button>
              </ButtonGroup>
            </Flex>
            {/* <Box>
                            <Button
                                as={Link}
                                to='./../..'
                                size='sm'
                                variant='outline'
                                leftIcon={<BiLeftArrowAlt />}
                            >
                                Back
                            </Button>
                        </Box> */}

            <Flex>
              <Heading mb={4} color="brand.blue.900">
                {data.name}
              </Heading>
            </Flex>
            <HStack id={`general`}>
              <StatusTag label={true}>{data.status}</StatusTag>
            </HStack>

            <Grid
              templateColumns={{ base: "100%", lg: "1fr 1fr" }}
              gap={{ base: 0, lg: 6 }}
            >
              <Box
                boxShadow="var(--shadow-elevation-medium)"
                borderRadius="2px"
                bgColor="white"
              >
                <Heading
                  as="h4"
                  fontSize="md"
                  bg="gray.100"
                  color="gray.700"
                  p={2}
                  px={4}
                  mb={4}
                >
                  Legal information
                </Heading>

                <Table size="sm">
                  <Tbody>
                    {data.accreditation.status ? (
                      <Tr>
                        <Td>Accreditation status</Td>
                        <Td>
                          <Tag>{data.accreditation.status}</Tag>
                        </Td>
                      </Tr>
                    ) : null}
                    {data.agreementDate ? (
                      <Tr>
                        <Td>Agreement date</Td>
                        <Td>{data.agreementDate}</Td>
                      </Tr>
                    ) : null}
                    <Tr>
                      <Td>Accreditation number</Td>
                      <Td>{data.accreditation.number}</Td>
                    </Tr>
                    <Tr>
                      <Td>First accreditation</Td>
                      <Td>
                        {data.accreditation && data.accreditation.firstDate
                          ? data.accreditation.firstDate.slice(0, 10)
                          : null}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Accreditation renewal date</Td>
                      <Td>
                        {data.accreditation && data.accreditation.renewalDate
                          ? data.accreditation.renewalDate.slice(0, 10)
                          : null}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>

              <Box
                boxShadow="var(--shadow-elevation-medium)"
                borderRadius="2px"
                bgColor="white"
              >
                <Heading
                  as="h4"
                  fontSize="md"
                  bg="gray.100"
                  color="gray.700"
                  p={2}
                  px={4}
                  mb={4}
                >
                  Contact
                </Heading>

                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Address</Td>
                      <Td>{data.address}</Td>
                    </Tr>
                    <Tr>
                      <Td>Phone</Td>
                      <Td>{data.phoneNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>Website</Td>
                      <Td>
                        <Link
                          as={`a`}
                          cursor="pointer"
                          target="_blank"
                          href={data.websiteUrl}
                        >
                          {data.websiteUrl}
                        </Link>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>

              <Box
                boxShadow="var(--shadow-elevation-medium)"
                borderRadius="2px"
                bgColor="white"
              >
                <Heading
                  as="h4"
                  fontSize="md"
                  bg="gray.100"
                  color="gray.700"
                  p={2}
                  px={4}
                  mb={4}
                >
                  EABHES contacts
                </Heading>

                <Table size="sm">
                  <Tbody>
                    {data.staff
                      ? data.staff.map((item) => (
                          <Tr key={`schoolStaffMember-${item.email}`}>
                            <Td>
                              <Link
                                color="blue.700"
                                textDecor={`underline`}
                                href={`mailto:${item.email}`}
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                              <br />
                              <Text as="span" fontSize={"xs"}>
                                {item.phoneNumber}
                              </Text>
                            </Td>
                            <Td textAlign={`right`}>{item.position}</Td>
                          </Tr>
                        ))
                      : null}
                  </Tbody>
                </Table>

                <Box p={3}>
                  <Text fontSize={"xs"}>Portal users :</Text>
                  {data.users && data.users.length ? (
                    data.users.map((item) => (
                      <Button
                        size="xs"
                        key={item._id}
                        boxShadow="sm"
                        py={1}
                        rightIcon={<BiUserCheck />}
                        as={Link}
                        to={`/${appRoute(view)}/user/view/${item._id}`}
                        mr={2}
                      >
                        {item.firstName} {item.lastName}
                      </Button>
                    ))
                  ) : (
                    <Text fontSize={`sm`}>No staff referenced</Text>
                  )}
                </Box>

                {data.staffComment && data.staffComment !== "" ? (
                  <>
                    <Divider />
                    <Heading fontSize={"xs"} mx={3} mt={3}>
                      Contacts comments :
                    </Heading>
                    <Text p={3} fontSize="sm" whiteSpace="pre-wrap">
                      {data.staffComment}
                    </Text>
                  </>
                ) : null}
              </Box>
              {/* {
                                    // view === 'admin' &&
                                    data.invoices && data.invoices.length ?
                                        <Box
                                            boxShadow='var(--shadow-elevation-medium)'
                                            borderRadius='2px'
                                            bgColor='white'
                                        >
                                            <Heading as='h4' fontSize='md' bg='gray.100' color='gray.700' p={2} px={4} mb={4}>
                                                Finance
                                            </Heading>
                                            <Table size='sm'>
                                                <Tbody>
                                                    <Tr>
                                                        <Td>Invoice{data.invoices.length > 1 ? 's' : ''}</Td>
                                                        <Td>
                                                            <Button
                                                                variant={`link`}
                                                                as={RouterLink}
                                                                to={`../invoice/${schoolId}`}
                                                                size='sm'
                                                            >
                                                                {data.invoices.length} document{data.invoices.length > 1 ? 's' : ''}
                                                            </Button>
                                                        </Td>
                                                    </Tr>

                                                    <Tr>
                                                        <Td>Total amount</Td>
                                                        <Td>{
                                                            _.sum(
                                                                data.invoices.map(item => item.amount)
                                                            )
                                                        }€
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </Box>
                                        : null} */}

              <Box
                boxShadow="var(--shadow-elevation-medium)"
                borderRadius="2px"
                bgColor="white"
              >
                <Heading
                  as="h4"
                  fontSize="md"
                  bg="gray.100"
                  color="gray.700"
                  p={2}
                  px={4}
                  mb={4}
                >
                  General information
                </Heading>

                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Founding date</Td>
                      {/* <Td>{moment(data.foundingDate).format('YYYY')} </Td> */}
                      <Td>{data.foundingDate} </Td>
                    </Tr>
                    <Tr>
                      <Td>Institutional capital</Td>
                      <Td>{data.institutionalCapital}</Td>
                    </Tr>
                    <Tr>
                      <Td>Institutional annual turnover</Td>
                      <Td>{data.institutionalAnnualTurnover}</Td>
                    </Tr>
                    <Tr>
                      <Td>Number of teachers</Td>
                      <Td>{data.numberOfTeachers}</Td>
                    </Tr>
                    <Tr>
                      <Td>Number of administrative employees</Td>
                      <Td>{data.numberOfAdminEmployees}</Td>
                    </Tr>
                    <Tr>
                      <Td>Number of students</Td>
                      <Td>{data.numberOfStudents}</Td>
                    </Tr>
                    {data.isPartOfGroup && data.groupName ? (
                      <Tr>
                        <Td>Group</Td>
                        <Td>{data.groupName}</Td>
                      </Tr>
                    ) : null}

                    {data.campuses.length ? (
                      <>
                        <Tr>
                          <Td>Campuses</Td>
                          <Td>
                            {data.campuses.map((item, index) => (
                              <Box key={`campusItem-${item._id}`}>
                                {item.name}
                              </Box>
                            ))}
                          </Td>
                        </Tr>
                      </>
                    ) : null}
                  </Tbody>
                </Table>
              </Box>

              {/* <Box
                                    boxShadow='var(--shadow-elevation-medium)'
                                    // p={4}
                                    borderRadius='xs'
                                    h='200px'
                                    bgColor='white'
                                >
                                    {data.location ?
                                        <Map location={data.location} />
                                        : null}
                                </Box> */}
            </Grid>

            <Stack spacing={4} id="documents">
              <Heading fontSize="md">Documents</Heading>
              {/* <Debug data={data.documents} /> */}
              <DocumentsList
                data={data.documents}
                deleteAllowed={view === "admin"}
                deleteCallback={() => getData()}
              />
              <UploadButton
                uploadCallback={() => getData()}
                linkedDocumentId={data._id}
              />
            </Stack>

            <Stack id={`comments`}>
              <Heading fontSize="md">Comments</Heading>
              <CommentList
                schoolId={data._id}
                formIsVisible={showCommentForm}
              />
              <Flex justify={`flex-end`}>
                <CommentPublishButton
                  onClick={() => setShowCommentForm(!showCommentForm)}
                />
              </Flex>
              {showCommentForm ? (
                <CommentPublishForm
                  schoolId={data._id}
                  onPublish={() => {
                    setShowCommentForm(false);
                  }}
                />
              ) : null}
            </Stack>

            <Heading fontSize="md" id={`programs`}>
              School's programmes
            </Heading>
            {data.programs ? (
              <SimpleGrid columns={2} gap={4}>
                {data.programs.map((item) => (
                  <ProgramCard
                    key={item._id}
                    _id={item._id}
                    toDetails={`/${appRoute(view)}/program/view/${item._id}/${
                      item.primaryCoursePlan
                    }`}
                    toEdit={`/${appRoute(view)}/program/edit/${item._id}`}
                    name={item.name}
                    status={item.status}
                    category={item.category}
                    minor={item.minor}
                    launchDate={item.launchDate}
                    primaryCoursePlan={item.primaryCoursePlan}
                  />
                ))}
              </SimpleGrid>
            ) : null}
            <HStack>
              <Button
                as={RouterLink}
                to={`/${appRoute(view)}/program/new/school/${data._id}`}
                leftIcon={<BiPlusCircle />}
                isDisabled={data.status !== "validated"}
                size="sm"
              >
                Add a programme
              </Button>
              {data.status !== "validated" ? (
                <Text fontSize="sm" color="gray.600">
                  To add a programme, this school have to be <em>validated</em>,{" "}
                  <Link
                    as={RouterLink}
                    to={`/${appRoute(view)}/school/edit/${data._id}`}
                    textDecor={"underline"}
                  >
                    update it status before
                  </Link>
                  .
                </Text>
              ) : null}
            </HStack>

            <Heading fontSize="md">School's details</Heading>

            {/* <Box
                                boxShadow='var(--shadow-elevation-medium)'
                                borderRadius='2px'
                                bgColor='white'
                            >

                                <Heading as='h4' fontSize='md' bg='gray.100' color='gray.700' p={2} px={4} mb={4}>
                                    Academics
                                </Heading>
                                <Table>
                                    <Tbody>
                                        <Tr>
                                            <Td>Requirements for undergraduate programs</Td>
                                            <Td>{data.requirementsUnderGraduatePrograms}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Requirements for graduate programmes</Td>
                                            <Td>{data.requirementsGraduatePrograms}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Average number of students enrolled per programme</Td>
                                            <Td>{data.numberStudentsPerProgram}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Total number of students</Td>
                                            <Td>{data.numberOfStudents}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Diplomas offered
                                                <Flex >
                                                    <Text fontSize={'xs'} p={0.5} px={2} bgColor='blue.50' color='blue.800' borderRadius={'xl'}>
                                                        In blue are those for which accreditation is desired.
                                                    </Text>
                                                </Flex>

                                            </Td>
                                            <Td>
                                                <Stack alignItems='flex-start'>
                                                    {data.diplomasOffered.map(item => <Badge colorScheme={item.accreditationDesired ? 'blue' : 'gray'}>{item.level} {item.title}</Badge>)}
                                                </Stack>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Programme delivery mode</Td>
                                            <Td>
                                                <Stack alignItems='flex-start'>
                                                    {data.programDeliveryModes.map(item => <Badge>{item}</Badge>)}
                                                </Stack>

                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>

                            </Box> */}

            <Box
              boxShadow="var(--shadow-elevation-medium)"
              borderRadius="2px"
              bgColor="white"
              id="details"
            >
              <Heading
                as="h4"
                fontSize="md"
                bg="gray.100"
                color="gray.700"
                p={2}
                px={4}
                mb={4}
              >
                Internationalization
              </Heading>
              <Table>
                <Tbody>
                  <Tr>
                    <Td>List of your programmes taught in english</Td>
                    <Td>
                      <Stack alignItems="flex-start">
                        {data.programsTaughtInEnglish.map((item) => (
                          <Badge>{item}</Badge>
                        ))}
                      </Stack>
                    </Td>
                  </Tr>
                  {/* <Tr>
                                            <Td>List of your international programmes</Td>
                                            <Td>
                                                <Stack alignItems='flex-start'>
                                                    {data.internationalPrograms.map(item => <Badge>{item}</Badge>)}
                                                </Stack>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>International language test or language diploma offered at your school</Td>
                                            <Td>
                                                <Stack alignItems='flex-start'>
                                                    {data.internationalLanguageTests.map(item => <Badge>{item}</Badge>)}
                                                </Stack>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Student nationalities</Td>
                                            <Td>
                                                <Stack alignItems='flex-start'>
                                                    {data.studentsNationalites.map(item => <Badge>{item}</Badge>)}
                                                </Stack>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Can you precise which international organisation ?</Td>
                                            <Td>
                                                {data.memberOfOrganisationDetails}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Partnership with abroad schools ?</Td>
                                            <Td>
                                                {data.partnerWithSchoolDetails}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>International strategy presentation</Td>
                                            <Td>{data.internationalStrategyDescription}</Td>
                                        </Tr> */}
                </Tbody>
              </Table>
            </Box>

            <Box id="partnershipsandcertifications">
              <SimpleGrid columns={2}>
                {data.certificationsDescription ? (
                  <Stack>
                    <Heading fontSize="md">Certifications</Heading>
                    <Box whiteSpace="pre-wrap">
                      {data.certificationsDescription}
                    </Box>
                  </Stack>
                ) : null}

                {data.partnershipsDescription ? (
                  <Stack spacing={2}>
                    <Heading fontSize="md">Partnerships</Heading>
                    <Box whiteSpace="pre-wrap">
                      {data.partnershipsDescription}
                    </Box>
                  </Stack>
                ) : null}
              </SimpleGrid>
            </Box>
          </Stack>
        </Box>
      </Wrapper>
    </Grid>
  ) : null;
};
