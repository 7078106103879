import { Button } from '@chakra-ui/react'
import * as React from 'react'
import { BiPlus } from 'react-icons/bi'

interface addButtonProps {
    onClick?: Function,
    label?: string
}

export const AddButton: React.FC<addButtonProps> = (props) => (
    <Button
        leftIcon={<BiPlus />}
        type="button"
        size='xs'
        {...props}
    >
        {props.label ? props.label : `Add one`}
    </Button>
)