import {
    Box,
    Button,
    Heading,
    Divider,
    FormLabel,
    Stack,
    Select,
    FormControl
} from "@chakra-ui/react"
import axios from "axios"
import * as React from "react"
import { config } from "../../Utils/Config"
import { BiArrowBack } from 'react-icons/bi'
import { Link } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { CourseFormBase } from "./New"
import Debug from "../Debug"
import { Wrapper } from "../../Components/Wrapper"
import { BackButton } from "../../UI/BackButton"
import { UserContext } from "../../Auth/userContext"

export const CourseFormWrapper = ({ view }) => {
    const { authed, user } = React.useContext(UserContext);


    const { programId, courseId } = useParams();
    const [programs, setPrograms] = React.useState();
    const [course, setCourse] = React.useState();

    const [linkedProgram, setLinkedProgram] = React.useState(programId ? programId : null);
    const [linkedProgramDetails, setLinkedProgramDetails] = React.useState();


    React.useEffect(() => {

        console.log('update program details');
        getData();
    }, []);

    React.useEffect(() => {

        if (linkedProgram) {
            getProgramDetails();
        }

    }, [linkedProgram]);

    const getData = () => {
        axios.get(`${config.apiUrl}/program/all`)
            .then(result => setPrograms(result.data));

        if (courseId) {
            axios.get(`${config.apiUrl}/course/${courseId}`)
                .then(result => {
                    setCourse(result.data);

                    axios.get(`${config.apiUrl}/program/${result.data.program}`)
                        .then(result => setLinkedProgramDetails(result.data));

                });
        }
    }

    const getProgramDetails = () => {
        axios.get(`${config.apiUrl}/program/${linkedProgram}`)
            .then(result => setLinkedProgramDetails(result.data));
    }

    // React.useEffect(() => {
    //     if (linkedProgram) {
    //         getProgramData();
    //     } else {
    //         setLinkedProgramDetails({});
    //     }
    // }, [linkedProgram]);

    // const getProgramData = () => {
    //     axios.get(`${config.apiUrl}/program/${linkedProgram}`)
    //         .then(result => setLinkedProgramDetails(result.data));
    // }



    return (

        <Wrapper key={course}>

            <Stack
                spacing={{ base: 2, lg: 12 }}
            >
                <BackButton label='Back' />
                <Heading>
                    {course && course.name ? course.name : "New course"}
                </Heading>

                <FormControl>
                    <FormLabel>To which programme is this course linked ?</FormLabel>
                    {programs ?
                        <Select
                            onChange={(e) => setLinkedProgram(e.target.value)}
                            value={linkedProgram ? linkedProgram : programId || course && course.program}
                            bgColor='white'
                        >
                            <option value={''}>choose</option>
                            {
                                view === 'user' ?
                                    programs.map(item =>
                                        user.school
                                            && user.school === item.school._id ?
                                            <option
                                                key={item.key}
                                                value={item._id}
                                            >
                                                {item.localName}
                                            </option> : null
                                    ) :

                                    programs.map(item =>
                                        <option
                                            key={item.key}
                                            value={item._id}
                                        >
                                            {item.name}
                                        </option>
                                    )
                            }
                        </Select> : null}
                </FormControl>

                {/* <Debug data={[linkedProgram, linkedProgramDetails, courseId, course]} /> */}

                {
                    (courseId && course)
                        || (linkedProgram && linkedProgramDetails)
                        ?
                        <>
                            <Divider />
                            <CourseFormBase
                                key={{ ...linkedProgram, ...linkedProgramDetails }}
                                programId={linkedProgram}
                                programData={linkedProgramDetails}
                                data={course}
                                view={view}
                            />
                        </>
                        : null}

            </Stack>
        </Wrapper >
    )
}