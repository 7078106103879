import { Text } from '@chakra-ui/react'

export const Italic = ({ children, ...rest }) => (
    <Text
        as='span'
        fontFamily={'Georgia'}
        fontStyle='italic'
        {...rest}
    >
        {children}
    </Text>
)

export const Strong = ({ children, ...rest }) => (
    <Text
        as='span'
        fontFamily={'Satoshi'}
        fontWeight={600}
        {...rest}
    >
        {children}
    </Text>
)