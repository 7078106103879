import { FormHelperText, Input } from '@chakra-ui/react'
import * as React from 'react'

interface urlFieldProps {
    value: string,
    name: string,
    setFieldValue: Function,
    setFieldTouched: Function
}



export const UrlField: React.FC<urlFieldProps> = ({
    value,
    name,
    setFieldValue,
    setFieldTouched
}) => {

    const cleanValue = (input) => (
        input.replace(/\s+/g, '')
    )

    const addPrefix = (input) => (
        input.length >= 4 ?
            input.slice(0, 4) === 'http' ? input : `http://${input}`
            : input
    )

    return (
        <>
            <Input
                type={`text`}
                value={value}
                onChange={(e) => setFieldValue(name, addPrefix(cleanValue(e.target.value)), true, true)}
                onBlur={() => setFieldTouched(name, true, true)}
                bgColor='white'
            />
        </>
    )
}