import { Box, Heading, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { UserContext } from '../Auth/userContext'
import Debug from '../Forms/Debug'
import { NotificationsList } from '../Notification/List'
import { StatsList } from '../Stats'

export const Dashboard = () => {
    const { user } = React.useContext(UserContext)
    return (
        <Box>

            <Stack spacing={10}>
                <Heading>EABHES Portal Dashboard</Heading>


                {/* <Debug data={user} />
                <Box>

                    <Heading fontSize='md'>
                        Notifications
                    </Heading>

                    <NotificationsList />

                </Box> */}

                {/* <Box>

                    <Heading fontSize='md'>
                        Stats
                    </Heading>

                    <StatsList data={
                        [
                            { "label": "Accredited progams", "value": "756" },
                            { "label": "Students", "value": "18000" },
                            { "label": "Avg. accreditation rate", "value": "72.87%" }
                        ]
                    } />
                </Box> */}
            </Stack>
        </Box>
    )
}