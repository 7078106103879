import { Img, Button, CheckboxGroup, Checkbox, Divider, FormControl, Heading, HStack, Text, Flex, FormLabel, Input, Stack, SimpleGrid, Select, Textarea, FormHelperText, ButtonGroup, Box } from "@chakra-ui/react"
import axios from "axios"
import { Formik, Form, Field, FieldArray } from "formik"
import * as React from "react"
import { config } from "../../Utils/Config"
import Debug from "../Debug"
import FieldBlock from "../FieldBlock"
import { ProgramFormSchema } from "./Schema"
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from "react-router-dom"
import { BackButton } from "../../UI/BackButton"
import { UserContext } from "../../Auth/userContext"
import { months } from "../../Utils/Months"
import { programmeCategories } from "../../Utils/ProgrammeCategories"
import { Wrapper } from "../../Components/Wrapper"
import { arrayIntRange } from "../../Utils/arrayIntRange"
import { DeleteButton } from "../../UI/DeleteButton"
import { BiHide, BiShow } from "react-icons/bi"
import { MarkdownEditor } from "../MarkdownEditor.tsx"
import { UploadButton } from '../../Upload/Button'
var lodash = require('lodash');

export const ProgramFormDetails = ({ view }) => {
    let { userId, programId, schoolId } = useParams();
    let { user } = React.useContext(UserContext);

    const navigate = useNavigate();
    const toast = useToast();
    const [data, setData] = React.useState();
    const [schools, setSchools] = React.useState();
    const [programCoursePlans, setProgramCoursePlans] = React.useState();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {

        axios.get(`${config.apiUrl}/school/all/`)
            .then(result => setSchools(result.data));

        axios.get(`${config.apiUrl}/course-plan/`)
            .then(result => setProgramCoursePlans(result.data));

        if (programId) {
            axios.get(`${config.apiUrl}/program/${programId}`)
                .then(result => {
                    let data = result.data;
                    delete data.courses;
                    // data.intakesDate = data.intakesDate.slice(0, 10);
                    // data.endDate = data.endDate ? data.endDate.slice(0, 10) : '';
                    setData(data)
                }
                );
        }
    }

    const feesFields = (number) => {
        let fields = []
        for (let index = 1; index <= Math.ceil(number / 2); index++) {
            fields.push(
                <Field name={`feesYear${index}`}>
                    {({ field, form, meta }) => (
                        <FieldBlock
                            isInvalid={meta.error && meta.touched}
                            error={meta.error}
                        >
                            {/* <FormLabel></FormLabel> */}
                            <Input
                                {...field}
                                placeholder={`Year ${index}`}
                            />
                        </FieldBlock>
                    )}
                </Field>
            )
        }
        return fields
    }







    return (
        !userId || data ?
            <Formik
                key={data}
                initialValues={
                    data ?
                        {
                            ...data,
                            school: data.school._id,
                            processSteps: data.processSteps ? data.processSteps : [],
                            accreditation: {
                                ...data.accreditation,
                                date: data.accreditation && data.accreditation.date ? data.accreditation.date.slice(0, 10) : ``
                            }
                        }
                        :
                        view === 'user' ?
                            {
                                school: user.school,
                                status: 'created',
                                processSteps: [],
                                transcript: { showYears: true }
                            } // need to put user school here 
                            : {
                                processSteps: [],
                                transcript: { showYears: true }
                            }
                }
                validationSchema={ProgramFormSchema}
                onSubmit={(values, { setSubmitting }) => {
                    !data ?
                        axios.post(
                            `${config.apiUrl}/program/`,
                            values
                        ).then(
                            () => {
                                toast({
                                    title: 'Programme created.',
                                    description: "Success.",
                                    status: 'success',
                                    duration: 9000,
                                    isClosable: true,
                                });
                                navigate(view === 'user' ? '/account/program' : '/admin/program');
                            }
                        )
                        :
                        axios.put(
                            `${config.apiUrl}/program/${data._id}`,
                            values
                        ).then(() => toast({
                            title: 'Programme updated.',
                            description: "Success",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        }))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <Wrapper>

                        <Stack
                            as={Form}
                            spacing={{ base: 2, lg: 10 }}
                            bgColor='white'
                            p={10}
                        >
                            <BackButton label='back' />
                            <Heading>
                                {values && values.name ?
                                    `${values.name} details`
                                    : values ? `Programme update`
                                        : `New programme`
                                }
                            </Heading>






                            <Field name='presentation'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}

                                    >
                                        <FormLabel>Brief presentation of the program</FormLabel>
                                        <MarkdownEditor
                                            value={field.value}
                                            onChange={value => setFieldValue(field.name, value)}
                                        />
                                        <FormHelperText>
                                            Maximum 200 words, overall objectives, method, professions
                                        </FormHelperText>
                                    </FieldBlock>
                                )}
                            </Field>

                            <Field name='aims'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Aims of the programme</FormLabel>
                                        <MarkdownEditor
                                            value={field.value}
                                            onChange={value => setFieldValue(field.name, value)}
                                        />
                                    </FieldBlock>
                                )}
                            </Field>

                            <Field name='learningOutcomes'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Learning outcomes</FormLabel>
                                        <MarkdownEditor
                                            value={field.value}
                                            onChange={value => setFieldValue(field.name, value)}
                                        />
                                    </FieldBlock>
                                )}
                            </Field>

                            <Field name='assessment'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Assessment</FormLabel>
                                        <MarkdownEditor
                                            value={field.value}
                                            onChange={value => setFieldValue(field.name, value)}
                                        />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name='admissionProcess'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Admission process</FormLabel>
                                        <MarkdownEditor
                                            value={field.value}
                                            onChange={value => setFieldValue(field.name, value)}
                                        />
                                    </FieldBlock>
                                )}
                            </Field>

                            <Field name='illustrationFile'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Illustration (used on Programme Specification page)</FormLabel>
                                        {field.value ?
                                            <Img
                                                src={`${config.apiUrl}/document/${programId}/${field.value}`}
                                                mb={4}
                                            />
                                            : null}

                                        <UploadButton
                                            align={`left`}
                                            linkedDocumentId={programId}
                                            outputName={`illustration`}
                                            isPublic={true}
                                            uploadCallback={(file) => {
                                                console.log(file);
                                                setFieldValue(
                                                    field.name,
                                                    `illustration.${file.name.split(".").pop()}`,
                                                    null,
                                                    null
                                                );
                                                handleSubmit();
                                            }
                                            }
                                        />

                                    </FieldBlock>
                                )}
                            </Field>

                            <Field name='curriculumMapFile'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                        isRequired={false}
                                    >
                                        <FormLabel>Curriculum map</FormLabel>
                                        {field.value ?
                                            <Img
                                                src={`${config.apiUrl}/document/${programId}/${field.value}`}
                                            />
                                            : null}

                                        <UploadButton
                                            align={`left`}
                                            linkedDocumentId={programId}
                                            outputName={`curriculum-map`}
                                            isPublic={true}
                                            uploadCallback={(file) => {
                                                console.log(file);
                                                setFieldValue(
                                                    field.name,
                                                    `curriculum-map.${file.name.split(".").pop()}`,
                                                    null,
                                                    null
                                                );
                                                handleSubmit();
                                            }
                                            }
                                        />

                                    </FieldBlock>
                                )}
                            </Field>



                            <Divider />
                            <Flex>
                                <Button
                                    type='submit'
                                    isDisabled={
                                        Object.keys(errors).length !== 0
                                    }>
                                    Save
                                </Button>
                            </Flex>





                            <Debug data={errors} />
                        </Stack>
                    </Wrapper>
                )
                }

            </Formik >
            : null

    )
}