import { Box, Button, Flex, FormControl, FormLabel, Grid, Heading, Input, SimpleGrid, Stack, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { BiCloudUpload } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import { UploadButton } from '../Upload/Button';
import { config } from '../Utils/Config';
import { SchoolVerticalNav } from './VerticalNav';

export const InvoiceUploadButton = ({ schoolId, uploadCallback, linkedDocumentId }) => {
    const fileInput = React.useRef()


    // React.useEffect(() => {
    //     getData();
    //     getInvoices();
    // }, []);

    // const getData = () => {
    //     axios.get(`${config.apiUrl}/school/${schoolId}`)
    //         .then(result => setData(result.data));
    // }
    // const getInvoices = () => {
    //     axios.get(`${config.apiUrl}/invoice/school/${schoolId}`)
    //         .then(result => setInvoices(result.data));
    // }

    // const saveInvoice = (values) => {
    //     axios.post(
    //         `${config.apiUrl}/invoice/`,
    //         values
    //     ).then(res => onSave())
    // }

    const isPublic = true

    const [isUploading, setIsUploading] = React.useState(0);
    const [uploadProgress, setUploadProgress] = React.useState(0);

    var axiosParams = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    const proceedUpload = file => {
        console.log('file', file)
        var formData = new FormData();
        formData.append("document", file);
        formData.append("public", isPublic ? isPublic : false);
        axios.post(
            `${config.apiUrl}/document/${linkedDocumentId}/invoice`
            , formData,
            axiosParams
        ).then(async res => {
            setUploadProgress(null)
            uploadCallback(file.name);
            if (fileInput.current) { fileInput.current.value = null; };
        })
    }

    const handleChange = e => {
        const files = e.target.files;
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            proceedUpload(file);
        }
    }

    return (
        <>
            <Button
                as='label'
                htmlFor='fileInput'
                size='sm'
                // leftIcon={<BiCloudUpload />}
                leftIcon={<BiCloudUpload />}
                cursor='pointer'
                display={isUploading ? 'none' : 'flex'}

            >
                Browse and upload
                {/* {uploadProgress ? ` (${uploadProgress})%` : ''} */}
                {/* {isUploading ? <Spinner size='xs' /> : null} */}
            </Button>

            <input
                style={{
                    opacity: 0,
                    // position: "relative",
                    zIndex: 1,
                    position: 'absolute',
                    left: '-5000px'

                }}
                id='fileInput'
                multiple
                type='file'
                name='file'
                onChange={handleChange}
                ref={fileInput}
            />
        </>
    )
}