export const courseBgColor = (
    type,
    isMandatory
) => {

    return (

        isMandatory ?
            // { bg: type === 'Fundamental' ? 'orange.100' : type === 'Practical' ? 'blue.100' : 'green.100' }

            { bg: type === 'Fundamental' ? 'orange.100' : type === 'Practical' ? 'blue.100' : 'green.100' }

            :
            {
                bg:
                    `repeating-linear-gradient(
                    45deg,
                    #FFFFFF22,
                    #FFFFFF22 10px,
                    #FFFFFF66 10px,
                    #FFFFFF66 20px
                )`,
                bgColor: type === 'Fundamental' ? 'orange.100' : type === 'Practical' ? 'blue.100' : 'green.100'
            }
    )
}


export const courseTextColor = (
    type
) => {

    return (

        { color: type === 'Fundamental' ? 'orange.500' : type === 'Practical' ? 'blue.500' : 'green.500' }

    )
}

export const courseFacultativeStripes = (
    isMandatory
) => {

    return (

        isMandatory ?
            // { bg: type === 'Fundamental' ? 'orange.100' : type === 'Practical' ? 'blue.100' : 'green.100' }
            {}
            :
            {
                bg:
                    `repeating-linear-gradient(
                45deg,
                #FFFFFF22,
                #FFFFFF22 10px,
                #FFFFFF66 10px,
                #FFFFFF66 20px
            )`,
            }

    )
}