import {
    Box,
    Flex,
    Image,
    Heading,
    SimpleGrid,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'

export const UnloggedLayout = ({ children, title, subTitle }) => (

    <Box minH="100vh" bg={{ md: mode('gray.100', 'inherit') }}>
        <Box maxW="6xl" mx="auto" py={{ base: '10', md: '20' }} px={{ base: '4', md: '10' }}>
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="14">
                <Box w="full" maxW="xl" mx="auto">
                    <Box
                        bg={{ md: mode('white', 'gray.700') }}
                        rounded={{ md: '2xl' }}
                        p={{ base: '4', md: '12' }}
                        borderWidth={{ md: '1px' }}
                        borderColor={mode('gray.200', 'transparent')}
                        shadow={{ md: 'lg' }}
                    >
                        <Image
                            src='https://www.eabhes.eu/wp-content/uploads/2019/01/logo-trasparente-1.png'
                            alt='Logo Eabhes'
                            w='100px'
                        />
                        <Box mb="8" textAlign={{ base: 'center', md: 'start' }}>
                            <Heading size="lg" mb="2" fontWeight="extrabold">
                                {title ? title : `Welcome to EABHES Portal`}
                            </Heading>
                            <Text fontSize="lg" color={mode('gray.600', 'gray.400')} fontWeight="medium">
                                {subTitle ? subTitle : `Enter your loggin info to get started`}
                            </Text>
                        </Box>

                        {children}

                    </Box>

                    <Text mt="8" align="center" fontWeight="medium">
                        Not already an EABHES member ?{' '}
                        <Box
                            as='a'
                            target='_blank'
                            href="https://www.eabhes.eu/"
                            color={mode('blue.600', 'blue.200')}
                            display={{ base: 'block', md: 'inline-block' }}
                        >
                            Check our services
                        </Box>
                    </Text>
                </Box>

                <Flex direction="column" py="24" display={{ base: 'none', lg: 'flex' }}>
                    {/* <Testimonial /> */}
                    {/* <SimpleGrid
              columns={3}
              spacing="10"
              paddingStart="12"
              alignItems="center"
              color="gray.400"
            >
              <Logos.Wakanda />
              <Logos.ChatMonkey />
              <Logos.Lighthouse />
            </SimpleGrid> */}
                </Flex>
            </SimpleGrid>
        </Box>
    </Box>
)