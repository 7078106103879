import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
    opacity: 0,
    transform: `translateX(-10px)`
}

const transitionStyles = {
    entering: { opacity: 1, transform: `translateX(-10px)` },
    entered: { opacity: 1, transform: `translateX(0px)` },
    exiting: { opacity: 0, transform: `translateX(20px)` },
    exited: { opacity: 0, transform: `translateX(20px)` },
};

export const Fade = ({ in: inProp, children }) => (
    <Transition in={inProp} timeout={duration}>
        {state => (
            <div style={{
                ...defaultStyle,
                ...transitionStyles[state]
            }}>
                { children }
            </div>
        )}
    </Transition>
);
