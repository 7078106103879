import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import * as React from "react";
import VerticalLogoWithMessage from "../../Images/logo/vertical-blue-extended-with-message.svg";
import { config } from "../../Utils/Config";

export const DiplomaLogo = ({ data }) => {
  const {
    school: { _id: schoolId, logoFileVertical, displaySchoolLogoOnDocuments },
  } = data;

  console.log("logoVerticalFile", logoFileVertical);

  const LogoUrl = !displaySchoolLogoOnDocuments
    ? VerticalLogoWithMessage
    : `${config.apiUrl}/document/${schoolId}/${logoFileVertical}`;

  return (
    <Flex justifyItems="center" alignItems="flex-end" color="brand.blue.900">
      <Box px={`4mm`} py={`15mm`}>
        <Image src={LogoUrl} alt={`EABHES Logo`} h={`80mm`} />
      </Box>
    </Flex>
  );
};
