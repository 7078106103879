import { Alert, Box, Button, FormHelperText, Stack } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'

import { useParams } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../../Auth/userContext';

import { Wrapper } from '../../Components/Wrapper';
import Debug from '../../Forms/Debug';
import FieldBlock from '../../Forms/FieldBlock';
import { BackButton } from '../../UI/BackButton';
import { config } from '../../Utils/Config';
import { ProgramsSuggestField } from '../SuggestField';
import { SubProgramSelect } from './SubProgramSelect';

export const ProgramMerge = ({ view }) => {
    const { authed, user } = React.useContext(UserContext);

    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState('')
    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [schools, setSchools] = React.useState(null)

    let userSchool: string = '';
    if (view === 'user') {
        userSchool = user.school;
    } else {
        userSchool = '';
    }

    const handleSubmit = () => {
        console.log(mainProgramId, subPrograms)

        axios.post(
            `${config.apiUrl}/program/merge/`,
            { mainProgram: mainProgramId, subPrograms: subPrograms }
        ).then(
            res => navigate(`/admin/program/view/${res.data.mainProgram}/${res.data.mainProgramCoursePlan}`)
        )
    }

    const [mainProgramId, setMainProgramId] = React.useState('')
    const [subPrograms, setSubPrograms] = React.useState('')

    return (
        <>
            <Wrapper>
                <Box mb={10}>
                    <BackButton label="Back" />
                </Box>

                {!mainProgramId || !subPrograms ?
                    <Stack spacing={8}>
                        <FieldBlock
                            title='Main program to merge'
                        >
                            <ProgramsSuggestField
                                onChange={(value) => setMainProgramId(value)}
                            />
                            <FormHelperText>
                                This program will host a list of sub-programs that will be, after conversion, course plans of the main program.
                            </FormHelperText>
                        </FieldBlock>

                        {mainProgramId ?
                            <SubProgramSelect
                                onSave={(values) => setSubPrograms(values)}
                            />
                            : null}


                    </Stack>
                    :
                    <Stack spacing={4}>

                        <Alert>
                            You are about to mix {subPrograms.length + 1} programmes into 1, this action cannot be undone. If you are perfectly sure, click on "proceed":
                        </Alert>
                        <Box>
                            <Button
                                colorScheme={`orange`}
                                onClick={() => handleSubmit()}
                            >
                                Proceed
                            </Button>
                        </Box>
                    </Stack>
                }
            </Wrapper>
        </>
    )
}