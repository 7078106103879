import { Box, Button, Flex, FormControl, FormLabel, Grid, Heading, Input, SimpleGrid, Stack, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { BiCloudUpload, BiX } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import { UploadButton } from '../Upload/Button';
import { config } from '../Utils/Config';
import { SchoolVerticalNav } from './VerticalNav';

export const InvoiceDeleteButton = ({ schoolId, deleteCallback, value, linkedDocumentId }) => {

    const handleDelete = () => {
        axios.delete(
            `${config.apiUrl}/document/`,
            {
                data: { path: `${schoolId}/invoice/${value}` }
            }
        );
        deleteCallback();
    }


    return (
        <>
            <Button
                as='label'
                htmlFor='fileInput'
                size='sm'
                // leftIcon={<BiCloudUpload />}
                leftIcon={<BiX />}
                cursor='pointer'
                onClick={() => handleDelete()}

            >
                {value}
                {/* {uploadProgress ? ` (${uploadProgress})%` : ''} */}
                {/* {isUploading ? <Spinner size='xs' /> : null} */}
            </Button>

        </>
    )
}