import { Box, Center, Grid, Heading, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

export const ProgramExcerpt = ({ program }) => {
    return (
        <Box
            p={`20mm`}
        >
            <VStack
                alignItems={'flex-start'}
                spacing={8}
                fontSize='sm'
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    {program.localName}
                </Text>

                <Text>
                    Major : {program.major}
                </Text>

                <Text>
                    {program.presentation}
                </Text>

            </VStack>

        </Box >
    )
}