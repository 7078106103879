import { Box, Heading } from '@chakra-ui/layout'
import axios from 'axios';
import * as React from 'react'
import { config } from '../Utils/Config';

import {
    Link as ChakraLink,
    Button,
    HStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    Flex,
    useColorModeValue as mode,
    Checkbox,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { Link, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { TableActions } from './ListHeader';
import { TablePagination } from './ListPagination';
import Debug from '../Forms/Debug';
// import { StatusTag } from './Tag';
import { Loader } from '../UI/Loader';
import { BiArrowFromTop, BiBookBookmark, BiBookmark, BiChevronDown, BiChevronDownSquare, BiFile, BiPencil, BiRightArrowAlt, BiWindows } from 'react-icons/bi';
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { GrDocumentPdf, GrEdit } from 'react-icons/gr'
import { BsFileArrowDown } from 'react-icons/bs';
import { StatusTag } from './Tag';
import { NoResults } from '../UI/NoResults';
import { UserContext } from '../Auth/userContext';
import { scrollToTopContent } from '../Utils/scrollToTopContent';

const InscriptionList = ({ view }) => {

    const { user } = React.useContext(UserContext);

    const navigate = useNavigate();
    const params = useParams();

    const { studentId } = params;

    const [data, setData] = React.useState(null)
    const [currentPage, setCurrentPage] = React.useState(params.page ? params.page : 1)

    const [lastPage, setLastPage] = React.useState('')
    const [totalResults, setTotalResults] = React.useState(0)

    const [search, setSearch] = React.useState({})
    const [schools, setSchools] = React.useState(null)
    const [programs, setPrograms] = React.useState(null)
    const [coursePlans, setCoursePlans] = React.useState(null)

    const [student, setStudent] = React.useState(null)

    React.useEffect(() => {
        getData();
    }, [params.page, search]);


    React.useEffect(() => {
        getCoursePlan();
    }, []);

    const getData = () => {
        if (!studentId) {
            axios.get(
                user.role === 'admin' ?
                    `${config.apiUrl}/inscription/${search.program ? search.program : '-'}/${search.coursePlan ? search.coursePlan : '-'}/${search.startYear ? search.startYear : '-'}/${search.endYear ? search.endYear : '-'}/${params.page ? params.page : '-'}`
                    :
                    `${config.apiUrl}/inscription/school/${user.school}/program/${search.program ? search.program : '-'}/${params.page ? params.page : '1'}`
            )
                .then(result => {
                    setData(result.data);

                    setLastPage(parseInt(result.headers.lastpage));
                    setCurrentPage(parseInt(result.headers.currentpage));
                    setTotalResults(parseInt(result.headers.results));

                    if (view === 'admin') scrollToTopContent();

                });
        } else {
            axios.get(`${config.apiUrl}/inscription/student/${studentId}`)
                .then(result => setData(result.data));

            axios.get(`${config.apiUrl}/student/${studentId}`)
                .then(result => setStudent(result.data));

        }


        axios.get(
            user.role === 'user' ?
                `${config.apiUrl}/program/school/${user.school}/all/`
                :
                `${config.apiUrl}/program/all/`
        )
            .then(result => setPrograms(result.data));
    }

    const getCoursePlan = () => {
        axios.get(`${config.apiUrl}/course-plan/`)
            .then(result => setCoursePlans(result.data));


    }

    const getPrograms = () => {
        axios.get(
            user.role === 'user' ?
                `${config.apiUrl}/program/school/${user.school}`
                :
                `${config.apiUrl}/program`
        ).then(
            res => setPrograms(res.data)
        )

    }

    const handleUpdateInscriptions = (postData) => {
        axios.patch(
            `${config.apiUrl}/inscription/batch`,
            postData
        ).then(res => getData());
    }

    return (
        <>
            <Heading mb={4} id="top">
                Curriculum(s){' '}
                {student ? `for "${student.firstName} ${student.lastName.toUpperCase()}"` : null}
            </Heading>

            {/* <Debug data={params} /> */}
            {/* <Debug data={user} /> */}

            {programs ?
                <TableActions
                    getValues={(values) => setSearch(values)}
                    programFilters={programs}
                    program={search.program}
                    startYear={search.startYear}
                    endYear={search.endYear}
                    key={data}
                    view={view}
                />
                : null}

            {/* <Debug data={data} /> */}

            {
                data ?
                    data.length ?
                        <Formik
                            initialValues={{}}
                            onSubmit={(values) => {
                                let postData = values;
                                postData.inscriptions = values.inscriptions.filter(item => item && item.length);
                                handleUpdateInscriptions(postData);
                                console.log(postData);
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    program: Yup.string().required(),
                                    coursePlan: Yup.string().required()
                                })
                            }
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <Form>

                                    <Table mt="8" mb={2} borderWidth="1px" fontSize="sm" bgColor='white'>
                                        <Thead bg={mode('gray.50', 'gray.800')}>
                                            <Tr>
                                                {/* <Th w='30px' p={0} /> */}
                                                <Th>Student</Th>
                                                <Th>Program/Course Plan</Th>
                                                <Th>Period</Th>
                                                <Th>ECTS</Th>
                                                {/* <Th>Region</Th> */}
                                                <Th>Status</Th>
                                                <Th
                                                    textAlign='right'
                                                >
                                                    Action
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {data ?
                                                <FieldArray
                                                    name='inscriptions'
                                                    render={arrayHelpers => (
                                                        data.map((item, index) =>
                                                            <Tr key={item._id}>
                                                                {/*<Td px={4}>
                                                                   <Field name={`inscriptions.${index}`}>
                                                                        {({ field, meta }) => (

                                                                            <Checkbox
                                                                                value={true}
                                                                                onChange={(e) => {
                                                                                    // console.log(e.target.value);
                                                                                    setFieldValue(
                                                                                        field.name
                                                                                        , e.target.checked ? item._id : null
                                                                                    )
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field> 
                                                                </Td>*/}
                                                                <Td whiteSpace="nowrap">
                                                                    <Link
                                                                        to={`/${view === `admin` ? `admin` : `account`}/student/view/${item.student._id}`}
                                                                        target={`_blank`}
                                                                    >
                                                                        <ChakraLink>
                                                                            {`${item.student.firstName} ${item.student.lastName.toUpperCase()}`}
                                                                        </ChakraLink>

                                                                    </Link>
                                                                </Td>
                                                                <Td>
                                                                    {item.program && item.program.name ? item.program.name : ''}
                                                                    {/*
                                                                    {item.program.category ? `/${item.program.category}` : ''}
                                                                    {item.program.major ? `/${item.program.major}` : ''}
                                                                    /
                                                                    */}
                                                                    {` / `}
                                                                    {item.coursePlan.name ? item.coursePlan.name : ``}
                                                                </Td>
                                                                <Td>
                                                                    <Flex alignItems={'center'}>
                                                                        {`${item.startDate}`} <BiRightArrowAlt /> {`${item.endDate}`}
                                                                    </Flex>
                                                                </Td>
                                                                <Td>{item.ectsCreditsObtained}</Td>
                                                                {/* <Td>{item.status}</Td> */}
                                                                <Td>
                                                                    <StatusTag>
                                                                        {item.status}
                                                                    </StatusTag>
                                                                </Td>
                                                                <Td textAlign="right">

                                                                    <Menu>
                                                                        <MenuButton
                                                                            as={Button}
                                                                            rightIcon={<BiChevronDown />}
                                                                            size='xs'
                                                                        >
                                                                            <BiChevronDownSquare />
                                                                        </MenuButton>
                                                                        <MenuList>
                                                                            <MenuItem
                                                                                icon={<GrEdit />}
                                                                                as={RouterLink}
                                                                                to={`/${view === 'admin' ? `admin` : `account`}/inscription/update/${item._id}`}
                                                                            >
                                                                                Update details
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiFile />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/registration-certificate/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Registration certificate
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiFile />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/transcript/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Transcript
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                icon={<BiBookBookmark />}
                                                                                as={RouterLink}
                                                                                to={`/inscription/${item._id}/diploma/export/`}
                                                                                isDisabled={!item.program.accountingSituation}
                                                                            >
                                                                                Certificate of Completion
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    </Menu>

                                                                    {/* <Button
                                                                size='sm'
                                                                variant="link"
                                                                colorScheme="blue"
                                                                as={RouterLink}
                                                                to={`/admin/inscription/view/${item._id}`}
                                                            >
                                                                Details
                                                            </Button>

                                                            <Button
                                                                size='sm'
                                                                variant="link"
                                                                colorScheme="blue"
                                                                as={'a'}
                                                                target={'_blank'}
                                                                href={`${config.apiUrl}/inscription/transcript/${item._id}`}
                                                            >
                                                                Transcript
                                                            </Button> */}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    )}
                                                />
                                                : null}

                                        </Tbody>
                                    </Table >

                                    {
                                        values.inscriptions
                                            && values.inscriptions.filter(item => item && item.length).length > 0 ?
                                            <Flex justify={'flex-start'} >
                                                <Flex alignItems={'center'} bgColor='white' p={2}>

                                                    <Text mr={2}>
                                                        Change selected inscription affectation :
                                                    </Text>
                                                    <Field name='program'>
                                                        {({ field, meta }) => (
                                                            <Select mr={2} w='min-intrinsic' size='sm' placeholder='Select' {...field}>
                                                                {programs.map(item =>
                                                                    <option key={item._id} value={item._id}>{item.name}</option>
                                                                )}
                                                            </Select>
                                                        )}
                                                    </Field>

                                                    {values.program ?
                                                        <Field name='coursePlan'>
                                                            {({ field, meta }) => (
                                                                <Select mr={2} w='min-intrinsic' size='sm' placeholder='Select' {...field}>
                                                                    {coursePlans.map(item =>
                                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                                    )}
                                                                </Select>
                                                            )}
                                                        </Field>
                                                        : null}
                                                    <Button
                                                        size='sm'
                                                        type='submit'
                                                        leftIcon={<BiPencil />}
                                                        isDisabled={errors && Object.keys(errors).length > 0}
                                                    >
                                                        Update
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                            : null}
                                    {/* <Debug data={values} /> */}
                                    {values.inscriptions ?
                                        <>
                                            <Button
                                                as='a'
                                                href={
                                                    `${config.apiUrl}/inscription/transcript/${values.inscriptions.filter(item => item && item.length).join(',')}/EABHES%20Transcript%20Master%20of%20Art%20and%20Musi%202020-2022`
                                                }
                                                target='_blank'
                                            >
                                                Exports transcripts
                                            </Button>
                                            <Debug data={values.inscriptions.filter(item => item && item.length)} />
                                        </>

                                        : null}
                                </Form>
                            )}



                        </Formik>
                        : <NoResults label='No inscription found' />
                    : <Loader label='No inscription to display' />
            }

            {/* <Debug data={[totalResults, lastPage, params]} /> */}

            {
                data ?
                    <TablePagination
                        totalResults={totalResults}
                        lastPage={lastPage}
                        currentPage={currentPage ? currentPage : 0}
                        baseLink={view === 'user' ? '/account/inscription/' : '/admin/inscription/'}

                    />
                    : null
            }


        </>
    )
}

export default InscriptionList