import { Box, Button, Divider, Flex, FormLabel, Input, Stack, SimpleGrid, Select, FormHelperText, SelectField, Heading } from "@chakra-ui/react"
import axios from "axios"
import { Formik, Form, Field } from "formik"
import * as React from "react"
import { config } from "../../Utils/Config"
import { countries } from "../../Utils/Countries"
import Debug from "../Debug"
import FieldBlock from "../FieldBlock"
import { UserFormSchema } from "./Schema"
import { useToast } from '@chakra-ui/react'
import { Wrapper } from "../../Components/Wrapper"
import { BiArrowBack } from "react-icons/bi"
import { Navigate, useNavigate } from "react-router-dom"
import { BackButton } from "../../UI/BackButton"
import { SchoolSuggestField } from "../../School/SuggestField"



const UserFormNew = ({ userId }) => {
    const toast = useToast()
    const [data, setData] = React.useState({});
    const [users, setUsers] = React.useState({});
    const [schools, setSchools] = React.useState({});

    const navigate = useNavigate();

    React.useEffect(() => {
        getData();

    }, []);

    const getData = () => {
        axios.get(`${config.apiUrl}/user/`)
            .then(result => setUsers(result.data));

        axios.get(`${config.apiUrl}/school/`)
            .then(result => setSchools(result.data));
    }

    return (
        <Wrapper>
            <Formik
                initialValues={{}}
                validationSchema={UserFormSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axios.post(
                        `${config.apiUrl}/user`,
                        values
                    ).then(() => {
                        toast({
                            title: 'Account created.',
                            description: "Account created and user notified.",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        });
                        navigate('./..');
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => (
                    <Stack
                        as={Form}
                        onSubmit={handleSubmit}
                        spacing={{ base: 2, lg: 22 }}
                    >
                        <BackButton label='Back' />
                        <Heading>
                            {data ? data.name : "Create user"}
                        </Heading>

                        <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                            <Field name='firstName'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                    >
                                        <FormLabel>Firstname</FormLabel>
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                            <Field name='lastName'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched ? true : false}
                                        error={meta.error}
                                    >
                                        <FormLabel>Lastname</FormLabel>
                                        <Input {...field} />
                                    </FieldBlock>
                                )}
                            </Field>
                        </SimpleGrid>
                        <Field name='email'>
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    isInvalid={meta.error && meta.touched}
                                    error={meta.error}
                                >
                                    <FormLabel>Email address</FormLabel>
                                    <Input
                                        {...field}
                                    />
                                </FieldBlock>
                            )}
                        </Field>

                        <Field name='role'>
                            {({ field, form, meta }) => (
                                <FieldBlock
                                    isInvalid={meta.error && meta.touched}
                                    error={meta.error}
                                    isRequired={true}
                                >
                                    <FormLabel>Role</FormLabel>
                                    <Select
                                        {...field}
                                        placeholder='Select please'
                                    >
                                        <option value='admin'>EABHES staff</option>
                                        <option value='user'>School staff</option>
                                    </Select>
                                </FieldBlock>
                            )}
                        </Field>

                        {values.role === 'user' ?
                            <Field name='school'>
                                {({ field, form, meta }) => (
                                    <FieldBlock
                                        isInvalid={meta.error && meta.touched}
                                        error={meta.error}
                                    >
                                        <FormLabel>School</FormLabel>
                                        <SchoolSuggestField
                                            onChange={(programId: string) => setFieldValue(field.name, programId)}
                                        />
                                        {/* {schools ?
                                            <Select {...field} placeholder='Select'>
                                                {schools.map(item =>
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                )}
                                            </Select>
                                            : null} */}
                                    </FieldBlock>
                                )}
                            </Field> : null}
                        <Divider />
                        <Flex>
                            <Button
                                type='submit'
                                isDisabled={
                                    Object.keys(errors).length !== 0
                                    // && Object.getPrototypeOf(errors) === Object.prototype
                                }>
                                Save
                            </Button>
                        </Flex>
                        <Divider />
                        <Debug data={[errors, touched, values, Object.keys(errors).length]} />
                    </Stack>
                )}
            </Formik>
        </Wrapper>
    )
}

export default UserFormNew