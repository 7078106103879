import * as Yup from 'yup';
const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const ActivationFormSchema = Yup.object().shape({
    firstName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    lastName:
        Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    // email:
    //     Yup.string()
    //         .email()
    //         .required('Required'),
    // phoneNumber:
    //     Yup.string()
    //         .matches(phoneRegExp, 'Phone number is not valid')
    //         .required('Required')
    //         ,
    // mobilePhoneNumber:
    //     Yup.string()
    //         .matches(phoneRegExp, 'Phone number is not valid')
    //         .nullable()
    //         // .required('Required')
    //         ,
    password:
        Yup.string()
            .required('You must create a password')
            .min(6, '6 characters minimum')
            .nullable(),
    passwordConfirmation:
        Yup.string()
            .required('You must create a password')

            // .required('Password is required please')
            .oneOf([Yup.ref('password'), null], 'It\'s not same :/')
});

export { ActivationFormSchema }