import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import { InscriptionUpdate } from './Update'

export const InscriptionUpdateModal = ({
    inscriptionId,
    handleClose
}) => {

    return (
        <Modal
            isOpen={inscriptionId}
            onClose={handleClose}
            size='5xl'
            aria-modal
        >
            <ModalOverlay />

            <ModalContent
                // bg={'gray.100'}
                bg='transparent'
                boxShadow={'none'}
            >
                <ModalBody>
                    <>
                        <InscriptionUpdate
                            inscriptionId={inscriptionId}
                            view='admin'
                            handleClose={handleClose}
                            context={'modal'}
                        />
                        <div>Student update</div>
                    </>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}