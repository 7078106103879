export const programmeCategories = [
    "Arts",
    "Business Administration",
    "Fine Arts",
    "Law",
    "Professional Course",
    "Professional Studies",
    "Sciences",
    "Summer course",


    "European Master of Professional Photography",
    "European Master of Computer Science",
    "European Bachelor of Computer Science",
    "European Master of Arts",
    "European Philosophical Doctorate",
    "European Bachelor of Design",
    "European Bachelor of Science in Design",
    "European Master of Science in Design",
    "European Bachelor of Fine Arts",
    "European Master of Fine Arts",
    "European Bachelor of Applied Chemistry",
    "European Master of Applied Chemistry",
    "European Bachelor in Hospitality Administration",
    "European Master of Business Administration",
    "European Bachelor of Science in Business",
    "European Master of Science in Business",
    "European Bachelor of Arts",
    "European Bachelor of Professional Studies",
    "European Master of Hospitality Management",
    "European Bachelor of Music",
    "European Master of Management of the Performing Arts",
    "European Bachelor of Culinary Arts",
    "European Master of Professional Studies",
    "European Executive MBA",
    "European Bachelor of Science",
    "European Bachelor of Science in Nutrition",
    "European Bachelor in Fashion Design and Patternmaking",
    "Summer Course",
    "European Bachelor of Fashion Design",
    "European Bachelor of Business Administration",
    "European Bachelor in Communication",
    "European Bachelor in Political Science",
    "Foundation of Arts Culture and Italian Language",
    "European Master of Public Administration",
    "European Bachelor in Law and Legal Activities",
    "European Master of Design",
    "European Bachelor in Public Law",
    "European Bachelor in Legal Activities",
    "European Bachelor in Visual Arts and Design",
    "Short Programme"
]