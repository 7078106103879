import { Box, Button, Divider, FormLabel, Grid, HStack, Input, Select, Stack, Textarea } from '@chakra-ui/react'
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import FieldBlock from '../Forms/FieldBlock';
import { config } from '../Utils/Config';
import { useToast } from '@chakra-ui/react'
// import { ProfilPictWizard } from '../Components/ProfilPict/Wizard';
// import { ProfilPict } from '../Components/ProfilPict/View';
import { DeleteButton } from '../UI/DeleteButton';
import { navigate } from '@reach/router';
import { BackButton } from '../UI/BackButton';
import { DocumentsList } from '../Document/List';
import { UploadButton } from '../Upload/Button';

export const StudentUpdate = ({ view }) => {

    const toast = useToast()
    const navigate = useNavigate()

    const [data, setData] = React.useState();
    const { studentId } = useParams();


    React.useEffect(
        () => {
            getData();
        },
        [studentId]
    )

    const getData = () => {
        axios.get(
            `${config.apiUrl}/student/${studentId}`
        ).then(res => setData(res.data));
    }

    const handleSubmit = (values) => {
        console.log('update student', values)
        axios.put(
            `${config.apiUrl}/student/${studentId}`,
            { ...values }
        ).then(() => toast({
            title: 'Student update',
            description: `The student “${values.firstName} ${values.lastName}” has been updated in database.`,
            status: 'success',
            duration: 9000,
            isClosable: true,
        }));
    }

    const handleDelete = () => {
        axios.delete(
            `${config.apiUrl}/student/${studentId}`
        ).then(() => {
            toast({
                title: 'Student update',
                description: `The student has been deleted in database.`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            navigate('/admin/student/');
        }
        );
    }

    return (
        <Wrapper>
            <Box
                pb={10}
            >
                <BackButton label={`Back`} />
            </Box>
            {/*
            <FormLabel>Profile picture</FormLabel>
             <HStack>
                <ProfilPict
                    filePath={data && data.profilPict ? data.profilPict.document : null}
                    key={Date.now()}
                />
                <ProfilPictWizard
                    userId={data && data._id ? data._id : null}
                    document={data && data.profilPict ? data.profilPict.document : null}
                    pictProcessedCallback={() => getData()}
                />
            </HStack>
             <Box
                py={10}
            >
                <Divider />

            </Box>
            */}


            {data ?
                <Formik
                    key={data}
                    // onSubmit={(values) => handleSubmit(values)}
                    onSubmit={handleSubmit}
                    initialValues={{
                        ...data,
                        birth: {
                            date: data.birth.date ? data.birth.date.slice(0, 10) : ``,
                            city: data.birth.city,
                            country: data.birth.country
                        },
                        highschoolDiploma: {
                            date: data.highschoolDiploma.date ? data.highschoolDiploma.date.slice(0, 10) : ``,
                            status: data.highschoolDiploma.status ? 1 : 0,
                            document: data.highschoolDiploma.document
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form>
                            <Stack spacing={8}>
                                {/* <Debug data={values} /> */}
                                <Grid
                                    templateColumns={`20% 40% 1fr`}
                                    gap={2}
                                >

                                    <Field name='code'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='Code'
                                            >
                                                <Input
                                                    {...field}
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    <Field name='firstName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='Firstname'
                                            >
                                                <Input
                                                    {...field}
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                    <Field name='lastName'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='Lastname'
                                            >
                                                <Input
                                                    {...field}
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>
                                </Grid>

                                <Grid
                                    templateColumns={`20% 40% 1fr`}
                                    gap={2}
                                >

                                    <Field name='birth.date'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='Birthdate'
                                            >
                                                <Input
                                                    {...field}
                                                    type='date'
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    <Field name='birth.city'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='City'
                                            >
                                                <Input
                                                    {...field}
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    <Field name='birth.country'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={true}
                                                title='Country'
                                            >
                                                <Input
                                                    {...field}
                                                    bgColor='white'
                                                />
                                            </FieldBlock>
                                        )}
                                    </Field>

                                </Grid>

                                <Grid
                                    templateColumns={`1fr 1fr 1fr`}
                                    gap={2}
                                >

                                    <Field name='highschoolDiploma.status'>
                                        {({ field, form, meta }) => (
                                            <FieldBlock
                                                isInvalid={meta.error && meta.touched}
                                                error={meta.error}
                                                isRequired={false}
                                                title='High school diploma status'
                                            >
                                                <Select
                                                    placeholder='Select'
                                                    {...field}
                                                >
                                                    <option value={1}>Obtained</option>
                                                    <option value={0}>Not obtained</option>
                                                </Select>
                                            </FieldBlock>
                                        )}
                                    </Field>

                                    {values.highschoolDiploma.status == 1 ?
                                        <>
                                            <Field name='highschoolDiploma.date'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={false}
                                                        title='High school diploma date'
                                                    >
                                                        <Input
                                                            {...field}
                                                            type='date'
                                                            bgColor='white'
                                                        />
                                                    </FieldBlock>
                                                )}
                                            </Field>

                                            <Field name='highschoolDiploma.document'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        title='High school diploma document'
                                                    >
                                                        <Select
                                                            {...field}
                                                            placeholder='Select'
                                                        >
                                                            {data.documents.map(item =>
                                                                <option
                                                                    key={item.Key}
                                                                    value={item.Key}
                                                                >
                                                                    {item.Key.slice(32, 1000)}
                                                                </option>
                                                            )}
                                                        </Select>
                                                        {/* <Input
                                            {...field}
                                            type='date'
                                            bgColor='white'
                                        /> */}
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                        </>
                                        : null
                                    }
                                </Grid>

                                <Divider />


                                <Field name='comment'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                            title='Comment'
                                        >
                                            <Textarea
                                                {...field}
                                            />
                                        </FieldBlock>
                                    )}
                                </Field>

                                <Box>
                                    <Button
                                        type='submit'
                                    >
                                        Update
                                    </Button>
                                </Box>


                            </Stack>

                        </Form>
                    )}

                </Formik>
                : null}
            <Stack spacing={8} mt={8}>

                <Box w='full'>
                    {data && data.documents ?
                        <DocumentsList
                            data={data.documents}
                            deleteCallback={() => getData()}
                            deleteAllowed={view === 'admin'}
                        />
                        : null}
                </Box>
                <UploadButton
                    uploadCallback={() => getData()}
                    linkedDocumentId={studentId}
                />
                <DeleteButton
                    message={`Delete this student`}
                    handleClick={() => handleDelete()}
                />
            </Stack>
            {/* <Debug data={data} /> */}
        </Wrapper >
    )
}