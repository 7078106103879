import { Badge, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

interface statusTagProps {
    children: string,
    label?: boolean
}

export const StatusTag: React.FC<statusTagProps> = ({
    children,
    label
}) => (
    <Flex>
        <Flex
            bgColor='gray.100'
            borderLeftRadius={'xs'}
        >
            {label ?
                <Text
                    fontSize={'xs'}
                    px={1}

                >
                    Status :
                </Text>
                : null}
            <Badge
                textDecor={children === 'archived' ? 'line-through' : 'none'}
                colorScheme={
                    children === 'current' ? 'gray'
                        : children === 'incomplete' ? 'orange'
                            : children === 'complete' ? 'green'
                                : children === 'finalized' ? 'green'
                                    : 'gray'
                }
            >
                {children}
            </Badge>
        </Flex>
    </Flex>

)