import { Button, Center, Divider, Flex, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'

export const DeleteButton = ({ handleClick, message }) => {
    const [isActive, setIsActive] = React.useState(false);
    return (
        <Stack pt={8} spacing={4}>
            <Divider />

            <Text fontSize={'xs'}>
                {message}
            </Text>

            <Flex spacing={0}>
                <Center
                    bgColor='gray.100'
                    borderLeftRadius={'md'}
                    cursor='pointer'
                    px={3}
                    py={1}
                    pr={4}

                    h='32px'
                >
                    <Text

                        fontSize='sm'
                        onClick={() => setIsActive(!isActive)}
                    >
                        Delete :
                    </Text>
                </Center>
                {isActive ?
                    <Button
                        size='sm'
                        colorScheme='red'
                        ml={'-2'}
                        onClick={() => handleClick()}
                    >
                        Delete this entry
                    </Button>
                    : null}
            </Flex>
        </Stack>
    )
}