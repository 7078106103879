import { FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Button, Divider, Flex, Heading, Stack, Text, SimpleGrid } from '@chakra-ui/react'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { config } from '../../Utils/Config'
import FieldBlock from '../FieldBlock'
import { useParams, useNavigate } from 'react-router-dom'
import { ActivationFormSchema } from './ActivationSchema'
import { Link as RouterLink } from 'react-router-dom'
import Debug from '../Debug'
import { PublicLayoutNew } from '../../Components/PublicLayout/new'
// import authService from '../../Auth/auth.service'

export const ActivationForm = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = React.useState();
    let { userId } = params

    React.useEffect(
        () => {
            getData();
        }
        , [userId])

    const getData = () => {
        axios.get(`${config.apiUrl}/user/${userId}`)
            .then(result => setData(result.data));
    }

    return (
        <PublicLayoutNew>
            {data ?
                <Formik
                    initialValues={
                        {
                            ...data,
                            status: `activated`
                        }
                    }
                    validationSchema={ActivationFormSchema}
                    onSubmit={
                        (values) => {
                            axios.put(`${config.apiUrl}/user/update/${params.userId}`, values)
                                .then(result => navigate('./../../login'));
                        }
                    }
                >
                    {({ errors, touched }) => (

                        <Stack
                            as={Form}
                            w='full'
                            mx='auto'
                            spacing={6}
                            py={12}
                        >
                            <Heading
                                color='brand.blue.900'
                                fontSize={{ base: '3xl' }}
                                letterSpacing={'tight'}
                                fontStyle={`italic`}
                            >
                                Complete your profile
                            </Heading>
                            <Text>
                                Then, you will be allowed to access to the application form.
                            </Text>
                            <Divider />
                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='firstName'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                            isRequired={true}
                                        >
                                            <FormLabel>First name</FormLabel>
                                            <Input {...field} bgColor='white' />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='lastName'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                            isRequired={true}
                                        >
                                            <FormLabel>Last name</FormLabel>
                                            <Input {...field} bgColor='white' />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>

                            <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 2 }}>
                                <Field name='password'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                            isRequired={true}
                                        >
                                            <FormLabel>Password</FormLabel>
                                            <Input {...field} type='password' autoComplete={`new-password`} bgColor='white' />
                                        </FieldBlock>
                                    )}
                                </Field>
                                <Field name='passwordConfirmation'>
                                    {({ field, form, meta }) => (
                                        <FieldBlock
                                            isInvalid={meta.error && meta.touched}
                                            error={meta.error}
                                            isRequired={true}
                                        >
                                            <FormLabel>Confirmation</FormLabel>
                                            <Input {...field} type='password' autoComplete={`new-password`} bgColor='white' />
                                        </FieldBlock>
                                    )}
                                </Field>
                            </SimpleGrid>

                            <Divider />

                            <Flex>
                                <Button
                                    rightIcon={<BiRightArrowAlt />}
                                    bg={`brand.blue.900`}
                                    color={`white`}
                                    opacity={.8}
                                    _hover={{ bg: `brand.blue.900`, opacity: 1 }}
                                    type='submit'
                                    isDisabled={
                                        Object.keys(errors).length > 0
                                        || Object.keys(touched).length === 0
                                    }
                                >
                                    Save your profile
                                </Button>
                            </Flex>
                        </Stack>
                    )}
                </Formik>
                : null}
        </PublicLayoutNew>
    )
}