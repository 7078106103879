import * as React from 'react'

import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
} from '@chakra-ui/react'

import { BsSearch } from 'react-icons/bs'
import { RiAddFill, RiArrowRightUpLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Field, Form, Formik, FieldProps } from 'formik'
import Debug from '../Forms/Debug'

export const TableActions = ({ getValues, view }) => {
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values) => console.log(values)}
            validate={(values) => getValues(values)}
        >
            {({ form, values, erros }) => (

                <Stack as={Form} spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <HStack>
                        <Field name='search'>
                            {({ field }) => (
                                <FormControl minW={{ md: '200px' }} id="search">
                                    <InputGroup size="sm">
                                        <FormLabel srOnly>Filter by number or name :</FormLabel>
                                        <InputLeftElement pointerEvents="none" color="gray.400">
                                            <BsSearch />
                                        </InputLeftElement>
                                        <Input {...field} rounded="base" type="search" placeholder="Filter by number or name..." bgColor='white' />
                                    </InputGroup>
                                </FormControl>
                            )}

                        </Field>
                    </HStack>
                    <ButtonGroup size="sm" variant="outline">
                        <Button
                            iconSpacing="1"
                            leftIcon={<RiAddFill fontSize="1.25em" />}
                            as={Link}
                            to={`/${view === `admin` ? `admin` : `account`}/student/import`}
                            bgColor='white'
                        >
                            Import
                        </Button>
                        {/* <Button iconSpacing="1" leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}>
                    Export CSV
                </Button> */}
                    </ButtonGroup>
                    {/* <Debug data={[values]} /> */}
                </Stack>
            )}
        </Formik>
    )
}
