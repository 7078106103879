import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import * as React from "react";
import Debug from "../Forms/Debug";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { config } from "../Utils/Config";
import { BiCheck, BiImport, BiTrash, BiX } from "react-icons/bi";
import { StudentsList } from "./List";
import FieldBlock from "../Forms/FieldBlock";
import { Wrapper } from "../Components/Wrapper";
import { LoadCsvButton } from "./LoadCsvButton";
import { StudentsListPreview } from "./StudentsListPreview";
import { BackButton } from "../UI/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { ProgramsSuggestField } from "../Program/SuggestField";
import { UserContext } from "../Auth/userContext";

export const StudentImport = ({ view }) => {
  const { preProgramId } = useParams();

  const { user } = React.useContext(UserContext);
  const toast = useToast();
  const navigate = useNavigate();

  const handleDelete = () => {
    axios.delete(`${config.apiUrl}/student`);
  };

  const [programs, setPrograms] = React.useState([]);
  const [programId, setProgramId] = React.useState(
    preProgramId ? preProgramId : ""
  );
  const [programData, setProgramData] = React.useState("");
  const [coursePlanId, setCoursePlanId] = React.useState("");

  const [convertEncoding, setConvertEncoding] = React.useState(false);

  React.useEffect(() => {
    getData();
  }, [programId]);

  const getData = async () => {
    // axios.get(`${config.apiUrl}/program/`)
    //     .then(result => setPrograms(result.data));

    if (programId) {
      // axios.get(`${config.apiUrl}/program/${programId}`)
      //     .then(result => setProgramData(result.data));

      const programCoursePlans = await axios.get(
        `${config.apiUrl}/program/${programId}`
      );
      setProgramData(programCoursePlans.data);

      if (programCoursePlans.data.coursePlans.length > 1) {
        // setProgramData(programCoursePlans.data);
      } else {
        setCoursePlanId(programCoursePlans.data.coursePlans[0]._id);
      }
      // .then(result => setProgramData(result.data));
    }
  };

  return (
    <Wrapper>
      <Stack spacing={5}>
        <Flex justify={"space-between"}>
          <BackButton label="Back" />
          {/* <Button onClick={() => handleDelete()} leftIcon={<BiTrash />}>Delete all</Button> */}
        </Flex>
        <Heading>Student registration</Heading>

        {/* <Debug data={programData} /> */}

        {programData ? (
          <FieldBlock title="Program :">
            <Tag>{programData.name}</Tag>
          </FieldBlock>
        ) : null}
        {/* <StudentsList view='admin' /> */}

        {/* <Debug data={programData ? programData : programId} /> */}
        <SimpleGrid columns={2} gap={4}>
          {!preProgramId ? (
            <FieldBlock title="Involved programme" isRequired={true}>
              <ProgramsSuggestField
                // onChange={(programId) => setProgramId(programId)}
                onChange={(programId: string) => setProgramId(programId)}
                schoolId={user.role === "user" ? user.school : null}
                value={programId}
              />
              {/* <Select
                            placeholder='Select program'
                            onChange={(e) => setProgramId(e.target.value)}
                        >
                            {programs.map(item =>
                                <option value={item._id}>{item.localName}</option>
                            )}
                        </Select> */}
            </FieldBlock>
          ) : null}
          {programId && programData ? (
            <FieldBlock title="Course plan" isRequired={true}>
              <Select
                placeholder="Select the course plan"
                onChange={(e) => setCoursePlanId(e.target.value)}
              >
                {programData.coursePlans.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FieldBlock>
          ) : null}
        </SimpleGrid>

        {coursePlanId ? (
          <Formik
            initialValues={{
              program: programId,
              coursePlan: coursePlanId,
            }}
            onSubmit={(values) => {
              // console.log(values);

              const body = new FormData();
              body.append("file", values.file);
              axios.post(`${config.apiUrl}/student/import`, values).then(
                (result) => {
                  toast({
                    title: "Program created.",
                    description: "We've program this program for you.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                  navigate(
                    view === "user"
                      ? "/account/inscription"
                      : "/admin/inscription"
                  );
                },
                (error) => {
                  toast({
                    title: "An error has occured.",
                    description: "Student import could not be performed.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                }
              );
            }}
            validationSchema={yup.object().shape({
              program: yup.string().required(),
              startDate: yup
                .string()
                .matches(/^[0-9]{4}-[0-9]{2}$/, "Unexpected date format")
                .required(),
              endDate: yup
                .string()
                .matches(/^[0-9]{4}-[0-9]{2}$/, "Unexpected date format")
                .required(),
              students: yup
                .array()
                .of(
                  yup.object().shape({
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    birthDate: yup
                      .string()
                      .matches(
                        /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
                      )
                      .required(),
                    birthPlaceCity: yup.string().min(1).required(),
                    birthPlaceCountry: yup.string().min(1).required(),
                  })
                )
                .required(),
              // file: yup.mixed().required(),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => (
              <Form>
                <Stack spacing={5}>
                  {/* <Debug data={values} /> */}
                  <SimpleGrid columns={2} gap={3}>
                    <Field name="startDate">
                      {({ field, meta }) => (
                        <FieldBlock
                          title="Start date"
                          isRequired={true}
                          isInvalid={meta.touched && meta.error}
                          error={meta.error}
                        >
                          <Input {...field} type="month" />
                          <FormHelperText>
                            Expected format YYYY-MM ex : 2021-09
                          </FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>

                    <Field name="endDate">
                      {({ field, meta }) => (
                        <FieldBlock
                          title="End date"
                          isRequired={true}
                          isInvalid={meta.touched && meta.error}
                          error={meta.error}
                        >
                          <Input {...field} type="month" />
                          <FormHelperText>
                            Expected format YYYY-MM ex : 2022-06
                          </FormHelperText>
                        </FieldBlock>
                      )}
                    </Field>
                  </SimpleGrid>

                  {/* <Debug data={values} /> */}

                  {values.startDate && values.endDate ? (
                    <>
                      <Flex justify={"space-between"}>
                        <Heading fontSize={"md"}>
                          CSV file {values.students ? "preview" : null}
                        </Heading>
                        {!values.students || values.students.length === 0 ? (
                          <Flex
                            bgColor={!convertEncoding ? "gray.50" : "green.100"}
                            alignItems={"center"}
                            p={1}
                            px={3}
                            borderRadius={"full"}
                            fontSize={"sm"}
                            cursor="pointer"
                            onClick={() => setConvertEncoding(!convertEncoding)}
                          >
                            Convert encoding
                            <Box ml={1}>
                              {!convertEncoding ? (
                                <BiX size="20px" />
                              ) : (
                                <BiCheck size="20px" />
                              )}
                            </Box>
                          </Flex>
                        ) : null}
                      </Flex>

                      {!values.students || values.students.length === 0 ? (
                        <>
                          <LoadCsvButton
                            handleData={(data) => {
                              setFieldValue("students", data);
                              setFieldTouched("students", true, true);
                            }}
                            convertEncoding={convertEncoding}
                          />
                        </>
                      ) : null}

                      {values.students && values.students.length > 0 ? (
                        <>
                          <StudentsListPreview data={values.students} />
                          <Flex fontSize="sm" justify={"center"}>
                            <Button
                              variant="link"
                              size="sm"
                              leftIcon={<BiTrash />}
                              onClick={() => setFieldValue("students", [])}
                            >
                              Reset students list
                            </Button>
                          </Flex>
                        </>
                      ) : null}
                      {/* <Field name='file'>
                                    {({ field, meta }) => (
                                        <FieldBlock
                                            title='CSV file'
                                        >
                                            <input
                                                id="file"
                                                name="file"
                                                type="file"
                                                onChange={(event) => {
                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                }}
                                                className="form-control"
                                            />
                                        </FieldBlock>
                                    )}
                                </Field> */}
                      <Box>
                        <Button
                          type="submit"
                          leftIcon={<BiImport />}
                          isDisabled={
                            Object.keys(errors).length > 0 ||
                            Object.keys(touched).length === 0 ||
                            values.students.length === 0
                          }
                        >
                          Import
                        </Button>
                      </Box>

                      {touched.students && errors.students ? (
                        <Alert colorScheme={`red`}>
                          <AlertTitle>Some errors in the data</AlertTitle>
                          <AlertDescription>
                            There are some errors that prevent students from
                            being imported.
                          </AlertDescription>
                        </Alert>
                      ) : null}

                      {/* <Debug data={[values, errors, touched]} /> */}
                      <Debug data={[errors]} />
                      <Debug data={[values.students]} />
                    </>
                  ) : null}
                </Stack>
              </Form>
            )}
          </Formik>
        ) : null}
      </Stack>
    </Wrapper>
  );
};
