import { Box, Button, ButtonGroup, Flex, FormControl, FormLabel, Grid, Heading, Input, Select, SimpleGrid, Stack, Table, Tag, Tbody, Td, Tr } from '@chakra-ui/react'
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react'
import { BiSave, BiX } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../Components/Wrapper';
import Debug from '../Forms/Debug';
import { config } from '../Utils/Config';
import { InvoiceDeleteButton } from './InvoiceDeleteButton';
import { InvoiceUploadButton } from './InvoiceUpload';
import { SchoolVerticalNav } from './VerticalNav';
import { periodOptions } from './invoiceOptionsList';

export const InvoiceForm = ({ schoolId, onSave, handleClose }) => {


    // React.useEffect(() => {
    //     getData();
    //     getInvoices();
    // }, []);

    // const getData = () => {
    //     axios.get(`${config.apiUrl}/school/${schoolId}`)
    //         .then(result => setData(result.data));
    // }
    // const getInvoices = () => {
    //     axios.get(`${config.apiUrl}/invoice/school/${schoolId}`)
    //         .then(result => setInvoices(result.data));
    // }

    const saveInvoice = (values) => {
        axios.post(
            `${config.apiUrl}/invoice/`,
            values
        ).then(res => onSave())
    }



    return (
        <Formik
            initialValues={{
                school: schoolId
            }}
            onSubmit={(values, actions) => {
                saveInvoice(values);
                actions.resetForm({
                    values: {
                        title: '',
                        number: '',
                        file: '',
                        status: '',
                        amount: '',
                        issueDate: '',
                        paymentDate: '',
                        periode: ''
                    }
                });
            }}
        >
            {({
                values,
                setFieldValue
            }) => (
                <Form>
                    <Stack
                        spacing={6}
                    >
                        <Debug data={values} />
                        <SimpleGrid
                            columns={2}
                            gap={5}
                        >
                            <Field name="title">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Title</FormLabel>
                                        <Input {...field} />
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="number">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Number</FormLabel>
                                        <Input {...field} />
                                    </FormControl>
                                )}

                            </Field>
                        </SimpleGrid>
                        <SimpleGrid
                            columns={4}
                            gap={5}
                        >


                            <Field name="status">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Status</FormLabel>
                                        <Select
                                            {...field}
                                            placeholder={`Choose a status`}
                                        >
                                            <option>unpaid</option>
                                            <option>paid</option>
                                        </Select>
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="amount">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Amount</FormLabel>
                                        <Input {...field} />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="period">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Period</FormLabel>
                                        <Select placeholder="select" {...field}>
                                            {periodOptions()}
                                        </Select>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="issueDate">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Issue date</FormLabel>
                                        <Input type={`date`} {...field} />
                                    </FormControl>
                                )}

                            </Field>
                            <Field name="paymentDate">
                                {({ field, meta }) => (
                                    <FormControl>
                                        <FormLabel>Payment date</FormLabel>
                                        <Input type={`date`} {...field} />
                                    </FormControl>
                                )}

                            </Field>

                        </SimpleGrid>

                        <Field name="file">
                            {({ field, meta }) => (
                                <FormControl>
                                    <FormLabel>File</FormLabel>
                                    {values.file ?
                                        <InvoiceDeleteButton
                                            deleteCallback={(value) => setFieldValue(field.name, ``)}
                                            value={field.value}
                                            schoolId={schoolId}
                                        />
                                        :
                                        <Flex>
                                            <InvoiceUploadButton
                                                linkedDocumentId={schoolId}
                                                uploadCallback={(value) => setFieldValue(field.name, value)}
                                            />
                                        </Flex>
                                    }
                                </FormControl>
                            )}
                        </Field>

                        <ButtonGroup
                            size='sm'
                        >
                            <Button
                                type={`submit`}
                                colorScheme={`blue`}
                                leftIcon={<BiSave />}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => handleClose()}
                                leftIcon={<BiX />}
                            >
                                Close
                            </Button>
                        </ButtonGroup>

                        <Debug data={values} />
                    </Stack>
                </Form>
            )}

        </Formik>
    )
}