import { Box, Flex, Image, Grid, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import * as React from 'react'
import { DiplomaContext } from './Document'
import { DiplomadQrCode } from './QrCode'
import Stamp from '../../Images/stamps/stamps-purple.png'
import Debug from '../../Forms/Debug'

export const DiplomaFooter = ({ data }) => {
    const baseLeftPadding = 32

    const value = React.useContext(DiplomaContext)
    let hasBg = value

    let formattedDate = dayjs(data.graduationDate).format('MMMM DD YYYY')

    return (
        <Flex
            justify={'space-between'}
            my={`5mm`}
            // ml={``}
            transform={`translate(-10mm)`}
            color={`brand.blue.900`}
        >
            <SimpleGrid
                columns={2}
                gap={`4mm`}
            >
                <Flex
                    alignItems={'flex-end'}
                    justifyContent={`center`}
                >
                    <Box

                    >
                        <Image
                            visibility={value.bg ? 'visible' : 'hidden'}
                            // mt={`10mm`}
                            src={Stamp}
                            alt='Tampon EABHES'
                            bgColor='transparent'
                            w='30mm'
                        />
                    </Box>
                </Flex>
                <Box>
                    <Stack
                        fontSize={`11px`}
                    >
                        <Text
                            fontFamily={'Satoshi'}
                            fontWeight={600}
                        >

                            {data.graduationDate ?
                                <>Paris, {formattedDate}</>
                                : null}
                        </Text>
                        <Text
                            fontFamily={'Georgia'}
                            fontStyle='italic'
                        >
                            The Principal
                        </Text>
                    </Stack>
                </Box>
            </SimpleGrid>

            <Box>
                <DiplomadQrCode w={'20mm'} bg={value.bg} />
            </Box>


        </Flex>

    )
}