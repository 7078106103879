import {
    Box,
    Button,
    Divider,
    Flex,
    FormLabel,
    Text,
    Input,
    Stack,
    SimpleGrid,
    Select,
    Textarea,
    FormHelperText,
    HStack,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    ButtonGroup,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from "@chakra-ui/react"
import axios from "axios"
import { Formik, Form, Field, FieldArray } from "formik"
import * as React from "react"
import { config } from "../../Utils/Config"
import Debug from "../Debug"
import FieldBlock from "../FieldBlock"
import { CourseFormSchema } from "./Schema"
import { useToast } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { courseCode } from "../../Utils/CourseCode"
import { BiArrowToBottom, BiCalculator } from "react-icons/bi"
import { arrayIntRange } from "../../Utils/arrayIntRange"
import { ectsToUsCredits } from "../../Utils/ectsToUsCredits"
import { MarkdownEditor } from "../MarkdownEditor"

var lodash = require('lodash')

export const CourseFormBase = ({
    programId,
    programData,
    data,
    view,
}) => {
    const { userId, courseId } = useParams();
    const navigate = useNavigate();
    const toast = useToast()
    const [programs, setPrograms] = React.useState();

    React.useEffect(() => {
        // getData();
    }, []);

    // const getData = () => {
    //     // axios.get(`${config.apiUrl}/user/${userId}`)
    //     //     .then(result => setData(result.data));

    //     axios.get(`${config.apiUrl}/program/all`)
    //         .then(result => setPrograms(result.data));
    // }

    // const [enableDetails, setEnableDetails] = React.useState(false);

    const semestersList = () => {
        let items = [];
        for (let index = 0; index < parseInt(programData.semestersNumber); index++) {
            items.push(<option>{index + 1}</option>)
        }
        return items
    }

    const wordLength = (value) => {
        if (!value) return `0 word`
        var regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
        var cleanString = value.replace(regex, '')
        var wordLength = cleanString.split(' ').length
        return `${wordLength} ${wordLength === 0 ? '' : wordLength === 1 ? 'word' : 'words'}`
    }

    const CourseStats = ({ values }) => {

        if (!values.semesters) return null

        let cwlSum = lodash.sum(values.semesters.map(item => parseInt(item.hours)));
        let pwlSum = lodash.sum(values.semesters.map(item => parseInt(item.personalWorkLoad)));
        let ectsSum = lodash.sum(values.semesters.map(item => parseFloat(item.ectsCredits)));
        let usSum = lodash.sum(values.semesters.map(item => parseInt(item.usCredits)));

        return (
            <>
                <Text>
                    Total class hours : <strong>{cwlSum}h</strong>
                </Text>
                <Text>
                    Total personal work hours : <strong>{pwlSum}h</strong>
                </Text>
                <Box />
                <Text>
                    Total ECTS credits : <strong>{ectsSum}</strong>
                </Text>
                <Text>
                    Total Us Credits : <strong>{usSum}</strong>
                </Text>
                <Box />
            </>
        )
    }

    const handleDelete = () => {
        axios.delete(
            `${config.apiUrl}/course/${courseId}`
        ).then(
            res => navigate(view === 'user' ? '/account/course' : '/admin/course')
        )
    }

    return (
        programData ?
            <Formik
                initialValues={
                    data ?
                        {
                            ...data,
                        } :
                        {
                            program: programId,
                            lowestNote: programData.lowestNote,
                            highestNote: programData.highestNote,
                            passing: {
                                system: "fixedLimite"
                            }
                        }
                }
                validationSchema={CourseFormSchema}
                onSubmit={(values, { setSubmitting }) => {

                    let enhancedValues = values;

                    delete enhancedValues.hours;
                    delete enhancedValues.personalWorkLoad;
                    delete enhancedValues.ectsCredits;
                    delete enhancedValues.usCredits;

                    console.log(values, enhancedValues)

                    let cwlSum = lodash.sum(values.semesters.map(item => parseInt(item.hours)));
                    let pwlSum = lodash.sum(values.semesters.map(item => parseInt(item.personalWorkLoad)));
                    let ectsSum = lodash.sum(values.semesters.map(item => parseFloat(item.ectsCredits)));
                    let usSum = lodash.sum(values.semesters.map(item => parseInt(item.usCredits)));

                    enhancedValues.hours = cwlSum
                    enhancedValues.personalWorkLoad = pwlSum
                    enhancedValues.ectsCredits = ectsSum
                    enhancedValues.usCredits = usSum

                    if (!courseId) {
                        axios.post(
                            `${config.apiUrl}/course/`,
                            enhancedValues
                        ).then(() => {
                            toast({
                                title: 'Course created.',
                                description: `The course “${values.name}” has been created in database.`,
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            });
                            navigate(view === 'admin' ? `/admin/program/view/${programId}/${programData.primaryCoursePlan}` : `/account/program/view/${programId}/${programData.primaryCoursePlan}`);
                        })
                    } else {
                        axios.put(
                            `${config.apiUrl}/course/${courseId}`,
                            enhancedValues
                        ).then(() => toast({
                            title: 'Course updated.',
                            description: `The course “${values.name}” has been updated in database.`,
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        }))
                    }
                    // navigate('./..');
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    validateForm
                    /* and other goodies */
                }) => (
                    <Stack
                        as={Form}
                        // onSubmit={handleSubmit}
                        spacing={{ base: 2, lg: 22 }}
                    >

                        {/* <Debug data={data} /> */}
                        {/* <Debug data={values} /> */}

                        <Tabs
                            variant='enclosed'
                        >
                            <TabList
                                justifyContent={'flex-end'}
                            >
                                <Tab>Basic information</Tab>
                                <Tab>Syllabus</Tab>
                            </TabList>


                            <TabPanels>
                                <TabPanel>

                                    <Stack spacing={8}>

                                        <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 3 }}>
                                            <Field name='localName'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>Local name</FormLabel>
                                                        <Input
                                                            {...field}
                                                            bgColor='white'
                                                        />
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='name'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>English name</FormLabel>
                                                        <Input
                                                            bgColor='white'
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                // console.log(e.target.value);

                                                                let code = courseCode(
                                                                    e.target.value,
                                                                    values.type,
                                                                    values.startSemester,
                                                                    values.endSemester);

                                                                if (!touched.code) {
                                                                    setFieldValue('code', `${code}`);
                                                                }

                                                                setFieldValue('name', e.target.value)

                                                            }}
                                                        />
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='version'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>Version</FormLabel>
                                                        <Input
                                                            {...field}
                                                            bgColor='white'
                                                        />
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                        </SimpleGrid>

                                        <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 3 }}>
                                            <Field name='type'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>Type</FormLabel>
                                                        <Select
                                                            value={field.value}

                                                            bgColor='white'
                                                            placeholder="Choose please"
                                                            onChange={
                                                                (e) => {
                                                                    setFieldValue(field.name, e.target.value);
                                                                    let code = courseCode(
                                                                        values.name,
                                                                        e.target.value,
                                                                        values.startSemester,
                                                                        values.endSemester);

                                                                    if (!touched.code) {
                                                                        setFieldValue('code', `${code}`);
                                                                    }
                                                                }
                                                            }

                                                        >
                                                            <option>Fundamental</option>
                                                            <option>General</option>
                                                            <option>Practical</option>
                                                            <option>Elective</option>
                                                        </Select>

                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='code'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>Code</FormLabel>
                                                        <Input w='2xs' {...field} />
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='isMandatory'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                        title='Is this course mandatory'
                                                    >
                                                        <Select
                                                            {...field}
                                                            placeholder='select'
                                                        >
                                                            <option value={true}>Yes</option>
                                                            <option value={false}>No</option>
                                                        </Select>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                        </SimpleGrid>
                                        <Divider />
                                        <Field name='deliveryMode'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                    isRequired={true}
                                                    title='Course delivery mode'
                                                >
                                                    <ButtonGroup
                                                        size='sm'
                                                    >
                                                        <Button
                                                            onClick={() => setFieldValue(field.name, 'on-campus')}
                                                            colorScheme={field.value === 'on-campus' ? 'blue' : null}
                                                        >
                                                            On campus
                                                        </Button>
                                                        <Button
                                                            onClick={() => setFieldValue(field.name, 'online')}
                                                            colorScheme={field.value === 'online' ? 'blue' : null}
                                                        >
                                                            Online
                                                        </Button>
                                                        <Button
                                                            onClick={() => setFieldValue(field.name, 'blended')}
                                                            colorScheme={field.value === 'blended' ? 'blue' : null}
                                                        >
                                                            Blended
                                                        </Button>
                                                    </ButtonGroup>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Divider />

                                        <SimpleGrid gap={{ base: 2, lg: 4 }} columns={{ base: 1, lg: 3 }}>

                                            <Field name='startSemester'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>Start semester</FormLabel>
                                                        {/* {programs ? */}
                                                        <Select
                                                            {...field}
                                                            placeholder="Choose please"
                                                            bgColor='white'
                                                            onChange={(e) => {
                                                                setFieldValue('startSemester', e.target.value);
                                                                let code = courseCode(
                                                                    values.name,
                                                                    values.type,
                                                                    e.target.value,
                                                                    values.endSemester);

                                                                if (!touched.code) {
                                                                    setFieldValue('code', `${code}`);
                                                                }

                                                                let courseSemesterDuration = values.endSemester - e.target.value + 1

                                                                if (values.semesters) {
                                                                    setFieldValue('semesters', values.semesters.slice(0, courseSemesterDuration));
                                                                }

                                                            }}
                                                        >
                                                            {semestersList()}
                                                        </Select>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='endSemester'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        isRequired={true}
                                                    >
                                                        <FormLabel>End semester</FormLabel>
                                                        {/* {programs ? */}
                                                        <Select
                                                            {...field}
                                                            placeholder="Choose please"
                                                            bgColor='white'
                                                            onChange={(e) => {
                                                                setFieldValue('endSemester', e.target.value);
                                                                let code = courseCode(
                                                                    values.name,
                                                                    values.type,
                                                                    values.startSemester,
                                                                    e.target.value);

                                                                if (!touched.code) {
                                                                    setFieldValue('code', `${code}`);
                                                                }
                                                                let courseSemesterDuration = e.target.value - values.startSemester + 1

                                                                if (values.semesters && values.semesters.length) {
                                                                    setFieldValue('semesters', values.semesters.slice(0, courseSemesterDuration));
                                                                }

                                                            }}
                                                        >
                                                            {semestersList()}
                                                        </Select>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                        </SimpleGrid>

                                        {values && values.startSemester && values.endSemester ?
                                            <FieldArray
                                                name='semesters'
                                                render={arrayHelpers => (
                                                    arrayIntRange(values.startSemester, values.endSemester).map((semester, index) =>
                                                        <HStack>
                                                            <Text
                                                                mt={8}
                                                                px={1}
                                                                rounded='md'
                                                                whiteSpace={'pre'}
                                                                fontSize={'xs'}
                                                                bgColor='gray.700'
                                                                color='white'
                                                                fontWeight={'bold'}
                                                            >
                                                                Semester {semester}
                                                            </Text>
                                                            <SimpleGrid gap={{ base: 2, lg: 2 }} columns={{ base: 1, lg: 4 }}>
                                                                <Field name={`semesters[${index}].hours`}>

                                                                    {({ field, form, meta }) => (
                                                                        <FieldBlock
                                                                            isInvalid={meta.error && meta.touched}
                                                                            error={meta.error}
                                                                            isRequired={true}
                                                                            title='Class hours'
                                                                            size='sm'
                                                                        >
                                                                            <Input
                                                                                type="number"
                                                                                // {...field}
                                                                                size='sm'
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    setFieldValue(field.name, e.target.value);
                                                                                }}
                                                                            />
                                                                        </FieldBlock>
                                                                    )}
                                                                </Field>
                                                                <Field name={`semesters[${index}].personalWorkLoad`}>
                                                                    {({ field, form, meta }) => (
                                                                        <FieldBlock
                                                                            isInvalid={meta.error && meta.touched}
                                                                            error={meta.error}
                                                                            isRequired={true}
                                                                            title='Personal workload'
                                                                            size='sm'
                                                                        >
                                                                            <Input type="number" {...field} size='sm' />
                                                                        </FieldBlock>
                                                                    )}
                                                                </Field>
                                                                <Field name={`semesters[${index}].ectsCredits`}>
                                                                    {({ field, form, meta }) => (
                                                                        <FieldBlock
                                                                            isInvalid={meta.error && meta.touched}
                                                                            error={meta.error}
                                                                            isRequired={true}
                                                                            title='ECTS'
                                                                            size='sm'
                                                                        >
                                                                            <Input
                                                                                {...field}
                                                                                size='sm'
                                                                            />
                                                                        </FieldBlock>
                                                                    )}
                                                                </Field>
                                                                <Field name={`semesters[${index}].usCredits`}>
                                                                    {({ field, form, meta }) => (
                                                                        <FieldBlock
                                                                            isInvalid={meta.error && meta.touched}
                                                                            error={meta.error}
                                                                            isRequired={true}
                                                                            size='sm'
                                                                        >
                                                                            <Flex
                                                                                justify={'space-between'}
                                                                            >
                                                                                <FormLabel fontSize={'sm'}>
                                                                                    Us credits
                                                                                </FormLabel>
                                                                                <Button
                                                                                    size='xs'
                                                                                    onClick={() => {
                                                                                        setFieldValue(field.name, ectsToUsCredits(values.semesters[index].ectsCredits))
                                                                                        console.log('clickkk')
                                                                                    }}
                                                                                ><BiCalculator /></Button>
                                                                            </Flex>
                                                                            <Input {...field} size='sm' />
                                                                        </FieldBlock>
                                                                    )}
                                                                </Field>
                                                            </SimpleGrid>
                                                        </HStack>
                                                    )
                                                )}
                                            />
                                            : null}

                                        <Divider />

                                        <SimpleGrid columns={3} gap={1}>
                                            <CourseStats values={values} />
                                        </SimpleGrid>

                                        <Divider />


                                        <SimpleGrid
                                            columns={2}
                                            gap={4}
                                        >
                                            <Field name='passing.system'>
                                                {({ field, meta }) => (
                                                    <FieldBlock
                                                        title='Passing system'
                                                    >
                                                        {/* <Debug data={field} /> */}
                                                        <ButtonGroup size='sm' align={'flex-start'}>
                                                            <Button
                                                                colorScheme={
                                                                    field.value === 'fixedLimite' ? 'blue' : 'gray'
                                                                }
                                                                onClick={() => {
                                                                    setFieldValue(field.name, 'fixedLimite')
                                                                    setFieldValue('passing.ratio', null)
                                                                }}
                                                            >Fixed value</Button>
                                                            <Button
                                                                colorScheme={
                                                                    field.value === 'marginToClassAverage' ? 'blue' : 'gray'
                                                                }
                                                                onClick={() => setFieldValue(field.name, 'marginToClassAverage')}
                                                            >Class average margin</Button>
                                                            <Button
                                                                colorScheme={
                                                                    field.value === 'passOrFail' ? 'blue' : 'gray'
                                                                }
                                                                onClick={() => {
                                                                    setFieldValue(field.name, 'passOrFail');
                                                                    setFieldValue('passing.value', 1);
                                                                    setFieldValue('lowestNote', 0);
                                                                    setFieldValue('highestNote', 1);
                                                                }
                                                                }
                                                            >Pass or fail</Button>
                                                        </ButtonGroup>

                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field
                                                name='passing.value'
                                            >
                                                {({ field, meta }) => (
                                                    <FieldBlock
                                                        title='Passing value'
                                                    >
                                                        <Input
                                                            {...field}

                                                            isDisabled={values.passing.system === 'passOrFail'}

                                                            type='number'
                                                            size='sm'
                                                            step='0.01'
                                                        />
                                                        <FormHelperText>
                                                            The cut-off score for obtaining credits. (10 most of the time)
                                                        </FormHelperText>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            {values && values.passing && values.passing.system === 'marginToClassAverage'
                                                ?
                                                <Field
                                                    name='passing.ratio'
                                                >
                                                    {({ field, meta }) => (
                                                        <FieldBlock
                                                            title='Margin to class average'
                                                        >
                                                            <Input
                                                                {...field}
                                                                type='number'
                                                                size='sm'
                                                                step='0.01'
                                                            />
                                                            <FormHelperText>
                                                                The class average ratio required to obtain the credits. (ex: 0.8 for 80%)
                                                            </FormHelperText>
                                                        </FieldBlock>
                                                    )}
                                                </Field>
                                                : null}
                                        </SimpleGrid>

                                        <SimpleGrid columns={3} gap={{ base: 2, lg: 4 }} >
                                            <Field name='lowestNote'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        title='Lowest note'
                                                    >
                                                        <Input {...field} isDisabled={values.passing.system === 'passOrFail'} />
                                                        <FormHelperText>
                                                            The lowest score on the rating scale.
                                                        </FormHelperText>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='highestNote'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        title='Highest note'
                                                    >
                                                        <Input {...field} isDisabled={values.passing.system === 'passOrFail'} />
                                                        <FormHelperText>
                                                            The highest score on the rating scale.
                                                        </FormHelperText>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            <Field name='failingNote'>
                                                {({ field, form, meta }) => (
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                        title='Eliminatory grade'
                                                    >
                                                        <Input {...field} isDisabled={values.passing.system === 'passOrFail'} />
                                                        <FormHelperText>
                                                            The grade under which student will have an F grade.
                                                        </FormHelperText>
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                        </SimpleGrid>

                                        <Field name='description'>
                                            {({ field, form, meta }) => (

                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FormLabel>Course short description</FormLabel>
                                                    <Textarea
                                                        {...field}
                                                        minH='72'
                                                    />
                                                    <Button
                                                        size='sm'
                                                        variant={'link'}
                                                        onClick={() => setFieldValue(field.name, `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut ornare orci. Vestibulum vitae sodales eros, ac posuere mi. Proin non diam nisi. Phasellus ipsum purus, rutrum et luctus eget, laoreet vel ex. Phasellus et erat aliquet, fermentum ante sit amet, efficitur justo. Etiam vel dapibus ex. Nullam ac lacinia ex, ut faucibus est. Pellentesque a risus sagittis, fringilla mauris a, mattis nunc. Fusce iaculis augue in sem rhoncus, a feugiat tortor auctor. Morbi venenatis metus bibendum velit aliquet, ut pharetra nunc molestie. Curabitur arcu erat, dapibus nec porta sit amet, accumsan vel nisl. Maecenas leo ante, convallis nec dignissim a, imperdiet nec orci. Curabitur erat enim, vehicula vel mi et, sodales malesuada quam. Aliquam non convallis nisl. Phasellus arcu erat, tempus sed consequat non, maximus non mauris. Quisque non sem id nisi feugiat venenatis.`)}
                                                    >
                                                        Lipsum...
                                                    </Button>
                                                    <Flex
                                                        justify={'space-between'}
                                                    >
                                                        <FormHelperText>
                                                            Present your programme in 100-200 words (strictly).
                                                        </FormHelperText>
                                                        <FormHelperText>
                                                            {wordLength(field.value)}
                                                        </FormHelperText>
                                                    </Flex>

                                                </FieldBlock>
                                            )}
                                        </Field>
                                    </Stack>
                                </TabPanel>
                                <TabPanel>

                                    <Stack
                                        spacing={{ base: 3, lg: 4 }}
                                    >

                                        {view === 'admin' ?
                                            <Field name='comment'>
                                                {({ field, form, meta }) => (

                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>EABHES comment</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                )}
                                            </Field>
                                            : null}

                                        <Field name='learningOutcomes'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Learning outcomes</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='courseDescription'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Course description</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='teachingMethod'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Teaching method</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='plateformAndTechnology'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Plateform and technologies</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='studentWorkload'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Student workload</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='assessmentMethods'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Assessment methods</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='gradingSystem'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Grading system</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>

                                        <Field name='bibliography'>
                                            {({ field, form, meta }) => (
                                                <FieldBlock
                                                    isInvalid={meta.error && meta.touched}
                                                    error={meta.error}
                                                >
                                                    <FieldBlock
                                                        isInvalid={meta.error && meta.touched}
                                                        error={meta.error}
                                                    >
                                                        <FormLabel>Bibliography & webography</FormLabel>
                                                        {/* <Textarea {...field} /> */}
                                                        <MarkdownEditor
                                                            value={field.value}
                                                            onChange={value => setFieldValue(field.name, value)}
                                                        />
                                                    </FieldBlock>
                                                </FieldBlock>
                                            )}
                                        </Field>





                                        <Divider />
                                    </Stack>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                        <Flex>
                            <Button
                                type='submit'
                                isDisabled={
                                    Object.keys(errors).length !== 0
                                }>
                                Save
                            </Button>
                        </Flex>

                        {view === 'admin' ?
                            <Stack pt={8} spacing={4}>
                                <Divider />
                                <Flex spacing={0}>
                                    <Text bgColor='gray.100' p={1} pr={4} borderLeftRadius={'md'}>Danger zone : </Text>
                                    <Button
                                        size='sm'
                                        colorScheme='red'
                                        ml={'-2'}
                                        onClick={() => handleDelete()}
                                    >Delete course</Button>
                                </Flex>
                            </Stack>
                            : null}

                    </Stack>
                )
                }

            </Formik >
            : null
    )
}