import { Box, Center, Heading, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'
import Debug from '../../Forms/Debug'

export const TitlePage = ({ program, coursePlan }) => {
    return (
        <Box
            pt={`150mm`}
            pl={`80mm`}
            pr={`30mm`}
        >
            {/* <Debug data={program} /> */}
            <VStack
                alignItems={'flex-start'}
                spacing={8}
            >
                <Text
                    color='blue.400'
                    opacity={.8}
                    lineHeight={'10'}
                    fontSize={'2xl'}
                    fontWeight='700'
                    textTransform={'uppercase'}
                >
                    Course Catalogue
                </Text>
                <Box
                    color='blue.800'
                    lineHeight={'10'}

                >
                    <Text
                        fontSize={'4xl'}
                        fontWeight='700'
                    >
                        {program.school.name}
                    </Text>
                    <Text
                        fontSize={'3xl'}
                        fontWeight='700'
                    >
                        {program.school.description}
                    </Text>
                    <Text
                        fontSize={'3xl'}
                        fontWeight='700'
                        color='blue.600'
                    >
                        {program.school.country}
                    </Text>
                </Box>
                <Text
                    color='gray.600'
                >
                    Accreditation Number<br />
                    {
                        program.accreditation
                            && program.accreditation.number
                            ? program.accreditation.number
                            : ''
                    }
                </Text>
            </VStack>

        </Box >
    )
}