import { Box, Heading, Flex } from '@chakra-ui/layout'
import * as React from 'react'

import VisibilitySensor from "react-visibility-sensor";


export const StepTitle = ({ step, setStep, children, isStarted }) => (

    <VisibilitySensor
        partialVisibility={false}
        onChange={() => isStarted ? setStep(step) : null}
    >
        <Box>
            <Heading
                // fontFamily='Source Sans pro'
                fontSize={{ base: 'xl', lg: '2xl' }}
                color='brand.blue.900'
                mb={4}
            >
                {children}
            </Heading>
            <Flex>
                <Box
                    w='60px'
                    h='3px'
                    bgColor='brand.blue.900'
                />
            </Flex>
        </Box>
    </VisibilitySensor>
)