import {
  Box,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { UserProvider } from '../../Auth/userContext'
import { PublicLayout } from '../PublicLayout'
import { PublicLayoutNew } from '../PublicLayout/new'

import { SigninForm } from './SignupForm'
import { Testimonial } from './Testimonial'

export const LoginForm: React.FC = () => {

  const navigate = useNavigate();
  const user = React.useContext(UserProvider);

  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      window.location = user.role === 'user' ? '/account' : '/admin'
      // navigate(user.role === 'user' ? '/account' : '/admin');
    }
  }, [])

  return (
    <PublicLayoutNew>
      <Stack
        maxW={`md`}
        spacing={`4`}
      >

        <Heading
          color={`brand.blue.900`}
          fontSize={{ base: '3xl' }}
          letterSpacing={'tight'}
          fontFamily={`Georgia`}
          fontStyle={`italic`}

        >
          Welcome to EABHES Portal
        </Heading>

        <Text>
          The platform for EABHES members to share information, access resources and more.
        </Text>
        {/* <Text
          fontFamily={`Georgia`}
          fontStyle={`italic`}
        >
          Facilitating International Dialogue for Quality Higher Education


        </Text> */}

        <Divider />
        <SigninForm />
      </Stack>
    </PublicLayoutNew>

  )
}
