import * as React from 'react'

import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
} from '@chakra-ui/react'

import { BsSearch } from 'react-icons/bs'
import { RiAddFill, RiArrowRightDownLine, RiArrowRightUpLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Field, Form, Formik, FieldProps } from 'formik'
import Debug from '../Forms/Debug'

export const TableActions = ({ getValues, programFilters, view }) => {
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values) => console.log(values)}
            validate={(values) => getValues(values)}
        >
            {({ form, values, erros }) => (

                <Stack as={Form} spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <HStack>
                        <Field name='search'>
                            {({ field }) => (
                                <FormControl minW={{ md: '200px' }} id="search">
                                    <InputGroup size="sm">
                                        <FormLabel srOnly>Filter by name</FormLabel>
                                        <InputLeftElement pointerEvents="none" color="gray.400">
                                            <BsSearch />
                                        </InputLeftElement>
                                        <Input {...field} rounded="base" type="search" placeholder="Filter by name..." bgColor='white' />
                                    </InputGroup>
                                </FormControl>
                            )}

                        </Field>
                        <Field name='program'>
                            {({ field }) => (
                                <Select {...field} w={{ base: '300px', md: '200px' }} rounded="base" size="sm" placeholder="All programs">
                                    {programFilters && programFilters.map(item =>
                                        <option key={item._id} value={item._id}>
                                            {`${item.school.acronym ? `${item.school.acronym} - ` : ''} ${item.name}`}
                                        </option>
                                    )}
                                </Select>)}
                        </Field>
                    </HStack>
                    <ButtonGroup size="sm" variant="outline">
                        <Button
                            iconSpacing="1"
                            leftIcon={<RiAddFill fontSize="1.25em" />}
                            as={Link}
                            to={`/${view === 'admin' ? 'admin' : 'account'}/course/new`}
                        >
                            New course
                        </Button>
                        {/* <Button as={Link} to='/admin/course/import' iconSpacing="1" leftIcon={<RiArrowRightDownLine fontSize="1.25em" />}>
                            Mass import CSV
                        </Button> */}
                    </ButtonGroup>
                    {/* <Debug data={[values]} /> */}
                </Stack>
            )}
        </Formik>
    )
}
