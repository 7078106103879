import { Select } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../Auth/userContext';

export const CoursePlansSwitcher = ({
    coursePlans,
    programId,
    coursePlanId,
    size
}) => {
    const navigate = useNavigate();
    const { user } = React.useContext(UserContext);
    return (

        <Select
            placeholder={`Course plan version`}
            w='xs'
            size={size ? size : 'xs'}
            value={coursePlanId}
            onChange={
                (e) => {
                    if (e.target.value) {
                        navigate(`/${user.role === 'admin' ? 'admin' : 'account'}/program/view/${programId}/${e.target.value}`)
                    }
                }
            }
        >
            {coursePlans.map(item =>
                item.status !== 'archived' ?
                    <option value={item._id}>{item.name}</option>
                    : null
            )}
        </Select>
    )
}