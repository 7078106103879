import { Button } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { config } from '../../Utils/Config'

export const CoursePlansDeleteButton = ({
    coursePlanId,
    handleDeleted
}) => (
    <Button
        size='xs'
        onClick={() => axios.delete(
            `${config.apiUrl}/course-plan/${coursePlanId}`
        ).then(res => handleDeleted())}
    >
        Delete
    </Button>
)