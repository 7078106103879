import { Box, Button, Text } from '@chakra-ui/react'
import * as React from 'react'
import { BiArrowBack } from 'react-icons/bi'

interface BackButtonProps {
    label?: string
}

export const BackButton: React.FC<BackButtonProps> = ({ label }) => (
    <Box>
        <Button
            size='sm'
            onClick={() => window.history.back()}
        >
            <BiArrowBack />

            {label ?
                <Text ml={1}>
                    {label}
                </Text>
                : null}
        </Button>
    </Box>
)