import { FormHelperText, Input } from '@chakra-ui/react'
import * as React from 'react'

interface phoneFieldProps {
    value: string,
    name: string,
    setFieldValue: Function,
    setFieldTouched: Function
}



export const PhoneField: React.FC<phoneFieldProps> = ({
    value,
    name,
    setFieldValue,
    setFieldTouched
}) => {

    const cleanValue = (input) => (
        input.replace(/\s+/g, '')
    )

    const addPlus = (input) => (
        input.length >= 1 ?
            input.slice(0, 1) === '+' ? input : `+${input}`
            : ''
    )

    return (
        <>
            <Input
                type={`tel`}
                value={value}
                onChange={(e) => setFieldValue(name, addPlus(cleanValue(e.target.value)))}
                onblur={() => setFieldTouched(name, true, true)}

            />
            <FormHelperText>
                With the international prefix please.
            </FormHelperText>
        </>
    )
}